import { useAppApi } from "api/staff/AppApi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { BookingPage } from "api/staff";
import React, { useEffect } from "react";
import { Input, TextArea, Toggle } from "Tailwind/Input/Controlled";
import { DividerWithLabel, Button } from "Tailwind";

export function BookingForm(props: { onSave: () => void }) {
  const api = useAppApi();

  const { t } = useTranslation("application");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<BookingPage>();

  useEffect(() => {
    api.misc.getBookingSettingsWebshopBookingGet().then((b) => {
      reset(b);
    });
  }, []);

  return (
    <form
      className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
      onSubmit={handleSubmit((data) => {
        api.misc.updateBookingSettingsWebshopBookingPut(data).then(() => {
          props.onSave();
        });
      })}
    >
      <Toggle
        className="sm:col-span-4"
        control={control}
        name={"enabled"}
        label={t("bookingForm.toggleActivate")}
      />
      <TextArea
        rows={8}
        className="col-span-full"
        register={register("description")}
        label={t("bookingForm.textArea.description.label")}
        description={t("bookingForm.textArea.description.description")}
      />
      <TextArea
        rows={8}
        className="col-span-full"
        register={register("terms")}
        label={t("bookingForm.textArea.terms.label")}
        description={t("bookingForm.textArea.terms.description")}
      />
      <DividerWithLabel
        className={"sm:col-span-6 my-5"}
        label={t("bookingForm.divider.timeLimits")}
      />
      <Input
        rightDecoration={t(
          "bookingForm.input.booking_limit_max_days.rightDecoration"
        )}
        className="col-span-1 sm:col-span-3"
        register={register("booking_limit_max_days")}
        errors={errors}
        label={t("bookingForm.input.booking_limit_max_days.label")}
        description={t("bookingForm.input.booking_limit_max_days.description")}
      />
      <Input
        rightDecoration={t(
          "bookingForm.input.booking_limit_min_hours.rightDecoration"
        )}
        className="col-span-1 sm:col-span-3"
        register={register("booking_limit_min_hours")}
        errors={errors}
        label={t("bookingForm.input.booking_limit_min_hours.label")}
        description={t("bookingForm.input.booking_limit_min_hours.description")}
      />

      <Input
        rightDecoration={t(
          "bookingForm.input.cancel_limit_max_hours.rightDecoration"
        )}
        className="col-span-1 sm:col-span-3"
        register={register("cancel_limit_max_hours")}
        errors={errors}
        label={t("bookingForm.input.cancel_limit_max_hours.label")}
        description={t("bookingForm.input.cancel_limit_max_hours.description")}
      />
      <div className="col-span-full flex justify-end">
        <Button type="submit">{t("bookingForm.button.save")}</Button>
      </div>
    </form>
  );
}
