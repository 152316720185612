import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserPlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { AddClientModal } from "./AddClientModal";
import { Client } from "api/staff";
import { Button, DashedEmptyState, FormSection, TextButton } from "Tailwind";

export function ClientSection(props: {
  onClientSelected: (client: Client) => void;
  onClientRemoved: () => void;
  client?: Client;
}) {
  const { t } = useTranslation("application");
  const [addClient, setAddClient] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <FormSection
            title={t("clientSection.formSection.title")}
            subtitle={t("clientSection.formSection.subtitle")}
          />
        </div>
        {props.client ? (
          <div className="col-span-1 sm:col-span-full">
            <ClientCard
              client={props.client}
              onRemove={() => props.onClientRemoved()}
            />
          </div>
        ) : (
          <div className="col-span-6">
            <DashedEmptyState
              text={t("clientSection.emptyState.title")}
              onClick={() => {
                setAddClient(true);
              }}
              accessory={<UserPlusIcon />}
            />
          </div>
        )}
      </div>
      <AddClientModal
        isOpen={addClient}
        onClose={() => {
          setAddClient(false);
        }}
        onAddClient={(c) => {
          setAddClient(false);
          props.onClientSelected(c);
        }}
      />
    </>
  );
}

function ClientCard(props: { client: Client; onRemove: () => void }) {
  const { t } = useTranslation("application");

  return (
    <div
      key={props.client.id}
      className="flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2"
    >
      <div className="min-w-0 flex-1">
        <p className="text-sm font-medium text-gray-900">{props.client.name}</p>
        <p className="truncate text-sm text-gray-500">{props.client.email}</p>
        <p className="truncate text-sm text-gray-500">
          {props.client.phone_number}
        </p>
      </div>
      <TextButton onClick={() => props.onRemove()}>
        <XCircleIcon width={24} />
      </TextButton>
    </div>
  );
}
