export const serviceColors = [
  /* Nephritis */

  "#d4efdf",

  "#7dcea0",

  "#27ae60",

  "#1e8449",

  "#145a32",

  /* Belize Hole */

  "#d4e6f1",

  "#7fb3d5",

  "#2980b9",

  "#1f618d",

  "#154360",

  /* Wisteria */

  "#e8daef",

  "#bb8fce",

  "#8e44ad",

  "#6c3483",

  "#4a235a",

  /* Midnight Blue */

  "#d5d8dc",

  "#808b96",

  "#2c3e50",

  "#212f3d",

  "#17202a",

  /* Orange */

  "#fdebd0",

  "#f8c471",

  "#f39c12",

  "#b9770e",

  "#7e5109",

  /* Pomegranate */

  "#f2d7d5",

  "#d98880",

  "#c0392b",

  "#922b21",

  "#641e16",
];
