import { FormSection } from "Tailwind/Form/FormSection";
import { UserRolePicker } from "../../Users/UserRolePicker";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "api/staff";

export function EmployeeSection({
  employee,
  setEmployee,
}: {
  employee: UserRole;
  setEmployee: (u: UserRole) => void;
}) {
  const { t } = useTranslation("application");

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-6">
        <FormSection
          title={t("employeeSection.formSection.title")}
          subtitle={t("employeeSection.formSection.subtitle")}
        />
      </div>
      <div className="col-span-4">
        <UserRolePicker
          currentUserRole={employee}
          onUserRoleChanged={(userRole) => {
            setEmployee(userRole);
          }}
        />
      </div>
    </div>
  );
}
