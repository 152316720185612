import { useContext } from "react";
import { DefaultService } from "./services/DefaultService";
import { AppContext } from "./AppContext";
import { AppAuth } from "./AppAuth";
import { ClientsService } from "./services/ClientsService";
import { CommunicationService } from "./services/CommunicationService";
import { DashboardService } from "./services/DashboardService";
import { DineroService } from "./services/DineroService";
import { FilesService } from "./services/FilesService";
import { NotificationsService } from "./services/NotificationsService";
import { PigmentsService } from "./services/PigmentsService";
import { ProductsService } from "./services/ProductsService";
import { UsersService } from "./services/UsersService";
import { BookingsService } from "./services/BookingsService";

export function useAppApi() {
  const appContext = useContext(AppContext);

  return {
    misc: DefaultService,
    clients: ClientsService,
    communication: CommunicationService,
    dashboard: DashboardService,
    dinero: DineroService,
    files: FilesService,
    notifications: NotificationsService,
    pigments: PigmentsService,
    products: ProductsService,
    bookings: BookingsService,
    users: UsersService,
    auth: new AppAuth(),
    context: appContext,
    userProfile: appContext.userProfile,
    plan: "Basic",
  };
}
