import React, { ReactNode } from "react";
import { Switch } from "@headlessui/react";

interface ToggleProps {
  error?: string;
  enabled: boolean;
  onChange: (val: boolean) => void;
  label?: string | null | React.ReactNode;
  description?: ReactNode;
}

export function Toggle(props: ToggleProps) {
  const color = props.enabled ? "bg-indigo-500" : "bg-gray-200";
  const position = props.enabled ? "translate-x-5" : "translate-x-0";

  return (
    <div>
      <Switch.Group as="div" className="flex items-center">
        <Switch
          onChange={props.onChange}
          checked={props.enabled}
          className={
            color +
            " relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          }
        >
          <span
            aria-hidden="true"
            className={
              position +
              " pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            }
          />
        </Switch>
        <Switch.Label as="span" className="ml-3 text-sm">
          {props.label}
        </Switch.Label>
      </Switch.Group>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
      {props.error && (
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      )}
    </div>
  );
}
