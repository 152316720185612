import { UserRole } from "api/staff";

export function ServiceEmployeeForm(props: {
  employees: UserRole[];
  selected: UserRole[];
  onEmployeeRemoved(employee: UserRole): void;
  onEmployeeAdded(employee: UserRole): void;
}) {
  return (
    <fieldset>
      <div className="divide-y divide-gray-200 divide-gray-200">
        {props.employees.map((employee, personIdx) => (
          <div key={personIdx} className="relative flex items-start">
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label
                htmlFor={`person-${employee.id}`}
                className="select-none font-medium text-gray-900"
              >
                {employee.user.name}
              </label>
            </div>
            <div className="ml-3 flex h-6 items-center">
              <input
                id={`person-${employee.id}`}
                name={`person-${employee.id}`}
                type="checkbox"
                checked={
                  props.selected.find((e) => e.id === employee.id) !== undefined
                }
                onChange={(e) => {
                  const checked =
                    props.selected.find((e) => e.id === employee.id) !==
                    undefined;
                  if (checked) {
                    props.onEmployeeRemoved(employee);
                  } else {
                    props.onEmployeeAdded(employee);
                  }
                }}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
