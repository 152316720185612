import React, { useEffect, useState } from "react";
import { ClientPageLayout } from "./ClientPageLayout";
import { useLocale } from "../../lib/locale";
import { LoadingScreen } from "../../pages/LoadingScreen";
import { Link } from "react-router-dom";
import { useWebshopApi } from "../../api/webshop/WebshopApi";
import { FullSessionInDb } from "../../api/webshop";

export function BookingsPage() {
  const [sessions, setSessions] = useState<FullSessionInDb[]>();
  const [previousSessions, setPreviousSessions] = useState<FullSessionInDb[]>();
  const [canceledSessions, setCanceledSessions] = useState<FullSessionInDb[]>();
  const [loading, setLoading] = useState(false);

  const api = useWebshopApi();

  useEffect(() => {
    setLoading(true);
    api.api.getClientSessionsSessionsGet().then((s) => {
      const previous = s.filter((s) => s.status === "Completed");
      const upcoming = s.filter((s) => s.status === "Scheduled");
      const canceled = s.filter((s) => s.status === "Canceled");
      setSessions(upcoming);
      setPreviousSessions(previous);
      setCanceledSessions(canceled);
      setLoading(false);
    });
  }, []);

  return (
    <ClientPageLayout index={2}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <main className="py-10">
          <header>
            <div className="max-w-7xl px-4 lg:px-8">
              <h1 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
                Kommende aftaler
              </h1>
            </div>
          </header>
          <div className="overflow-hidden bg-white shadow sm:rounded-md mt-4 mx-4 mm:px-6 lg:mx-8">
            <ul role="list" className="divide-y divide-gray-200">
              {sessions?.map((s, index) => {
                return <ListItem key={index} session={s} />;
              })}
            </ul>
          </div>
          <header className="mt-12">
            <div className="max-w-7xl px-4 lg:px-8">
              <h1 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
                Tidligere aftaler
              </h1>
            </div>
          </header>
          <div className="overflow-hidden bg-white shadow sm:rounded-md mt-4 mx-4 mm:px-6 lg:mx-8">
            <ul role="list" className="divide-y divide-gray-200">
              {previousSessions?.map((s, index) => {
                return <ListItem key={index} session={s} />;
              })}
            </ul>
          </div>
          <header className="mt-12">
            <div className="max-w-7xl px-4 lg:px-8">
              <h1 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
                Aflyste aftaler
              </h1>
            </div>
          </header>
          <div className="overflow-hidden bg-white shadow sm:rounded-md mt-4 mx-4 mm:px-6 lg:mx-8">
            <ul role="list" className="divide-y divide-gray-200">
              {canceledSessions?.map((s, index) => {
                return <ListItem key={index} session={s} />;
              })}
            </ul>
          </div>
        </main>
      )}
    </ClientPageLayout>
  );
}

function ListItem(props: { session: FullSessionInDb }) {
  const locale = useLocale();

  return (
    <li>
      <Link
        to={`/my/booking/${props.session.id}`}
        className="block hover:bg-gray-50"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex text-sm">
                <p className="truncate font-medium text-indigo-600">
                  {props.session.services.length > 0
                    ? props.session.services[0].name
                    : ""}
                </p>
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-sm text-gray-500">
                  <svg
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p>
                    {props.session.start &&
                      locale.localeDateTime(new Date(props.session.start))}{" "}
                    hos {props.session.user_role?.user.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </Link>
    </li>
  );
}
