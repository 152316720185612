import React, { ReactNode, useEffect, useRef, useState } from "react";
import { BusinessHoursHeader } from "./BusinessHoursHeader";
import { BusinessHoursMenu } from "./BusinessHoursMenu";
import { BusinessHoursTimeScale } from "./BusinessHoursTimeScale";
import {
  addZeroes,
  Breakpoints,
  useBreakpoint,
  useCalendarConsts,
  UtilDay,
} from "../../../../../lib/utils";

import { TimeslotForm } from "./TimeslotForm";
import { TimeslotInDb, UserRole } from "api/staff";
import { Modal } from "Tailwind";
import { useAppApi } from "api/staff/AppApi";

export default function BusinessHours(props: { showUserRoles?: boolean }) {
  const { daysOfWeek, hoursOfday, weekdays, dayFromIndex } =
    useCalendarConsts();
  const api = useAppApi();
  const [selected, setSelected] = useState<UtilDay>(weekdays.MONDAY);
  const [timeslots, setTimeslots] = useState<TimeslotInDb[]>([]);
  const [screenSize, setScreenSize] = useState(768);
  const [roleId, setRoleId] = useState<number>(
    api.userProfile?.current_role?.id!
  );
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  let [isOpen, setIsOpen] = useState(false);

  const [currentDayAndTime, setCurrentDayAndTime] = useState<{
    day: UtilDay;
    time: number;
  }>();

  const [currentTimeslot, setCurrentTimeslot] = useState<
    TimeslotInDb | undefined
  >();

  const container = useRef<any>(null);
  const containerNav = useRef<any>(null);
  const containerOffset = useRef<any>(null);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (!roleId) {
      setRoleId(api.userProfile?.current_role?.id!);
    }
  }, [api.userProfile]);

  useEffect(() => {
    if (currentDayAndTime || currentTimeslot) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      if (roleId) {
        api.bookings.getScheduleScheduleGet(roleId).then((t) => {
          setTimeslots(t);
        });
      }
    }
  }, [currentDayAndTime, currentTimeslot]);

  useEffect(() => {
    setScreenSize(breakpoint);
  }, [breakpoint]);

  useEffect(() => {
    if (roleId) {
      api.bookings.getScheduleScheduleGet(roleId).then((t) => {
        setTimeslots(t);
      });
    }
  }, [roleId]);

  useEffect(() => {
    api.users.getAccountUsersAccountUsersGet().then((users) => {
      setUserRoles(users.my_roles);
    });
  }, []);

  function getClickableHoursFull() {
    let hours: ReactNode[] = [];
    let key = 0;
    daysOfWeek.forEach((day, i) => {
      key++;
      hoursOfday.forEach((hour, j) => {
        key++;
        const style = `${2 + hour.value * 12} / span 12`;
        const clss = `relative mt-px flex col-start-${day.value} col-end-${day.value}`;
        hours.push(
          <li key={key} className={clss} style={{ gridRow: style }}>
            <a
              onClick={() => {
                setCurrentDayAndTime({ day: day, time: hour.value });
              }}
              className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-gray-50"
            ></a>
          </li>
        );
      });
    });
    return hours;
  }

  function getClickableHoursDay() {
    let hours: ReactNode[] = [];
    hoursOfday.forEach((hour, i) => {
      const style = `${2 + hour.value * 12} / span 12`;
      const clss = `relative mt-px flex col-start-1 col-end-1`;
      hours.push(
        <li key={i} className={clss} style={{ gridRow: style }}>
          <a
            onClick={() => {
              setCurrentDayAndTime({ day: selected, time: hour.value });
            }}
            className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-gray-50"
          ></a>
        </li>
      );
    });
    return hours;
  }

  function getClickableHours() {
    if (breakpoint >= Breakpoints.md) {
      return getClickableHoursFull();
    } else {
      return getClickableHoursDay();
    }
  }

  return (
    <div className="flex h-full flex-col">
      <BusinessHoursHeader
        title={selected.label}
        userRoles={props.showUserRoles ? userRoles : undefined}
        currentUserRoleId={roleId}
        onUserRoleChanged={(id) => {
          setRoleId(id);
        }}
        onCreateTimeslot={() => {
          setCurrentDayAndTime({ day: selected, time: 8 });
        }}
      />
      <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto bg-white"
      >
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            <BusinessHoursMenu
              selectedDay={selected}
              onDaySelected={(day) => {
                setSelected(day);
              }}
            />
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <BusinessHoursTimeScale>
                <div ref={containerOffset} className="row-end-1 h-7"></div>
              </BusinessHoursTimeScale>
              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}
              >
                {getClickableHours()}
                <RenderTimeslots
                  day={selected.value}
                  timeslots={timeslots}
                  compact={screenSize < Breakpoints.md}
                  onClick={(t) => {
                    setCurrentTimeslot(t);
                  }}
                />
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isOpen}
        setVisible={() => {
          setCurrentTimeslot(undefined);
          setCurrentDayAndTime(undefined);
        }}
        title=""
      >
        <>
          <TimeslotForm
            userRoleId={roleId}
            timeslotId={currentTimeslot?.id}
            currentDay={
              currentTimeslot
                ? dayFromIndex(currentTimeslot.day!)
                : currentDayAndTime
                ? currentDayAndTime.day
                : selected
            }
            onCurrentDayChange={(day) =>
              setCurrentDayAndTime({ day: day, time: currentDayAndTime?.time! })
            }
            startHour={
              currentDayAndTime
                ? currentDayAndTime.time
                : currentTimeslot
                ? currentTimeslot.start_hour
                : 8
            }
            startMinute={currentTimeslot ? currentTimeslot.start_minute : 0}
            endHour={
              currentDayAndTime
                ? currentDayAndTime.time + 8
                : currentTimeslot
                ? currentTimeslot.end_hour
                : 16
            }
            endMinute={currentTimeslot ? currentTimeslot.end_minute : 0}
            onDelete={() => {
              setCurrentTimeslot(undefined);
              setCurrentDayAndTime(undefined);
            }}
            onSave={() => {
              setCurrentTimeslot(undefined);
              setCurrentDayAndTime(undefined);
              api.bookings.getScheduleScheduleGet(roleId).then((t) => {
                setTimeslots(t);
              });
            }}
          />
        </>
      </Modal>
    </div>
  );
}

function RenderTimeslots(props: {
  day: number;
  timeslots: TimeslotInDb[];
  compact: boolean;
  onClick: (timeslot: TimeslotInDb) => void;
}) {
  const tsx = props.timeslots
    .filter((t) => {
      if (props.compact) {
        return t.day === props.day;
      } else {
        return true;
      }
    })
    .map((t, i) => {
      return (
        <RenderTimeslot
          key={i}
          timeslot={t}
          onClick={(ts) => {
            props.onClick(t);
          }}
          full={!props.compact}
        />
      );
    });
  return <>{tsx}</>;
}

function RenderTimeslot(props: {
  timeslot: TimeslotInDb;
  onClick: (t: TimeslotInDb) => void;
  full: boolean;
}) {
  const offset = Math.floor(
    ((props.timeslot.start_hour! * 60 + props.timeslot.start_minute!) / 60) *
      12 +
      2
  );
  const minutes =
    props.timeslot.end_hour! * 60 +
    props.timeslot.end_minute! -
    (props.timeslot.start_hour! * 60 + props.timeslot.start_minute!);
  const span = Math.floor((minutes / 60) * 12);
  const col = props.full ? props.timeslot.day : 1;
  return (
    <li
      className={`relative mt-px flex col-start-${col} col-end-${col}`}
      style={{ gridRow: `${offset} / span ${span}` }}
    >
      <a
        onClick={() => {
          props.onClick(props.timeslot);
        }}
        className="border-2 border-blue-200 group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-100 p-2 text-xs leading-5 hover:bg-blue-200"
      >
        <p className="order-1 font-semibold text-blue-700"></p>
        <p className="text-blue-500 group-hover:text-blue-700 flex flex-row justify-between">
          <time>
            {addZeroes(props.timeslot.start_hour!)}:
            {addZeroes(props.timeslot.start_minute!)}
          </time>
          <time>
            {addZeroes(props.timeslot.end_hour!)}:
            {addZeroes(props.timeslot.end_minute!)}
          </time>
        </p>
      </a>
    </li>
  );
}
