import React from "react";
import { OnboardingContainer } from "./OnboardingContainer";
import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";
import { ActionPanelTopRight, Link } from "Tailwind";
import { ServicesList } from "../Pages/Services/ServicesList";
import { useTranslation } from "react-i18next";

export function ServicesOnboarding() {
  const { t } = useTranslation("application");

  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={""}
          subTitle={""}
          actions={[
            <Link key={1} to={"/onboarding/business-hours"}>
              <span aria-hidden="true">←</span>{" "}
              {t("servicesOnboarding.link.back")}
            </Link>,
            <Link key={1} to={"/onboarding/products"}>
              {t("servicesOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Link>,
          ]}
        >
          <>
            <div className={"my-10"} />
            <ServicesList />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
