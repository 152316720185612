import {
  Button,
  Font,
  List,
  Paginator,
  SlideOver,
  Td,
  TextButton,
} from "Tailwind";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Product, Template } from "../../../../api/staff";
import { useAppApi } from "../../../../api/staff/AppApi";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { TemplateSlideOver } from "./TemplateSlideOver";
import { vi } from "date-fns/locale";

export function TemplatesList() {
  const [data, setData] = useState<Template[]>([]);
  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const [visible, setVisible] = useState(false);
  const [editTemplate, setEditTemplate] = useState<Template | undefined>();

  const { t } = useTranslation("application");
  const api = useAppApi();

  useEffect(() => {}, []);

  function getData() {
    api.misc.searchTemplatesSearchTemplatesGet(term, offset).then((result) => {
      setData(result.results);
      setTotal(result.total);
      setNextOffset(result.offset);
      setPageSize(result.page_size);
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, [term, offset]);

  return (
    <>
      <List
        title={t("templatesList.list.title")}
        subtitle={t("templatesList.list.subtitle")}
        data={data}
        renderLine={(item) => (
          <>
            <Td first>{item.title}</Td>
            <Td>
              <Font size={"xs"} className={"whitespace-pre-wrap"}>
                {item.content.slice(0, 200)}
                {""}
                {item.content.length > 200 && "...."}
              </Font>
            </Td>
            <Td last>
              <TextButton
                onClick={() => {
                  setEditTemplate(item);
                }}
              >
                <div className={"p-2"}>
                  <PencilSquareIcon width={24} />
                </div>
              </TextButton>
            </Td>
          </>
        )}
        headers={[
          t("templatesList.list.headers.title"),
          t("templatesList.list.headers.content"),
          "",
        ]}
        actions={[
          <Button
            onClick={() => {
              setVisible(true);
            }}
          >
            {t("templatesList.button.create")}
          </Button>,
        ]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={setOffset}
        onPrevious={setOffset}
      />
      <TemplateSlideOver
        template={editTemplate}
        open={visible || editTemplate != undefined}
        onClose={() => {
          getData();
          setVisible(false);
          setEditTemplate(undefined);
        }}
      />
    </>
  );
}
