import React, { useState } from "react";
import { File, FullSessionInDb } from "../../../../../api/staff";
import {
  Button,
  FileUploadWidget,
  Font,
  HeadingWithActions,
  Modal,
  SimpleEmptyState,
  TextButton,
} from "Tailwind";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { manipulate_image_file } from "../../../../../lib/utils";
import { useTranslation } from "react-i18next";

export function ImagesSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (updatedSession: FullSessionInDb) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState<File | undefined>();
  const { misc, plan } = useAppApi();
  const { t } = useTranslation("application");

  function uploadImage(file: Blob) {
    setLoading(true);
    misc
      .uploadImageSessionsSessionIdImagesPost(session.id, {
        uploaded_file: file,
      })
      .then((s) => {
        onSessionUpdated(s);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function deleteImage(file: File) {
    const c = window.confirm(t("imageSection.confirm.delete")!);
    if (c) {
      setLoading(true);
      misc
        .deleteImageSessionsSessionIdImagesImageIdDelete(session.id, file.id)
        .then((s) => {
          onSessionUpdated(s);
        })
        .finally(() => {
          setLoading(false);
          setShowImage(undefined);
        });
    }
  }

  return (
    <>
      <div>
        <HeadingWithActions
          title={t("imageSection.heading.title")}
          actions={[]}
        />
        <div className="grid grid-cols-3 gap-1">
          {session.files.map((image) => (
            <ImageTile image={image} onClick={(image) => setShowImage(image)} />
          ))}
        </div>
        {session.files.length > 0 && <div className="mb-4" />}
        <div>
          <FileUploadWidget
            title={t("imageSection.uploadWidget.title")}
            subTitle={t("imageSection.uploadWidget.subtitle")}
            loading={loading}
            onFilesSelected={(file) => {
              uploadImage(file);
            }}
          />
        </div>
      </div>
      <Modal
        visible={!!showImage}
        setVisible={() => {
          setShowImage(undefined);
        }}
      >
        <div className="flex flex-col flex-shrink">
          {showImage && (
            <>
              <img
                src={manipulate_image_file(showImage, [])}
                className="object-cover object-center rounded-md"
              />
              <div className="mt-4 flex justify-end gap-2">
                <Button
                  color="red"
                  onClick={() => {
                    deleteImage(showImage);
                  }}
                >
                  {t("imageSection.button.delete")}
                </Button>
                <Button
                  onClick={() => {
                    setShowImage(undefined);
                  }}
                >
                  {t("imageSection.button.close")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

function ImageTile({
  image,
  onClick,
}: {
  image: File;
  onClick: (image: File) => void;
}) {
  return (
    <img
      onClick={() => {
        onClick(image);
      }}
      src={manipulate_image_file(image, ["w_500"])}
      className="object-cover object-center hover:ring-2 rounded-sm ring-indigo-500 cursor-pointer"
    />
  );
}
