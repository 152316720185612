import { List, Td } from "Tailwind/List";
import {
  ActionCol,
  MultiLineCol,
  SingleLineCol,
  LoadingOverlay,
  Font,
  Paginator,
  Button,
  TextButton,
} from "Tailwind";
import React, { useEffect, useState } from "react";
import { Product } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { ProductSlideOver } from "./ProductSlideOver";
import { Input } from "Tailwind/Input/Uncontrolled";
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../lib/locale";

export function ProductsList() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editProduct, setEditProduct] = useState<Product | undefined>();

  const [data, setData] = useState<Product[]>([]);
  const [term, setTerm] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const api = useAppApi();
  const locale = useLocale();
  const { t } = useTranslation("application");

  useEffect(() => {
    if (editProduct !== undefined) {
      setVisible(true);
    }
  }, [editProduct]);

  useEffect(() => {
    setLoading(true);
    api.products
      .searchProductsSearchProductsGet(term, offset)
      .then((result) => {
        setData(result.results);
        setTotal(result.total);
        setNextOffset(result.offset);
        setPageSize(result.page_size);
        setLoading(false);
      });
  }, [term, offset]);

  useEffect(() => {
    setOffset(0);
  }, [term]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <List
        title={t("productsList.list.title")}
        subtitle={t("productsList.list.subtitle")}
        data={data}
        renderLine={(i: Product) => {
          return (
            <>
              <Td first>
                <div>
                  <div className="font-medium text-gray-900">{i.name}</div>
                  {i.description && (
                    <div className="mt-1 max-w-[300px] font-normal text-gray-500">
                      {i.description.slice(0, 50)}
                      {i.description.length > 50 && "..."}
                    </div>
                  )}
                </div>
              </Td>

              <Td>{locale.currency.format(i.price)}</Td>
              <Td last>
                <TextButton
                  onClick={() => {
                    setEditProduct(i);
                  }}
                >
                  <div className={"p-2"}>
                    <PencilSquareIcon width={24} />
                  </div>
                </TextButton>
              </Td>
            </>
          );
        }}
        headers={[
          t("productsList.headers.description"),
          t("productsList.headers.price"),
          "",
        ]}
        searchWidget={
          <Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"max-w-30"}
            name={t("productsList.input.search")}
            onChange={(v) => {
              setTerm(v.target.value);
            }}
            value={term}
          />
        }
        actions={[
          <Button
            onClick={() => {
              setVisible(true);
            }}
          >
            {t("productsList.button.create")}
          </Button>,
        ]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={setOffset}
        onPrevious={setOffset}
      />
      <ProductSlideOver
        product={editProduct}
        isOpen={visible}
        onClose={() => {
          setVisible(false);
          setEditProduct(undefined);
        }}
        onComplete={() => {
          setVisible(false);
          setEditProduct(undefined);
          api.products
            .searchProductsSearchProductsGet(term, offset)
            .then((result) => {
              setData(result.results);
            });
        }}
      />
    </>
  );
}
