import React from "react";
import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import { ActionPanelTopRight, Link, DividerWithLabel } from "Tailwind";
import { WebsiteForm } from "../Pages/WebsiteSettings/Website/WebsiteForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function WebsiteOnboarding() {
  const { t } = useTranslation("application");
  const navigate = useNavigate();

  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={t("websiteOnboarding.actionPanel.title")}
          subTitle={t("websiteOnboarding.actionPanel.subtitle")}
          actions={[
            <Link key={1} to={"/onboarding/booking"}>
              <span aria-hidden="true">←</span>{" "}
              {t("websiteOnboarding.link.back")}
            </Link>,
            <Link to={"/onboarding/users"}>
              {t("websiteOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Link>,
          ]}
        >
          <>
            <div className={"my-10"}>
              <DividerWithLabel label={t("websiteOnboarding.divider")} />
            </div>
            <WebsiteForm
              onSave={() => {
                navigate("/onboarding/users");
              }}
            />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
