import React, { useContext, useState } from "react";
import { MobileMenuButton } from "../../components/MobileMenuButton";
import { MenuItemMobile } from "../../components/MenuItemMobile";
import { ClientMenu } from "../../components/ClientMenu";
import { DropdownMenu } from "../../components/DropdownMenu";
import { MobileMenu } from "../../components/MobileMenu";
import { Link } from "react-router-dom";
import { useWebshopApi } from "../../api/webshop/WebshopApi";

export function ClientPageLayout(props: {
  index: number;
  children: React.ReactNode;
}) {
  const api = useWebshopApi();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div>
      <DesktopFixedMenu index={props.index} />
      <div className="flex flex-1 flex-col md:pl-64 bg-white">
        <nav className="border-b border-slate-200 bg-white shadow">
          <div className="mx-auto px-4 lg:px-8">
            <div className="flex h-16 justify-between md:justify-end">
              <Link
                to="/"
                className="md:hidden flex flex-shrink-0 items-center"
              >
                <h2 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
                  {api.webshop?.company_name}
                </h2>
              </Link>
              <div className="hidden md:flex">
                <ClientMenu
                  dropdownVisible={dropdownVisible}
                  setDropdownVisible={setDropdownVisible}
                >
                  <DropdownMenu />
                </ClientMenu>
              </div>
              <MobileMenuButton onClick={() => setMenuVisible(!menuVisible)} />
            </div>
          </div>
          {menuVisible && (
            <MobileMenu links={[]}>
              <MenuItemMobile
                text="Ny aftale"
                href="/my/book"
                selected={props.index === 1}
              />
              <MenuItemMobile
                text="Mine aftaler"
                href="/my/bookings"
                selected={props.index === 2}
              />
            </MobileMenu>
          )}
        </nav>
        <div className="mx-auto container bg-white">{props.children}</div>
      </div>
    </div>
  );
}

function LeftMenuItem(props: {
  selected: boolean;
  text: string;
  href: string;
  icon: React.ReactElement;
}) {
  const style = props.selected
    ? "bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md";

  return (
    <Link to={props.href} className={style}>
      {props.icon}
      {props.text}
    </Link>
  );
}

function DesktopFixedMenu(props: { index: number }) {
  const api = useWebshopApi();

  return (
    <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
        <div className="flex flex-shrink-0 items-center px-4">
          <h2 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
            {api.webshop?.company_name}
          </h2>
        </div>
        <div className="mt-5 flex flex-grow flex-col">
          <nav className="flex-1 space-y-1 px-2 pb-4">
            <LeftMenuItem
              text="Ny aftale"
              href="/my/book"
              selected={props.index === 1}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
            />
            <LeftMenuItem
              text="Mine aftaler"
              href="/my/bookings"
              selected={props.index === 2}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>
              }
            />
          </nav>
        </div>
      </div>
    </div>
  );
}
