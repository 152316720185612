import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import da from "./locales/da";
import en from "./locales/en";

export const resources = {
  da,
  en,
};

i18next.use(initReactI18next).init({
  lng: "da", // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
});
