import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function TattooArtist(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/v1696404657/app/production_id_4125980_2160p_qsdv2m.mp4"
      }
    />
  );
}
