/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';
import type { ClientCreate } from '../models/ClientCreate';
import type { FullSessionInDb } from '../models/FullSessionInDb';
import type { NewNote } from '../models/NewNote';
import type { SearchClientResult } from '../models/SearchClientResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientsService {

    /**
     * Update Client
     * @param requestBody
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static updateClientClientsPut(
        requestBody: Client,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/clients',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Client
     * @param requestBody
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static createClientClientsPost(
        requestBody: ClientCreate,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clients',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Clients
     * @param term
     * @param offset
     * @returns SearchClientResult Successful Response
     * @throws ApiError
     */
    public static searchClientsSearchClientsGet(
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchClientResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/clients',
            query: {
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client
     * @param clientId
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static getClientClientClientIdGet(
        clientId: number,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{client_id}',
            path: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Client Note
     * @param clientId
     * @param requestBody
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static createClientNoteClientsClientIdNotesPost(
        clientId: number,
        requestBody: NewNote,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/clients/{client_id}/notes',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Client Note
     * @param clientId
     * @param noteId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createClientNoteClientsClientIdNotesNoteIdDelete(
        clientId: number,
        noteId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/clients/{client_id}/notes/{note_id}',
            path: {
                'client_id': clientId,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sessions
     * @param clientId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static getSessionsClientClientIdSessionsGet(
        clientId: number,
    ): CancelablePromise<Array<FullSessionInDb>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{client_id}/sessions',
            path: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
