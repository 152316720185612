import React from "react";
import { useNavigate } from "react-router-dom";
import { useWebshopApi } from "../api/webshop/WebshopApi";

export function DropdownMenu(props: {
  children?: React.ReactElement | React.ReactElement[];
}) {
  const api = useWebshopApi();
  const navigate = useNavigate();

  return (
    <div
      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
      tabIndex={-1}
    >
      {props.children}
      <button
        onClick={() => {
          api.auth.logout().then(() => {
            navigate("/");
          });
        }}
        className="block px-4 py-2 text-sm text-slate-700"
      >
        Log ud
      </button>
    </div>
  );
}
