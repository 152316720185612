/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AccountConnectionType {
    PRODUCT = 'product',
    SERVICE = 'service',
}
