import React from "react";
import {
  FullSessionInDb,
  SessionProduct,
  SessionService,
} from "../../../api/webshop";

export function Invoice({ session }: { session: FullSessionInDb }) {
  const subtotal_services_incl_vat =
    session.services
      ?.map((s) => s.price)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const subtotal_products_incl_vat =
    session.products
      ?.map((s) => s.price)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const discount_services =
    session.services
      ?.map((s) => s.discount_amount)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const discount_products =
    session.products
      ?.map((s) => s.discount_amount)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const vat_services =
    session.services
      ?.map((s) => s.vat_amount)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const vat_products =
    session.products
      ?.map((s) => s.vat_amount)
      .reduce((prev, cur) => prev + cur, 0) || 0;

  const total =
    subtotal_services_incl_vat +
    subtotal_products_incl_vat -
    discount_services -
    discount_products;

  return (
    <div className={"flex flex-col px-4 py-4 sm:px-6"}>
      {session.services?.map((s, index) => {
        return (
          <div key={index}>
            <InvoiceItem item={s} />
          </div>
        );
      })}
      {session.products?.map((p, index) => {
        return (
          <div key={index}>
            <InvoiceItem item={p} />
          </div>
        );
      })}
      <div className="flex row justify-end mt-4">
        <div className="w-1/2 flex flex-col">
          <div className={"flex flex-row justify-between"}>
            <span className={"text-sm font-normal text-gray-500"}>
              {"Subtotal"}
            </span>
            <span className={"text-sm text text-gray-500"}>
              {total - vat_products - vat_services} kr
            </span>
          </div>
          <div className={"flex flex-row justify-between"}>
            <span className={"text-sm font-normal text-gray-500"}>
              {"Moms"}
            </span>
            <span className={"text-sm text-gray-500"}>
              {vat_products + vat_services} kr
            </span>
          </div>
          <div className={"flex flex-row justify-between"}>
            <span className={"text-sm font-bold text-gray-700"}>{"Total"}</span>
            <span className={"text-sm font-bold text-gray-700"}>
              {total} kr
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function InvoiceItem(props: { item: SessionProduct | SessionService }) {
  return (
    <div className={"flex-1 flex-col justify-between border-b border-gray-300"}>
      <div className={"flex flex-col py-4"}>
        <span className={"truncate text-sm font-medium text-indigo-600"}>
          {props.item.name}
        </span>
        {props.item.discount != 0 && (
          <span className={"mt-0.5 text-sm font-medium  text-gray-500"}>
            {props.item.discount}% {"Rabat"} / {props.item.discount_amount} kr
          </span>
        )}
        <div className="flex flex-row justify-between">
          <span className={"mt-0.5 text-sm font-medium text-gray-500"}>
            {"Incl"} {props.item.vat}% {"moms"} / {props.item.vat_amount} kr
          </span>
          <span className={"text-right text-sm font-bold text-gray-700"}>
            {props.item.price - props.item.discount_amount} kr.
          </span>
        </div>
      </div>
    </div>
  );
}
