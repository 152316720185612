import React, { useEffect } from "react";
import { Product } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { Button } from "Tailwind/Button";
import { Input, TextArea, Toggle } from "Tailwind/Input/Controlled";
import { FormSection } from "Tailwind/Form/FormSection";
import { useForm } from "react-hook-form";
import { SlideOver } from "Tailwind/Overlays/SlideOver";
import { useTranslation } from "react-i18next";

export function ProductSlideOver({
  onComplete,
  isOpen,
  onClose,
  product,
}: {
  onComplete: () => void;
  onClose: () => void;
  isOpen: boolean;
  product?: Product;
}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Product>({
    defaultValues: product
      ? product
      : {
          vat: 25,
          price: 0,
          description: "",
          name: "",
        },
  });

  useEffect(() => {
    if (product !== undefined) {
      reset(product);
    } else {
      reset({
        vat: 25,
        description: "",
        name: "",
        price: 0,
      });
    }
  }, [product]);

  const api = useAppApi();
  const { t } = useTranslation("application");

  function submitData(data: Product) {
    if (product) {
      api.products
        .updateProductProductsProductIdPut(product.id, data)
        .then((s) => {
          onComplete();
        });
    } else {
      api.products.createProductProductsPost(data).then((service) => {
        onComplete();
      });
    }
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title={
        product
          ? t("productForm.slideOver.edit.title")
          : t("productForm.slideOver.create.title")
      }
      actions={[
        <>
          {product && (
            <Button
              color="red"
              onClick={() => {
                const confirm = window.confirm(
                  t("productForm.confirm.delete")!
                );
                if (confirm) {
                  // TODO delete product backend
                }
              }}
            >
              {t("productForm.button.delete")}
            </Button>
          )}
        </>,
        <Button color="secondary" onClick={() => onComplete()}>
          {t("productForm.button.cancel")}
        </Button>,
        <Button type="submit" onClick={handleSubmit(submitData)}>
          {t("productForm.button.save")}
        </Button>,
      ]}
    >
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <FormSection subtitle={t("productForm.formSection")} />
        </div>
        <div className="col-span-full">
          <Input
            label={t("productForm.input.name.label")}
            type="text"
            errors={errors}
            register={register("name")}
            description={t("productForm.input.name.description")}
          />
        </div>
        <div className="col-span-full">
          <TextArea
            label={t("productForm.input.description.label")}
            errors={errors}
            register={register("description")}
            description={t("productForm.input.description.description")}
            rows={6}
          />
        </div>
        <div className="col-span-full sm:col-span-3">
          <Input
            label={t("productForm.input.price.label")}
            register={register("price")}
            errors={errors}
            leftDecoration={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>
            }
            rightDecoration={api.userProfile?.current_role?.account.currency}
          />
        </div>
        <div className="col-span-full sm:col-span-3">
          <Input
            register={register("vat", { min: 0, max: 100 })}
            errors={errors}
            label={t("productForm.input.vat.label")}
            rightDecoration={"%"}
          />
        </div>
      </div>
    </SlideOver>
  );
}
