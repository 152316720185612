import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { File, UserProfile } from "../api/staff";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

export function useUtils() {
  function capitalizeSentence(sentence: string): string {
    const words = sentence.split(" ");
    return words
      .map((element) => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      })
      .join(" ");
  }

  return {
    capitalizeSentence: capitalizeSentence,
  };
}

export function isValidEmail(email: string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryToken(redirectUrl?: string): string {
  const q = useQuery();
  const navigate = useNavigate();

  return React.useMemo(() => {
    const token = q.get("token");
    if (!token) {
      navigate(redirectUrl ? redirectUrl : "/signin");
      return "";
    } else {
      return token;
    }
  }, [q]);
}

export const Breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState<number>(Breakpoints.md);
  const resize = () => {
    if (window.innerWidth > Breakpoints.lg) {
      setBreakpoint(Breakpoints.xl);
    } else if (window.innerWidth >= Breakpoints.md) {
      setBreakpoint(Breakpoints.lg);
    } else if (window.innerWidth > Breakpoints.sm) {
      setBreakpoint(Breakpoints.md);
    } else {
      setBreakpoint(Breakpoints.sm);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return breakpoint;
}

export type UtilDay = { value: number; label: string };
export type Days = { [name: string]: UtilDay };

export function useCalendarConsts() {
  const { t } = useTranslation("application");

  const WeekDays: Days = {
    MONDAY: { value: 1, label: t("generic.Monday") },
    TUESDAY: { value: 2, label: t("generic.Tuesday") },
    WEDNESDAY: { value: 3, label: t("generic.Wednesday") },
    THURSDAY: { value: 4, label: t("generic.Thursday") },
    FRIDAY: { value: 5, label: t("generic.Friday") },
    SATURDAY: { value: 6, label: t("generic.Saturday") },
    SUNDAY: { value: 7, label: t("generic.Sunday") },
  };

  const WeekDaysList: UtilDay[] = [
    { value: 1, label: t("generic.Monday") },
    { value: 2, label: t("generic.Tuesday") },
    { value: 3, label: t("generic.Wednesday") },
    { value: 4, label: t("generic.Thursday") },
    { value: 5, label: t("generic.Friday") },
    { value: 6, label: t("generic.Saturday") },
    { value: 7, label: t("generic.Sunday") },
  ];

  const hoursOfday = useMemo(() => {
    return [
      { value: 0, label: "00" },
      { value: 1, label: "01" },
      { value: 2, label: "02" },
      { value: 3, label: "03" },
      { value: 4, label: "04" },
      { value: 5, label: "05" },
      { value: 6, label: "06" },
      { value: 7, label: "07" },
      { value: 8, label: "08" },
      { value: 9, label: "09" },
      { value: 10, label: "10" },
      { value: 11, label: "11" },
      { value: 12, label: "12" },
      { value: 13, label: "13" },
      { value: 14, label: "14" },
      { value: 15, label: "15" },
      { value: 16, label: "16" },
      { value: 17, label: "17" },
      { value: 18, label: "18" },
      { value: 19, label: "19" },
      { value: 20, label: "20" },
      { value: 21, label: "21" },
      { value: 22, label: "22" },
      { value: 23, label: "23" },
    ];
  }, []);

  function dayFromIndex(i: number) {
    if (i === 1) {
      return WeekDays["MONDAY"];
    } else if (i === 2) {
      return WeekDays["TUESDAY"];
    } else if (i === 3) {
      return WeekDays["WEDNESDAY"];
    } else if (i === 4) {
      return WeekDays["THURSDAY"];
    } else if (i === 5) {
      return WeekDays["FRIDAY"];
    } else if (i === 6) {
      return WeekDays["SATURDAY"];
    } else if (i === 7) {
      return WeekDays["SUNDAY"];
    } else {
      return WeekDays["MONDAY"];
    }
  }

  return {
    weekdays: WeekDays,
    weekdaysList: WeekDaysList,
    daysOfWeek: Object.values(WeekDays),
    hoursOfday: hoursOfday,
    dayFromIndex: dayFromIndex,
  };
}

export function addZeroes(n: number) {
  return n < 10 ? `0${n}` : n.toString(10);
}

export function manipulate_image_file(url: File, modes: string[]): string {
  const parts = url.url.split("upload");
  return parts[0] + "upload/" + modes.join(",") + "/" + url.public_id;
}

export function manipulate_image_url(url: string, modes: string[]): string {
  const parts = url.split("upload");
  return parts[0] + "upload/" + modes.join(",") + parts[1];
}

export function validatePhone(phoneNumber?: string) {
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  return phoneNumber && phoneNumber.match(phoneRegex);
}
