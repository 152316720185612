import React, { useState } from "react";
import { manipulate_image_url } from "../../lib/utils";
import { Settings } from "../../lib/settings";
import { useWwwApi } from "../../api/www/WwwApi";
import { Ink1 } from "../../video/backgrounds/Ink1";

export function FakeDoor() {
  const [email, setEmail] = useState("");
  const [completedSignup, setCompletedSignup] = useState(false);
  const api = useWwwApi();

  const onSubmit = (e: any) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (email === "") {
      return;
    }

    api.api
      .signUpMarketingPreLaunchSignupPost({
        email: email,
      })
      .then(() => {
        setCompletedSignup(true);
      })
      .finally(() => {
        setCompletedSignup(true);
      });
  };

  return (
    <div className={"flex min-h-full justify-center items-center"}>
      <Ink1 show={true} />
      <div className={"relative"}>
        <div className="w-full justify-center sm:flex hidden">
          <img
            className="h-auto mb-10 rounded-lg"
            src={manipulate_image_url(Settings.textLogoDark, ["w_400"])}
            alt=""
          />
        </div>
        <div className="w-full justify-center sm:hidden flex">
          <img
            className="h-auto mb-10 rounded-lg"
            src={manipulate_image_url(Settings.textLogoDark, ["w_250"])}
            alt=""
          />
        </div>
        <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
          <span className="block text-white">Få besked, når vi lancerer</span>
        </h1>
        <p className="mx-auto mt-6 max-w-lg text-center text-xl text-gray-300 sm:max-w-3xl">
          En komplet digital løsning til kræsne professionelle. Meld dig til og
          få et gratis bookingsystem som har fuld fokus på at gøre alt det
          kedelige nemt og hurtigt klaret
        </p>
        {completedSignup && (
          <div className="mt-10 rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800">
                  Tusind tak! Vi skriver til dig så snart platformen er klar!
                  🚀👏
                </p>
              </div>
            </div>
          </div>
        )}
        {!completedSignup && (
          <form className="mx-2 mt-10 flex gap-x-4" onSubmit={onSubmit}>
            <label htmlFor="email-address" className="sr-only">
              Email adresse
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
              className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              placeholder="Indtast din email"
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-gradient-to-r from-indigo-600 to-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Tilmeld
            </button>
          </form>
        )}
      </div>
    </div>
  );
}
