/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountCreate } from '../models/AccountCreate';
import type { AccountCreationResult } from '../models/AccountCreationResult';
import type { AccountDraftFromCvr } from '../models/AccountDraftFromCvr';
import type { Body_login_token_post } from '../models/Body_login_token_post';
import type { CompleteSignupRequest } from '../models/CompleteSignupRequest';
import type { CreateUserInvitation } from '../models/CreateUserInvitation';
import type { InvitationResponse } from '../models/InvitationResponse';
import type { MyUserRoles } from '../models/MyUserRoles';
import type { OptResponse } from '../models/OptResponse';
import type { Password } from '../models/Password';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';
import type { UserProfile } from '../models/UserProfile';
import type { UserRoleData } from '../models/UserRoleData';
import type { UserRoles } from '../models/UserRoles';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Login
     * @param formData
     * @param channel
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static loginTokenPost(
        formData: Body_login_token_post,
        channel: string = 'password',
    ): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token',
            query: {
                'channel': channel,
            },
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Whoami
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static whoamiUsersWhoamiGet(): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/whoami',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * My User Roles
     * @returns MyUserRoles Successful Response
     * @throws ApiError
     */
    public static myUserRolesUsersMyRolesGet(): CancelablePromise<MyUserRoles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/my-roles',
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutLogoutDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/logout',
        });
    }

    /**
     * Get Account
     * @returns Account Successful Response
     * @throws ApiError
     */
    public static getAccountAccountGet(): CancelablePromise<Account> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account',
        });
    }

    /**
     * Create Account
     * @param requestBody
     * @returns AccountCreationResult Successful Response
     * @throws ApiError
     */
    public static createAccountAccountPost(
        requestBody: AccountCreate,
    ): CancelablePromise<AccountCreationResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Account
     * @param confirm
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAccountAccountDelete(
        confirm: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/account',
            query: {
                'confirm': confirm,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get My Invitations
     * @param token
     * @returns InvitationResponse Successful Response
     * @throws ApiError
     */
    public static getMyInvitationsAccountMyInvitationsGet(
        token: string,
    ): CancelablePromise<InvitationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/my-invitations',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Respond To Invitation
     * @param invitationId
     * @param accept
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static respondToInvitationAccountMyInvitationsInvitationIdGet(
        invitationId: number,
        accept: boolean,
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/my-invitations/{invitation_id}',
            path: {
                'invitation_id': invitationId,
            },
            query: {
                'accept': accept,
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Signup
     * @param token
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static completeSignupAccountCompleteSignupPost(
        token: string,
        requestBody: CompleteSignupRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/complete-signup',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User Role
     * @param userRoleId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateUserRoleAccountUserRoleUserRoleIdPut(
        userRoleId: number,
        requestBody: UserRoleData,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/account/user-role/{user_role_id}',
            path: {
                'user_role_id': userRoleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invite User
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static inviteUserAccountInviteUserPost(
        requestBody: CreateUserInvitation,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/invite-user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete User Invite
     * @param email
     * @param role
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteUserInviteAccountInviteUserDelete(
        email: string,
        role: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/account/invite-user',
            query: {
                'email': email,
                'role': role,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Account Users
     * @returns MyUserRoles Successful Response
     * @throws ApiError
     */
    public static getAccountUsersAccountUsersGet(): CancelablePromise<MyUserRoles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/users',
        });
    }

    /**
     * Switch Role
     * @param userRoleId
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static switchRoleSwitchRoleGet(
        userRoleId: number,
    ): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/switch-role',
            query: {
                'user_role_id': userRoleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Default Role
     * @param userRoleId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setDefaultRoleDefaultRolePut(
        userRoleId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/default-role',
            query: {
                'user_role_id': userRoleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Exit Role
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static exitRoleExitRoleGet(): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/exit-role',
        });
    }

    /**
     * Opt
     * @param phoneNumber
     * @returns OptResponse Successful Response
     * @throws ApiError
     */
    public static optOtpGet(
        phoneNumber: string,
    ): CancelablePromise<OptResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/otp',
            query: {
                'phone_number': phoneNumber,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Opt
     * @param email
     * @returns OptResponse Successful Response
     * @throws ApiError
     */
    public static optOtpEmailGet(
        email: string,
    ): CancelablePromise<OptResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/otp/email',
            query: {
                'email': email,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update User
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public static updateUserUsersPut(
        requestBody: UserUpdate,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User
     * @param requestBody
     * @returns UserProfile Successful Response
     * @throws ApiError
     */
    public static createUserUsersPost(
        requestBody: UserCreate,
    ): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetPasswordUsersRequestResetPasswordPost(
        requestBody: PasswordResetRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/request-reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Passwords
     * @param token
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetPasswordsUsersResetPasswordPost(
        token: string,
        requestBody: Password,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/reset-password',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User Role
     * @param userId
     * @param role
     * @param color
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createUserRoleAccountUserRolesPost(
        userId: number,
        role: UserRoles,
        color: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/user_roles',
            query: {
                'user_id': userId,
                'role': role,
                'color': color,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Cvr
     * @param cvrNumber
     * @returns AccountDraftFromCvr Successful Response
     * @throws ApiError
     */
    public static getCvrAccountCvrGet(
        cvrNumber: string,
    ): CancelablePromise<AccountDraftFromCvr> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/cvr',
            query: {
                'cvr_number': cvrNumber,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
