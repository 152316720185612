import { useDraggable } from "@dnd-kit/core";
import { addZeroes } from "../../../../../lib/utils";
import { CalendarEvent } from "./WeekCalendar";
import { Font } from "Tailwind";

export function WeekCalendarSession(props: {
  event: CalendarEvent;
  index: number;
  col: number;
  onClick: () => void;
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `${props.index}`,
  });
  /* See https://docs.dndkit.com/introduction/getting-started */
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const begins = Math.floor(
    ((props.event.start.getHours() * 60 + props.event.start.getMinutes()) /
      60) *
      12 +
      2
  );

  const minutes =
    props.event.end.getHours() * 60 +
    props.event.end.getMinutes() -
    (props.event.start.getHours() * 60 + props.event.start.getMinutes());

  const duration = Math.max(Math.floor((minutes / 60) * 12), 0);

  const width =
    props.event.leftOffset + props.event.width !== 1
      ? props.event.width
      : props.event.width - 0.05;

  return (
    <li
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={`relative mt-px flex col-start-${props.col} col-end-${props.col}`}
      style={{
        gridRow: `${begins} / span ${duration}`,
        left: `calc((100% - 0px) * ${props.event.leftOffset} + 0px)`,
        width: `calc((100% - 0px) * ${width})`,
        ...style,
      }}
    >
      <button
        style={{
          borderColor: `${props.event.event.user_role.color}50`,
          backgroundColor: `${props.event.event.user_role.color}15`,
        }}
        onMouseDown={() => {
          props.onClick();
        }}
        className="group border border-indigo-200 absolute inset-0.5 flex flex-col overflow-y-auto rounded-lg pl-1 pt-1 text-xs leading-5 hover:bg-blue-100"
      >
        <div
          className={
            duration < 16
              ? "flex flex-row gap-1 whitespace-nowrap"
              : "flex flex-col"
          }
        >
          <div className="flex items-center gap-1">
            {duration > 6 &&
              props.event.event.services.map((s) => (
                <div
                  style={{ backgroundColor: s.color }}
                  className="h-2 w-2 rounded-full"
                />
              ))}
            {duration <= 6 &&
              props.event.event.services.map((s) => (
                <div
                  style={{ backgroundColor: s.color }}
                  className="h-1 w-1 rounded-full -my-1"
                />
              ))}
            {duration > 8 && (
              <div>
                <Font
                  size="xs"
                  weight="semibold"
                  className="overflow-hidden truncate text-left"
                >
                  {props.event.event.client?.name}
                </Font>
              </div>
            )}
          </div>
          {duration > 16 && (
            <Font size="xs" className="overflow-hidden text-left">
              {props.event.event.client?.phone_number}
            </Font>
          )}
          {duration > 12 && (
            <div className="flex gap-1">
              <Font
                size="xs"
                className="overflow-hidden whitespace-nowrap text-left"
              >
                {addZeroes(props.event.start.getHours())}:
                {addZeroes(props.event.start.getMinutes())}
              </Font>
              -
              <Font
                size="xs"
                className="overflow-hidden whitespace-nowrap text-left"
              >
                {addZeroes(props.event.end.getHours())}:
                {addZeroes(props.event.end.getMinutes())}
              </Font>
            </div>
          )}
        </div>
      </button>
    </li>
  );
}
