/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_invalid_phone_number___USER_CONTROLLER_invalid_phone_number_____PEM_USER_CONTROLLER_email_already_in_use___USER_CONTROLLER_email_already_in_use____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_invalid_phone_number___USER_CONTROLLER_invalid_phone_number_____PEM_USER_CONTROLLER_email_already_in_use___USER_CONTROLLER_email_already_in_use____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_invalid_phone_number___USER_CONTROLLER_invalid_phone_number_____PEM_USER_CONTROLLER_email_already_in_use___USER_CONTROLLER_email_already_in_use____ {

    export enum detail {
        USER_CONTROLLER_INVALID_PHONE_NUMBER = 'USER_CONTROLLER_invalid_phone_number',
        USER_CONTROLLER_EMAIL_ALREADY_IN_USE = 'USER_CONTROLLER_email_already_in_use',
    }


}

