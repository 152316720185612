import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useWebshopApi } from "../../api/webshop/WebshopApi";
import { useFeedback } from "../../lib/useFeedback";
import { Ink1 } from "../../video/backgrounds/Ink1";
import { useTranslation } from "react-i18next";

export function LoginPage() {
  const navigate = useNavigate();
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  useEffect(() => {
    api.auth.refresh().then((res) => {
      if (res) {
        navigate("/booking");
      }
    });
  }, []);

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-center">
      <Ink1 show={true} />
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white opacity-95 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h2 className="mb-6 text-center text-2xl font-bold tracking-tight text-gray-900">
            {t("loginPage.title")}
          </h2>
          <p className="mb-6 text-center text-base font-medium tracking-tight text-gray-500">
            {t("loginPage.description")}
          </p>
          <LoginForm
            onLoginComplete={() => {
              api.auth.refresh().then((res) => {
                if (res) {
                  navigate("/booking");
                } else {
                  toast.error("Der skete en fejl", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              });
            }}
          />
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md"></div>
      </div>
    </div>
  );
}

export function LoginForm(props: { onLoginComplete: () => void }) {
  const [codeSent, setCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const [loading, setLoading] = useState(false);

  const api = useWebshopApi();
  const feedback = useFeedback();
  const { t } = useTranslation("webshop");

  return (
    <>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            {t("loginform.label.email")}
          </label>
          <div className="mt-1">
            <input
              disabled={codeSent || loading}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              autoComplete="email"
              required
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        {codeSent && (
          <p className="mb-6 text-center text-sm tracking-tight text-gray-500">
            {t("loginform.text.emailSent", { email: email })}
          </p>
        )}
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            {t("loginform.label.opt")}
          </label>
          <div className="mt-1">
            <input
              disabled={!codeSent || loading}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              type="number"
              required
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="mt-12">
        {codeSent ? (
          <Button
            onClick={() => {
              api.auth
                .signIn(email, otp, "sms")
                .then(() => {
                  props.onLoginComplete();
                })
                .catch((err) => {
                  feedback.showErrorToast(t("loginform.error.opt"));
                });
            }}
            disabled={otp.length < 3 || loading}
            text={t("loginform.button.login")}
          />
        ) : (
          <Button
            onClick={() => {
              setLoading(true);
              api.api
                .getOtpOtpGet(email)
                .then((w) => {
                  if (w.exists) {
                    setCodeSent(true);
                  } else {
                    feedback.showErrorToast(
                      t("loginform.error.noUser", {
                        companyName: api.webshop?.company_name,
                      })
                    );
                  }
                })
                .catch((err) => {
                  toast.error(t("loginform.error.unknown"), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setEmail("");
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            disabled={email.length < 3 || loading}
            text={t("loginform.button.sendOtp")}
          />
        )}
      </div>
    </>
  );
}
