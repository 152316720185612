/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_WEBSHOP_STAFF_CONTROLLER_logo_not_found___WEBSHOP_STAFF_CONTROLLER_logo_not_found____ = {
    detail: PS_Literal__PEM_WEBSHOP_STAFF_CONTROLLER_logo_not_found___WEBSHOP_STAFF_CONTROLLER_logo_not_found____.detail;
};

export namespace PS_Literal__PEM_WEBSHOP_STAFF_CONTROLLER_logo_not_found___WEBSHOP_STAFF_CONTROLLER_logo_not_found____ {

    export enum detail {
        WEBSHOP_STAFF_CONTROLLER_LOGO_NOT_FOUND = 'WEBSHOP_STAFF_CONTROLLER_logo_not_found',
    }


}

