import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import {
  ActionPanelTopRight,
  Button,
  Heading,
  HeadingWithActions,
  Link,
  TextButton,
} from "../../../Tailwind";
import { ProtectedPage } from "../ProtectedPage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAppApi } from "../../../api/staff/AppApi";
import { Webshop } from "../../../api/staff";

export function Tour2() {
  const { t } = useTranslation("application");
  const api = useAppApi();
  const navigate = useNavigate();
  const [webshop, setWebshop] = useState<Webshop>();

  useEffect(() => {
    const cachedWebshop = window.localStorage.getItem("WEBSHOP");
    if (cachedWebshop) {
      setWebshop(JSON.parse(cachedWebshop));
    } else {
      api.misc.getWebshopSettingsWebshopGeneralGet().then((s) => {
        window.localStorage.setItem("WEBSHOP", JSON.stringify(s));
        setWebshop(s);
      });
    }
  }, []);

  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight title={""} subTitle={""} actions={[]}>
          <>
            <HeadingWithActions
              title={t("Tour2.heading.title")}
              subtitle={t("Tour2.heading.description")}
              actions={[
                <Link key={1} to={"/onboarding/tour-1"}>
                  <span aria-hidden="true">←</span> {t("Tour2.link.back")}
                </Link>,
                <Button
                  color="branded"
                  onClick={() => {
                    window.localStorage.removeItem("tour");
                    navigate("/");
                  }}
                >
                  {t("Tour2.button.toApp")}
                  <span className="ml-1" aria-hidden="true">
                    →
                  </span>
                </Button>,
              ]}
            />
            <ul role="list" className="mt-8 space-y-8 text-gray-600">
              {webshop && webshop.enabled && (
                <li className="flex flex-col md:flex-row justify-between">
                  <div className="flex gap-x-3 grow w-full max-w-2xl">
                    <CheckCircleIcon
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {t("Tour2.challenge1.title")}
                      </strong>{" "}
                      {t("Tour2.challenge1.description")}
                    </span>
                  </div>
                  <div className="flex flex-col self-end md:self-start">
                    <TextButton
                      onClick={() => {
                        window.open(
                          `https://${webshop?.url}.parlorkit.com/`,
                          "_blank"
                        );
                      }}
                    >
                      {t("Tour2.button.challenge1")}
                    </TextButton>
                  </div>
                </li>
              )}

              <li className="flex flex-col md:flex-row justify-between">
                <div className="flex gap-x-3 grow w-full max-w-2xl">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {t("Tour2.challenge2.title")}
                    </strong>{" "}
                    {t("Tour2.challenge2.description")}
                  </span>
                </div>
                <div className="flex flex-col self-end md:self-start">
                  <TextButton
                    onClick={() => {
                      window.open(`/templates`, "_blank");
                    }}
                  >
                    {t("Tour2.button.challenge2")}
                  </TextButton>
                </div>
              </li>
              <li className="flex flex-col md:flex-row justify-between">
                <div className="flex gap-x-3 grow w-full max-w-2xl">
                  <CheckCircleIcon
                    className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      {t("Tour2.challenge3.title")}
                    </strong>{" "}
                    {t("Tour2.challenge3.description")}
                  </span>
                </div>
                <div className="flex flex-col self-end md:self-start">
                  <TextButton
                    onClick={() => {
                      window.open(`/calendar`, "_blank");
                    }}
                  >
                    {t("Tour2.button.challenge3")}
                  </TextButton>
                </div>
              </li>
            </ul>
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
