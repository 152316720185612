import React, { useEffect, useState } from "react";
import { useQuery, useQueryToken } from "../../lib/utils";
import { PermissionStatuses } from "../../api/webshop";
import { useWebshopApi } from "../../api/webshop/WebshopApi";

export function ClientPreferences() {
  const [permissions, setPermissions] = useState<PermissionStatuses>();

  const token = useQueryToken();
  const api = useWebshopApi();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.api.getPreferencesPreferencesGet(token).then((p) => setPermissions(p));
  }, []);

  return (
    <div className="my-4 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h2
              id="applicant-information-title"
              className="text-lg font-medium leading-6 text-slate-900"
            >
              Email preferencer
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-slate-500">
              Vælg hvilke emails du ønsker at modtage
            </p>
          </div>
          {permissions && (
            <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
              <fieldset className="space-y-5">
                <legend className="sr-only">Information</legend>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      disabled={loading}
                      type="checkbox"
                      checked={permissions.Information}
                      onChange={(c) => {
                        setLoading(true);
                        api.api
                          .updatePermissionPermissionsPut(token, {
                            permission: "Information",
                            granted: !permissions?.Information,
                          })
                          .then((p) => {
                            setPermissions(p);
                            setLoading(false);
                          });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Information
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      Vælg om du vil modtage tips og information fra Parlorkit.
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="candidates"
                      aria-describedby="candidates-description"
                      name="candidates"
                      type="checkbox"
                      disabled={loading}
                      checked={permissions.Promotions}
                      onChange={(c) => {
                        setLoading(true);
                        api.api
                          .updatePermissionPermissionsPut(token, {
                            permission: "Promotions",
                            granted: !permissions?.Promotions,
                          })
                          .then((p) => {
                            setPermissions(p);
                            setLoading(false);
                          });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="candidates"
                      className="font-medium text-gray-700"
                    >
                      Tilbud
                    </label>
                    <p id="candidates-description" className="text-gray-500">
                      Vælg om du vil modtage tilbud fra Parlorkit og partnere.
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="offers"
                      aria-describedby="offers-description"
                      name="offers"
                      type="checkbox"
                      checked={permissions.System}
                      disabled={true}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="offers"
                      className="font-medium text-gray-700"
                    >
                      System
                    </label>
                    <p id="offers-description" className="text-gray-500">
                      Beskeder som sendes af din automatisk eller manuelt fra
                      din behandler. Denne type beskeder kan ikke slås fra da
                      din behandler kan have behov for at sende dig beskeder med
                      vigtig information.
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
