import React, { useEffect, useState } from "react";
import {
  FullSessionInDb,
  SessionStatus,
  Template,
} from "../../../../../api/staff";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../../lib/locale";
import {
  Button,
  Heading,
  HeadingWithActions,
  LinkButton,
  LoadingOverlay,
  Modal,
  SlideOver,
  TextButton,
} from "../../../../../Tailwind";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { TextArea } from "../../../../../Tailwind/Input/Uncontrolled";
import { TemplateSelect } from "../../Templates/TemplateSelect";
import { useFeedback } from "../../../../../lib/useFeedback";
import {
  ArchiveBoxArrowDownIcon,
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { ChatSlideOver } from "../../../../../components/ChatSlideOver";

export function ActionsSection({
  session,
  onSessionUpdated,
  onSessionCanceled,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (session: FullSessionInDb) => void;
  onSessionCanceled: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [sending, setSending] = useState(false);

  const api = useAppApi();
  const { t } = useTranslation("application");
  const feedback = useFeedback();

  const actions =
    session.status === SessionStatus.SCHEDULED
      ? [
          <TextButton
            onClick={() => {
              setCancelVisible(true);
            }}
            color="red600"
          >
            {t("invoiceSection.button.cancelSession")}
          </TextButton>,
        ]
      : [];

  return (
    <>
      <HeadingWithActions
        title={t("actionsSection.heading")}
        actions={actions}
      />

      <div>
        <div className="mt-6">
          {session.status === SessionStatus.SCHEDULED && (
            <div className="flex gap-2">
              <Button
                color="secondary"
                onClick={() => {
                  setMessagesVisible(true);
                }}
              >
                <div className="flex flex-row gap-2">
                  <ChatBubbleLeftIcon className="w-4" />
                  {t("actionsSection.button.messages")}
                </div>
              </Button>
              <Button
                full
                onClick={() => {
                  setVisible(true);
                }}
              >
                {t("invoiceSection.button.completeSession")}
              </Button>
            </div>
          )}
          {session.status === SessionStatus.CANCELED && (
            <div className="flex gap-2">
              <Button
                color="secondary"
                onClick={() => {
                  setMessagesVisible(true);
                }}
              >
                <div className="flex flex-row gap-2">
                  <ChatBubbleLeftIcon className="w-4" />
                  {t("actionsSection.button.messages")}
                </div>
              </Button>
            </div>
          )}
          {session.status === SessionStatus.COMPLETED && (
            <div className="flex flex-row gap-2 items-end">
              <div>
                <Button
                  color="secondary"
                  onClick={() => {
                    setMessagesVisible(true);
                  }}
                >
                  <div className="flex flex-row gap-2">
                    <ChatBubbleLeftIcon className="w-4" />
                    {t("actionsSection.button.messages")}
                  </div>
                </Button>
              </div>
              <div className="flex flex-col">
                <div className="font-light text-xs mb-1 border-b border-indigo-200">
                  {t("actionsSection.group.receipt")}
                </div>
                <div className="flex gap-2 flex-shrink">
                  <Button
                    onClick={() => {
                      // Thanks Gemini
                      const url = `/staff/v1/sessions/${session.id}/receipt.pdf`;

                      fetch(url, {
                        method: "GET",
                        /* @ts-ignore */
                        responseType: "blob", // Crucial for PDF handling
                      })
                        .then((response) => {
                          if (!response.ok) {
                            throw new Error(
                              `Network error: HTTP ${response.status}`
                            );
                          }
                          return response.blob();
                        })
                        .then((pdfBlob) => {
                          // PDF display handling: New tab is a common choice
                          const fileURL = URL.createObjectURL(pdfBlob);
                          window.open(fileURL, "_blank");
                        })
                        .catch((error) => {
                          console.error("Error fetching PDF:", error);
                          // Display a  user-friendly  error message  in  your UI
                        });
                    }}
                  >
                    {t("invoiceSection.button.print")}
                  </Button>
                  <Button
                    disabled={sending}
                    onClick={() => {
                      setSending(true);
                      api.misc
                        .sendSessionReceiptSessionsSessionIdEmailReceiptGet(
                          session.id
                        )
                        .then(() => {})
                        .finally(() => {
                          feedback.showSuccessToast(
                            t("invoiceSection.toast.receiptSent", {
                              email: session.client?.email,
                            })
                          );
                          setSending(false);
                        });
                    }}
                  >
                    {t("invoiceSection.button.send")}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
      >
        {visible && (
          <SessionCompletionApp
            session={session}
            onCancel={() => {
              setVisible(false);
            }}
            onComplete={(session: FullSessionInDb) => {
              setVisible(false);
              onSessionUpdated(session);
            }}
          />
        )}
      </Modal>
      <Modal
        size="md"
        visible={cancelVisible}
        setVisible={() => setCancelVisible(false)}
      >
        <MessageSection session={session} />
        <Button
          full
          color="red"
          className="mt-4"
          onClick={() => {
            api.misc
              .cancelBookingSessionsSessionIdBookDelete(session.id)
              .then((s) => {
                feedback.showSuccessToast(
                  t("invoiceSection.toast.sessionCanceled")
                );
                onSessionCanceled();
              });
          }}
        >
          {t("invoiceSection.button.cancelSession")}
        </Button>
      </Modal>
      <ChatSlideOver
        session={session}
        isOpen={messagesVisible}
        onClose={() => {
          setMessagesVisible(false);
        }}
      ></ChatSlideOver>
    </>
  );
}

function SessionCompletionApp({
  session,
  onComplete,
  onCancel,
}: {
  session: FullSessionInDb;
  onComplete: (session: FullSessionInDb) => void;
  onCancel: () => void;
}) {
  const api = useAppApi();
  const { t } = useTranslation("application");

  function endSession() {
    api.misc.endSessionSessionsSessionIdEndPut(session.id).then((s) => {
      onComplete(s);
    });
  }

  return (
    <>
      <Heading
        title={t("sessionCompletionApp.heading.title")}
        description={t("sessionCompletionApp.heading.description")}
      />
      <MessageSection session={session} />
      <div className="flex gap-2 justify-end mt-4">
        <Button
          onClick={() => {
            endSession();
          }}
        >
          {t("sessionCompletionApp.button.complete")}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            onCancel();
          }}
        >
          {t("sessionCompletionApp.button.cancel")}
        </Button>
      </div>
    </>
  );
}

function MessageSection(props: { session: FullSessionInDb }) {
  const [template, setTemplate] = useState<Template | undefined>();
  const [renderedTemplate, setRenderedTemplate] = useState("");
  const api = useAppApi();
  const { t } = useTranslation("application");
  const feedback = useFeedback();
  const [loading, setLoading] = useState(false);
  const [smsCredits, setSMSCredits] = useState(0);

  useEffect(() => {
    api.communication.getSmsCreditsSmsCreditsGet().then((r) => {
      setSMSCredits(r.available_credits);
    });
  }, []);

  useEffect(() => {
    if (template && template.id) {
      api.misc
        .renderTemplateTemplatesRenderPost(
          template.id,
          props.session.client?.id,
          props.session.id
        )
        .then((r) => {
          setRenderedTemplate(r.rendered_body);
        });
    }
  }, [template]);

  return (
    <div className="my-4">
      <LoadingOverlay loading={loading} />
      <Heading
        title={t("messageSection.heading.title", {
          customer_name: props.session.client?.name,
        })}
        description={t("messageSection.heading.description")}
      />
      <div className="mt-4">
        <div className="mb-4">
          <TemplateSelect
            onSelect={(template) => {
              setTemplate(template);
            }}
            selected={template}
          />
        </div>
        <TextArea
          rows={4}
          value={renderedTemplate}
          name={"rendered"}
          onChange={(t) => {
            setRenderedTemplate(t);
          }}
        />
        <div className="flex flex-col sm:flex-row gap-2 justify-between mt-4">
          <div className="flex flex-col sm:flex-row gap-2">
            <Button
              onClick={() => {
                setLoading(true);
                if (props.session.client) {
                  api.communication
                    .sendClientEmailEmailClientPost({
                      message: renderedTemplate,
                      client_id: props.session.client.id,
                      session_id: props.session.id,
                    })
                    .then((r) => {
                      feedback.showSuccessToast(
                        t("messageSection.feedback.success")
                      );
                      setRenderedTemplate("");
                      setTemplate(undefined);
                    })
                    .finally(() => setLoading(false));
                }
              }}
              disabled={renderedTemplate === "" || loading}
            >
              {t("messageSection.button.sendEmail")}
            </Button>
            <Button
              onClick={() => {
                setLoading(true);
                if (props.session.client) {
                  api.communication
                    .sendClientSmsSmsClientPost({
                      message: renderedTemplate,
                      client_id: props.session.client.id,
                      session_id: props.session.id,
                    })
                    .then((r) => {
                      feedback.showSuccessToast(
                        t("messageSection.feedback.success")
                      );
                      setRenderedTemplate("");
                      setTemplate(undefined);
                    })
                    .finally(() => setLoading(false));
                }
              }}
              disabled={smsCredits === 0 || renderedTemplate === "" || loading}
            >
              {t("messageSection.button.sendSMS")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
