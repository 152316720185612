import { ProfileLayout } from "./ProfileLayout";
import { useAppApi } from "../../../../api/staff/AppApi";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  List,
  Modal,
  Paginator,
  Td,
  TextButton,
} from "../../../../Tailwind";
import { Service } from "../../../../api/staff";
import { manipulate_image_file } from "../../../../lib/utils";
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Input } from "../../../../Tailwind/Input/Uncontrolled";
import { ServiceSlideOver } from "../Services/ServiceSlideOver";
import { t } from "i18next";
import { useLocale } from "../../../../lib/locale";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../../catalyst/dialog";

export function ServicesProfileSection() {
  const [visible, setVisible] = useState(false);
  const [term, setTerm] = useState("");
  const [editService, setEditService] = useState<Service | undefined>();
  const [data, setData] = useState<Service[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const locale = useLocale();
  const api = useAppApi();
  const { t } = useTranslation("application");

  useEffect(() => {
    if (editService !== undefined) {
      setVisible(true);
    }
  }, [editService]);

  useEffect(() => {
    fetchServices();
  }, [term, offset]);

  function fetchServices() {
    api.misc
      .searchServicesSearchServicesGet(
        term,
        offset,
        api.userProfile?.current_role?.id
      )
      .then((s) => {
        setTotal(s.total);
        setNextOffset(s.offset);
        setPageSize(s.page_size);
        setData(s.results);
      });
  }

  return (
    <ProfileLayout active={"services"}>
      <>
        <List
          title={t("ServicesProfileSection.title")}
          subtitle={t("ServicesProfileSection.subtitle")}
          data={data}
          renderLine={(i: Service) => {
            return (
              <>
                <Td first>
                  <div className="flex items-center">
                    {i.files.length > 0 ? (
                      <div className="min-w-[70px]">
                        <img
                          className="h-11 w-11 rounded-md"
                          src={manipulate_image_file(i.files[0], [
                            "h_100",
                            "w_100",
                            "c_thumb",
                          ])}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                    <div>
                      <div className="flex gap-2 font-medium text-gray-900">
                        <div>{i.name}</div>
                        <div
                          style={{ backgroundColor: i.color }}
                          className="h-5 w-5 rounded-full"
                        />
                      </div>
                      {i.description && (
                        <div className="mt-1 max-w-[300px] font-normal text-gray-500">
                          {i.description.slice(0, 50)}
                          {i.description.length > 50 && "..."}
                        </div>
                      )}
                    </div>
                  </div>
                </Td>

                <Td>{locale.currency.format(i.price)}</Td>
                <Td>{i.duration}</Td>
                <Td last>
                  <div className="flex flex-col space-y-1">
                    {i.bookable_by_clients && (
                      <Badge
                        color="green"
                        label={t("servicesList.badge.webBooking.label")}
                      />
                    )}
                    {i.bookable && (
                      <Badge
                        color={"purple"}
                        label={t("servicesList.badge.employeeBooking.label")}
                      />
                    )}
                  </div>
                </Td>
              </>
            );
          }}
          headers={[
            t("servicesList.headers.name"),
            t("servicesList.headers.price"),
            t("servicesList.headers.duration"),
            t("servicesList.headers.booking"),
            "",
          ]}
          searchWidget={
            <Input
              leftDecoration={
                <MagnifyingGlassIcon
                  className="mx-auto h-5 w-5 text-gray-300"
                  aria-hidden="true"
                />
              }
              className={"max-w-30"}
              name={"search"}
              onChange={(v) => {
                setTerm(v.target.value);
              }}
              value={term}
            />
          }
          actions={[]}
        />
        <Paginator
          nextOffset={nextOffset}
          page_size={pageSize}
          total={total}
          onNext={setOffset}
          onPrevious={setOffset}
        />
      </>
    </ProfileLayout>
  );
}
