import React, { Fragment, PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";

export function BackgroundVideo(props: { show: boolean; url: string }) {
  return (
    <Transition appear show={props.show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-300"
        leave="ease-in duration-500"
        leaveFrom="opacity-300"
        leaveTo="opacity-0"
      >
        <div className="fixed top-0 left-0 w-full h-full overflow-hidden bg-gray-900 -z-10">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="absolute top-0 left-0 h-full w-full object-cover"
          >
            <source src={props.url} type="video/mp4" />
          </video>
        </div>
      </Transition.Child>
    </Transition>
  );
}
