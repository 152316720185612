import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProtectedPage } from "../ProtectedPage";
import { Link, Button, CTASimpleCentered, TextButton } from "Tailwind";
import { OnboardingHeader } from "./OnboardingHeader";
import { useNavigate } from "react-router-dom";
import { OnboardingContainer } from "./OnboardingContainer";
import { useAppApi } from "../../../api/staff/AppApi";
import { UserRole } from "../../../api/staff";

export function OnboardingIntro() {
  const [accounts, setAccounts] = useState<UserRole[]>([]);
  const [hasInvitations, setHasInvitations] = useState(false);
  const { t } = useTranslation("application");
  const navigate = useNavigate();
  const api = useAppApi();

  useEffect(() => {
    api.users.myUserRolesUsersMyRolesGet().then((roles) => {
      if (roles.my_roles.length === 0) {
      }
      setAccounts(roles.my_roles);
    });
  }, []);

  return (
    <ProtectedPage>
      <OnboardingHeader />
      <OnboardingContainer>
        <CTASimpleCentered
          title={t("onboardingIntro.cta.title")}
          content={t("onboardingIntro.cta.content")}
          actions={[
            <Button
              onClick={() => {
                navigate("/onboarding/create-account");
              }}
            >
              {t("onboardingIntro.button.continue")}
            </Button>,
            accounts.length > 0 && (
              <TextButton
                onClick={() => {
                  navigate("/select-account");
                }}
              >
                {t("onboardingIntro.link.skip")}{" "}
                <span aria-hidden="true">→</span>
              </TextButton>
            ),
          ]}
        />
      </OnboardingContainer>
    </ProtectedPage>
  );
}
