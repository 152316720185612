import React from "react";

export function Panel(props: { children: React.ReactElement, title: string }) {
    return <div className="bg-white shadow sm:rounded-md h-fit pt-2">
        <div className="border-b border-gray-200 bg-white px-2 pb-2 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{props.title}</h3>
        </div>
        <div>
            {props.children}
        </div>
    </div>
}