import React from "react";

const sizeMap = {
  xs: "h-8 w-8 -left-4 border-4",
  lg: "h-16 w-16 -left-8 border-8",
  xl: "h-24 w-24 -left-12 border-8",
};

export function BrandedSpinner(props: { size?: "xs" | "lg" | "xl" }) {
  const size = props.size ? sizeMap[props.size] : sizeMap["xs"];

  return (
    <div className="relative">
      <div
        className={`absolute top-0 inline-block ${size} animate-spin border-indigo-200 rounded-full border-solid border-current border-r-transparent border-t-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      ></div>
      <div
        className={`absolute top-0 inline-block ${size} animate-spin border-emerald-200 rounded-full border-solid border-current border-l-transparent border-b-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
        role="status"
      />
    </div>
  );
}
