import React, { PropsWithChildren, useState } from "react";
import { Link } from "react-router-dom";
import { LinkButtonProps } from "./LinkButtonProps";
import { color, roundedSizes, sizes } from "./constants";

function getProps(props: LinkButtonProps, extra?: string) {
  const shade = props.dark ? "dark" : "light";
  const s = props.size ? props.size : "lg";
  const clr = props.color
    ? ` ${color[shade][props.color]}`
    : ` ${color[shade]["primary"]}`;
  const e = extra ? ` ${extra}` : "";
  const r = props.rounded ? "rounded-full" : ` ${roundedSizes[s]}`;
  const space = props.leadingIcon || props.trailingIcon ? "gap-x-2" : "";
  return `${clr} ${e} ${sizes[s]} ${r} ${space}`;
}

export function LinkButton({
  className,
  children,
  ...props
}: PropsWithChildren<LinkButtonProps>) {
  const [cls] = useState(`${getProps(props)} ${className}`);

  return (
    <Link {...props} className={cls}>
      {props.leadingIcon}
      {children}
      {props.trailingIcon}
    </Link>
  );
}
