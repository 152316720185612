import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Settings } from "../lib/settings";
import React from "react";
import { Ink2 } from "../video/backgrounds/Ink2";

export default function App404() {
  const { t } = useTranslation("application");

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <main className="relative isolate min-h-full">
        <Ink2 show={true} />
        <div className="flex justify-center w-100 pt-8">
          <img
            className="h-12 sm:h-16 w-auto"
            src={Settings.textLogoDark}
            alt="Parlorkit"
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
          <p className="text-base font-semibold leading-8 text-white">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
            {t("app404.title")}
          </h1>
          <p className="mt-4 text-base text-white/70 sm:mt-6">
            {t("app404.description")}
          </p>
          <div className="mt-10 flex justify-center">
            <Link
              to={"/"}
              className="text-sm font-semibold leading-7 text-white"
            >
              <span aria-hidden="true">&larr;</span> {t("app404.link.text")}
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
