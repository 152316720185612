import { OnboardingHeader } from "./OnboardingHeader";
import { Link, ActionPanelTopRight } from "Tailwind";
import { ProtectedPage } from "../ProtectedPage";
import React, { useEffect, useState } from "react";
import { OnboardingContainer } from "./OnboardingContainer";
import { useTranslation } from "react-i18next";

import { CreateAccountForm } from "../Account/CreateAccountForm";
import { useNavigate } from "react-router-dom";

export function CreateAccountOnboarding() {
  const { t } = useTranslation("application");

  const navigate = useNavigate();

  return (
    <ProtectedPage>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={t("createAccountOnboarding.ActionPanel.title")}
          subTitle={t("createAccountOnboarding.ActionPanel.subtitle")}
          actions={[
            <Link key={1} to={"/onboarding"}>
              <span aria-hidden="true">←</span>{" "}
              {t("createAccountOnboarding.link.back")}
            </Link>,
          ]}
        >
          <CreateAccountForm
            onComplete={() => {
              navigate("/onboarding/business-hours");
            }}
          />
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
