import { ReactNode } from "react";
import { Font } from "Tailwind";

export function SimpleEmptyState(props: {
  decoration?: ReactNode;
  actions?: ReactNode[];
  title: string;
  description: string;
}) {
  return (
    <div className="text-center">
      {props.decoration && (
        <div className="flex justify-center w-full mx-auto">
          {props.decoration}
        </div>
      )}
      <h3 className="mt-2">
        <Font size="sm" color="gray" weight="semibold">
          {props.title}
        </Font>
      </h3>
      <div className="mt-1">
        <Font size="sm" color="gray500">
          {props.description}
        </Font>
      </div>
      {props.actions && (
        <div className="mt-6">
          {props.actions.map((a, index) => {
            return <div key={index}>{a}</div>;
          })}
        </div>
      )}
    </div>
  );
}
