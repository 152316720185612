import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneProps {
  country: string;
  value: string;
  onChange: (v: string) => void;

  className?: string;
  label?: string;
  description?: string;
  name: string;
  error?: string;
  disabled?: boolean;
}

export function Phone(props: PhoneProps) {
  return (
    <div className={props.className}>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        {/* @ts-ignore */}
        <PhoneInput
          defaultCountry={props.country}
          value={props.value}
          name={props.name}
          onChange={(phone) => props.onChange(phone)}
          inputClassName="w-full"
        />
      </div>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}

      {props.error && (
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      )}
    </div>
  );
}
