export const light = {
  primary:
    "text-sm leading-6 font-semibold text-indigo-600 hover:text-indigo-500",
  secondary:
    "text-sm leading-6 font-semibold text-gray-900 hover:text-gray-800",
};

export const dark = {
  primary:
    "text-sm leading-6 font-semibold text-indigo-500 hover:text-indigo-400",
  secondary: "text-sm leading-6 font-semibold text-white",
};

export const color = {
  dark: dark,
  light: light,
};
