import { toast, useToast } from "react-toastify";
import { AnyError, ApiError, PEM, ValidationError } from "../api/staff";
import { useTranslation } from "react-i18next";

export function useFeedback() {
  const { t } = useTranslation("errors");

  function isAnyError(e: any): e is AnyError {
    return e && "detail" in e && typeof e.detail == "string";
  }

  function isApiErrorSchema(e: any): e is { detail: ValidationError[] } {
    return (
      e &&
      "detail" in e &&
      Array.isArray(e.detail) &&
      e.detail.length > 0 &&
      "msg" in e.detail[0] &&
      "loc" in e.detail[0] &&
      Array.isArray(e.detail[0].loc) &&
      e.detail[0].loc.length > 0
    );
  }

  function handleApiError(err: ApiError) {
    const content = err.body;
    if (isAnyError(content)) {
      parseExceptionError(content);
    }
  }

  function handleErrors(err: ApiError) {
    const content = err.body;
    if (isAnyError(content)) {
      parseExceptionError(content);
    } else if (isApiErrorSchema(content)) {
      content.detail.forEach((e) => {
        showErrorToast(`${e.msg} (${e.loc[1]})`);
        console.error(`Unhandled error`, content);
      });
    }
  }

  function handleValidationError(err: ApiError) {
    const content = err.body;
    if (isApiErrorSchema(content)) {
      content.detail.forEach((e) => {
        showErrorToast(`${e.msg} (${e.loc[1]})`);
        console.error(`Unhandled error`, content);
      });
    }
  }

  function parseExceptionError(e: AnyError) {
    switch (e.detail) {
      case PEM.TEMPLATE_CONTROLLER_UNABLE_TO_RENDER:
        break;
      case PEM.USER_CONTROLLER_MISSING_PERMISSION_UPDATE_USER_ROLE:
        showErrorToast(
          t("USER_CONTROLLER_MISSING_PERMISSION_UPDATE_USER_ROLE")
        );
        break;
      case PEM.WEBSHOP_CLIENT_CONTROLLER_NO_AVAILABLE_BOOKING_SLOTS_FOUND:
        showErrorToast(
          t("WEBSHOP_CLIENT_CONTROLLER_NO_AVAILABLE_BOOKING_SLOTS_FOUND")
        );
        break;
      case PEM.WEBSHOP_CLIENT_CONTROLLER_CANCELLATION_THRESHOLD_EXCEEDED:
        showErrorToast(
          t("WEBSHOP_CLIENT_CONTROLLER_CANCELLATION_THRESHOLD_EXCEEDED")
        );
        break;
      case PEM.SESSIONS_CONTROLLER_BOOKING_UNAVAILABLE:
        showErrorToast(t("SESSIONS_CONTROLLER_BOOKING_UNAVAILABLE"));
        break;
      case PEM.WEBSHOP_CLIENT_CONTROLLER_WEBSITE_NOT_FOUND:
        showErrorToast(t("WEBSHOP_CLIENT_CONTROLLER_WEBSITE_NOT_FOUND"));
        break;
      case PEM.WEBSHOP_CLIENT_CONTROLLER_REQUESTED_BOOKING_NOT_AVAILABLE:
        showErrorToast(
          t("WEBSHOP_CLIENT_CONTROLLER_REQUESTED_BOOKING_NOT_AVAILABLE")
        );
        break;
      case PEM.SERVICES_CONTROLLER_SERVICE_NOT_FOUND:
        showErrorToast(t("SERVICES_CONTROLLER_SERVICE_NOT_FOUND"));
        break;
      case PEM.PRODUCT_CONTROLLER_BARCODE_IN_USE:
        showErrorToast(t("PRODUCT_CONTROLLER_BARCODE_IN_USE"));
        break;
      case PEM.CLIENT_CONTROLLER_CLIENT_ALREADY_EXISTS:
        showErrorToast(t("CLIENT_CONTROLLER_CLIENT_ALREADY_EXISTS"));
        break;
      case PEM.USER_CONTROLLER_NOT_LOGGED_IN:
        showErrorToast(t("USER_CONTROLLER_NOT_LOGGED_IN"));
        break;
      case PEM.USER_CONTROLLER_CVR_ALREADY_EXISTS:
        showErrorToast(t("USER_CONTROLLER_CVR_ALREADY_EXISTS"));
        break;
      case PEM.USER_CONTROLLER_URL_NOT_AVAILABLE:
        showErrorToast(t("USER_CONTROLLER_URL_NOT_AVAILABLE"));
        break;
      case PEM.USER_CONTROLLER_INVITATION_ALREADY_EXISTS:
        showErrorToast(t("USER_CONTROLLER_INVITATION_ALREADY_EXISTS"));
        break;
      case PEM.USER_CONTROLLER_USER_NOT_FOUND:
        showErrorToast(t("USER_CONTROLLER_USER_NOT_FOUND"));
        break;
      case PEM.USER_CONTROLLER_MAX_REQUESTS_REACHED:
        showErrorToast(t("USER_CONTROLLER_MAX_REQUESTS_REACHED"));
        break;
      case PEM.USER_CONTROLLER_INVALID_PHONE_NUMBER:
        showErrorToast(t("USER_CONTROLLER_INVALID_PHONE_NUMBER"));
        break;
      case PEM.USER_CONTROLLER_EMAIL_ALREADY_IN_USE:
        showErrorToast(t("USER_CONTROLLER_EMAIL_ALREADY_IN_USE"));
        break;
      case PEM.USER_CONTROLLER_NOT_CONFIRMED:
        showErrorToast(t("USER_CONTROLLER_NOT_CONFIRMED"));
        break;
      case PEM.USER_CONTROLLER_CVR_NOT_FOUND:
        showErrorToast(t("USER_CONTROLLER_CVR_NOT_FOUND"));
        break;
      case PEM.USER_CONTROLLER_UNSUPPORTED_CHANNEL:
        showErrorToast(t("USER_CONTROLLER_UNSUPPORTED_CHANNEL"));
        break;
      case PEM.USER_CONTROLLER_INCORRECT_OTP:
        showErrorToast(t("USER_CONTROLLER_INCORRECT_OTP"));
        break;
      case PEM.USER_CONTROLLER_MUST_ACCEPT_TERMS:
        showErrorToast(t("USER_CONTROLLER_MUST_ACCEPT_TERMS"));
        break;
      case PEM.USER_CONTROLLER_USER_SIGNUP_COMPLETED:
        showErrorToast(t("USER_CONTROLLER_USER_SIGNUP_COMPLETED"));
        break;
      case PEM.USER_CONTROLLER_NOTHING_TO_RESET:
        showErrorToast(t("USER_CONTROLLER_NOTHING_TO_RESET"));
        break;
      case PEM.DINERO_CONTROLLER_ITEM_NOT_FOUND:
        showErrorToast(t("DINERO_CONTROLLER_ITEM_NOT_FOUND"));
        break;
      case PEM.V1_NOT_LOGGED_IN:
        showErrorToast(t("V1_NOT_LOGGED_IN"));
        break;
      case PEM.V1_USER_ROLE_REQUIRED:
        break;
      case PEM.V1_TENANT_MUST_BE_SUPPLIED_AS_SUBDOMAIN:
        showErrorToast(t("V1_TENANT_MUST_BE_SUPPLIED_AS_SUBDOMAIN"));
        break;
      case PEM.V1_TENANT_NOT_FOUND:
        showErrorToast(t("V1_TENANT_NOT_FOUND"));
        break;
      case PEM.FILES_CONTROLLER_FILE_NOT_FOUND:
        showErrorToast(t("FILES_CONTROLLER_FILE_NOT_FOUND"));
        break;
      case PEM.PIGMENT_CONTROLLER_PIGMENT_NOT_FOUND:
        showErrorToast(t("PIGMENT_CONTROLLER_PIGMENT_NOT_FOUND"));
        break;
      case PEM.PIGMENT_CONTROLLER_BASE_PIGMENT_NOT_FOUND:
        showErrorToast(t("PIGMENT_CONTROLLER_BASE_PIGMENT_NOT_FOUND"));
        break;
      case PEM.WEBSHOP_STAFF_CONTROLLER_LOGO_NOT_FOUND:
        showErrorToast(t("WEBSHOP_STAFF_CONTROLLER_LOGO_NOT_FOUND"));
        break;
      default:
        console.error("Unimplemented error. See useErrorHandler.tsx", e);
    }
  }

  function showErrorToast(message: string) {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "light",
    });
  }

  function showSuccessToast(message: string) {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "light",
    });
  }

  return {
    handleErrors: handleErrors,
    handleApiError: handleApiError,
    handleValidationError: handleValidationError,
    showErrorToast: showErrorToast,
    showSuccessToast: showSuccessToast,
  };
}
