/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_FILES_CONTROLLER_file_not_found___FILES_CONTROLLER_file_not_found____ = {
    detail: PS_Literal__PEM_FILES_CONTROLLER_file_not_found___FILES_CONTROLLER_file_not_found____.detail;
};

export namespace PS_Literal__PEM_FILES_CONTROLLER_file_not_found___FILES_CONTROLLER_file_not_found____ {

    export enum detail {
        FILES_CONTROLLER_FILE_NOT_FOUND = 'FILES_CONTROLLER_file_not_found',
    }


}

