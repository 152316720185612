import React from "react";
import { WeekCalendarDateButton } from "./WeekCalendarDateButton";

export function WeekCalendarDates(props: {
  selectedDate?: Date;
  onDaySelected: (date: Date) => void;
  dates: Date[];
}) {
  return (
    <>
      <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
        <div className="col-end-1 w-14" />
        {props.dates.map((date, i) => {
          return (
            <React.Fragment key={i}>
              <WeekCalendarDateButton
                date={date}
                selected={
                  props.selectedDate?.toDateString() === date.toDateString()
                }
                onClick={() => {
                  props.onDaySelected(date);
                }}
              />
            </React.Fragment>
          );
        })}
      </div>
      <div className="grid grid-cols-7 text-xs leading-6 text-gray-500 sm:hidden">
        {props.dates.map((date, i) => (
          <React.Fragment key={i}>
            <WeekCalendarDateButton
              date={date}
              selected={
                props.selectedDate?.toDateString() === date.toDateString()
              }
              onClick={() => {
                props.onDaySelected(date);
              }}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
