import React, { useEffect, useState } from "react";
import { ProfileLayout } from "./ProfileLayout";
import { List, Td, TextButton } from "../../../../Tailwind";
import { UserRole } from "../../../../api/staff";
import { useAppApi } from "../../../../api/staff/AppApi";
import { useTranslation } from "react-i18next";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { UserRoleSlideOver } from "../Users/UserRoleSlideOver";

export function UserRoleProfileSettings() {
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const api = useAppApi();
  const { t } = useTranslation("application");
  const [editRole, setEditRole] = useState<UserRole | undefined>();

  useEffect(() => {
    if (api.userProfile?.user) {
      setUserRoles(api.userProfile?.user.roles);
    }
  }, [api.userProfile?.user]);

  return (
    <>
      <ProfileLayout active={"userRole"}>
        <List
          title={t("userRoleProfileSettings.list.title")}
          subtitle={t("userRoleProfileSettings.list.subtitle")}
          data={userRoles}
          renderLine={(userRole) => (
            <>
              <Td first>{userRole.account.company_name}</Td>
              <Td>{userRole.role}</Td>
              <Td>{userRole.title}</Td>
              <Td>{userRole.description}</Td>
              <Td last>
                <TextButton
                  onClick={() => {
                    setEditRole(userRole);
                  }}
                >
                  <PencilSquareIcon className={"h-6 w-6"} />
                </TextButton>
              </Td>
            </>
          )}
          headers={[
            t("userRoleProfileSettings.list.headers.company"),
            t("userRoleProfileSettings.list.headers.role"),
            t("userRoleProfileSettings.list.headers.title"),
            t("userRoleProfileSettings.list.headers.description"),
            "",
          ]}
          actions={[]}
        />
      </ProfileLayout>
      <UserRoleSlideOver
        userRole={editRole}
        onComplete={() => {
          setEditRole(undefined);
          api.auth.refresh().then(() => {
            if (api.userProfile?.user) {
              setUserRoles(api.userProfile?.user.roles);
            }
          });
        }}
        isOpen={!!editRole}
        onClose={() => {
          setEditRole(undefined);
        }}
      />
    </>
  );
}
