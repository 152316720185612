import React from "react";
import { MarketingPageLayout } from "../MarketingPageLayout";

export function Privacy() {
  return (
    <MarketingPageLayout>
      <div className="relative mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold" id="h.8ou1ujgtfxx">
          <span className="c12 c13 text-gray-700 dark:text-gray-400">
            Privacy Policy
          </span>
        </h1>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c4 c12">Last updated: June 05, 2022</span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            This policy is used to inform our users regarding our policies with
            the collection, use, storage and disclosure of Personal Information
            if anyone decides to use any or all of our services, including
            mobile and web applications.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            If you choose to use our Service, then you agree to the collection
            and use of information in relation with this policy. The Personal
            Information that we collect is used for providing and improving our
            services. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We process several different groups of individuals as we handle data
            for both our clients, and the clients clients. When we write
            &ldquo;Specialist&rdquo; we are referring to a customer of
            Parlorkit, a Tattoo/PMU artist working in a parlor in which
            Parlorkit is used, or a manager who uses Parlorkit in order to
            manage a tattoo parlor. When we write &ldquo;Client&rdquo; we refer
            to the customers utilizing the services of a specialist through
            Parlorkit.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            This Privacy Policy covers the following areas
          </span>
        </p>
        <ol
          className="pb-1 list-decimal list-inside lst-kix_dopr84gb0kw1-0 start"
          start={1}
        >
          <li className="c6 li-bullet-0">
            <span className="c3">What personal data we collect</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">How we may use the data collected</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Who we may disclose your personal info to
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              How we store and secure information we collect
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">Your privacy rights</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">Changes to this Privacy Policy</span>
          </li>
        </ol>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            This privacy does not cover the privacy policy between the
            specialist and the client.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.7un3lgn2okyi">
          <span className="c7">1. What data do we collect?</span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            Most, if not all of our client data is collected on behalf of the
            specialist, or is generated through the use of our services by both
            the specialist and the client.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            The data we collect can be grouped into the following categories:
          </span>
        </p>
        <ol
          className="pb-1 list-decimal list-inside lst-kix_ebrw028383yb-0 start"
          start={1}
        >
          <li className="c6 li-bullet-0">
            <span className="c3">
              Personal identification information: Examples of data in this
              category include name and may include national identifiers to
              identify/verify specialists.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Contact information: examples of this include postal address,
              email address and phone number.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Session data: examples of data in this category include completed
              and canceled sessions between client and specialists (including
              time and place), the nature of the session, communication between
              parties regarding a session, extensive list of resources utilized
              during a session and any special data related to a session (see
              point f).
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Profile information: examples of data in this category include
              sessions data for all involved parties.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Specialist subscription status regarding Parlorkit
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Special categories of data: examples of data in this category
              include client-health data collected by specialists before a
              session, or any data regarding complications emerging from a
              session.
            </span>
          </li>
        </ol>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We collect the data provided by a client to a specialist or directly
            by a specialist, ex. When creating a new client for a booking, or
            when filling out a mandatory health questionnaire before beginning a
            session. We also collect the data generated by the session,
            including any pigments used.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.llebkq9itf43">
          <span className="c9 c11">Children&rsquo;s Privacy</span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&rsquo;s consent before We collect and use that
            information.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.mwvgegxz6p0b">
          <span className="c7">2. How we may use the data collected</span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            How we use any information we have collected depends on which of our
            services you use, how you use them, and any preferences or consent
            that you have supplied to us. Below are the specific purposes for
            which we use the personal information we have collected.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.uumibh6h86bu">
          <span className="c9 c11">To fulfill legal obligations</span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            The main purpose of processing your data is to help specialists
            comply with legal obligations regarding the registration of pigments
            used, when and on whom.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.nhhgiz3voxoy">
          <span className="c9 c11">
            To providing users with services and customized experiences
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We also process personal data in order to provide services to
            specialists and clients. These include, but are not limited to
          </span>
        </p>
        <ul className="pb-1 list-decimal list-inside lst-kix_9e3nak1kq7a1-0 start">
          <li className="c6 li-bullet-0">
            <span className="c3">
              Services to facilitate session management so historic data is
              easier to locate for both specialist and client.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Services to facilitate management of clients related to the
              business of a specialist.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Services to facilitate management of a specialist business
              including pigment tracking and management of bookings, including
              self-booking from clients.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              Services to provide the Parlorkit management services to
              specialists.
            </span>
          </li>
        </ul>
        <h3 className="text-xl c1" id="h.4yp1k26lp3jz">
          <span className="c9 c11">To communicating regarding services</span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We use data provided to help specialists communicate regarding
            bookings for clients, to request a questionnaire being filled. And
            to communicate regarding any complications or reports of such from
            any sessions or external sources.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.5msihid9tv87">
          <span className="c9 c11">Customer support</span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We use the data supplied to help resolve any technical issues, or to
            assist in issues with our services.{" "}
          </span>
        </p>
        <h3 className="text-xl c1" id="h.s0r8jwdywo9u">
          <span className="c9 c11">
            To help the development of new services or products
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We might also use data provided so help develop new products or
            services that may help a specialist in their management of their
            business.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.7bxmi33ev714">
          <span className="c9 c11">With your consent</span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We will only use your information in scenarios, not listed above,
            where we have received a clear consent to do so.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.ob9zjl6puljq">
          <span className="c7">
            3. Who we may disclose your personal info to
          </span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We may share personal information with authorities, and other legal
            entities tasked with the responsibility of auditing specialists and
            their pigment registration. This registration will in some cases
            include customers.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We may also in some cases, with your consent, disclose personal
            information. Ex. to notify a third party specialist regarding a
            known complications with a specific pigment.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We will also share specialist information in order to verify any
            subscription status.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We may also share anonymized and or aggregated data for social
            research or statistical purposes.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.fywyn2ht1ivl">
          <span className="c7">
            4. How we store and secure information we collect
          </span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            Keeping your data safe and secure is at the center of how we do
            business. We use standard technical and organizational measures to
            secure the information we store. While no security system is
            impenetrable, we employ principles of security by design and privacy
            by design to help keep your data free, and to mitigate in the event
            of an intrusion. Remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While We
            strive to use commercially acceptable means to protect Your Personal
            Data, We cannot guarantee its absolute security.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We will retain Your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use Your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period of time, except when
            this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.n9e5t29p2h6e">
          <span className="c7">5. Your privacy rights</span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            GDPR regulates the processing of personal data. Your rights as a
            data subject is as follows:
          </span>
        </p>
        <h3 className="text-xl c1" id="h.h7z9vay24nw">
          <span className="c9 c11">
            Right To Access Data (Article 15) &nbsp;
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right to access the personal data we hold about you.
            Your right to access may, however, be restricted by legislation, the
            Parlorkit legitimate business interests or by the protection of a
            third party.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.lbz69296igsh">
          <span className="c9 c11">
            Right To Have Data Transferred (Article 20)
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right to receive any data you have provided us in a
            structured and machine-readable format.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.376em65kynvs">
          <span className="c9 c11">
            Right To Have Data Rectified (Article 16){" "}
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right to have any erroneous or incomplete data
            registered about you corrected under restriction by legislation. Be
            advised that most if not all client data is corrected through the
            specialist related to these client data.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.dt3awehdaalf">
          <span className="c9 c11">
            Right To Object To Processing (Article 21)
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right, when it is on relevant grounds, to object to
            processing of your personal data for a particular use.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.2mqi4ddffuw">
          <span className="c9 c11">
            Right To Have Data Erased &ndash; &ldquo;Right To Be
            Forgotten&rdquo; (Article 17)
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right to request the erasure of your data when it falls
            into one of the following scenarios:
          </span>
        </p>
        <ul className="pb-1 list-decimal list-inside lst-kix_kxpd2kjutfzt-0 start">
          <li className="c6 li-bullet-0">
            <span className="c3">
              The data is no longer needed for the purposes for which it was
              received or processed.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              The processing was based on consent, and the data subject
              withdraws that consent.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              The data subject successfully exercises the right to object (see
              above).
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">The data has been unlawfully processed.</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              EU or national law requires that the data be erased.
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c3">
              The data was collected in relation to the offer of services online
              to a child (that is, in circumstances where consent would require
              parental consent authorization).
            </span>
          </li>
        </ul>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            Due to legal obligations, we will, in many cases, not be able to
            oblige the request of erasure.
          </span>
        </p>
        <h3 className="text-xl c1" id="h.zdzm6hgcpq5p">
          <span className="c9 c11">
            Right To Restrict Processing (Article 18)
          </span>
        </h3>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You have the right, in certain circumstances, to have processing of
            your data temporarily suspended, so that no processing for that use,
            other than storage is executed. For example if you want us to
            establish the accuracy of your data.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            In the event that your objection is successfull, the processing of
            your data will be temporarily suspended until such an objection is
            lifted.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.r6w83gh3p6u5">
          <span className="c7">6. Changes to this Privacy Policy</span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            We are constantly improving and developing our services, products
            and websites, so we may change this privacy policy from time to
            time. This also means that we may update Our Privacy Policy from
            time to time. We will notify You of any changes by posting the new
            Privacy Policy on this page.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            If the changes are significant, we will, prior to the change
            becoming effective, provide a more prominent notice, when we are
            required to do so by applicable law. Additionally we will update the
            &ldquo;Last updated&rdquo; date at the top of this Privacy Policy.
          </span>
        </p>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>
        <h2 className="text-xl c1" id="h.tmy8rcex9plp">
          <span className="c7">Contact Us</span>
        </h2>
        <p className="mb-3 font-light text-gray-700 dark:text-gray-400">
          <span className="c3">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </span>
        </p>
        <ul className="pb-1 list-decimal list-inside lst-kix_mzdzimydbpip-0 start">
          <li className="c6 li-bullet-0">
            <span className="c3">By email: info@parlorkit.com</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c4">By visiting this page on our website: </span>
            <span className="c8">
              <a
                className="c14"
                href="https://www.google.com/url?q=https://www.parlorkit.com/&amp;sa=D&amp;source=editors&amp;ust=1672253355353366&amp;usg=AOvVaw1WjVWnU2P7dKS0SL-b9X-M"
              >
                https://www.parlorkit.com
              </a>
            </span>
          </li>
        </ul>
        <p className="c16">
          <span className="c9 c10"></span>
        </p>
      </div>
    </MarketingPageLayout>
  );
}
