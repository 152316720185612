import { FullSessionInDb } from "api/staff";
import React, { useEffect, useState } from "react";
import { useAppApi } from "api/staff/AppApi";
import { BasicCard, LoadingOverlay } from "../../../../../Tailwind";
import { EmployeeSection } from "./EmployeeSection";
import { ClientSection } from "./ClientSection";
import { NotesSection } from "./NotesSection";
import { ImagesSection } from "./ImagesSection";
import { SessionServicesSection } from "./SessionServicesSection";
import { SessionProductSection } from "./SessionProductSection";
import { ActionsSection } from "./ActionsSection";
import { SessionBookingSection } from "./SessionBookingSection";
import { SummerySection } from "./SummerySection";
import { ClientNotesSection } from "./ClientNotesSection";
export function SessionApp(props: { sessionId?: number; onClose: () => void }) {
  const [session, setSession] = useState<FullSessionInDb>();
  const api = useAppApi();

  useEffect(() => {
    if (props.sessionId) {
      api.misc.getSessionSessionsSessionIdGet(props.sessionId).then((s) => {
        setSession(s);
      });
    }
  }, [props.sessionId]);

  if (!session) {
    return <LoadingOverlay loading={session === undefined} />;
  }

  return (
    <div>
      <div className="flex flex-col gap-2 lg:flex-row justify-evenly">
        <div className="flex flex-col gap-2 flex-1">
          <div className="flex flex-col gap-2">
            <div className="flex-1">
              <BasicCard>
                <EmployeeSection
                  session={session}
                  onSessionUpdated={(s) => {
                    setSession(s);
                  }}
                />
              </BasicCard>
            </div>
            <div className="flex-1">
              <BasicCard>
                <SessionBookingSection
                  session={session}
                  onSessionUpdated={(s) => {
                    setSession(s);
                  }}
                />
              </BasicCard>
            </div>
          </div>
          <div>
            <BasicCard>
              <ClientSection
                session={session}
                onSessionUpdated={(s) => {
                  setSession(s);
                }}
              />
            </BasicCard>
          </div>
          <div>
            <BasicCard>
              <ClientNotesSection
                client={session.client}
                onClientUpdated={() => {
                  api.misc
                    .getSessionSessionsSessionIdGet(session.id)
                    .then((s) => {
                      setSession(s);
                    });
                }}
              />
            </BasicCard>
          </div>
          <div className="2xl:hidden flex flex-col gap-2">
            <BasicCard>
              <ImagesSection
                onSessionUpdated={(s) => {
                  setSession(s);
                }}
                session={session}
              />
            </BasicCard>
            <BasicCard>
              <NotesSection
                session={session}
                onSessionUpdated={(s) => {
                  setSession(s);
                }}
              />
            </BasicCard>
          </div>
        </div>
        <div className="hidden 2xl:flex md:flex-col md:flex-1 gap-2">
          <BasicCard>
            <ImagesSection
              onSessionUpdated={(s) => {
                setSession(s);
              }}
              session={session}
            />
          </BasicCard>
          <BasicCard>
            <NotesSection
              session={session}
              onSessionUpdated={(s) => {
                setSession(s);
              }}
            />
          </BasicCard>
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <BasicCard>
            <SessionServicesSection
              session={session}
              onSessionUpdated={(s) => {
                setSession(s);
              }}
            />
          </BasicCard>
          <BasicCard>
            <SessionProductSection
              session={session}
              onSessionUpdated={(s) => {
                setSession(s);
              }}
            />
          </BasicCard>
          <BasicCard>
            <SummerySection session={session} />
          </BasicCard>
          <BasicCard>
            <ActionsSection
              onSessionCanceled={() => props.onClose()}
              session={session}
              onSessionUpdated={(s) => {
                setSession(s);
              }}
            />
          </BasicCard>
        </div>
      </div>
    </div>
  );
}
