import { Logo } from "./Logo";
import React from "react";

export function Header() {
  return (
    <header>
      <div>
        <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <Logo />
        </div>
      </div>
    </header>
  );
}
