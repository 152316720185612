import React, { PropsWithChildren } from "react";
import { Font } from "../Font/Font";

export function ActionPanelBottom(
  props: PropsWithChildren<{
    title: string;
    subTitle: string;
    actions: React.ReactNode[];
  }>
) {
  return (
    <div className="bg-white w-full shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3>
          <Font className="leading-6" color={"gray"} weight={"semibold"}>
            {props.title}
          </Font>
        </h3>
        <div className="mt-2 max-w-xl">
          <Font color={"gray500"} size="sm">
            {props.subTitle}
          </Font>
        </div>
        <div className="mt-5 flex gap-x-4">
          {props.actions.map((a, i) => (
            <div key={i}>{a}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
