import React, { useState } from "react";
import {
  FullSessionInDb,
  SessionProduct,
  SessionStatus,
} from "../../../../../api/staff";
import { Button, List, Modal, Td, TextButton } from "Tailwind";
import { useTranslation } from "react-i18next";
import { ProductPicker } from "../../Products/ProductPicker";
import { useAppApi } from "../../../../../api/staff/AppApi";
import {
  PlusIcon,
  ReceiptPercentIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { SessionProductDiscountModal } from "./SessionProductDiscountModal";
import { useLocale } from "../../../../../lib/locale";

export function SessionProductSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (session: FullSessionInDb) => void;
}) {
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<SessionProduct>();

  const { t } = useTranslation("application");
  const api = useAppApi();
  const locale = useLocale();

  function addProduct(productId: number) {
    setSaving(true);
    api.misc
      .addSessionProductSessionsSessionIdProductProductIdPost(
        session.id,
        productId
      )
      .then((updatedSession) => {
        onSessionUpdated(updatedSession);
        setSaving(false);
      });
  }

  function removeProduct(sessionProductId: number) {
    setSaving(true);
    api.misc
      .removeSessionProductSessionsSessionIdProductProductIdDelete(
        session.id,
        sessionProductId
      )
      .then((updatedSession) => {
        setSaving(false);
        onSessionUpdated(updatedSession);
      });
  }

  return (
    <div>
      <List
        data={session.products}
        actions={[
          session.status === SessionStatus.SCHEDULED && (
            <TextButton
              onClick={() => {
                setVisible(true);
              }}
            >
              <PlusIcon className="w-6 h-6" />
            </TextButton>
          ),
        ]}
        title={t("sessionProductSection.list.title")}
        subtitle={""}
        renderLine={(product) => (
          <>
            <Td first>{product.name}</Td>
            <Td>
              <div className="flex flex-col">
                <div>
                  <span className={product.discount > 0 ? "line-through" : ""}>
                    {locale.currency.format(product.price)}
                  </span>
                </div>
                {product.discount > 0 && (
                  <div className="flex flex-row gap-2">
                    <span className={"text-green-800"}>
                      {locale.currency.format(
                        product.price - product.discount_amount
                      )}
                    </span>
                    <span>-</span>
                    <div className="text-green-800">
                      {t("sessionServicesSection.text.discount")}{" "}
                      {product.discount}
                      {"%"}
                    </div>
                  </div>
                )}
              </div>
            </Td>
            {session.status === SessionStatus.SCHEDULED && (
              <Td last>
                <div className="flex gap-2 justify-end">
                  <TextButton
                    disabled={saving}
                    onClick={() => {
                      setSelectedProduct(product);
                    }}
                  >
                    <ReceiptPercentIcon width={24} />
                  </TextButton>
                  <TextButton
                    disabled={saving}
                    onClick={() => {
                      removeProduct(product.id);
                    }}
                  >
                    <XCircleIcon width={24} />
                  </TextButton>
                </div>
              </Td>
            )}
          </>
        )}
        headers={[
          t("sessionProductSection.list.headers.name"),
          t("sessionProductSection.list.headers.price"),
          "",
        ]}
      />
      <Modal
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
      >
        <ProductPicker
          onProductPicked={(product) => {
            addProduct(product.id);
            setVisible(false);
          }}
        />
        <div className="mt-4 flex justify-end">
          <Button color="secondary" onClick={() => setVisible(false)}>
            {t("sessionServiceSection.button.cancel")}
          </Button>
        </div>
      </Modal>
      <SessionProductDiscountModal
        session={session}
        product={selectedProduct}
        onDiscountChanged={(session) => {
          setSelectedProduct(undefined);
          onSessionUpdated(session);
        }}
        onClose={() => {
          setSelectedProduct(undefined);
        }}
      />
    </div>
  );
}
