import { Service, UserRole } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import React, { useEffect, useState } from "react";
import { Button, Font, List, Paginator, Td } from "Tailwind";
import { Input } from "../../../../Tailwind/Input/Uncontrolled";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { manipulate_image_file } from "../../../../lib/utils";

export function ServicePicker({
  employee,
  onServicePicked,
}: {
  employee?: UserRole;
  onServicePicked: (service: Service) => void;
}) {
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);
  const [services, setServices] = useState<Service[]>([]);
  const [term, setTerm] = useState("");

  const api = useAppApi();
  const { t } = useTranslation("application");

  useEffect(() => {
    api.misc
      .searchServicesSearchServicesGet(term, offset, employee?.id)
      .then((response) => {
        setTotal(response.total);
        setNextOffset(response.offset);
        setPageSize(response.page_size);
        setServices(response.results);
      });
  }, [employee, term, offset]);

  return (
    <>
      <List
        title={t("servicePicker.list.title")}
        subtitle={t("servicePicker.list.subtitle")}
        data={services}
        renderLine={(service: Service) => {
          return (
            <>
              <Td first>
                <div className="flex items-center">
                  {service.files.length > 0 ? (
                    <div className="min-w-[70px]">
                      <img
                        className="h-11 w-11 rounded-md"
                        src={manipulate_image_file(service.files[0], [
                          "h_100",
                          "w_100",
                          "c_thumb",
                        ])}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div />
                  )}
                  <div>
                    <div className="flex gap-2 font-medium text-gray-900">
                      <div>{service.name}</div>
                      <div
                        style={{ backgroundColor: service.color }}
                        className="h-5 w-5 rounded-full"
                      />
                    </div>
                    {service.description && (
                      <div className="mt-1 max-w-[300px] font-normal text-gray-500">
                        {service.description.slice(0, 50)}
                        {service.description.length > 50 && "..."}
                      </div>
                    )}
                  </div>
                </div>
              </Td>
              <Td>
                <Font>{service.price} dkk</Font>
              </Td>
              <Td>
                <Font>{service.duration} min</Font>
              </Td>
              <Td last>
                <Button
                  color="secondary"
                  onClick={() => onServicePicked(service)}
                >
                  <div className="flex justify-between gap-2">
                    <div>{t("servicePicker.button.select")}</div>
                    <ChevronRightIcon width={18} />
                  </div>
                </Button>
              </Td>
            </>
          );
        }}
        headers={[
          t("servicePicker.list.headers.name"),
          t("servicePicker.list.headers.price"),
          t("servicePicker.list.headers.duration"),
          "",
        ]}
        searchWidget={
          <Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"max-w-30"}
            name={"search"}
            onChange={(v) => {
              setTerm(v.target.value);
              setOffset(0);
            }}
            value={term}
          />
        }
        actions={[]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={setOffset}
        onPrevious={setOffset}
      />
    </>
  );
}
