import { Layout } from "../../Layout/Layout";
import React, { useState } from "react";
import WeekCalendar from "./Week/WeekCalendar";
import MonthCalendar from "./Month/MonthCalendar";

export type calendarType = "week" | "month";

export function CalendarPage() {
  const [calendar, setCalendar] = useState<calendarType>("week");

  return (
    <Layout active={"calendar"}>
      {calendar === "week" && (
        <WeekCalendar
          onSwitchCalendar={(type: calendarType) => setCalendar(type)}
        />
      )}
      {calendar === "month" && (
        <MonthCalendar
          onSwitchCalendar={(type: calendarType) => setCalendar(type)}
        />
      )}
    </Layout>
  );
}
