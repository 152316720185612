import { Menu, Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useAppApi } from "api/staff/AppApi";
import {
  CircularAvatar,
  Font,
  Modal,
  NotificationWithActionsBelow,
  SimpleEmptyState,
  TextButton,
} from "Tailwind";
import { useTranslation } from "react-i18next";
import {
  ChevronDownIcon,
  BellIcon,
  GlobeAltIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { useLocale } from "../../../lib/locale";
import { useNavigate } from "react-router-dom";
import { CreateAccountForm } from "../Account/CreateAccountForm";
import { Notification, Webshop } from "../../../api/staff";

export function Header() {
  const [visible, setVisible] = useState(false);

  const api = useAppApi();
  const { t } = useTranslation("application");
  const navigate = useNavigate();
  const [term, setTerm] = useState("");
  const [webshop, setWebshop] = useState<Webshop>();
  const locale = useLocale();

  useEffect(() => {
    const cachedWebshop = window.localStorage.getItem("WEBSHOP");
    if (cachedWebshop) {
      setWebshop(JSON.parse(cachedWebshop));
    } else {
      api.misc.getWebshopSettingsWebshopGeneralGet().then((s) => {
        window.localStorage.setItem("WEBSHOP", JSON.stringify(s));
        setWebshop(s);
      });
    }
  }, []);

  return (
    <>
      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex flex-col flex-1 justify-center">
          {/*<Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"w-full"}
            placeholder={t("header.input.search.placeholder")!}
            name={t("productsList.input.search")}
            onChange={(v) => {
              setTerm(v.target.value);
            }}
            value={term}
          />*/}
        </div>
        <div className="flex items-center gap-x-2 lg:gap-x-4">
          <NotificationsPopover />
          {webshop ? (
            <TextButton
              onClick={() => {
                window.open(`https://${webshop?.url}.parlorkit.com/`);
              }}
            >
              <GlobeAltIcon className="w-6 h-6 text-gray-400 hover:text-gray-300" />
            </TextButton>
          ) : (
            <div className="w-6 h-6" />
          )}

          {/* Separator */}
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
            aria-hidden="true"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex p-1.5">
              <span className="sr-only">
                {t("header.button.menu.screenReader")}
              </span>
              <CircularAvatar
                file={api.userProfile?.current_role?.avatar}
                color={api.userProfile?.current_role?.color}
              />
              <div className="hidden lg:flex lg:items-center">
                <div className={"flex flex-col ml-4"}>
                  <span
                    className="text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                  >
                    {api.userProfile?.user.name}
                  </span>
                  <span
                    className="text-xs text-left text-gray-900"
                    aria-hidden="true"
                  >
                    {api.userProfile?.current_role?.account.company_name}
                  </span>
                </div>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute min-w-full right-0 flex items-start flex-col z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-nonep p-4">
                <h3 className="text-sm font-medium leading-6 whitespace-nowrap text-gray-500 border-b w-full mt-2">
                  {t("header.text.account")}
                </h3>
                <Menu.Item>
                  <MenuButton
                    title={t("header.link.changeRole")}
                    onClick={() => {
                      navigate("/select-account");
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <MenuButton
                    title={t("header.link.logout")}
                    onClick={() => {
                      api.auth.logout();
                      navigate("/");
                    }}
                  />
                </Menu.Item>
                <h3 className="text-sm font-medium leading-6 text-gray-500 border-b w-full mt-2">
                  {t("header.text.language")}
                </h3>
                <Menu.Item>
                  <MenuButton
                    title={"Dansk"}
                    onClick={() => {
                      locale.switchLanguage("da");
                    }}
                  />
                </Menu.Item>
                <Menu.Item>
                  <MenuButton
                    title={"English"}
                    onClick={() => {
                      locale.switchLanguage("en");
                    }}
                  />
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <Modal
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
      >
        <CreateAccountForm
          onComplete={() => {
            setVisible(false);
            api.auth.refresh();
          }}
        />
      </Modal>
    </>
  );
}

function MenuButton({
  title,
  subtitle,
  onClick,
  current,
}: {
  title: string;
  subtitle?: string;
  onClick: () => void;
  current?: boolean;
}) {
  return (
    <button
      disabled={current}
      onClick={onClick}
      className={current ? "flex gap-x-4 w-full" : "flex gap-x-4 w-full"}
    >
      <div className="flex flex-col items-start">
        <div
          className={
            current
              ? "text-sm font-light leading-6 text-indigo-700 whitespace-nowrap"
              : "text-sm font-normal leading-6 text-indigo-700 whitespace-nowrap"
          }
        >
          {title}
        </div>
        <div className="text-xs font-semibold text-gray-300">{subtitle}</div>
      </div>
    </button>
  );
}

function NotificationsPopover() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [color, setColor] = useState("text-gray-400 hover:text-gray-500");
  const api = useAppApi();
  const { t } = useTranslation("application");
  const locale = useLocale();

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  function fetchNotifications() {
    api.notifications.getNotificationsNotificationsGet().then((n) => {
      setNotifications(n);
      if (n.length === 0) {
        setColor("text-gray-400 hover:text-gray-300");
      } else {
        setColor("text-indigo-400 hover:text-indigo-300 ");
      }
    });
  }

  return (
    <Popover className="relative">
      <Popover.Button
        className={`${color} group inline-flex relative items-center px-2 py-2 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <div className="relative">
          <BellIcon className="h-6 w-6" aria-hidden="true" />
          {notifications.length > 0 && (
            <div className="absolute -top-2 right-0">
              <Font size="xs">{notifications.length}</Font>
            </div>
          )}
        </div>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-15 mt-3 -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
          {notifications.length === 0 && (
            <div className="border border-gray-400 border-dotted mt-1 p-4 rounded-md bg-white w-60">
              <div className="flex justify-center">
                <TrophyIcon width={24} />
              </div>
              <SimpleEmptyState
                title={t("header.notifications.emptystate.title")}
                description={t("header.notifications.emptystate.description")}
              />
            </div>
          )}
          {notifications.map((n, i) => {
            return (
              <div className="mt-2 w-screen">
                <NotificationWithActionsBelow
                  title={n.subject || ""}
                  content={n.message}
                  onDismiss={() => {
                    api.notifications
                      .getNotificationsNotificationsNotificationIdPut(n.id)
                      .then(() => {
                        fetchNotifications();
                      });
                  }}
                  actions={[
                    <TextButton
                      onClick={() => {
                        api.notifications
                          .getNotificationsNotificationsNotificationIdPut(n.id)
                          .then(() => {
                            fetchNotifications();
                          });
                      }}
                    >
                      {t("header.notification.ok")}
                    </TextButton>,
                  ]}
                />
              </div>
            );
          })}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
