import React, { PropsWithChildren } from "react";

export function Td(
  props: PropsWithChildren<{ first?: boolean; last?: boolean }>
) {
  const cls = props.first
    ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
    : props.last
    ? "relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3"
    : "whitespace-nowrap px-3 py-4 text-sm text-gray-500";

  return <td className={cls}>{props.children}</td>;
}
