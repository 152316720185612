import React, { ReactNode, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { CameraIcon } from "@heroicons/react/24/outline";

export function FileUploadWidget({
  title,
  subTitle,
  loading,
  onFilesSelected,
}: {
  title: ReactNode;
  subTitle: ReactNode;
  loading: boolean;
  onFilesSelected: (file: Blob) => void;
}) {
  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      onFilesSelected(file);
    });
  }, []);
  const [border, setBorder] = useState("border-gray-900/25");
  // @ts-ignore
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { t } = useTranslation("tailwind");

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`flex justify-center rounded-lg border border-dashed hover:border-indigo-700 h-full w-full ${border} px-6 py-6`}
        >
          <div className="text-center">
            {loading ? (
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  {t("common.loading")}
                </span>
              </div>
            ) : (
              <CameraIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
            )}
            <div className="mt-4 flex text-sm flex-col leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>{title}</span>
              </label>
              <p className="pl-1">{subTitle}</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              {t("fileUpload.restrictions")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
