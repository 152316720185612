/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PEM {
    WEBSHOP_CLIENT_CONTROLLER_CLIENT_DOES_NOT_EXIST = 'WEBSHOP_CLIENT_CONTROLLER_client_does_not_exist',
    TEMPLATE_CONTROLLER_UNABLE_TO_RENDER = 'TEMPLATE_CONTROLLER_unable_to_render',
    USER_CONTROLLER_MISSING_PERMISSION_UPDATE_USER_ROLE = 'USER_CONTROLLER_missing_permission_update_user_role',
    WEBSHOP_CLIENT_CONTROLLER_NO_AVAILABLE_BOOKING_SLOTS_FOUND = 'WEBSHOP_CLIENT_CONTROLLER_no_available_booking_slots_found',
    WEBSHOP_CLIENT_CONTROLLER_CANCELLATION_THRESHOLD_EXCEEDED = 'WEBSHOP_CLIENT_CONTROLLER_cancellation_threshold_exceeded',
    WEBSHOP_CLIENT_CONTROLLER_WEBSITE_NOT_FOUND = 'WEBSHOP_CLIENT_CONTROLLER_website_not_found',
    WEBSHOP_CLIENT_CONTROLLER_REQUESTED_BOOKING_NOT_AVAILABLE = 'WEBSHOP_CLIENT_CONTROLLER_requested_booking_not_available',
    SESSIONS_CONTROLLER_BOOKING_UNAVAILABLE = 'SESSIONS_CONTROLLER_booking_unavailable',
    SERVICES_CONTROLLER_SERVICE_NOT_FOUND = 'SERVICES_CONTROLLER_service_not_found',
    PRODUCT_CONTROLLER_BARCODE_IN_USE = 'PRODUCT_CONTROLLER_barcode_in_use',
    CLIENT_CONTROLLER_CLIENT_ALREADY_EXISTS = 'CLIENT_CONTROLLER_client_already_exists',
    USER_CONTROLLER_NOT_LOGGED_IN = 'USER_CONTROLLER_not_logged_in',
    USER_CONTROLLER_CVR_ALREADY_EXISTS = 'USER_CONTROLLER_cvr_already_exists',
    USER_CONTROLLER_URL_NOT_AVAILABLE = 'USER_CONTROLLER_url_not_available',
    USER_CONTROLLER_INVITATION_ALREADY_EXISTS = 'USER_CONTROLLER_invitation_already_exists',
    USER_CONTROLLER_USER_NOT_FOUND = 'USER_CONTROLLER_user_not_found',
    USER_CONTROLLER_MAX_REQUESTS_REACHED = 'USER_CONTROLLER_max_requests_reached',
    USER_CONTROLLER_INVALID_PHONE_NUMBER = 'USER_CONTROLLER_invalid_phone_number',
    USER_CONTROLLER_EMAIL_ALREADY_IN_USE = 'USER_CONTROLLER_email_already_in_use',
    USER_CONTROLLER_NOT_CONFIRMED = 'USER_CONTROLLER_not_confirmed',
    USER_CONTROLLER_CVR_NOT_FOUND = 'USER_CONTROLLER_cvr_not_found',
    USER_CONTROLLER_UNSUPPORTED_CHANNEL = 'USER_CONTROLLER_unsupported_channel',
    USER_CONTROLLER_INCORRECT_OTP = 'USER_CONTROLLER_incorrect_otp',
    USER_CONTROLLER_MUST_ACCEPT_TERMS = 'USER_CONTROLLER_must_accept_terms',
    USER_CONTROLLER_USER_SIGNUP_COMPLETED = 'USER_CONTROLLER_user_signup_completed',
    USER_CONTROLLER_NOTHING_TO_RESET = 'USER_CONTROLLER_nothing_to_reset',
    DINERO_CONTROLLER_ITEM_NOT_FOUND = 'DINERO_CONTROLLER_item_not_found',
    V1_NOT_LOGGED_IN = 'V1_not_logged_in',
    V1_USER_ROLE_REQUIRED = 'V1_user_role_required',
    V1_TENANT_MUST_BE_SUPPLIED_AS_SUBDOMAIN = 'V1_tenant_must_be_supplied_as_subdomain',
    V1_TENANT_NOT_FOUND = 'V1_tenant_not_found',
    V1_NOT_ALLOWED = 'V1_not_allowed',
    FILES_CONTROLLER_FILE_NOT_FOUND = 'FILES_CONTROLLER_file_not_found',
    PIGMENT_CONTROLLER_PIGMENT_NOT_FOUND = 'PIGMENT_CONTROLLER_pigment_not_found',
    PIGMENT_CONTROLLER_BASE_PIGMENT_NOT_FOUND = 'PIGMENT_CONTROLLER_base_pigment_not_found',
    WEBSHOP_STAFF_CONTROLLER_LOGO_NOT_FOUND = 'WEBSHOP_STAFF_CONTROLLER_logo_not_found',
    SMS_PAYMENT_CONTROLLER_PURCHASE_ALREADY_APPLIED = 'SMS_PAYMENT_CONTROLLER_purchase_already_applied',
    SMS_PAYMENT_CONTROLLER_PURCHASE_PENDING = 'SMS_PAYMENT_CONTROLLER_purchase_pending',
}
