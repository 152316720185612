import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppApi } from "../../../api/staff/AppApi";
import { useFeedback } from "../../../lib/useFeedback";
import { isValidEmail } from "../../../lib/utils";
import { Button, LoadingOverlay, Modal, TextButton } from "../../../Tailwind";
import { Input } from "../../../Tailwind/Input/Uncontrolled";
import { ModalProps } from "../../../lib/props";
import { SignInStep } from "./SignInSignUp";
import { t } from "i18next";

interface LoginWithPasswordModalProps extends ModalProps {
  step: SignInStep;
  email: string;
}

export function LoginWithPasswordModal(props: LoginWithPasswordModalProps) {
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState("");
  const [emailValidationError, setEmailValidationError] = useState<
    string | undefined
  >();
  const [resetPassword, setResetPassword] = useState(false);
  const { t } = useTranslation("application");
  const api = useAppApi();
  const feedback = useFeedback();

  useEffect(() => {
    if (emailValidationError && isValidEmail(email)) {
      setEmailValidationError(undefined);
    }
  }, [email]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  function login() {
    if (isValidEmail(email)) {
      api.auth.signIn(email, password, "password").catch((err) => {
        feedback.handleErrors(err);
        setEmail("");
        setPassword("");
      });
    } else {
      setEmailValidationError(t("loginWithPasswordModal.input.email.error")!);
    }
  }

  return (
    <Modal visible={props.visible} size={"xs"}>
      <div className="space-y-6">
        <div className="space-y-3">
          <Input
            name={"email"}
            value={email}
            placeholder={t("loginWithPasswordModal.input.email.placeholder")}
            onChange={(event) => setEmail(event.target.value)}
            description={t("loginWithPasswordModal.input.email.description")}
            label={t("loginWithPasswordModal.input.email.label")}
            type="email"
            error={emailValidationError}
            autoComplete="email"
          />
          <Input
            name={"password"}
            value={password}
            placeholder={t("loginWithPasswordModal.input.password.placeholder")}
            onChange={(event) => setPassword(event.target.value)}
            description={t("loginWithPasswordModal.input.password.description")}
            label={t("loginWithPasswordModal.input.password.label")}
            type="password"
            autoComplete="password"
          />
        </div>
        <Button
          full
          className="flex w-full justify-center"
          onClick={() => {
            login();
          }}
        >
          {t("loginWithPasswordModal.button.signIn")}
        </Button>
        <div className="flex justify-between ">
          <TextButton
            size="xs"
            onClick={() => {
              props.setVisible && props.setVisible(false);
            }}
          >
            <span aria-hidden="true">←</span>{" "}
            {t("loginWithPasswordModal.button.back")}
          </TextButton>
          <TextButton
            size="xs"
            onClick={() => {
              setResetPassword(true);
            }}
          >
            {t("loginWithPasswordModal.button.resetPassword")}
          </TextButton>
        </div>
      </div>
      <ResetPasswordModal
        visible={resetPassword}
        setVisible={() => {
          setResetPassword(false);
        }}
      />
    </Modal>
  );
}

function ResetPasswordModal(props: ModalProps) {
  const [email, setEmail] = useState("");
  const api = useAppApi();
  const { t } = useTranslation("application");
  const [loading, setLoading] = useState(false);
  const feedback = useFeedback();

  useEffect(() => {
    if (emailValidationError && isValidEmail(email)) {
      setEmailValidationError(undefined);
    }
  }, [email]);

  const [emailValidationError, setEmailValidationError] = useState<
    string | undefined
  >();

  return (
    <Modal {...props} size={"md"}>
      <LoadingOverlay loading={loading} />
      <div className="space-y-6">
        <Input
          name={"email"}
          value={email}
          placeholder={t("resetPasswordModal.input.email.placeholder")}
          onChange={(event) => setEmail(event.target.value)}
          description={t("resetPasswordModal.input.email.description")}
          label={t("resetPasswordModal.input.email.label")}
          type="email"
          error={emailValidationError}
          autoComplete="email"
        />
        <div className="flex gap-2">
          <Button
            color="secondary"
            onClick={() => props.setVisible && props.setVisible(false)}
          >
            {t("resetPasswordModal.button.cancel")}
          </Button>
          <Button
            full
            onClick={() => {
              if (!isValidEmail(email)) {
                setEmailValidationError(t("resetPasswordModal.error.email")!);
                return;
              }
              setLoading(true);
              api.users
                .resetPasswordUsersRequestResetPasswordPost({ email: email })
                .then(() => {
                  feedback.showSuccessToast(
                    t("resetPasswordModal.toast.success")
                  );
                  props.setVisible && props.setVisible(false);
                })
                .catch((err) => feedback.handleErrors(err))
                .finally(() => {
                  setEmail("");
                  setLoading(false);
                });
            }}
          >
            {t("resetPasswordModal.button.reset")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
