/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Direction {
    INCOMING = 'incoming',
    OUTGOING = 'outgoing',
}
