import {
  Button,
  List,
  LoadingOverlay,
  Paginator,
  Td,
  TextButton,
} from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import { Client } from "../../../../api/staff";
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Input } from "../../../../Tailwind/Input/Uncontrolled";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../../api/staff/AppApi";
import { ClientSlideOver } from "./ClientSlideOver";

export function ClientList() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editClient, setEditClient] = useState<Client | undefined>();

  const [data, setData] = useState<Client[]>([]);
  const [term, setTerm] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const { t } = useTranslation("application");
  const api = useAppApi();

  useEffect(() => {
    fetchData();
  }, [term, offset]);

  function fetchData() {
    setLoading(true);
    api.clients
      .searchClientsSearchClientsGet()
      .then((result) => {
        setData(result.results);
        setTotal(result.total);
        setNextOffset(result.offset);
        setPageSize(result.page_size);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <LoadingOverlay loading={loading} />
      <List
        searchWidget={
          <Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"max-w-30"}
            name={t("productsList.input.search")}
            onChange={(v) => {
              setTerm(v.target.value);
            }}
            value={term}
          />
        }
        title={t("clientList.list.title")}
        subtitle={t("clientList.list.subtitle")}
        data={data}
        renderLine={(client: Client) => (
          <>
            <Td first>{client.name}</Td>
            <Td>{client.email}</Td>
            <Td>{client.phone_number}</Td>
            <Td last>
              <TextButton
                onClick={() => {
                  setEditClient(client);
                }}
              >
                <div className={"p-2"}>
                  <PencilSquareIcon width={24} />
                </div>
              </TextButton>
            </Td>
          </>
        )}
        headers={[
          t("clientList.list.headers.name"),
          t("clientList.list.headers.email"),
          t("clientList.list.headers.phone"),
          "",
        ]}
        actions={[
          <Button
            onClick={() => {
              setVisible(true);
            }}
          >
            {t("clientList.button.create")}
          </Button>,
        ]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={(newOffset) => {
          setOffset(newOffset);
        }}
        onPrevious={(newOffset) => {
          setOffset(newOffset);
        }}
      />
      <ClientSlideOver
        visible={visible || editClient !== undefined}
        client={editClient}
        onClientSaved={(c) => {
          setVisible(false);
          setEditClient(undefined);
          fetchData();
        }}
        onCancel={() => {
          setEditClient(undefined);
          setVisible(false);
        }}
      />
    </>
  );
}
