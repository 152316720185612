import { Webshop } from "./models/Webshop";
import React from "react";
import { Client } from "./models/Client";

export interface ClientContextType {
  client: Client | undefined;
  setClient: (client: Client | undefined) => void;
}

export const ClientContext = React.createContext<ClientContextType>({
  client: undefined,
  setClient: () => undefined,
});
