import React from "react";
import { manipulate_image_url } from "../../../lib/utils";
import { Settings } from "../../../lib/settings";

export function Logo() {
  return (
    <div className="flex justify-start lg:w-0 lg:flex-1 z-10">
      <a href="/">
        <span className="sr-only">Parlorkit</span>
        <img
          className="h-8 w-auto sm:h-10"
          src={manipulate_image_url(Settings.textLogoDark, ["h_100"])}
          alt=""
        />
      </a>
    </div>
  );
}
