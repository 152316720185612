import { PropsWithChildren } from "react";

export function StackedListItem(props: PropsWithChildren<{ }>) {
  return (
    <li
      className="flex items-center justify-between gap-x-6 py-5"
    >
      {props.children}
    </li>
  );
}
