import { SettingsLayout } from "./SettingsLayout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import React from "react";
import { WebsiteForm } from "../WebsiteSettings/Website/WebsiteForm";

export function WebsiteSettings() {
  const { t } = useTranslation("application");

  return (
    <SettingsLayout active={"website"}>
      <WebsiteForm
        onSave={() => {
          toast.success(t("websiteSettings.toast.success.content"));
        }}
      />
    </SettingsLayout>
  );
}
