import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./translations/config";
import { WebshopRouter } from "./routers/WebshopRouter";
import { MarketingRouter } from "./routers/MarketingRouter";
import { AppRouter } from "./routers/AppRouter";
import mixpanel from "mixpanel-browser";

function App() {
  const [subdomain, setSubdomain] = useState(
    window.location.host.split(".")[0]
  );

  switch (subdomain) {
    case "www":
      return (
        <>
          <MarketingRouter />
          <ToastContainer />
        </>
      );
    case "parlorkit":
      return (
        <>
          <MarketingRouter />
          <ToastContainer />
        </>
      );
    case "app":
      return (
        <>
          <AppRouter />
          <ToastContainer />
        </>
      );
    default:
      return (
        <>
          <WebshopRouter />
          <ToastContainer />
        </>
      );
  }
}

export default App;
