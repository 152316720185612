import { Product, Service, UserRole } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import React, { useEffect, useState } from "react";
import { Button, Font, List, Paginator, Td } from "Tailwind";
import { Input } from "../../../../Tailwind/Input/Uncontrolled";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../lib/locale";

export function ProductPicker({
  onProductPicked,
}: {
  onProductPicked: (product: Product) => void;
}) {
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [term, setTerm] = useState("");

  const api = useAppApi();
  const { t } = useTranslation("application");
  const locale = useLocale();

  useEffect(() => {
    api.products
      .searchProductsSearchProductsGet(term, offset)
      .then((response) => {
        setTotal(response.total);
        setNextOffset(response.offset);
        setPageSize(response.page_size);
        setProducts(response.results);
      });
  }, [term, offset]);

  return (
    <>
      <List
        title={t("productPicker.list.title")}
        subtitle={t("productPicker.list.subtitle")}
        data={products}
        renderLine={(product: Product) => {
          return (
            <>
              <Td first>
                <Font weight="bold">{product.name}</Font>
              </Td>
              <Td>
                <Font>{locale.currency.format(product.price)}</Font>
              </Td>
              <Td last>
                <Button
                  color="secondary"
                  onClick={() => onProductPicked(product)}
                >
                  <div className="flex justify-between gap-2">
                    <div>{t("productPicker.button.select")}</div>
                    <ChevronRightIcon width={18} />
                  </div>
                </Button>
              </Td>
            </>
          );
        }}
        headers={[
          t("productPicker.list.headers.name"),
          t("productPicker.list.headers.price"),
          "",
        ]}
        searchWidget={
          <Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"max-w-30"}
            name={"search"}
            onChange={(v) => {
              setTerm(v.target.value);
              setOffset(0);
            }}
            value={term}
          />
        }
        actions={[]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={setOffset}
        onPrevious={setOffset}
      />
    </>
  );
}
