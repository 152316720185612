/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_nothing_to_reset___USER_CONTROLLER_nothing_to_reset____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_nothing_to_reset___USER_CONTROLLER_nothing_to_reset____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_nothing_to_reset___USER_CONTROLLER_nothing_to_reset____ {

    export enum detail {
        USER_CONTROLLER_NOTHING_TO_RESET = 'USER_CONTROLLER_nothing_to_reset',
    }


}

