import { RadioGroup } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function ColorPicker({
  selectedColor,
  onClick,
  colors,
}: {
  colors: string[];
  selectedColor: string;
  onClick: (color: string) => void;
}) {
  return (
    <RadioGroup value={selectedColor} onChange={onClick}>
      <div className="mt-4 flex flex-wrap items-center gap-4">
        {colors.map((color, index) => (
          <RadioGroup.Option
            key={index}
            value={color}
            style={{ backgroundColor: color }}
            className={({ active, checked }) =>
              classNames(
                active && checked ? "ring ring-offset-2" : "",
                !active && checked ? "ring-2 ring-offset-" : "",
                "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
              )
            }
          >
            <div
              aria-hidden="true"
              style={{ backgroundColor: color }}
              className={classNames(
                "h-12 w-12 rounded-full border border-black border-opacity-10 flex justify-center"
              )}
            ></div>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
