import React, { PropsWithChildren } from "react";
import { TextButton } from "../../../Tailwind";
import { useAppApi } from "../../../api/staff/AppApi";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Ink2 } from "../../../video/backgrounds/Ink2";

export function OnboardingContainer(props: PropsWithChildren<{}>) {
  const api = useAppApi();
  const { t } = useTranslation("application");

  return (
    <>
      <div className="mt-8 z-0 pb-8 mx-auto max-w-5xl sm:rounded-md lg:px-8">
        {props.children}
      </div>
      <div className="fixed left-6 bottom-5">
        <TextButton
          color="indigo400"
          size="xs"
          onClick={() => {
            api.auth.logout();
          }}
        >
          {t("onboardingContainer.button.logout")}
        </TextButton>
      </div>
    </>
  );
}
