import React, { useEffect, useState } from "react";
import { File } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { manipulate_image_file } from "../../../../lib/utils";
import { FileUploadWidget } from "Tailwind/Files";
import { LoadingOverlay } from "Tailwind";
import { useTranslation } from "react-i18next";

export function Gallery({
  onImageClicked,
}: {
  onImageClicked: (image: File) => void;
}) {
  const [images, setImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const api = useAppApi();
  const { t } = useTranslation("application");

  useEffect(() => {
    setLoading(true);
    api.files.getGalleryGalleryGet().then((g) => {
      setImages(g);
      setLoading(false);
    });
  }, []);

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4">
      <LoadingOverlay loading={loading} />
      <div className="col-span-4">
        <FileUploadWidget
          loading={loading}
          onFilesSelected={(file) => {
            setLoading(true);
            api.files
              .uploadToGalleryGalleryPost({ uploaded_file: file })
              .then((f) => {
                api.files.getGalleryGalleryGet().then((g) => {
                  setImages(g);
                  setLoading(false);
                });
              });
          }}
          title={t("gallery.title")}
          subTitle={t("gallery.subTitle")}
        />
      </div>
      {images.map((image, i) => {
        return (
          <div key={i} className="col-span-1">
            <GalleryTile
              file={image}
              onClick={() => {
                onImageClicked(image);
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export function GalleryTile({
  file,
  onClick,
}: {
  file: File;
  onClick: () => void;
}) {
  return (
    <a className="" onClick={() => onClick()}>
      <div className="cursor-pointer aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md shadow-md lg:aspect-none hover:opacity-75">
        <img
          src={manipulate_image_file(file, ["w_200"])}
          className="object-cover object-center"
        />
      </div>
    </a>
  );
}
