export const Settings = {
  fullLogoUrlDark:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686316787/brand/dark/Full_Logo_Dark_Gradient_Gray_rrc5zc.png",
  backgroundLogoUrlDark:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686316660/brand/dark/Text_Logo_Dark_Gradient_Background_yxyphq.png",
  fullLogoUrlLight:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686059325/brand/light/Full_Logo_White_f35vts.png",
  appLogoUrlLight:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686316659/brand/dark/App_Icon_Dark_z5aona.png",
  textLogoLight:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686329231/brand/light/Text_Light_nufzky.png",
  textLogoDark:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686329216/brand/dark/Text_Dark_zu30om.png",
  whiteBackgroundLogo:
    "https://res.cloudinary.com/dpxaexudg/image/upload/v1686330073/brand/dark/Text_Logo_White_Background_Dark_larcro.png",
};
