import React from "react";

export function LoadingScreen() {
    return <div style={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        zIndex: 1001
    }}>
        <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%)"}}>

        </div>
    </div>
}