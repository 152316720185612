import { Settings } from "../../../lib/settings";
import { Font } from "../../../Tailwind";
import { Link } from "react-router-dom";
import { CogIcon, UserIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Navigation, Page } from "./Layout";
import { useAppApi } from "../../../api/staff/AppApi";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function SidebarMobile({
  navigation,
  administration,
  active,
}: {
  navigation: Navigation[];
  administration: Navigation[];
  active: Page;
}) {
  const api = useAppApi();
  const { t } = useTranslation("application");

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <div className="relative">
          <img
            className="h-8 w-auto"
            src={Settings.textLogoDark}
            alt="Parlorkit"
          />
          {api.plan === "Pro" ? (
            <div className="absolute -right-6 top-2">
              <Font color="amber500" weight="bold" size="xs">
                Pro
              </Font>
            </div>
          ) : (
            <div className="absolute -right-8 top-2">
              <Font color="teal500" weight="light" size="xs">
                Basic
              </Font>
            </div>
          )}
        </div>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.name === active
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === active
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {(api.userProfile?.current_role?.role === "Owner" ||
            api.userProfile?.current_role?.role === "Manager") && (
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                {t("layout.navigation.divider")}
              </div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {administration.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.name === active
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.name === active
                            ? "text-indigo-600"
                            : "text-gray-400 group-hover:text-indigo-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
          <li className="mt-auto">
            <Link
              to="/profile/user"
              className={classNames(
                active === "profile"
                  ? "bg-gray-50 text-indigo-600"
                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold mb-2"
              )}
            >
              <UserIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                aria-hidden="true"
              />
              {t("layout.link.profile")}
            </Link>
            <Link
              to="/settings/website"
              className={classNames(
                active === "settings"
                  ? "bg-gray-50 text-indigo-600"
                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              )}
            >
              <CogIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                aria-hidden="true"
              />
              {t("layout.link.settings")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
