import { PropsWithChildren, useEffect, useState } from "react";
import { useAppApi } from "../../api/staff/AppApi";
import { useWwwApi } from "../../api/www/WwwApi";
import Maintenance from "../../pages/Maintenance";

export function ApiPage(props: PropsWithChildren<any>) {
  const [online, setOnline] = useState(true);
  const [i, setI] = useState<any>();
  const api = useWwwApi();

  useEffect(() => {
    checkHealth();
  }, []);

  useEffect(() => {
    if (!online) {
      const interval = setInterval(() => {
        checkHealth();
      }, 3000);
      setI(interval);
      return () => clearInterval(interval);
    } else {
      clearInterval(i);
    }
  }, [online]);

  function checkHealth() {
    api.api
      .healthHealthGet()
      .then(() => setOnline(true))
      .catch((reason) => {
        setOnline(false);
      });
  }

  return online ? <>{props.children}</> : <Maintenance />;
}
