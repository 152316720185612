import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ModalProps } from "../../lib/props";

export function Modal(
  props: PropsWithChildren<
    {
      size?: "full" | "5xl" | "4xl" | "3xl" | "2xl" | "xl" | "md" | "sm" | "xs";
      title?: ReactNode;
    } & ModalProps
  >
) {
  let width = props.size ? `max-w-${props.size}` : "max-w-4xl";

  return (
    <Transition appear show={props.visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={props.setVisible ? props.setVisible : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-300"
          leave="ease-in duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${width}`}
              >
                {props.visible && (
                  <>
                    {props.title && <div className="mb-8">{props.title}</div>}
                    {props.children}
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
