/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { NotificationSettings } from '../models/NotificationSettings';
import type { NotificationSettingsResponse } from '../models/NotificationSettingsResponse';
import type { PermissionChange } from '../models/PermissionChange';
import type { PermissionStatuses } from '../models/PermissionStatuses';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationsService {

    /**
     * Get Notification Settings
     * @returns NotificationSettingsResponse Successful Response
     * @throws ApiError
     */
    public static getNotificationSettingsSettingsNotificationsGet(): CancelablePromise<NotificationSettingsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/notifications',
        });
    }

    /**
     * Get Notification Settings
     * @param requestBody
     * @returns NotificationSettings Successful Response
     * @throws ApiError
     */
    public static getNotificationSettingsSettingsNotificationsPut(
        requestBody: NotificationSettings,
    ): CancelablePromise<NotificationSettings> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/settings/notifications',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Notifications
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static getNotificationsNotificationsGet(): CancelablePromise<Array<Notification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notifications',
        });
    }

    /**
     * Get Notifications
     * @param notificationId
     * @returns Notification Successful Response
     * @throws ApiError
     */
    public static getNotificationsNotificationsNotificationIdPut(
        notificationId: number,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/notifications/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Permissions
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static getPermissionsPermissionsGet(): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/permissions',
        });
    }

    /**
     * Update Permission
     * @param requestBody
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static updatePermissionPermissionsPut(
        requestBody: PermissionChange,
    ): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/permissions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
