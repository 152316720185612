import React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import PinInput from "react-pin-input";
import VerificationInput from "react-verification-input";

export interface InputProps {
  label?: string | null;
  errorText?: string;
  disabled?: boolean | undefined;
  id?: string | undefined;
  description?: string | null;
  placeholder?: string | null;
  className?: string | undefined;
  autoComplete?: string | undefined;
  error?: string;
  value: string;
  onChange: (otp: string) => void;
  autofocus?: boolean;
  dark?: boolean;
}

export function OneTimeCodeInput(props: InputProps) {
  return (
    <div>
      <label
        className={
          props.dark
            ? "block text-sm -mt-6 font-medium leading-6 text-white"
            : "block text-sm -mt-6 font-medium leading-6 text-gray-900"
        }
      >
        {props.label}
      </label>
      <div>
        <VerificationInput
          classNames={{
            container: "w-full",
            character: "border-indigo-400 rounded-md",
          }}
          validChars="0-9"
          value={props.value}
          onChange={(v) => props.onChange(v)}
          autoFocus={props.autofocus}
          placeholder={""}
        />
      </div>

      {props.error && (
        <p
          className={
            props.dark
              ? "mt-2 text-sm text-red-300"
              : "mt-2 text-sm text-red-600"
          }
        >
          {props.error}
        </p>
      )}
      {props.description && (
        <p
          className={
            props.dark
              ? "mt-3 text-sm leading-6 text-gray-300 -translate-y-3"
              : "mt-3 text-sm leading-6 text-gray-600 -translate-y-3"
          }
        >
          {props.description}
        </p>
      )}
    </div>
  );
}
