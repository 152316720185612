import { Button, DividerWithLabel } from "../../../Tailwind";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../api/staff/AppApi";
import { AccountCreate } from "../../../api/staff";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Input as Inpt } from "../../../Tailwind/Input/Controlled";

export function CreateAccountForm(props: { onComplete: () => void }) {
  const { t } = useTranslation("application");

  return (
    <>
      <>
        <div className={"my-10"}>
          <DividerWithLabel
            label={t("createAccountOnboarding.divider.details.label")}
          />
        </div>
        <CVRAccountForm onComplete={props.onComplete} />
      </>
    </>
  );
}

function CVRAccountForm(props: { onComplete: () => void }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AccountCreate>({
    defaultValues: {
      company_name: "",
      address: "",
      url: "",
      country: "Denmark",
      currency: "DKK",
      vat_number: "",
    },
  });

  const [urlHelperText, setUrlHelperText] = useState(
    <p className="mt-2 text-sm text-gray-600"></p>
  );

  const { t } = useTranslation("application");
  const { misc, users, auth } = useAppApi();

  useEffect(() => {
    reset({
      company_name: "",
      address: "",
      url: "",
      country: "Denmark",
      currency: "DKK",
      vat_number: "",
    });
  }, []);

  function checkUrl(url?: string) {
    if (url) {
      misc
        .checkAvailabilitySettingsWebshopCheckAvailabilityGet(url)
        .then((r) => {
          if (r.available) {
            setUrlHelperText(
              <p className="mt-2 text-sm text-gray-600">
                https://{r.slug}.parlorkit.com
              </p>
            );
          } else {
            setUrlHelperText(
              <p className="mt-2 text-sm text-red-600">
                {`https://${r.slug}.parlorkit.com ${t(
                  "createAccount.UrlNotAvailable"
                )}`}
              </p>
            );
          }
        })
        .catch(() => {
          setUrlHelperText(
            <p className="mt-2 text-sm text-red-600">
              ${t("createAccount.UrlNotAvailable")}
            </p>
          );
        });
    } else {
      setUrlHelperText(<p className="mt-2 text-sm text-gray-600"></p>);
    }
  }

  return (
    <form
      className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
      onSubmit={handleSubmit((data) => {
        users.createAccountAccountPost(data).then((a) => {
          auth
            .switchRole(a.user_role_id)
            .then(() => {
              props.onComplete();
            })
            .catch((e) => {});
        });
      })}
    >
      <Inpt
        className="sm:col-span-3"
        label={t("createAccountForm.input.company_name.label")}
        register={register("company_name", {
          required: t("createAccountForm.input.company_name.required")!,
        })}
        errors={errors}
      />
      <Inpt
        disabled
        label={t("createAccountForm.input.country.label")}
        register={register("country")}
        errors={errors}
        className="sm:col-span-2"
      />
      <Inpt
        disabled
        label={t("createAccountForm.input.currency.label")}
        register={register("currency")}
        errors={errors}
        className="sm:col-span-1"
      />
      <div className="sm:col-span-3">
        <Inpt
          label={t("createAccountForm.input.sms_sender_id.label")}
          description={t("createAccountForm.input.sms_sender_id.description")}
          register={register("sms_sender_id", {
            maxLength: 11,
            pattern: /^[A-Za-z0-9 \+\-_]*$/,
            required: t("createAccountForm.input.sms_sender_id.validation")!,
          })}
          errors={errors}
        />
      </div>
      <div className="sm:col-span-3">
        <Inpt
          label={t("createAccountForm.input.website_url.label")}
          register={register("url", {
            onChange: (e) => {
              checkUrl(e.target.value);
            },
            onBlur: (e) => {},
            required: t("createAccountForm.input.website_url.required")!,
          })}
          errors={errors}
        />
        <div>{urlHelperText}</div>
      </div>

      <div className="col-span-full flex justify-end">
        <Button className="sm:col-span-2" type="submit">
          {t("createAccountForm.button.create")}
        </Button>
      </div>
    </form>
  );
}
