import React, { useEffect, useState } from "react";
import { Layout } from "../../Layout/Layout";
import { useAppApi } from "../../../../api/staff/AppApi";
import { PaymentInformation } from "../../../../api/staff";
import { LoadingOverlay } from "../../../../Tailwind";

export function SMSPricingTable() {
  const [loading, setLoading] = useState(false);
  const [paymentInformation, setPaymentInformation] =
    useState<PaymentInformation>();
  const api = useAppApi();

  useEffect(() => {
    setLoading(true);
    api.misc.getPaymentInformationPaymentInformationGet().then((p) => {
      setPaymentInformation(p);
      setLoading(false);
    });
  }, []);

  return (
    <Layout active="services">
      {!paymentInformation ? (
        <LoadingOverlay loading={true} />
      ) : (
        <>
          <stripe-pricing-table
            success-url={paymentInformation.success_url}
            pricing-table-id={paymentInformation.pricing_table_id}
            publishable-key={paymentInformation.publishable_key}
            customer-email={paymentInformation.customer_email}
            client-reference-id={paymentInformation.customer_reference}
          ></stripe-pricing-table>
        </>
      )}
    </Layout>
  );
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
