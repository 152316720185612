import { useEffect, useMemo, useState } from "react";
import { format, formatDuration } from "date-fns";
import { da, enGB, enUS } from "date-fns/locale";
import i18next, { t } from "i18next";
import en from "../translations/locales/en";
import { useAppApi } from "../api/staff/AppApi";
import { SelectItem } from "../Tailwind/Input/Controlled/NativeSelect";
import { Professions } from "../api/staff";
import { useTranslation } from "react-i18next";

export const LocaleNamespaces = {
  AUTH: "auth",
  CALENDAR: "calendar",
  TAILWIND: "tailwind",
  ONBOARDING: "onboarding",
};

const localeMap = {
  en: enGB,
  da: da,
};

export function useLocale() {
  const [locale, setLocale] = useState<Locale>(da);
  const api = useAppApi();
  const { t } = useTranslation("application");

  const currencyFormatter = useMemo(() => {
    if (i18next.language && api.userProfile) {
      return Intl.NumberFormat(i18next.language, {
        style: "currency",
        currency: api.userProfile?.current_role?.account.currency || "DKK",
      });
    } else {
      return Intl.NumberFormat(i18next.language, {
        style: "currency",
        currency: "EUR",
      });
    }
  }, [i18next.language, api.userProfile]);

  useEffect(() => {
    if (i18next.language === "da") {
      setLocale(localeMap["da"]);
    } else {
      setLocale(localeMap["en"]);
    }
  }, [i18next.language]);

  function localeDateWithDay(dt: Date) {
    if (locale === da) {
      return format(dt, "EEEE d MMMM", { locale: locale });
    } else {
      return format(dt, "EEEE MMMM d", { locale: locale });
    }
  }

  function localeDateTime(dt: Date) {
    if (locale === da) {
      return format(dt, "EEEE d MMMM HH:mm", { locale: locale });
    } else {
      return format(dt, "EEEE MMMM d HH:mm", { locale: locale });
    }
  }

  function timeShort(dt: Date) {
    return format(dt, "HH:mm", { locale: locale });
  }

  function fmt(dt: Date, flags: string) {
    return format(dt, flags, { locale: locale });
  }

  function localeDay(dt: Date) {
    const day = format(dt, "EEEE ", { locale: locale });
    return day.charAt(0).toUpperCase() + day.slice(1);
  }

  function duration(d: number) {
    let hours = d / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    return formatDuration(
      {
        hours: rhours,
        minutes: rminutes,
      },
      { format: ["hours", "minutes"], locale: locale }
    );
  }

  function switchLanguage(locale: string) {
    const pathname = window.location.pathname;
    const prefix = pathname.split("/")[1];
    const rest = pathname.split("/").slice(2);
    const newPath = "/" + locale + "/" + rest.join("/");
    i18next.changeLanguage(locale);
    if (locale !== prefix) {
      window.location.replace(newPath);
    }
  }

  function addLanguagePrefix(locale: string) {
    const pathname = window.location.pathname;
    const query = pathname.split("?");
    const rest = pathname.split("/").slice(1);
    const newPath = "/" + locale + "/" + rest.join("/");
    i18next.changeLanguage(locale);
    window.location.replace(`${newPath}${window.location.search}`);
  }

  function detectLanguage() {
    const pathname = window.location.pathname;

    // Extract the prefix from the current location
    const prefix = pathname.split("/")[1];
    switch (prefix) {
      case "en":
        switchLanguage("en");
        setLocale(localeMap["en"]);
        return "en";
      case "da":
        switchLanguage("da");
        setLocale(localeMap["da"]);
        return "da";
      default:
        let lang = window.navigator.language;
        if (lang.includes("-")) {
          lang = lang.split("-")[0]; // Extract the primary code
        }
        if (lang === "da" || lang === "en") {
          addLanguagePrefix(lang);
          setLocale(localeMap[lang]);
          return lang;
        } else {
          addLanguagePrefix("en");
          setLocale(localeMap["en"]);
          return "en";
        }
    }
  }

  const professions: SelectItem[] = [
    { label: t("createUserModal.profession.other"), value: Professions.OTHER },
    {
      label: t("createUserModal.profession.beautician"),
      value: Professions.BEAUTICIAN,
    },
    {
      label: t("createUserModal.profession.tattooArtist"),
      value: Professions.TATTOO_ARTIST,
    },
    {
      label: t("createUserModal.profession.barber"),
      value: Professions.BARBER,
    },
    {
      label: t("createUserModal.profession.skincareSpecialist"),
      value: Professions.SKINCARE_SPECIALIST,
    },
    {
      label: t("createUserModal.profession.massageTherapist"),
      value: Professions.MASSAGE_THERAPIST,
    },
    {
      label: t("createUserModal.profession.bodyPiercer"),
      value: Professions.BODY_PIERCER,
    },
    {
      label: t("createUserModal.profession.hairdresser"),
      value: Professions.HAIRDRESSER,
    },
    {
      label: t("createUserModal.profession.manicurist"),
      value: Professions.MANICURIST,
    },
  ];

  return {
    currentLocale: locale,
    localeDateWithDay: localeDateWithDay,
    localeDay: localeDay,
    timeShort: timeShort,
    duration: duration,
    localeDateTime: localeDateTime,
    detectLanguage: detectLanguage,
    switchLanguage: switchLanguage,
    format: fmt,
    professions: professions,
    currency: currencyFormatter,
  };
}
