import { Layout } from "../../Layout/Layout";
import React from "react";
import BusinessHours from "../Profile/BusinessHours/BusinessHours";

export function BusinessHoursPage() {
  return (
    <Layout active={"sessions"}>
      <BusinessHours showUserRoles={true} />
    </Layout>
  );
}
