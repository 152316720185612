import { Tab } from "@headlessui/react";
import { Modal } from "Tailwind/Modal/Modal";
import { useTranslation } from "react-i18next";
import { DateTimePicker, Input } from "Tailwind/Input/Uncontrolled";
import { ClockIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Button } from "Tailwind/Button";
import { Datepicker } from "Tailwind/Calendar/Datepicker";
import { addMonths } from "date-fns";
import { useAppApi } from "api/staff/AppApi";
import { addZeroes } from "../../../../../lib/utils";
import { UserRole } from "api/staff";

export function PickTimeModal(props: {
  isOpen: boolean;
  employee: UserRole;
  start?: Date;
  onClose: () => void;
  duration: number;
  onTimePicked: (start: Date, duration: number) => void;
}) {
  const { t } = useTranslation("application");

  return (
    <Modal visible={props.isOpen} setVisible={props.onClose}>
      <Tab.Group>
        <Tab.List className="-mb-px flex space-x-8">
          <Tab
            className={({ selected }) =>
              selected
                ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            }
          >
            {t("pickTimeModal.tab.calendar")}
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            }
          >
            {t("pickTimeModal.tab.manual")}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="mt-8">
            <CalendarPick
              employee={props.employee}
              selectedDate={props.start}
              duration={props.duration}
              onChange={props.onTimePicked}
            />
          </Tab.Panel>
          <Tab.Panel className="mt-8">
            <ManualPick
              start={props.start || new Date()}
              duration={props.duration}
              onChange={props.onTimePicked}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
}

function CalendarPick(props: {
  duration: number;
  employee: UserRole;
  selectedDate?: Date;
  onChange: (start: Date, duration: number) => void;
}) {
  const [duration, setDuration] = useState(props.duration);

  const [calendarDays, setCalendarDays] = useState<
    { date: Date; showDot: boolean; slots: Date[] }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState(
    props.selectedDate ? props.selectedDate : new Date()
  );
  const [availableSlots, setAvailableSlots] = useState<Date[]>([]);
  const { t } = useTranslation("application");
  const api = useAppApi();

  useEffect(() => {
    if (duration.toString(10) === "") {
      setAvailableSlots([]);
      return;
    }
    api.bookings
      .getBookingCalendarBookingCalendarYearMonthGet(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        duration,
        undefined,
        props.employee.id
      )
      .then((c) => {
        const month = c.map((d) => {
          return {
            date: new Date(d.current_day),
            showDot: d.bookable_slots.length > 0,
            slots: d.bookable_slots.map((s) => new Date(s)),
          };
        });
        setCalendarDays(month);
      });
  }, [selectedDate, duration]);

  useEffect(() => {
    const slots = calendarDays.find(
      (c) => c.date.toDateString() === selectedDate.toDateString()
    );
    setAvailableSlots(slots ? slots.slots : []);
  }, [calendarDays]);

  return (
    <div>
      <Input
        name="duration"
        label={t("calendarPick.input.duration.label")}
        onChange={(d) => {
          setDuration(d.target.value);
        }}
        value={duration}
        leftDecoration={<ClockIcon className={"w-5 h-5"} />}
        rightDecoration={t("calendarPick.input.duration.unit")}
        error={
          duration.toString(10) === ""
            ? t("calendarPick.input.error.duration")!
            : ""
        }
      />
      <Datepicker
        days={calendarDays}
        onPreviousMonth={() => {
          setSelectedDate(addMonths(selectedDate, -1));
        }}
        onNextMonth={() => {
          setSelectedDate(addMonths(selectedDate, 1));
        }}
        selected={selectedDate}
        onChange={(d) => {
          setSelectedDate(d);
        }}
      />
      <div className="mt-2 grid grid-cols-4 gap-2">
        {availableSlots.map((slot, index) => {
          const isSelectedTimeslot =
            slot.toDateString() == selectedDate.toDateString() &&
            slot.getHours() === selectedDate.getHours() &&
            slot.getMinutes() === selectedDate.getMinutes();
          return (
            <div key={index} className="flex flex-grow ">
              <Button
                className={"flex-grow gap-2"}
                color={isSelectedTimeslot ? "primary" : "secondary"}
                onClick={() => props.onChange(slot, duration)}
              >
                {addZeroes(slot.getHours())}:{addZeroes(slot.getMinutes())}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function ManualPick(props: {
  start: Date;
  duration: number;
  onChange: (start: Date, duration: number) => void;
}) {
  const { t } = useTranslation("application");
  const [start, setStart] = useState(new Date(props.start.getTime()));
  const [duration, setDuration] = useState(props.duration);

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-1 sm:col-span-3">
        <DateTimePicker
          label={t("manualPick.dateInput.start.label")}
          date={start}
          onChange={(d) => {
            setStart(d);
          }}
        />
      </div>
      <div className="col-span-1 sm:col-span-3">
        <Input
          name="duration"
          label={t("manualPick.input.duration.label")}
          onChange={(d) => {
            setDuration(d.target.value);
          }}
          value={duration}
          leftDecoration={<ClockIcon className={"w-5 h-5"} />}
          rightDecoration={t("manualPick.input.duration.unit")}
        />
      </div>
      <div className="mt-8 col-span-1 sm:col-span-6 flex justify-end">
        <Button onClick={() => props.onChange(start, duration)}>
          {t("manualPick.button.use")}
        </Button>
      </div>
    </div>
  );
}
