import { Logo } from "../../MarketingSection/Components/Logo";
import React from "react";
import { Button, LanguageSelector } from "Tailwind";
import { useTranslation } from "react-i18next";
import { useAppApi } from "../../../api/staff/AppApi";
import { Ink2 } from "../../../video/backgrounds/Ink2";
import { Ink1 } from "../../../video/backgrounds/Ink1";

export function OnboardingHeader() {
  return (
    <header>
      <Ink1 show={true} />
      <div>
        <div className="mx-auto flex items-center justify-between px-4 py-6 sm:px-6 lg:px-8">
          <Logo />
          <LanguageSelector dark />
        </div>
      </div>
    </header>
  );
}
