/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_CLIENT_CONTROLLER_client_already_exists___CLIENT_CONTROLLER_client_already_exists____ = {
    detail: PS_Literal__PEM_CLIENT_CONTROLLER_client_already_exists___CLIENT_CONTROLLER_client_already_exists____.detail;
};

export namespace PS_Literal__PEM_CLIENT_CONTROLLER_client_already_exists___CLIENT_CONTROLLER_client_already_exists____ {

    export enum detail {
        CLIENT_CONTROLLER_CLIENT_ALREADY_EXISTS = 'CLIENT_CONTROLLER_client_already_exists',
    }


}

