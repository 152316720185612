import React from "react";
import { Header } from "../Components/Header";
import { Footer } from "../Components/Footer";
import { MarketingPageLayout } from "../MarketingPageLayout";

export function Terms() {
  return (
    <MarketingPageLayout>
      <div className="relative mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p className="c0 my-1 mb-2">
          <span className="c1 text-2xl">Terms of use</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">Last updated: June 06, 2022</span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-4">
          <span className="c1">TABLE OF CONTENTS</span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">AGREEMENT TO TERMS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">INTELLECTUAL PROPERTY RIGHTS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">USER REPRESENTATIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">FEES AND PAYMENT</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">CANCELLATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">PROHIBITED ACTIVITIES</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">USER GENERATED CONTRIBUTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">CONTRIBUTION LICENSE</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">SUBMISSIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">SERVICES MANAGEMENT</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">TERM AND TERMINATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">MODIFICATIONS AND INTERRUPTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">GOVERNING LAW</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">DISPUTE RESOLUTION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">CORRECTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">DISCLAIMER</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">LIMITATIONS OF LIABILITY</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">INDEMNIFICATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">USER DATA</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">MISCELLANEOUS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">CONTACT US</span>
        </p>
        <p className="c0 my-1 mt-4">
          <span className="c1">1. AGREEMENT TO TERMS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity
            (&ldquo;you&rdquo;) and Parlorkit (&ldquo;Company&ldquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;),
            concerning your access to and use of the Parlorkit Site and services
            as well as any other media form, media channel, website, mobile
            website or mobile application related, linked, or otherwise
            connected thereto (collectively, the &ldquo;Services&rdquo;). You
            agree that by accessing the services, you have read, understood, and
            agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE
            WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            &nbsp;Supplemental terms and conditions or documents that may be
            posted on the Website from time to time are hereby expressly
            incorporated herein by reference. We reserve the right, in our sole
            discretion, to make changes or modifications to these Terms of Use
            at any time and for any reason. We will alert you about any changes
            by updating the &ldquo;Last updated&rdquo; date of these Terms of
            Use, and you waive any right to receive specific notice of each such
            change. Please ensure that you check the applicable Terms every time
            you use our Services so that you understand which Terms apply. You
            will be subject to, and will be deemed to have been made aware of
            and to have accepted, the changes in any revised Terms of Use by
            your continued use of the Site after the date such revised Terms of
            Use are posted.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            &nbsp;The information provided through the Services is not intended
            for distribution to or use by any person or entity in any
            jurisdiction or country where such distribution or use would be
            contrary to law or regulation or which would subject us to any
            registration requirement within such jurisdiction or country.
            Accordingly, those persons who choose to access the Services from
            other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent
            local laws are applicable.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">2. INTELLECTUAL PROPERTY RIGHTS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Unless otherwise indicated, the Services is our proprietary property
            and all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the
            Services(collectively, the &ldquo;Content&rdquo;) and the
            trademarks, service marks, and logos contained therein (the
            &ldquo;Marks&rdquo;) are owned or controlled by us or licensed to
            us, and are protected by copyright and trademark laws and various
            other intellectual property rights and unfair competition laws of
            the United States, international copyright laws, and international
            conventions. The Content and the Marks are provided on the Services
            &ldquo;AS IS&rdquo; for your information and personal use only.
            Except as expressly provided in these Terms of Use, no part of the
            Services and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Provided that you are eligible to use the Services, you are granted
            a limited license to access and use the Services and to download or
            print a copy of any portion of the Content to which you have
            properly gained access solely for your personal, non-commercial use.
            We reserve all rights not expressly granted to you in and to the
            Services, the Content and the Marks.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">3. USER REPRESENTATIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            By using the Services, you represent and warrant that: (1) you have
            the legal capacity and you agree to comply with these Terms of Use;
            (2) you are not a minor in the jurisdiction in which you reside; (3)
            you will not access the Services through automated or non-human
            means, whether through a bot, script or otherwise; (4) you will not
            use the Services for any illegal or unauthorized purpose; and (5)
            your use of the Site will not violate any applicable law or
            regulation.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services(or any portion thereof).
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">4. FEES AND PAYMENT</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">We accept the following forms of payment:</span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Through the respective app stores, and through any forms of peyments
            allowed by our current payment processing platform.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You may be required to purchase or pay a fee to access some of our
            services. You agree to provide current, complete, and accurate
            purchase and account information for all purchases made via the
            Services. You further agree to promptly update account and payment
            information, including email address, payment method, and payment
            card expiration date, so that we can complete your transactions and
            contact you as needed. We bill you through an online billing account
            for purchases made via the Services. Sales tax will be added to the
            price of purchases as deemed required by us. We may change prices at
            any time.{" "}
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You agree to pay all charges or fees at the prices then in effect
            for your purchases, and you authorize us to charge your chosen
            payment provider for any such amounts upon making your purchase.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We reserve the right to correct any errors or mistakes in pricing,
            even if we have already requested or received payment. We also
            reserve the right to refuse any order placed through the Services.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">5. CANCELLATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            &nbsp;You can cancel your subscription at any time. Your
            cancellation will take effect at the end of the current paid term.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            If your plan have been subscribed through a mobile app store, you
            will need to cancel a subscription through the same channel.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            If you are unsatisfied with our services, please email us at
            info@parlorkit.com.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">6. PROHIBITED ACTIVITIES</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You may not access or use the Services for any purpose other than
            that for which we make them available. The Services may not be used
            in connection with any commercial endeavors except those that are
            specifically endorsed or approved by us.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">&nbsp;</span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            As a user of the Services, you agree not to:
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Systematically retrieve data or other content from the Services to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Circumvent, disable, or otherwise interfere with security-related
            features of the Services, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the Services and/or the Content contained therein.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Use any information obtained from the Services in order to harass,
            abuse, or harm another person.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Use the Services in a manner inconsistent with any applicable laws
            or regulations.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Engage in unauthorized framing of or linking to the Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party&rsquo;s uninterrupted use and enjoyment of
            the Services or modifies, impairs, disrupts, alters, or interferes
            with the use, features, functions, operation, or maintenance of the
            Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Delete the copyright or other proprietary rights notice from any
            Content.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Attempt to impersonate another user or person or use the username of
            another user.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web
            bugs, cookies, or other similar devices (sometimes referred to as
            &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo;
            or &ldquo;pcms&rdquo;).
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Interfere with, disrupt, or create an undue burden on the Services
            or the networks or services connected to the Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Services to you.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Attempt to bypass any measures of the Services designed to prevent
            or restrict access to the Services, or any portion of the Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Copy or adapt the Services&rsquo; software, including but not
            limited to HTML, JavaScript, or other code.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Services, or
            using or launching any unauthorized script or other software.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Use a buying agent or purchasing agent to make purchases on the
            Services.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Make any unauthorized use of the Services, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Use the Services as part of any effort to compete with us or
            otherwise use the Services and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">7. USER GENERATED CONTRIBUTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            The Services does not offer users to submit or post content. We may
            provide you with the opportunity to create, submit, post, display,
            transmit, perform, publish, distribute, or broadcast content and
            materials to us or on the Services, including but not limited to
            text, writings, video, audio, photographs, graphics, comments,
            suggestions, or personal information or other material
            (collectively, &ldquo;Contributions&rdquo;). Contributions may be
            viewable by other users of the Services and through third-party
            websites. As such, any Contributions you transmit may be treated in
            accordance with the Services Privacy Policy. When you create or make
            available any Contributions, you thereby represent and warrant that:
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Services, and other users of the Services to use your
            Contributions in any manner contemplated by the Services and these
            Terms of Use.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Services and these Terms of Use.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions are not false, inaccurate, or misleading.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence
            against a specific person or className of people.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Any use of the Services in violation of the foregoing violates these
            Terms of Use and may result in, among other things, termination or
            suspension of your rights to use the Services.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">8. CONTRIBUTION LICENSE</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You and the Services agree that we may access, store, process, and
            use any information and personal data that you provide following the
            terms of the Privacy Policy and your choices (including settings).
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            By submitting suggestions or other feedback regarding the Services,
            you agree that we can use and share &nbsp;such feedback for any
            purpose without compensation to you.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We do not assert any ownership over your Contributions. You retain
            full ownership of all of your Contributions and any intellectual
            property rights or other proprietary rights associated with your
            Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on
            the Services. You are solely responsible for your Contributions to
            the Services and you expressly agree to exonerate us from any and
            all responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">9. SUBMISSIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Services
            (&ldquo;Submissions&rdquo;) provided by you to us are
            non-confidential and shall become our sole property. We shall own
            exclusive rights, including all intellectual property rights, and
            shall be entitled to the unrestricted use and dissemination of these
            Submissions for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you. You hereby waive all moral
            rights to any such Submissions, and you hereby warrant that any such
            Submissions are original with you or that you have the right to
            submit such Submissions. You agree there shall be no recourse
            against us for any alleged or actual infringement or
            misappropriation of any proprietary right in your Submissions.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">10 SERVICES MANAGEMENT</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We reserve the right, but not the obligation, to: (1) monitor the
            Services for violations of these Terms of Use; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in
            our sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems; and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">11. TERM AND TERMINATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            These Terms of Use shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS
            OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE Services OR DELETE ANY CONTENT OR
            INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
            SOLE DISCRETION.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">12. MODIFICATIONS AND INTERRUPTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We also reserve the right to modify or
            discontinue all or part of the Services without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Terms of Use will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">13. GOVERNING LAW</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            These Terms shall be governed by and defined following the laws of
            Denmark. Parlorkit and yourself irrevocably consent that the courts
            of Denmark shall have exclusive jurisdiction to resolve any dispute
            which may arise in connection with these terms.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">14. DISPUTE RESOLUTION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            If You have any concern or dispute about the Services, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">15. CORRECTIONS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">16 &nbsp;DISCLAIMER</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            THE SERVICES IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES AND OUR SERVICES WILL BE AT YOUR
            SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND
            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES&rsquo; CONTENT OR THE
            CONTENT OF ANY WEBSITES LINKED TO THE SERVICES AND WE WILL ASSUME NO
            LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
            INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
            PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
            ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR
            USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
            AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
            CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
            THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
            OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
            WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
            EXERCISE CAUTION WHERE APPROPRIATE.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">17. LIMITATIONS OF LIABILITY</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            &nbsp;IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
            INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
            ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN
            US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
            ADDITIONAL RIGHTS.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">18. INDEMNIFICATION</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys&rsquo;
            fees and expenses, made by any third party due to or arising out of:
            (1) use of the Services; (2) breach of these Terms of Use; (3) any
            breach of your representations and warranties set forth in these
            Terms of Use; (4) your violation of the rights of a third party,
            including but not limited to intellectual property rights; or (5)
            any overt harmful act toward any other user of the Services with
            whom you connected via the Services. Notwithstanding the foregoing,
            we reserve the right, at your expense, to assume the exclusive
            defense and control of any matter for which you are required to
            indemnify us, and you agree to cooperate, at your expense, with our
            defense of such claims. We will use reasonable efforts to notify you
            of any such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">19 USER DATA</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            20 ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            Services. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">21. MISCELLANEOUS</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            These Terms of Use and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of Use
            shall not operate as a waiver of such right or provision. These
            Terms of Use operate to the fullest extent permissible by law. We
            may assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms of
            Use is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Terms of Use and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Terms of Use or use of the Services. You agree that
            these Terms of Use will not be construed against us by virtue of
            having drafted them. You hereby waive any and all defenses you may
            have based on the electronic form of these Terms of Use and the lack
            of signing by the parties hereto to execute these Terms of Use.
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">22. CONTACT US</span>
        </p>
        <p className="c0 my-1">
          <span className="c1">
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
        <p className="c0 my-1">
          <span className="c1">info@parlotkit.com</span>
        </p>
        <p className="c0 c2">
          <span className="c1"></span>
        </p>
      </div>
    </MarketingPageLayout>
  );
}
