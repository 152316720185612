import React, { useEffect, useRef, useState } from "react";
import { FullSessionInDb, SessionService } from "../../../../../api/staff";
import { Input } from "../../../../../Tailwind/Input/Uncontrolled";
import { Button, Modal } from "../../../../../Tailwind";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { useTranslation } from "react-i18next";

export function SessionServiceDiscountModal(props: {
  session: FullSessionInDb;
  service?: SessionService;
  onDiscountChanged: (session: FullSessionInDb) => void;
  onClose: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const [discount, setDiscount] = useState(0);
  const api = useAppApi();
  const { t } = useTranslation("application");

  useEffect(() => {
    if (props.service) {
      setDiscount(props.service.discount || 0);
      setVisible(true);
    } else {
      setDiscount(0);
      setVisible(false);
    }
  }, [props.service]);

  return (
    <Modal
      size="md"
      visible={visible}
      setVisible={() => {
        setVisible(false);
        props.onClose();
      }}
    >
      {props.service && (
        <div>
          <Input
            leftDecoration={"%"}
            label={t("sessionServiceDiscountModal.input.discount")}
            name={"discount"}
            type={"number"}
            onChange={(e) => {
              setDiscount(e.target.value);
            }}
            value={discount}
          />
        </div>
      )}
      <div className="mt-4 flex justify-end gap-2">
        <Button
          disabled={discount < 0 || discount > 100}
          activateOnEnter
          onClick={() => {
            if (props.service) {
              api.misc
                .updateSessionServiceSessionsSessionIdServiceSessionServiceIdPut(
                  props.session.id,
                  props.service?.id,
                  { discount: discount }
                )
                .then((s) => {
                  setVisible(false);
                  props.onDiscountChanged(s);
                });
            }
          }}
        >
          {t("sessionServiceDiscountModal.button.save")}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setVisible(false);
            props.onClose();
          }}
        >
          {t("sessionServiceDiscountModal.button.cancel")}
        </Button>
      </div>
    </Modal>
  );
}
