/*
export const textColors = {
  inherit: "text-inherit",
  current: "text-current",
  transparent: "text-transparent",
  black: "text-black",
  white: "text-white",
  slate50: "text-slate-50",
  slate100: "text-slate-100",
  slate200: "text-slate-200",
  slate300: "text-slate-300",
  slate400: "text-slate-400",
  slate500: "text-slate-500",
  slate600: "text-slate-600",
  slate700: "text-slate-700",
  slate800: "text-slate-800",
  slate900: "text-slate-900",
  slate950: "text-slate-950",
  gray50: "text-gray-50",
  gray100: "text-gray-100",
  gray200: "text-gray-200",
  gray300: "text-gray-300",
  gray400: "text-gray-400",
  gray500: "text-gray-500",
  gray600: "text-gray-600",
  gray700: "text-gray-700",
  gray800: "text-gray-800",
  gray900: "text-gray-900",
  gray950: "text-gray-950",
  zinc50: "text-zinc-50",
  zinc100: "text-zinc-100",
  zinc200: "text-zinc-200",
  zinc300: "text-zinc-300",
  zinc400: "text-zinc-400",
  zinc500: "text-zinc-500",
  zinc600: "text-zinc-600",
  zinc700: "text-zinc-700",
  zinc800: "text-zinc-800",
  zinc900: "text-zinc-900",
  zinc950: "text-zinc-950",
  neutral50: "text-neutral-50",
  neutral100: "text-neutral-100",
  neutral200: "text-neutral-200",
  neutral300: "text-neutral-300",
  neutral400: "text-neutral-400",
  neutral500: "text-neutral-500",
  neutral600: "text-neutral-600",
  neutral700: "text-neutral-700",
  neutral800: "text-neutral-800",
  neutral900: "text-neutral-900",
  neutral950: "text-neutral-950",
  stone50: "text-stone-50",
  stone100: "text-stone-100",
  stone200: "text-stone-200",
  stone300: "text-stone-300",
  stone400: "text-stone-400",
  stone500: "text-stone-500",
  stone600: "text-stone-600",
  stone700: "text-stone-700",
  stone800: "text-stone-800",
  stone900: "text-stone-900",
  stone950: "text-stone-950",
  red50: "text-red-50",
  red100: "text-red-100",
  red200: "text-red-200",
  red300: "text-red-300",
  red400: "text-red-400",
  red500: "text-red-500",
  red600: "text-red-600",
  red700: "text-red-700",
  red800: "text-red-800",
  red900: "text-red-900",
  red950: "text-red-950",
  orange50: "text-orange-50",
  orange100: "text-orange-100",
  orange200: "text-orange-200",
  orange300: "text-orange-300",
  orange400: "text-orange-400",
  orange500: "text-orange-500",
  orange600: "text-orange-600",
  orange700: "text-orange-700",
  orange800: "text-orange-800",
  orange900: "text-orange-900",
  orange950: "text-orange-950",
  amber50: "text-amber-50",
  amber100: "text-amber-100",
  amber200: "text-amber-200",
  amber300: "text-amber-300",
  amber400: "text-amber-400",
  amber500: "text-amber-500",
  amber600: "text-amber-600",
  amber700: "text-amber-700",
  amber800: "text-amber-800",
  amber900: "text-amber-900",
  amber950: "text-amber-950",
  yellow50: "text-yellow-50",
  yellow100: "text-yellow-100",
  yellow200: "text-yellow-200",
  yellow300: "text-yellow-300",
  yellow400: "text-yellow-400",
  yellow500: "text-yellow-500",
  yellow600: "text-yellow-600",
  yellow700: "text-yellow-700",
  yellow800: "text-yellow-800",
  yellow900: "text-yellow-900",
  yellow950: "text-yellow-950",
  lime50: "text-lime-50",
  lime100: "text-lime-100",
  lime200: "text-lime-200",
  lime300: "text-lime-300",
  lime400: "text-lime-400",
  lime500: "text-lime-500",
  lime600: "text-lime-600",
  lime700: "text-lime-700",
  lime800: "text-lime-800",
  lime900: "text-lime-900",
  lime950: "text-lime-950",
  green50: "text-green-50",
  green100: "text-green-100",
  green200: "text-green-200",
  green300: "text-green-300",
  green400: "text-green-400",
  green500: "text-green-500",
  green600: "text-green-600",
  green700: "text-green-700",
  green800: "text-green-800",
  green900: "text-green-900",
  green950: "text-green-950",
  emerald50: "text-emerald-50",
  emerald100: "text-emerald-100",
  emerald200: "text-emerald-200",
  emerald300: "text-emerald-300",
  emerald400: "text-emerald-400",
  emerald500: "text-emerald-500",
  emerald600: "text-emerald-600",
  emerald700: "text-emerald-700",
  emerald800: "text-emerald-800",
  emerald900: "text-emerald-900",
  emerald950: "text-emerald-950",
  teal50: "text-teal-50",
  teal100: "text-teal-100",
  teal200: "text-teal-200",
  teal300: "text-teal-300",
  teal400: "text-teal-400",
  teal500: "text-teal-500",
  teal600: "text-teal-600",
  teal700: "text-teal-700",
  teal800: "text-teal-800",
  teal900: "text-teal-900",
  teal950: "text-teal-950",
  cyan50: "text-cyan-50",
  cyan100: "text-cyan-100",
  cyan200: "text-cyan-200",
  cyan300: "text-cyan-300",
  cyan400: "text-cyan-400",
  cyan500: "text-cyan-500",
  cyan600: "text-cyan-600",
  cyan700: "text-cyan-700",
  cyan800: "text-cyan-800",
  cyan900: "text-cyan-900",
  cyan950: "text-cyan-950",
  sky50: "text-sky-50",
  sky100: "text-sky-100",
  sky200: "text-sky-200",
  sky300: "text-sky-300",
  sky400: "text-sky-400",
  sky500: "text-sky-500",
  sky600: "text-sky-600",
  sky700: "text-sky-700",
  sky800: "text-sky-800",
  sky900: "text-sky-900",
  sky950: "text-sky-950",
  blue50: "text-blue-50",
  blue100: "text-blue-100",
  blue200: "text-blue-200",
  blue300: "text-blue-300",
  blue400: "text-blue-400",
  blue500: "text-blue-500",
  blue600: "text-blue-600",
  blue700: "text-blue-700",
  blue800: "text-blue-800",
  blue900: "text-blue-900",
  blue950: "text-blue-950",
  indigo50: "text-indigo-50",
  indigo100: "text-indigo-100",
  indigo200: "text-indigo-200",
  indigo300: "text-indigo-300",
  indigo400: "text-indigo-400",
  indigo500: "text-indigo-500",
  indigo600: "text-indigo-600",
  indigo700: "text-indigo-700",
  indigo800: "text-indigo-800",
  indigo900: "text-indigo-900",
  indigo950: "text-indigo-950",
  violet50: "text-violet-50",
  violet100: "text-violet-100",
  violet200: "text-violet-200",
  violet300: "text-violet-300",
  violet400: "text-violet-400",
  violet500: "text-violet-500",
  violet600: "text-violet-600",
  violet700: "text-violet-700",
  violet800: "text-violet-800",
  violet900: "text-violet-900",
  violet950: "text-violet-950",
  purple50: "text-purple-50",
  purple100: "text-purple-100",
  purple200: "text-purple-200",
  purple300: "text-purple-300",
  purple400: "text-purple-400",
  purple500: "text-purple-500",
  purple600: "text-purple-600",
  purple700: "text-purple-700",
  purple800: "text-purple-800",
  purple900: "text-purple-900",
  purple950: "text-purple-950",
  fuchsia50: "text-fuchsia-50",
  fuchsia100: "text-fuchsia-100",
  fuchsia200: "text-fuchsia-200",
  fuchsia300: "text-fuchsia-300",
  fuchsia400: "text-fuchsia-400",
  fuchsia500: "text-fuchsia-500",
  fuchsia600: "text-fuchsia-600",
  fuchsia700: "text-fuchsia-700",
  fuchsia800: "text-fuchsia-800",
  fuchsia900: "text-fuchsia-900",
  fuchsia950: "text-fuchsia-950",
  pink50: "text-pink-50",
  pink100: "text-pink-100",
  pink200: "text-pink-200",
  pink300: "text-pink-300",
  pink400: "text-pink-400",
  pink500: "text-pink-500",
  pink600: "text-pink-600",
  pink700: "text-pink-700",
  pink800: "text-pink-800",
  pink900: "text-pink-900",
  pink950: "text-pink-950",
  rose50: "text-rose-50",
  rose100: "text-rose-100",
  rose200: "text-rose-200",
  rose300: "text-rose-300",
  rose400: "text-rose-400",
  rose500: "text-rose-500",
  rose600: "text-rose-600",
  rose700: "text-rose-700",
  rose800: "text-rose-800",
  rose900: "text-rose-900",
  rose950: "text-rose-950",
};
*/
export const textReducedColors = {
  inherit: "text-inherit",
  current: "text-current",
  transparent: "text-transparent",
  black: "text-black",
  white: "text-white",
  slate500: "text-slate-500",
  slate700: "text-slate-700",
  slate: "text-slate-900",
  gray300: "text-gray-300",
  gray400: "text-gray-400",
  gray500: "text-gray-500",
  gray700: "text-gray-700",
  gray: "text-gray-900",
  zinc500: "text-zinc-500",
  zinc700: "text-zinc-700",
  zinc: "text-zinc-900",
  neutral500: "text-neutral-500",
  neutral700: "text-neutral-700",
  neutral: "text-neutral-900",
  stone500: "text-stone-500",
  stone700: "text-stone-700",
  stone: "text-stone-900",
  red500: "text-red-500",
  red700: "text-red-700",
  red: "text-red-900",
  orange500: "text-orange-500",
  orange700: "text-orange-700",
  orange: "text-orange-900",
  amber500: "text-amber-500",
  amber700: "text-amber-700",
  amber: "text-amber-900",
  yellow500: "text-yellow-500",
  yellow700: "text-yellow-700",
  yellow: "text-yellow-900",
  lime500: "text-lime-500",
  lime700: "text-lime-700",
  lime: "text-lime-900",
  green500: "text-green-500",
  green700: "text-green-700",
  green: "text-green-900",
  emerald500: "text-emerald-500",
  emerald700: "text-emerald-700",
  emerald: "text-emerald-900",
  teal500: "text-teal-500",
  teal700: "text-teal-700",
  teal: "text-teal-900",
  cyan500: "text-cyan-500",
  cyan700: "text-cyan-700",
  cyan: "text-cyan-900",
  sky500: "text-sky-500",
  sky700: "text-sky-700",
  sky: "text-sky-900",
  blue500: "text-blue-500",
  blue700: "text-blue-700",
  blue: "text-blue-900",
  indigo500: "text-indigo-500",
  indigo700: "text-indigo-700",
  indigo: "text-indigo-900",
  violet500: "text-violet-500",
  violet700: "text-violet-700",
  violet: "text-violet-900",
  purple500: "text-purple-500",
  purple700: "text-purple-700",
  purple: "text-purple-900",
  fuchsia500: "text-fuchsia-500",
  fuchsia700: "text-fuchsia-700",
  fuchsia: "text-fuchsia-900",
  pink500: "text-pink-500",
  pink700: "text-pink-700",
  pink: "text-pink-900",
  rose500: "text-rose-500",
  rose700: "text-rose-700",
  rose: "text-rose-900",
};
