import React, { useEffect, useState } from "react";
import { useAppApi } from "api/staff/AppApi";
import { UserRole } from "api/staff";
import { UserSelect } from "./UserSelect";
import { use } from "i18next";
import { UsersSelect } from "./UsersSelect";

export function UserRolesPicker(props: {
  currentUserRoles: UserRole[];
  onUserRoleChanged: (userRoles: UserRole[]) => void;
  all?: boolean;
}) {
  const api = useAppApi();

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>(
    props.currentUserRoles
  );

  useEffect(() => {
    props.onUserRoleChanged(selectedRoles);
  }, [selectedRoles]);

  useEffect(() => {
    api.users.getAccountUsersAccountUsersGet().then((users) => {
      setUserRoles(users.my_roles);
      if (props.all) {
        setSelectedRoles(users.my_roles);
      }
    });
  }, []);

  return (
    <UsersSelect
      selected={selectedRoles}
      users={userRoles}
      onChange={(u) => {
        setSelectedRoles(u);
      }}
    />
  );
}
