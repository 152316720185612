import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { UserRole } from "api/staff";
import { manipulate_image_file } from "lib/utils";
import { useTranslation } from "react-i18next";
import { Font } from "Tailwind";

export function UsersSelect(props: {
  users: UserRole[];
  selected: UserRole[];
  onChange: (users: UserRole[]) => void;
}) {
  const { t } = useTranslation("application");

  return (
    <Listbox
      multiple={true}
      value={props.selected}
      onChange={(s) => {
        props.onChange(s);
      }}
    >
      {({ open }) => (
        <>
          <div className="relative w-full sm:w-72">
            <Listbox.Button className="w-full h-9 flex justify-center flex-col cursor-default rounded-md bg-white py-1.5 pl-3 pr-1 md:pl-3 md:pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <div className="flex items-center justify-between">
                {props.selected.length === 0 && (
                  <>
                    <span className="inline-flex w-full">
                      <Font>{t("usersSelect.text.selectUsers")}</Font>
                    </span>
                  </>
                )}
                {props.selected.map((s, index) => (
                  <div key={index} className="mr-0.5">
                    {s.avatar ? (
                      <img
                        style={{ borderColor: s.color }}
                        src={manipulate_image_file(s.avatar, [
                          "h_200",
                          "w_200",
                        ])}
                        alt=""
                        className="h-6 w-6 rounded-full border"
                      />
                    ) : (
                      <span
                        style={{
                          borderColor: s.color,
                          backgroundColor: s.color,
                        }}
                        className="inline-flex h-6 w-6 items-center justify-center border rounded-full"
                      >
                        <span className="text-xs leading-none text-white">
                          {s.user.name?.slice(0, 1).toUpperCase()}
                        </span>
                      </span>
                    )}
                  </div>
                ))}
                <span className="pointer-events-none absolute right-0 items-center pr-2 flex flex-row justify-center">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.users.map((user) => (
                  <Listbox.Option
                    key={user.id}
                    className={({ active }) =>
                      active
                        ? "bg-indigo-500 text-white relative cursor-default select-none py-2 pl-3 pr-9"
                        : "text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                    }
                    value={user}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          {user.avatar ? (
                            <img
                              style={{ borderColor: user.color }}
                              src={manipulate_image_file(user.avatar, [
                                "h_200",
                                "w_200",
                              ])}
                              alt=""
                              className="h-6 w-6 flex-shrink-0 rounded-full border"
                            />
                          ) : (
                            <span
                              style={{
                                borderColor: user.color,
                                backgroundColor: user.color,
                              }}
                              className="text-xs inline-flex h-6 w-8 items-center justify-center rounded-full"
                            >
                              <span className="leading-none text-white items-center">
                                {user.user.name?.slice(0, 1).toUpperCase()}
                              </span>
                            </span>
                          )}
                          <span
                            className={
                              selected
                                ? "ml-2 font-semibold truncate"
                                : "ml-2 font-normal truncate"
                            }
                          >
                            {user.user.name}
                          </span>
                          <span
                            className={
                              active
                                ? "text-indigo-200 ml-2 truncate"
                                : "text-gray-500 ml-2 truncate"
                            }
                          >
                            {user.user.email}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={
                              active
                                ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
