import { ProtectedPage } from "../ProtectedPage";
import React, { useEffect, useState } from "react";
import { UserRole } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DividerWithLabel,
  Button,
  CircularAvatar,
  SimpleEmptyState,
  Logo,
} from "Tailwind";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Ink1 } from "../../../video/backgrounds/Ink1";

export function UserRolePicker() {
  const [accounts, setAccounts] = useState<UserRole[]>([]);
  const api = useAppApi();
  const { t } = useTranslation("application");
  const navigate = useNavigate();

  useEffect(() => {
    api.users.myUserRolesUsersMyRolesGet().then((roles) => {
      if (roles.my_roles.length === 0) {
      }
      setAccounts(roles.my_roles);
      return Promise.resolve();
    });
  }, []);

  return (
    <>
      <ProtectedPage account={false}>
        <Ink1 show={true} />
        <div className="">
          <div className="mt-8 z-0 mx-auto bg-white sm:rounded-xl max-w-2xl sm:shadow-xl opacity-90">
            <div className="px-6 py-24 sm:px-6 sm:py-16 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {t("userRolePicker.title")}
                </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                  {t("userRolePicker.description")}
                </p>
                <div className="mx-auto mt-6 mb-10 max-w-xl text-lg leading-8 text-gray-600">
                  {accounts.length === 0 ? (
                    <>
                      <SimpleEmptyState
                        title={t("userRolePicker.emptyState.title")}
                        description={t("userRolePicker.emptyState.description")}
                      />
                      <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button onClick={() => navigate("/onboarding")}>
                          {t("userRolePicker.gotoOnboarding")}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col gap-4">
                      {accounts.map((account) => (
                        <button
                          key={account.id}
                          onClick={() => {
                            api.auth.switchRole(account.id).then((r) => {
                              navigate("/");
                            });
                          }}
                          className="w-full relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                        >
                          <div className="flex flex-col">
                            <CircularAvatar
                              file={account.avatar}
                              color={account.color}
                            />
                          </div>
                          <div className="min-w-0 flex-1">
                            <a href="#" className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              <p className="text-sm font-medium text-gray-900">
                                {account.user.name}
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                {account.account.company_name}
                              </p>
                            </a>
                          </div>
                          <div>
                            <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
                          </div>
                        </button>
                      ))}
                      <DividerWithLabel label={t("userRolePicker.or")} />
                      <div className="mt-10 flex items-center justify-center">
                        <Button
                          onClick={() => navigate("/onboarding/create-account")}
                        >
                          {t("userRolePicker.gotoOnboarding")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProtectedPage>
    </>
  );
}
