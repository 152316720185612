/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EmailEventType {
    PROCESSED = 'processed',
    DROPPED = 'dropped',
    DELIVERED = 'delivered',
    DEFERRED = 'deferred',
    BOUNCE = 'bounce',
    OPEN = 'open',
    CLICK = 'click',
    SPAMREPORT = 'spamreport',
    UNSUBSCRIBE = 'unsubscribe',
    GROUP_UNSUBSCRIBE = 'group_unsubscribe',
    GROUP_RESUBSCRIBE = 'group_resubscribe',
}
