import React, { useEffect, useState } from "react";
import { ProfileLayout } from "./ProfileLayout";
import { useAppApi } from "../../../../api/staff/AppApi";
import { PermissionStatuses } from "../../../../api/staff";
import { Toggle } from "../../../../Tailwind/Input/Uncontrolled";
import { useTranslation } from "react-i18next";
import { Button, Heading } from "../../../../Tailwind";
import { useFeedback } from "../../../../lib/useFeedback";
export function PermissionsProfileSettings() {
  const [permissions, setPermissions] = useState<PermissionStatuses>();

  const [information, setInformation] = useState(false);
  const [system, setSystem] = useState(false);
  const [promotion, setPromotion] = useState(false);

  const [loading, setLoading] = useState(false);

  const api = useAppApi();
  const { t } = useTranslation("application");
  const feedback = useFeedback();

  useEffect(() => {
    getPermissions();
  }, []);

  function getPermissions() {
    setLoading(true);
    api.notifications
      .getPermissionsPermissionsGet()
      .then((p) => {
        setPermissions(p);
        setInformation(p.Information);
        setSystem(p.System);
        setPromotion(p.Promotions);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function updatePermissions() {
    setLoading(true);
    let informationPromise = new Promise((resolve, reject) => {
      resolve(undefined);
    });
    let systemPromise = new Promise((resolve, reject) => {
      resolve(undefined);
    });
    let promotionsPromise = new Promise((resolve, reject) => {
      resolve(undefined);
    });
    if (information !== permissions?.Information) {
      informationPromise = api.notifications.updatePermissionPermissionsPut({
        permission: "Information",
        granted: information,
      });
    }
    if (system !== permissions?.System) {
      systemPromise = api.notifications.updatePermissionPermissionsPut({
        permission: "System",
        granted: system,
      });
    }
    if (promotion !== permissions?.Promotions) {
      promotionsPromise = api.notifications.updatePermissionPermissionsPut({
        permission: "Promotions",
        granted: promotion,
      });
    }
    Promise.all([informationPromise, systemPromise, promotionsPromise]).finally(
      () => {
        setLoading(false);
        getPermissions();
        feedback.showSuccessToast(
          t("permissionsProfileSettings.toast.success")
        );
      }
    );
  }

  return (
    <ProfileLayout active={"permissions"}>
      <div className="flex flex-col gap-y-6">
        <Heading
          title={t("permissionsProfileSettings.heading.title")}
          description={t("permissionsProfileSettings.heading.description")}
        />
        <Toggle
          enabled={information}
          onChange={setInformation}
          label={t("permissionsProfileSettings.toggle.information.label")}
        />
        <Toggle
          enabled={system}
          onChange={setSystem}
          label={t("permissionsProfileSettings.toggle.system.label")}
        />
        <Toggle
          enabled={promotion}
          onChange={setPromotion}
          label={t("permissionsProfileSettings.toggle.promotion.label")}
        />
        <div className="flex justify-end mt-5">
          <Button disabled={loading} onClick={updatePermissions}>
            {t("permissionsProfileSettings.button.save")}
          </Button>
        </div>
      </div>
    </ProfileLayout>
  );
}
