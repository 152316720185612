/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_TEMPLATE_CONTROLLER_unable_to_render___TEMPLATE_CONTROLLER_unable_to_render____ = {
    detail: PS_Literal__PEM_TEMPLATE_CONTROLLER_unable_to_render___TEMPLATE_CONTROLLER_unable_to_render____.detail;
};

export namespace PS_Literal__PEM_TEMPLATE_CONTROLLER_unable_to_render___TEMPLATE_CONTROLLER_unable_to_render____ {

    export enum detail {
        TEMPLATE_CONTROLLER_UNABLE_TO_RENDER = 'TEMPLATE_CONTROLLER_unable_to_render',
    }


}

