import { Layout } from "../../Layout/Layout";
import { useEffect, useState } from "react";
import { useQuery } from "../../../../lib/utils";
import { useAppApi } from "../../../../api/staff/AppApi";
import { Button, LinkButton, LoadingOverlay } from "../../../../Tailwind";

export function PurchaseComplete() {
  const sid = useQuery().get("sid");
  const api = useAppApi();

  const [paymentState, setPaymentState] = useState<
    "pending" | "complete" | "failed"
  >("pending");

  useEffect(() => {
    if (sid) {
      api.misc
        .completeSessionAndApplyPaymentsCompletePost({
          sid: sid,
          customer_id: "",
        })
        .then((r) => {
          if (r.paid) {
            setPaymentState("complete");
          }
        })
        .catch((err) => {
          if (
            err.body.detail ===
            "SMS_PAYMENT_CONTROLLER_purchase_already_applied"
          ) {
            setPaymentState("complete");
          }
        });
    }
  }, [sid]);

  return (
    <Layout active={"services"}>
      {paymentState === "pending" && <LoadingOverlay loading={true} />}
      {paymentState === "complete" && (
        <>
          <div>Tak for dit køb!</div>
          <LinkButton to={"/calendar"}>Gå til kalender</LinkButton>
        </>
      )}
    </Layout>
  );
}
