import React, { useState } from "react";
import { FullSessionInDb } from "../../../../../api/staff";
import {
  Activity,
  Feed,
  HeadingWithActions,
  Modal,
  TextButton,
} from "Tailwind";
import { SessionNoteModal } from "../SessionNoteModal";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/24/outline";

export function NotesSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (session: FullSessionInDb) => void;
}) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation("application");

  return (
    <>
      <div>
        <HeadingWithActions
          title={t("notesSection.heading.title")}
          actions={[
            <TextButton
              onClick={() => {
                setVisible(true);
              }}
            >
              <PlusIcon className="w-6 h-6" />
            </TextButton>,
          ]}
        />

        <Feed>
          {session.notes?.map((note, index) => {
            if (session.notes) {
              const first = index === session.notes?.length - 1;
              return <Activity first={first} item={note} index={index} />;
            } else {
              return <div />;
            }
          })}
        </Feed>
      </div>
      <Modal
        size={"2xl"}
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
      >
        <SessionNoteModal
          session={session}
          onSessionUpdated={(session) => {
            setVisible(false);
            onSessionUpdated(session);
          }}
          onCancel={() => {
            setVisible(false);
          }}
        />
      </Modal>
    </>
  );
}
