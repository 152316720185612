import {
  Button,
  Heading,
  HeadingWithActions,
  TextButton,
} from "../../../../Tailwind";
import { DateTimePicker } from "../../../../Tailwind/Input/Uncontrolled";
import { Dialog } from "../../../../catalyst/dialog";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../../api/staff/AppApi";
import { FullSessionInDb, UserRole } from "../../../../api/staff";
import { UserRolePicker } from "../Users/UserRolePicker";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";

export function TimeOffModal(props: {
  visible: boolean;
  setVisible: () => void;
  sessionId?: number;
}) {
  const api = useAppApi();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [userRole, setUserRole] = useState<UserRole>(
    api.userProfile?.current_role!
  );
  const [session, setSession] = useState<FullSessionInDb>();
  const { t } = useTranslation("application");

  useEffect(() => {
    if (props.sessionId) {
      api.misc.getSessionSessionsSessionIdGet(props.sessionId).then((s) => {
        setSession(s);
        setStartDate(new Date(s.start!));
        setEndDate(new Date(s.end!));
        setUserRole(s.user_role!);
      });
    } else {
      setSession(undefined);
      setStartDate(new Date());
      setEndDate(new Date());
      setUserRole(api.userProfile?.current_role!);
    }
  }, [props.sessionId]);

  return (
    <Dialog open={props.visible} onClose={props.setVisible}>
      <HeadingWithActions
        title={t("timeOffPage.modal.title")}
        actions={[
          <TextButton
            onClick={() => {
              props.setVisible();
            }}
          >
            <div className={"p-2"}>
              <XMarkIcon width={16} />
            </div>
          </TextButton>,
        ]}
      />
      <div>
        <UserRolePicker
          onUserRoleChanged={(r) => setUserRole(r)}
          currentUserRole={session?.user_role}
        />
      </div>
      <div className="flex flex-col gap-6 mt-4">
        <div className="flex flex-row gap-4">
          <div className="flex-1">
            <DateTimePicker
              date={startDate}
              label={t("timeOffPage.modal.input.start")}
              onChange={setStartDate}
            />
          </div>
          <div className="flex-1">
            <DateTimePicker
              date={endDate}
              label={t("timeOffPage.modal.input.end")}
              onChange={setEndDate}
            />
          </div>
        </div>
        {session ? (
          <div className="flex flex-row gap-4">
            <Button
              color="red"
              className="flex-1"
              onClick={() => {
                const confirm = window.confirm(t("timeOffPage.confirm")!);
                if (confirm) {
                  api.misc
                    .deleteTimeOffTimeOffTimeOffIdDelete(session?.id)
                    .then(() => {
                      props.setVisible();
                    });
                }
              }}
            >
              {t("timeOffPage.modal.button.delete")}
            </Button>
            <Button
              className="flex-1"
              onClick={() => {
                api.misc
                  .updateTimeOffTimeOffPut({
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    user_role: userRole.id,
                    time_off_id: session?.id,
                  })
                  .then(() => {
                    props.setVisible();
                  });
              }}
            >
              {t("timeOffPage.modal.button.update")}
            </Button>
          </div>
        ) : (
          <div className="flex justify-end">
            <Button
              onClick={() => {
                api.misc
                  .scheduleTimeOffTimeOffPost({
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    user_role: userRole.id,
                  })
                  .then(() => {
                    props.setVisible();
                  });
              }}
            >
              {t("timeOffPage.modal.button.create")}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}
