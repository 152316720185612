import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SignInSignUp } from "../sections/AppSection/Authentication/SignInSignUp";
import { ClientsPage } from "../sections/AppSection/Pages/Clients/ClientsPage";
import { OnboardingIntro } from "../sections/AppSection/Onboarding/OnboardingIntro";
import { CreateAccountOnboarding } from "../sections/AppSection/Onboarding/CreateAccountOnboarding";
import { BookingOnboarding } from "../sections/AppSection/Onboarding/BookingOnboarding";
import { AppContext } from "../api/staff/AppContext";
import { WebsiteOnboarding } from "../sections/AppSection/Onboarding/WebsiteOnboarding";
import { BusinessHoursOnboarding } from "../sections/AppSection/Onboarding/BusinessHoursOnboarding";
import { ServicesOnboarding } from "../sections/AppSection/Onboarding/ServicesOnboarding";
import { UsersOnboarding } from "../sections/AppSection/Onboarding/UsersOnboarding";
import { AcceptInvitation } from "../sections/AppSection/Authentication/AcceptInvitation";
import { ProductsOnboarding } from "../sections/AppSection/Onboarding/ProductsOnboarding";
import { useLocale } from "../lib/locale";
import { ProductsPage } from "../sections/AppSection/Pages/Products/ProductsPage";
import { ServicesPage } from "../sections/AppSection/Pages/Services/ServicesPage";
import { UsersPage } from "../sections/AppSection/Pages/Users/UsersPage";
import { CalendarPage } from "../sections/AppSection/Pages/Calendar/CalendarPage";
import { UserRolePicker } from "../sections/AppSection/Authentication/UserRolePicker";
import { SessionsPage } from "../sections/AppSection/Pages/Sessions/SessionsPage";
import { WebsiteSettings } from "../sections/AppSection/Pages/Settings/WebsiteSettings";
import { BookingPageSettings } from "../sections/AppSection/Pages/Settings/BookingPageSettings";
import { BusinessHoursSettings } from "../sections/AppSection/Pages/Profile/BusinessHoursSettings";
import { TemplatesPage } from "../sections/AppSection/Pages/Templates/TemplatesPage";
import { UserProfile } from "../api/staff";
import { UserProfileSettings } from "../sections/AppSection/Pages/Profile/UserProfileSettings";
import { UserRoleProfileSettings } from "../sections/AppSection/Pages/Profile/UserRoleProfileSettings";
import { NotificationsProfileSettings } from "../sections/AppSection/Pages/Profile/NotificationsProfileSettings";
import { PermissionsProfileSettings } from "../sections/AppSection/Pages/Profile/PermissionsProfileSettings";
import App404 from "./App404";
import { ResetPassword } from "../sections/AppSection/Authentication/ResetPassword";
import { Tour2 } from "../sections/AppSection/Onboarding/Tour2";
import { SMSPricingTable } from "../sections/AppSection/Pages/Store/SMSPricingTable";
import { PurchaseComplete } from "../sections/AppSection/Pages/Store/PurchaseComplete";
import { TimeOffPage } from "../sections/AppSection/Pages/TimeOff/TimeOffPage";
import { SalesPage } from "../sections/AppSection/Pages/Sales/SalesPage";
import { Tour1 } from "../sections/AppSection/Onboarding/Tour1";
import { BusinessHoursPage } from "../sections/AppSection/Pages/BusinessHours/BusinessHoursPage";
import { ServicesProfileSection } from "../sections/AppSection/Pages/Profile/ServicesProfileSection";

export function AppRouter() {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const [prefix, setPrefix] = useState("da");
  const locale = useLocale();

  useEffect(() => {
    setPrefix(locale.detectLanguage());
    const userProfile = window.localStorage.getItem("USERPROFILE");
    if (userProfile) {
      setUserProfile(JSON.parse(userProfile));
    }
  }, [window.location.pathname]);

  return (
    <>
      <BrowserRouter basename={prefix}>
        <AppContext.Provider
          value={{
            userProfile: userProfile,
            setUserProfile: setUserProfile,
          }}
        >
          <Routes>
            <Route path={"/"} element={<Navigate to={"/calendar"} />} />
            <Route path={"/settings/website"} element={<WebsiteSettings />} />
            <Route
              path={"/profile/business-hours"}
              element={<BusinessHoursSettings />}
            />
            <Route
              path={"/settings/booking"}
              element={<BookingPageSettings />}
            />
            <Route path={"/profile/user"} element={<UserProfileSettings />} />
            <Route
              path={"/profile/services"}
              element={<ServicesProfileSection />}
            />
            <Route
              path={"/profile/userRole"}
              element={<UserRoleProfileSettings />}
            />
            <Route
              path={"/profile/notifications"}
              element={<NotificationsProfileSettings />}
            />
            <Route
              path={"/profile/permissions"}
              element={<PermissionsProfileSettings />}
            />
            <Route path={"/calendar"} element={<CalendarPage />} />
            <Route path={"/store/sms-packages"} element={<SMSPricingTable />} />
            <Route path={"/store/complete"} element={<PurchaseComplete />} />
            <Route path={"/sessions"} element={<SessionsPage />} />
            <Route path={"/sales"} element={<SalesPage />} />
            <Route path={"/time-off"} element={<TimeOffPage />} />
            <Route path={"/clients"} element={<ClientsPage />} />
            <Route path={"/products"} element={<ProductsPage />} />
            <Route path={"/services"} element={<ServicesPage />} />
            <Route path={"/templates"} element={<TemplatesPage />} />
            <Route path={"/users"} element={<UsersPage />} />
            <Route path={"/business-hours"} element={<BusinessHoursPage />} />
            <Route path={"/signin"} element={<SignInSignUp />} />
            <Route path={"/reset-password"} element={<ResetPassword />} />
            <Route path={"/select-account"} element={<UserRolePicker />} />
            <Route path={"/accept-invitation"} element={<AcceptInvitation />} />
            <Route path={"/onboarding"} element={<OnboardingIntro />} />
            <Route
              path={"/onboarding/create-account"}
              element={<CreateAccountOnboarding />}
            />
            <Route
              path={"/onboarding/booking"}
              element={<BookingOnboarding />}
            />
            <Route
              path={"/onboarding/website"}
              element={<WebsiteOnboarding />}
            />
            <Route
              path={"/onboarding/services"}
              element={<ServicesOnboarding />}
            />
            <Route
              path={"/onboarding/products"}
              element={<ProductsOnboarding />}
            />
            <Route path={"/onboarding/users"} element={<UsersOnboarding />} />
            <Route path={"/onboarding/tour-1"} element={<Tour1 />} />
            <Route path={"/onboarding/tour-2"} element={<Tour2 />} />
            <Route
              path={"/onboarding/business-hours"}
              element={<BusinessHoursOnboarding />}
            />
            <Route path={"*"} element={<App404 />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
    </>
  );
}
