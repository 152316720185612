import { useLocale } from "../../lib/locale";
import i18next from "i18next";
import { MultiActionButton } from "../Button";
import { Font } from "../Font/Font";
import React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export function LanguageSelector({ dark }: { dark?: boolean }) {
  const locale = useLocale();

  const values = [
    { label: "Dansk", value: "da", flag: <div className="mr-2">🇩🇰</div> },
    { label: "English", value: "en", flag: <div className="mr-2">🇬🇧</div> },
  ];

  const selected = values.find((v) => v.value === i18next.language) || {
    flag: <div></div>,
    label: "",
  };

  return (
    <MultiActionButton
      dark={dark}
      actions={values.map((v) => ({
        action: () => {
          locale.switchLanguage(v.value);
        },
        label: v.label,
        icon: v.flag,
      }))}
    >
      <div className={dark ? "flex py-1.5 px-3" : "flex py-1.5 px-3"}>
        {selected.flag}{" "}
        <div className="flex flex-col justify-center pr-1">
          <Font size={"sm"} color={dark ? "white" : "gray500"}>
            {selected.label}
          </Font>
        </div>
        <div className="flex flex-col justify-center">
          <ChevronDownIcon className="h-4 w-4" />
        </div>
      </div>
    </MultiActionButton>
  );
}
