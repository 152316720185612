import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function Manicurist(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696449758/app/production_id_3997854_2160p_rxmwzf.mp4"
      }
    />
  );
}
