/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AboutPage } from '../models/AboutPage';
import type { Body_login_token_post } from '../models/Body_login_token_post';
import type { BookingCalendar } from '../models/BookingCalendar';
import type { BookingRequest } from '../models/BookingRequest';
import type { Client } from '../models/Client';
import type { ClientCreateWithCode } from '../models/ClientCreateWithCode';
import type { CurrentWebshop } from '../models/CurrentWebshop';
import type { FullSessionInDb } from '../models/FullSessionInDb';
import type { NextAvailableDate } from '../models/NextAvailableDate';
import type { OtpResponse } from '../models/OtpResponse';
import type { PermissionChange } from '../models/PermissionChange';
import type { PermissionStatuses } from '../models/PermissionStatuses';
import type { Service } from '../models/Service';
import type { ServicesPage } from '../models/ServicesPage';
import type { Staff } from '../models/Staff';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get About Page
     * @param hostname
     * @returns AboutPage Successful Response
     * @throws ApiError
     */
    public static getAboutPagePagesAboutGet(
        hostname: string,
    ): CancelablePromise<AboutPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/about',
            query: {
                'hostname': hostname,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Services Page
     * @param hostname
     * @returns ServicesPage Successful Response
     * @throws ApiError
     */
    public static getServicesPagePagesServicesGet(
        hostname: string,
    ): CancelablePromise<ServicesPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pages/services',
            query: {
                'hostname': hostname,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Services
     * @param staffId
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static getServicesServicesGet(
        staffId?: number,
    ): CancelablePromise<Array<Service>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services',
            query: {
                'staff_id': staffId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Services
     * @param services
     * @returns Staff Successful Response
     * @throws ApiError
     */
    public static getServicesStaffGet(
        services?: Array<number>,
    ): CancelablePromise<Array<Staff>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/staff',
            query: {
                'services': services,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Booking Calendar
     * @param year
     * @param month
     * @param services
     * @param staff
     * @returns BookingCalendar Successful Response
     * @throws ApiError
     */
    public static getBookingCalendarBookingCalendarGet(
        year: number,
        month: number,
        services?: Array<number>,
        staff?: Array<number>,
    ): CancelablePromise<Array<BookingCalendar>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/booking-calendar',
            query: {
                'year': year,
                'month': month,
                'services': services,
                'staff': staff,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Booking
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static createBookingBookingPost(
        requestBody: BookingRequest,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/booking',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Whereami
     * @returns CurrentWebshop Successful Response
     * @throws ApiError
     */
    public static whereamiWhereamiGet(): CancelablePromise<CurrentWebshop> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/whereami',
        });
    }

    /**
     * Next Available Date
     * @param duration
     * @param employeeId
     * @returns NextAvailableDate Successful Response
     * @throws ApiError
     */
    public static nextAvailableDateBookingNextAvailableDateGet(
        duration: number,
        employeeId: number,
    ): CancelablePromise<NextAvailableDate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/booking/next-available-date',
            query: {
                'duration': duration,
                'employee_id': employeeId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Whoami
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static whoamiWhoamiGet(): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/whoami',
        });
    }

    /**
     * Get Otp
     * @param email
     * @returns OtpResponse Successful Response
     * @throws ApiError
     */
    public static getOtpOtpGet(
        email: string,
    ): CancelablePromise<OtpResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/otp',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param formData
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static loginTokenPost(
        formData: Body_login_token_post,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutLogoutDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/logout',
        });
    }

    /**
     * Create Client And Login
     * @param requestBody
     * @returns Client Successful Response
     * @throws ApiError
     */
    public static createClientAndLoginClientPost(
        requestBody: ClientCreateWithCode,
    ): CancelablePromise<Client> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client Sessions
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static getClientSessionsSessionsGet(): CancelablePromise<Array<FullSessionInDb>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions',
        });
    }

    /**
     * Get Completed Client Session
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static getCompletedClientSessionSessionSessionIdGet(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/session/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Session
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelSessionSessionSessionIdDelete(
        sessionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/session/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Preferences
     * @param token
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static getPreferencesPreferencesGet(
        token: string,
    ): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/preferences',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Permission
     * @param token
     * @param requestBody
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static updatePermissionPermissionsPut(
        token: string,
        requestBody: PermissionChange,
    ): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/permissions',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
