import { Modal } from "Tailwind/Modal/Modal";
import { Tab } from "@headlessui/react";
import { ClientPicker } from "../../Clients/ClientPicker";
import { Client } from "api/staff";
import { useTranslation } from "react-i18next";
import React from "react";
import { CreateClientForm } from "../../Clients/CreateClientForm";

export function AddClientModal(props: {
  isOpen: boolean;
  onClose: () => void;
  onAddClient: (client: Client) => void;
}) {
  const { t } = useTranslation("application");

  return (
    <Modal visible={props.isOpen} setVisible={props.onClose}>
      <Tab.Group>
        <Tab.List className="-mb-px flex space-x-8">
          <Tab
            className={({ selected }) =>
              selected
                ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            }
          >
            {t("addClientModal.tab.create")}
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
            }
          >
            {t("addClientModal.tab.search")}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="mt-8">
            <CreateClientForm onClientCreated={(c) => props.onAddClient(c)} />
          </Tab.Panel>
          <Tab.Panel className="mt-8">
            <ClientPicker onClientSelected={(c) => props.onAddClient(c)} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
}
