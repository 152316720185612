import { DateTimePicker, Input } from "Tailwind/Input/Uncontrolled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ClockIcon } from "@heroicons/react/24/outline";
import { Button, DashedEmptyState, FormSection } from "Tailwind";
import { PickTimeModal } from "./PickTimeModal";
import { UserRole } from "api/staff";

export function BookingSection(props: {
  start?: Date;
  duration: number;
  employee: UserRole;
  onDurationOverride: (override: number) => void;
  onChange: (date: Date) => void;
}) {
  const { t } = useTranslation("application");

  const [pickTime, setPickTime] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-6">
          <FormSection
            title={t("bookingSection.formSection.booking.title")}
            subtitle={t("bookingSection.formSection.booking.subtitle")}
          />
        </div>

        {props.start ? (
          <>
            <div className="col-span-6">
              <Button onClick={() => setPickTime(true)}>
                {t("bookingSection.button.changeTime")}
              </Button>
            </div>
            <div className="col-span-3">
              <DateTimePicker
                disabled={true}
                label={t("bookingSection.dateInput.start.label")}
                date={props.start}
                onChange={(d) => {
                  props.onChange(d);
                }}
              />
            </div>
            <div className="col-span-3">
              <Input
                disabled={true}
                name="duration"
                label={t("bookingSection.input.duration.label")}
                onChange={() => {}}
                value={props.duration}
                leftDecoration={<ClockIcon className={"w-5 h-5"} />}
                rightDecoration={t("bookingSection.input.duration.unit")}
              />
            </div>
          </>
        ) : (
          <div className="col-span-6">
            <DashedEmptyState
              text={t("bookingSection.emptyState.title")}
              onClick={() => setPickTime(true)}
              accessory={<ClockIcon />}
            />
          </div>
        )}
      </div>
      <PickTimeModal
        isOpen={pickTime}
        employee={props.employee}
        start={props.start}
        onClose={() => setPickTime(false)}
        duration={props.duration}
        onTimePicked={(start, duration) => {
          props.onChange(start);
          props.onDurationOverride(duration);
          setPickTime(false);
        }}
      />
    </>
  );
}
