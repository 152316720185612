import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useWebshopApi } from "../api/webshop/WebshopApi";
import { useTranslation } from "react-i18next";

export function ClientMenu({
  dropdownVisible,
  setDropdownVisible,
  children,
}: {
  dropdownVisible: boolean;
  setDropdownVisible: (state: boolean) => void;
  children: React.ReactElement;
}) {
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  return (
    <div className="hidden sm:ml-6 sm:flex sm:items-center">
      {dropdownVisible && (
        <div
          onClick={() => setDropdownVisible(false)}
          className="absolute z-1 left-0 border-1 top-0 w-full h-full"
        />
      )}
      <div className="relative ml-3">
        {api.client !== undefined ? (
          <div>
            <button
              type="button"
              onClick={(e) => {
                setDropdownVisible(!dropdownVisible);
                e.preventDefault();
              }}
              className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span className="sr-only">Åben menu</span>
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-slate-500">
                <span className="text-xs font-medium leading-none text-white">
                  {api.client.name?.slice(0, 2).toUpperCase()}
                </span>
              </span>
            </button>
          </div>
        ) : (
          <span className="inline-flex rounded-md shadow">
            <Link
              to="/login"
              className="nline-flex items-center rounded border border-slate-300 bg-white px-2.5 py-1.5 text-xs font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {t("clientMenu.link.login")}
            </Link>
          </span>
        )}
        {dropdownVisible && children}
      </div>
    </div>
  );
}
