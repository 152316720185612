import React, { useState } from "react";
import { NativeSelect, TimeInput } from "Tailwind/Input/Uncontrolled";
import { addZeroes, useCalendarConsts, UtilDay } from "lib/utils";
import { Button, FormSection } from "Tailwind";
import { useTranslation } from "react-i18next";
import { useAppApi } from "api/staff/AppApi";

export function TimeslotForm(props: {
  timeslotId?: number;
  currentDay: UtilDay;
  onCurrentDayChange: (day: UtilDay) => void;
  userRoleId: number;
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
  onSave: () => void;
  onDelete: () => void;
}) {
  const [start, setStart] = useState(
    props.startHour !== undefined && props.startMinute !== undefined
      ? `${addZeroes(props.startHour)}:${addZeroes(props.startMinute)}`
      : "08:00"
  );
  const [end, setEnd] = useState(
    props.endHour !== undefined && props.endMinute !== undefined
      ? `${addZeroes(props.endHour)}:${addZeroes(props.endMinute)}`
      : "16:00"
  );
  const { t } = useTranslation("application");
  const api = useAppApi();
  const { weekdaysList, dayFromIndex } = useCalendarConsts();

  const onSave = (
    startHour: number,
    startMinute: number,
    endHour: number,
    endMinute: number
  ) => {
    if (props.timeslotId === undefined) {
      api.bookings
        .createScheduleSchedulePost({
          user_role_id: props.userRoleId,
          start_hour: startHour,
          start_minute: startMinute,
          end_hour: endHour,
          end_minute: endMinute,
          day: props.currentDay.value,
          start: new Date(
            1996,
            0,
            props.currentDay.value,
            startHour,
            startMinute,
            0
          ).toISOString(),
          end: new Date(
            1996,
            0,
            props.currentDay.value,
            endHour,
            endMinute,
            0
          ).toISOString(),
          bookable: true,
          bookable_by_clients: true,
        })
        .then(() => {
          props.onSave();
        });
    } else if (props.timeslotId) {
      //Update it
      api.bookings.updateTimeslotScheduleTimeslotTimeslotIdPut(
        props.timeslotId,
        {
          start_hour: startHour,
          start_minute: startMinute,
          end_hour: endHour,
          end_minute: endMinute,
          day: props.currentDay.value,
          start: new Date(
            1996,
            0,
            props.currentDay.value,
            startHour,
            startMinute,
            0
          ).toISOString(),
          end: new Date(
            1996,
            0,
            props.currentDay.value,
            endHour,
            endMinute,
            0
          ).toISOString(),
          bookable: true,
          bookable_by_clients: true,
        }
      );
      props.onSave();
    }
  };

  function onDelete() {
    props.timeslotId !== undefined &&
      api.bookings
        .deleteTimeslotScheduleTimeslotTimeslotIdDelete(props.timeslotId)
        .then(() => {
          props.onDelete();
        });
  }

  return (
    <>
      <div className="mt-2">
        <FormSection title={t("timeslotForm.formSection.title")} />
      </div>
      <div>
        <div className="border-b border-gray-900/10 pb-12">
          <div className="space-y-12">
            <div className="mt-10 grid gap-x-6 gap-y-8 grid-cols-1 sm:grid-cols-6">
              {!props.timeslotId && (
                <div className="sm:col-span-6">
                  <NativeSelect
                    onChange={(i) => {
                      props.onCurrentDayChange(dayFromIndex(parseInt(i)));
                    }}
                    label={t("timeslotForm.timeInput.day.label")}
                    name="day"
                    items={weekdaysList}
                    value={props.currentDay.value}
                  />
                </div>
              )}
              <div className="sm:col-span-3">
                <TimeInput
                  label={t("timeslotForm.timeInput.start.label")}
                  name={"start"}
                  onChange={(e) => {
                    setStart(e.target.value);
                  }}
                  value={start}
                />
              </div>
              <div className="sm:col-span-3">
                <TimeInput
                  label={t("timeslotForm.timeInput.end.label")}
                  name={"end"}
                  onChange={(e) => {
                    setEnd(e.target.value);
                  }}
                  value={end}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full flex-row justify-end space-x-4">
          {props.timeslotId && (
            <Button
              color="red"
              onClick={() => {
                if (props.timeslotId) {
                  onDelete();
                }
              }}
            >
              {t("timeslotForm.button.delete")}
            </Button>
          )}
          <Button
            onClick={() => {
              const s = start.split(":");
              const e = end.split(":");
              onSave(
                parseInt(s[0]),
                parseInt(s[1]),
                parseInt(e[0]),
                parseInt(e[1])
              );
            }}
          >
            {props.timeslotId
              ? t("timeslotForm.button.update")
              : t("timeslotForm.button.save")}
          </Button>
        </div>
      </div>
    </>
  );
}
