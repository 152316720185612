/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HealthResponse } from '../models/HealthResponse';
import type { Message } from '../models/Message';
import type { PermissionChange } from '../models/PermissionChange';
import type { PermissionStatuses } from '../models/PermissionStatuses';
import type { PreLaunchSignUp } from '../models/PreLaunchSignUp';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get Preferences
     * @param token
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static getPreferencesPreferencesGet(
        token: string,
    ): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/preferences',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Contact
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static contactContactPost(
        requestBody: Message,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Permission
     * @param token
     * @param requestBody
     * @returns PermissionStatuses Successful Response
     * @throws ApiError
     */
    public static updatePermissionPermissionsPut(
        token: string,
        requestBody: PermissionChange,
    ): CancelablePromise<PermissionStatuses> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/permissions',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sign Up
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static signUpMarketingPreLaunchSignupPost(
        requestBody: PreLaunchSignUp,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/marketing/pre-launch-signup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Health
     * @returns HealthResponse Successful Response
     * @throws ApiError
     */
    public static healthHealthGet(): CancelablePromise<HealthResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/health',
        });
    }

}
