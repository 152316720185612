import React, { useContext } from "react";
import { WebshopPageLayout } from "./WebshopPageLayout";
import { BookingComponent } from "../../components/BookingComponent";
import { useWebshopApi } from "../../api/webshop/WebshopApi";
import { useTranslation } from "react-i18next";

export function WebshopNewBookingPage() {
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");
  if (!api.webshop?.booking_page.enabled) {
    return (
      <>
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%)",
            }}
          >
            <h1>{t("webshopNewBookingPage.title")}</h1>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <WebshopPageLayout index={0}>
        <main className="py-10 px-4 sm:px-6 lg:px-8 bg-slate-50">
          <BookingComponent compact={true} />
        </main>
      </WebshopPageLayout>
    );
  }
}
