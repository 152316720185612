import { Layout } from "../../Layout/Layout";
import { TemplatesList } from "./TemplatesList";

export function TemplatesPage() {
  return (
    <Layout active={"templates"}>
      <TemplatesList />
    </Layout>
  );
}
