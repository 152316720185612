import { useTranslation } from "react-i18next";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Input } from "Tailwind/Input/Uncontrolled";
import React, { useEffect, useState } from "react";
import { Client, Service } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { Paginator } from "Tailwind/List/Paginator/Paginator";
import { useLocale } from "../../../../lib/locale";
import { Button, Font, List, Td } from "Tailwind";

export function ClientPicker(props: {
  onClientSelected: (client: Client) => void;
}) {
  const { t } = useTranslation("application");
  const [term, setTerm] = useState("");
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [clients, setClients] = useState<Client[]>([]);
  const api = useAppApi();

  useEffect(() => {
    fetchClients();
  }, [term]);

  function fetchClients() {
    api.clients.searchClientsSearchClientsGet(term, offset).then((c) => {
      setClients(c.results);
      setNextOffset(c.offset);
      setPageSize(c.page_size);
      setTotal(c.total);
    });
  }

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-4">
        <Input
          leftDecoration={
            <MagnifyingGlassIcon
              className="mx-auto h-5 w-5 text-gray-300"
              aria-hidden="true"
            />
          }
          className={"max-w-30"}
          name="term"
          onChange={(v) => {
            setTerm(v.target.value);
          }}
          value={term}
        />
      </div>
      <div className="col-span-full">
        <List
          title={""}
          subtitle={""}
          data={clients}
          renderLine={(client) => (
            <>
              <Td first>{client.name}</Td>
              <Td>{client.email}</Td>
              <Td>{client.phone_number}</Td>
              <Td last>
                <Button
                  color="secondary"
                  onClick={() => props.onClientSelected(client)}
                >
                  <div className="flex justify-between gap-2">
                    <div>{t("ClientPicker.button.select")}</div>
                    <ChevronRightIcon width={18} />
                  </div>
                </Button>
              </Td>
            </>
          )}
          headers={[
            t("ClientPicker.list.headers.name"),
            t("ClientPicker.list.headers.email"),
            t("ClientPicker.list.headers.phone_number"),
            "",
          ]}
          actions={[]}
        />
      </div>
      <div className="col-span-full">
        <Paginator
          nextOffset={nextOffset}
          page_size={pageSize}
          total={total}
          onNext={(o) => {
            setOffset(o);
          }}
          onPrevious={(o) => {
            setOffset(o);
          }}
        />
      </div>
    </div>
  );
}

function ClientItem({
  client,
  onClick,
}: {
  client: Client;
  onClick: () => void;
}) {
  const locale = useLocale();

  return (
    <li
      className="relative flex justify-between gap-x-6 py-5 hover:bg-gray-50 hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="ml-4 flex gap-x-4">
        <div className="min-w-0 flex-auto">
          <Font weight="semibold">{client.name}</Font>
        </div>
      </div>
      <div className="flex items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <Font className="whitespace-nowrap">{client.email}</Font>
          <Font color="gray500" size="xs">
            {client.phone_number}
          </Font>
        </div>
        <svg
          className="h-5 w-5 flex-none text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </li>
  );
}
