import { Control } from "react-hook-form/dist/types/form";
import React, { ReactNode } from "react";
import { useController } from "react-hook-form";
import { Switch } from "@headlessui/react";

interface ToggleProps {
  dark?: boolean;
  control: Control<any, boolean>;
  error?: string;
  name: string;
  label?: string | null | React.ReactNode;
  className?: string;
  description?: ReactNode;
}

export function Toggle(props: ToggleProps) {
  const {
    field: { value, onChange },
  } = useController(props);

  const color = Boolean(value) ? "bg-indigo-500" : "bg-gray-200";
  const position = Boolean(value) ? "translate-x-5" : "translate-x-0";
  return (
    <div
      className={`ring-1 ring-gray-300 rounded-md shadow-md p-2 ${props.className}`}
    >
      <Switch.Group as="div" className={`flex items-center`}>
        <Switch
          name={props.name}
          checked={Boolean(value)}
          onChange={onChange}
          className={
            color +
            " relative inline-flex self-start h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          }
        >
          <span
            aria-hidden="true"
            className={
              position +
              " pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            }
          />
        </Switch>
        <Switch.Label
          as="span"
          className={
            props.dark
              ? "block text-sm font-medium leading-6 text-white ml-3 w-full"
              : "block text-sm font-medium leading-6 text-gray-900 ml-3 w-full"
          }
        >
          <div>
            <div>{props.label}</div>
            <div>
              {props.description && (
                <p
                  className={
                    props.dark
                      ? "mt-2 text-sm leading-6 text-gray-300"
                      : "mt-2 text-sm leading-6 text-gray-600"
                  }
                >
                  {props.description}
                </p>
              )}
            </div>
          </div>
        </Switch.Label>
      </Switch.Group>
      {props.error && (
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      )}
    </div>
  );
}
