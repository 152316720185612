import { Layout } from "../../Layout/Layout";
import React from "react";
import { SessionsList } from "./SessionsList";

export function SessionsPage() {
  return (
    <Layout active={"sessions"}>
      <SessionsList />
    </Layout>
  );
}
