import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function BodyPiercer(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696449583/app/pexels-pavel-danilyuk-5434727_1440p_mcf2yu.mp4"
      }
    />
  );
}
