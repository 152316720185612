import React from "react";

export function Button({
  onClick,
  text,
  ...props
}: {
  onClick: () => void;
  disabled?: boolean;
  text: string;
  selected?: boolean;
  color?: "white" | "indigo" | "danger";
  fullWidth?: boolean;
}) {
  let disabled = !!props.disabled;
  let selected = props.selected ? " ring-indigo-500 ring-offset-2 ring-2" : "";

  let color =
    " bg-indigo-500 border border-transparent hover:bg-indigo-700 text-white";

  switch (props.color) {
    case "white":
      color =
        " bg-white border border-slate-800 hover:bg-slate-300 text-slate-900";
      break;

    case "indigo":
      color =
        " bg-indigo-500 border border-transparent hover:bg-indigo-700 text-white";
      break;
    case "danger":
      color =
        " bg-red-600 border border-transparent hover:bg-red-700 text-white";
      break;
  }

  let width = props.fullWidth ? " w-full" : "";

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        "flex justify-center rounded-md py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50" +
        selected +
        color +
        width
      }
    >
      {text}
    </button>
  );
}
