import React, { useState } from "react";
import {
  FullSessionInDb,
  SessionService,
  SessionStatus,
} from "../../../../../api/staff";
import { Button, List, Modal, Td, TextButton } from "Tailwind";
import { ServicePicker } from "../../Services/ServicePicker";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { useTranslation } from "react-i18next";
import { SessionServiceDiscountModal } from "./SessionServiceDiscountModal";
import {
  PlusIcon,
  ReceiptPercentIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useLocale } from "../../../../../lib/locale";

export function SessionServicesSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (session: FullSessionInDb) => void;
}) {
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedService, setSelectedService] = useState<SessionService>();
  const api = useAppApi();
  const { t } = useTranslation("application");
  const locale = useLocale();

  function addService(serviceId: number) {
    setSaving(true);
    api.misc
      .addSessionServiceSessionsSessionIdServiceServiceIdPost(
        session.id,
        serviceId
      )
      .then((updatedSession) => {
        onSessionUpdated(updatedSession);
      })
      .finally(() => {
        setSaving(false);
        setVisible(false);
      });
  }

  function removeService(sessionServiceId: number) {
    setSaving(true);
    api.misc
      .removeSessionServiceSessionsSessionIdServiceServiceIdDelete(
        session.id,
        sessionServiceId
      )
      .then((updatedSession) => {
        onSessionUpdated(updatedSession);
      })
      .finally(() => {
        setSaving(false);
        setVisible(false);
      });
  }

  return (
    <>
      <div>
        <List
          title={t("sessionServicesSection.title")}
          subtitle={""}
          data={session.services}
          renderLine={(service) => (
            <>
              <Td first>{service.name}</Td>
              <Td>
                {service.duration} {"min"}
              </Td>
              <Td>
                <div className="flex flex-col">
                  <div>
                    <span
                      className={service.discount > 0 ? "line-through" : ""}
                    >
                      {locale.currency.format(service.price)}
                    </span>
                  </div>
                  {service.discount > 0 && (
                    <div className="flex flex-row gap-2">
                      <span className={"text-green-800"}>
                        {locale.currency.format(
                          service.price - service.discount_amount
                        )}
                      </span>
                      <span>-</span>
                      <div className="text-green-800">
                        {t("sessionServicesSection.text.discount")}{" "}
                        {service.discount}
                        {"%"}
                      </div>
                    </div>
                  )}
                </div>
              </Td>
              {session.status === SessionStatus.SCHEDULED && (
                <Td last>
                  <div className="flex gap-2 justify-end">
                    <TextButton
                      disabled={saving}
                      onClick={() => {
                        setSelectedService(service);
                      }}
                    >
                      <ReceiptPercentIcon width={24} />
                    </TextButton>
                    <TextButton
                      disabled={saving}
                      onClick={() => {
                        removeService(service.id);
                      }}
                    >
                      <XCircleIcon width={24} />
                    </TextButton>
                  </div>
                </Td>
              )}
            </>
          )}
          headers={[
            t("sessionServicesSection.list.headers.name"),
            t("sessionServicesSection.list.headers.duration"),
            t("sessionServicesSection.list.headers.price"),
            "",
          ]}
          actions={[
            session.status === SessionStatus.SCHEDULED && (
              <TextButton
                disabled={saving}
                onClick={() => {
                  setVisible(true);
                }}
              >
                <PlusIcon className="w-6 h-6" />
              </TextButton>
            ),
          ]}
        ></List>
      </div>
      <Modal
        visible={visible}
        setVisible={() => {
          setVisible(false);
        }}
      >
        <ServicePicker
          employee={session.user_role}
          onServicePicked={(service) => {
            addService(service.id);
          }}
        />
        <div className="mt-4 flex justify-end">
          <Button color="secondary" onClick={() => setVisible(false)}>
            {t("sessionServiceSection.button.cancel")}
          </Button>
        </div>
      </Modal>
      <SessionServiceDiscountModal
        session={session}
        service={selectedService}
        onDiscountChanged={(s) => {
          onSessionUpdated(s);
        }}
        onClose={() => {
          setSelectedService(undefined);
        }}
      />
    </>
  );
}
