import React, { useContext, useState } from "react";
import { ClientMenu } from "../../components/ClientMenu";
import { MenuItemMobile } from "../../components/MenuItemMobile";
import { MobileMenu } from "../../components/MobileMenu";
import { MobileMenuButton } from "../../components/MobileMenuButton";
import { DropdownMenu } from "../../components/DropdownMenu";
import { Link } from "react-router-dom";
import { Logo } from "Tailwind";
import { useWebshopApi } from "../../api/webshop/WebshopApi";
import { useTranslation } from "react-i18next";

export function WebshopPageLayout(props: {
  index: number;
  children: React.ReactNode;
}) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  return (
    <>
      <div className="min-h-full bg-white">
        <nav className="border-b border-slate-200 bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 sm:px-0">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <Link to="/" className="flex flex-shrink-0 items-center">
                  {api.webshop?.logo ? (
                    <div>
                      <Logo file={api.webshop?.logo} />
                    </div>
                  ) : (
                    <h2 className="text-2xl font-bold leading-tight tracking-tight text-slate-900">
                      {api.webshop?.company_name}
                    </h2>
                  )}
                </Link>
                <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                  <MenuItemDesktop
                    text="Booking"
                    href="/booking"
                    selected={props.index === 0}
                  />
                </div>
              </div>
              <ClientMenu
                dropdownVisible={dropdownVisible}
                setDropdownVisible={setDropdownVisible}
              >
                <DropdownMenu>
                  <Link
                    to={"/my/bookings"}
                    className="block px-4 py-2 text-sm text-slate-700"
                  >
                    {t("webshopPageLayout.link.myPage")}
                  </Link>
                </DropdownMenu>
              </ClientMenu>
              <MobileMenuButton onClick={() => setMenuVisible(!menuVisible)} />
            </div>
          </div>
          {menuVisible && (
            <MobileMenu
              links={[{ href: "/my", text: "Min side" }]}
            ></MobileMenu>
          )}
        </nav>
        {props.children}
      </div>
    </>
  );
}

function MenuItemDesktop({
  selected,
  text,
  href,
}: {
  selected: boolean;
  text: string;
  href: string;
}) {
  const cls = selected
    ? "border-indigo-500 text-slate-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
    : "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";

  return (
    <Link to={href} className={cls} aria-current="page">
      {text}
    </Link>
  );
}
