import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocale } from "../../../lib/locale";
import { useUtils } from "../../../lib/utils";
import { ClientPageLayout } from "../ClientPageLayout";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { Invoice } from "./Receipt";
import { Panel } from "../../../components/Panel";
import { PigmentItem } from "./PigmentItem";
import { Product } from "./Product";
import { ServiceItem } from "./ServiceItem";
import { CompletedBookingHeader } from "./CompletedBookingHeader";
import { UpcomingBookingHeader } from "./UpcomingBookingHeader";
import { FullSessionInDb } from "../../../api/webshop";
import { useWebshopApi } from "../../../api/webshop/WebshopApi";

export function BookingPage() {
  const [session, setSession] = useState<FullSessionInDb>();
  let { id } = useParams();
  const locale = useLocale();
  const utils = useUtils();

  const api = useWebshopApi();

  useEffect(() => {
    if (id !== undefined) {
      api.api
        .getCompletedClientSessionSessionSessionIdGet(parseInt(id, 10))
        .then((s) => {
          setSession(s);
        });
    }
  }, [id]);

  return (
    <ClientPageLayout index={2}>
      <Breadcrumbs
        crumbs={[
          { text: "Mine aftaler", url: "/my/bookings" },
          {
            text: session?.start
              ? utils.capitalizeSentence(
                  locale.localeDateWithDay(new Date(session?.start))
                )
              : "",
            url: `/my/booking/${id}`,
          },
        ]}
      />
      <div className="my-10 mx-4 mm:px-6 lg:mx-8">
        {session?.status === "Scheduled" && (
          <UpcomingBookingHeader session={session} />
        )}
        {session?.status === "Completed" && (
          <CompletedBookingHeader session={session} />
        )}
        {session && (
          <>
            <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
              {session.services.length > 0 && (
                <Panel title={"Ydelser"}>
                  <ul role="list" className="divide-y divide-gray-200">
                    {session.services.map((s, index) => {
                      return (
                        <div key={index}>
                          <ServiceItem service={s} />
                        </div>
                      );
                    })}
                  </ul>
                </Panel>
              )}
              {session.products.length > 0 && (
                <Panel title={"Produkter"}>
                  <ul role="list" className="divide-y divide-gray-200">
                    {session.products.map((p, index) => {
                      return (
                        <div key={index}>
                          <Product product={p} />
                        </div>
                      );
                    })}
                  </ul>
                </Panel>
              )}
              {session.pigments.length > 0 && (
                <Panel title={"Pigmenter"}>
                  <ul role="list" className="divide-y divide-gray-200">
                    {session.pigments.map((p, index) => {
                      return (
                        <div key={index}>
                          <PigmentItem pigment={p} />
                        </div>
                      );
                    })}
                  </ul>
                </Panel>
              )}
              {session.status === "Completed" && (
                <Panel title={"Kvittering"}>
                  <Invoice session={session} />
                </Panel>
              )}
            </div>
          </>
        )}
      </div>
    </ClientPageLayout>
  );
}
