import React, { useContext, useState } from "react";
import { useWebshopApi } from "../api/webshop/WebshopApi";

export function MobileMenu(props: {
  children?: React.ReactElement[] | React.ReactElement;
  links: { href: string; text: string }[];
}) {
  const api = useWebshopApi();

  return (
    <div className="md:hidden" id="mobile-menu">
      <div className="space-y-1 pt-2 pb-3">{props.children}</div>
      {api.client !== undefined ? (
        <div className="border-t border-gray-200 pt-4 pb-3">
          <div className="flex items-center px-4">
            <div className="flex-shrink-0">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                <span className="text-xs font-medium leading-none text-white">
                  {api.client.name?.slice(0, 2).toUpperCase()}
                </span>
              </span>
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {api.client.name}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {api.client.email}
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            {props.links.map((link) => {
              return (
                <a
                  href={link.href}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  {link.text}
                </a>
              );
            })}

            <button
              onClick={() => {
                api.auth.logout();
              }}
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
            >
              Log ud
            </button>
          </div>
        </div>
      ) : (
        <a
          href="/login?back=booking"
          className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
        >
          Log ind
        </a>
      )}
    </div>
  );
}
