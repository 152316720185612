/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SessionStatus {
    DRAFT = 'Draft',
    SCHEDULED = 'Scheduled',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed',
    TIME_OFF = 'TimeOff',
}
