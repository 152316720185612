import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import PhoneInput, { Value } from "react-phone-number-input/input";
import { OtpResponse } from "../../../api/webshop";
import { useWebshopApi } from "../../../api/webshop/WebshopApi";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { OneTimeCodeInput, Phone } from "../../../Tailwind/Input/Uncontrolled";

export function ClientVerification(props: { onComplete: () => void }) {
  const [otpResponse, setOtpResponse] = useState<OtpResponse>();

  return (
    <div className="bg-white shadow sm:rounded-lg">
      {!otpResponse && <GetOtp otpSent={(r) => setOtpResponse(r)} />}
      {!!otpResponse && (
        <VerifyOtp
          otpResponse={otpResponse}
          onCancel={() => {
            setOtpResponse(undefined);
          }}
          onComplete={() => {
            props.onComplete();
          }}
        />
      )}
    </div>
  );
}

function VerifyOtp(props: {
  otpResponse: OtpResponse;
  onCancel: () => void;
  onComplete: () => void;
}) {
  return (
    <>
      {props.otpResponse.exists ? (
        <LoginModal
          email={props.otpResponse.email}
          onComplete={() => {
            props.onComplete();
          }}
          onClose={() => {
            props.onCancel();
          }}
        />
      ) : (
        <CreateModal
          email={props.otpResponse.email}
          onComplete={() => {
            props.onComplete();
          }}
          onClose={() => {
            props.onCancel();
          }}
        />
      )}
    </>
  );
}

function GetOtp(props: { otpSent: (r: OtpResponse) => void }) {
  const api = useWebshopApi();
  const [email, setEmail] = useState("");
  const { t } = useTranslation("webshop");

  function getCode() {
    api.api
      .getOtpOtpGet(email)
      .then((r) => {
        props.otpSent(r);
      })
      .catch((e) => {
        alert("Der er sket en fejl. Prøv igen.");
        setEmail("");
      });
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-slate-900"
        >
          {t("getOtp.title")}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {t("getOtp.description")}
        </p>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
        <label
          htmlFor="email"
          className="mt-1 block text-sm font-medium text-gray-700"
        >
          {t("getOtp.label.email")}
        </label>
        <div className="mt-1">
          <input
            disabled={false}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            autoComplete="email"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex flex-1 mt-6 justify-center">
          <Button
            disabled={email.length < 3}
            text="Send kode"
            onClick={() => {
              getCode();
            }}
          />
        </div>
      </div>
    </>
  );
}

function CreateModal(props: {
  email: string;
  onClose: () => void;
  onComplete: () => void;
}) {
  const api = useWebshopApi();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState<Value>("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("webshop");

  function create() {
    api.api
      .createClientAndLoginClientPost({
        email: props.email,
        name: name,
        phone_number: phone,
        otp: otp,
      })
      .then((client) => {
        api.clientContext.setClient(client);
      })
      .catch((e) => {
        alert(e);
        setLoading(false);
        setOtp("");
      });
  }

  useEffect(() => {
    console.log(isValidPhoneNumber(phone));
  }, [phone]);

  return (
    <Modal>
      <h2 className="mb-6 text-center text-2xl font-bold tracking-tight text-gray-900">
        {t("createModal.title")}
      </h2>
      <p className="mb-6 text-center text-sm font-medium tracking-tight text-gray-500">
        {t("createModal.description1")}
        <span className="font-medium text-base text-gray-900">
          {" "}
          {props.email}{" "}
        </span>
        {t("createModal.description2")}
      </p>
      <div>
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("createModal.label.email")}
            </label>
            <div className="mt-1">
              <input
                value={props.email}
                type="text"
                required
                disabled={true}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <label
              htmlFor="name"
              className="mt-4 block text-sm font-medium text-gray-700"
            >
              {t("createModal.label.name")}
            </label>
            <div className="mt-1">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="current-password"
                type="text"
                required
                autoFocus={true}
                name="name"
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <label
              htmlFor="phone"
              className="mt-4 block text-sm font-medium text-gray-700"
            >
              {t("createModal.label.phone")}
            </label>
            <div className="mt-1">
              <Phone
                value={phone}
                onChange={(p) => {
                  if (p !== undefined) {
                    setPhone(p);
                  }
                }}
                name="phone"
                country={"dk"}
              />
            </div>
            <div className="mt-10">
              <OneTimeCodeInput
                value={otp}
                label={t("createModal.label.otp")}
                onChange={(e) => setOtp(e)}
                autoComplete="current-password"
              />
            </div>
          </div>
        </div>
        <div className="mt-12 flex flex-row gap-2">
          <Button
            onClick={() => {
              setLoading(true);
              create();
            }}
            fullWidth={true}
            disabled={otp.length < 3 || loading}
            text="Opret og log ind"
          />
          <Button
            onClick={() => {
              props.onClose();
            }}
            fullWidth={true}
            color="white"
            disabled={loading}
            text="Annuler"
          />
        </div>
      </div>
    </Modal>
  );
}

function LoginModal(props: {
  email: string;
  onClose: () => void;
  onComplete: () => void;
}) {
  const api = useWebshopApi();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("webshop");

  return (
    <Modal>
      <h2 className="mb-6 text-center text-2xl font-bold tracking-tight text-gray-900">
        {t("loginModal.title")}
      </h2>
      <p className="mb-6 text-center text-sm font-medium tracking-tight text-gray-500">
        {t("loginModal.description1")}
        <p className="font-medium text-base text-gray-900">{props.email}</p>
        {t("loginModal.description2")}
      </p>
      <div className="pt-8">
        <div>
          <div>
            <div className="w-full">
              <OneTimeCodeInput
                label={t("loginModal.label.otp")}
                onChange={(e) => setOtp(e)}
                value={otp}
              />
            </div>
          </div>
        </div>
        <div className="pt-12 flex flex-row gap-2">
          <Button
            color="white"
            onClick={() => {
              props.onClose();
            }}
            fullWidth={true}
            text={t("loginModal.button.cancel")}
          />
          <Button
            onClick={() => {
              setLoading(true);
              api.auth
                .signIn(props.email, otp, "email")
                .then(() => {
                  api.auth.refresh().then((r) => {
                    if (r) {
                      props.onComplete();
                    }
                  });
                })
                .catch((err) => {});
            }}
            disabled={otp.length < 3 || loading}
            fullWidth={true}
            text={t("loginModal.button.login")}
          />
        </div>
      </div>
    </Modal>
  );
}

function Modal(props: { children: React.ReactNode }) {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
