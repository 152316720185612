import { File } from "../../api/staff";
import { manipulate_image_file } from "../../lib/utils";
import React from "react";

export function Logo({ file }: { file: File }) {
  return (
    <img
      className="block w-200 h-10"
      src={manipulate_image_file(file, ["w_200"])}
      alt=""
    />
  );
}
