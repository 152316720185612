import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { Button, TextButton } from "Tailwind";
import { SessionApp } from "./SessionApp";
import { XMarkIcon } from "@heroicons/react/24/outline";

export function FullScreenSessionModal(
  props: PropsWithChildren<{
    isOpen: boolean;
    sessionId?: number;
    onClose: () => void;
    title?: ReactNode;
  }>
) {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-300"
          leave="ease-in duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full w-full min-h-full min-w-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-0"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="bg-opacity-90 p-6 w-full h-full overflow-scroll transform bg-white text-left align-middle shadow-xl transition-all">
                <div className="mb-4 flex justify-end">
                  <TextButton onClick={props.onClose}>
                    <XMarkIcon className="h-8 w-8" />
                  </TextButton>
                </div>
                <SessionApp
                  sessionId={props.sessionId}
                  onClose={() => props.onClose()}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
