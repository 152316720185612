import React, { ReactNode } from "react";
import { Font } from "../Font/Font";
import { HeadingWithActions } from "../SectionHeadings";

export function List<T>({
  data,
  headers,
  renderLine,
  actions,
  title,
  subtitle,
  searchWidget,
  footer,
}: {
  title: string;
  subtitle: string;
  data: T[];
  renderLine: (d: T) => ReactNode;
  headers: string[];
  actions: ReactNode[];
  searchWidget?: ReactNode;
  footer?: ReactNode;
}) {
  return (
    <div className="sm:px-0 lg:px-0">
      <HeadingWithActions title={title} subtitle={subtitle} actions={actions} />
      {searchWidget && (
        <div className="mt-6 max-w-full sm:max-w-md">{searchWidget}</div>
      )}
      <div className={searchWidget ? "mt-8 flow-root" : "mt-6 flow-root"}>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {headers.map((label, i) => (
                    <th
                      scope="col"
                      key={i}
                      className={
                        i === 0
                          ? "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                          : i === headers.length - 1
                          ? "relative py-3.5 pl-3 pr-4 text-right sm:pr-3 text-sm font-semibold text-gray-900"
                          : "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      }
                    >
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((item, i) => (
                  <tr key={i}>{renderLine(item)}</tr>
                ))}
              </tbody>
              {footer}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
