import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function Barber(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696444729/app/jumpstory-download20231004-183826_ljjtnk.mp4"
      }
    />
  );
}
