/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_twilio_events_sms_twilio_events_post } from '../models/Body_twilio_events_sms_twilio_events_post';
import type { ClientNotification } from '../models/ClientNotification';
import type { Credit } from '../models/Credit';
import type { EmailEventResult } from '../models/EmailEventResult';
import type { SendClientMessage } from '../models/SendClientMessage';
import type { SendGridEvent } from '../models/SendGridEvent';
import type { SendMessage } from '../models/SendMessage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CommunicationService {

    /**
     * Get Messages
     * @param sessionId
     * @returns ClientNotification Successful Response
     * @throws ApiError
     */
    public static getMessagesUnifiedChatSessionSessionIdGet(
        sessionId: number,
    ): CancelablePromise<Array<ClientNotification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/unified-chat/session/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Message
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendMessageUnifiedChatMessagePost(
        requestBody: SendMessage,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/unified-chat/message',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Client Sms
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendClientSmsSmsClientPost(
        requestBody: SendClientMessage,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sms/client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Client Email
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendClientEmailEmailClientPost(
        requestBody: SendClientMessage,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/email/client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sms Credits
     * @returns Credit Successful Response
     * @throws ApiError
     */
    public static getSmsCreditsSmsCreditsGet(): CancelablePromise<Credit> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sms/credits',
        });
    }

    /**
     * Email Events
     * @param sessionId
     * @param clientId
     * @returns EmailEventResult Successful Response
     * @throws ApiError
     */
    public static emailEventsEmailEventsGet(
        sessionId: number,
        clientId?: number,
    ): CancelablePromise<Array<EmailEventResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/email/events',
            query: {
                'session_id': sessionId,
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Email Events
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static emailEventsEmailSendgridEventsPost(
        requestBody: Array<SendGridEvent>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/email/sendgrid/events',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Twilio Events
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static twilioEventsSmsTwilioEventsPost(
        formData?: Body_twilio_events_sms_twilio_events_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sms/twilio/events',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendEmailEmailTestPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/email/test',
        });
    }

}
