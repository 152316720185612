import { ModalProps } from "../../../lib/props";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useAppApi } from "../../../api/staff/AppApi";
import { useFeedback } from "../../../lib/useFeedback";
import { Button, LoadingOverlay, Modal, TextButton } from "../../../Tailwind";
import { OneTimeCodeInput } from "../../../Tailwind/Input/Uncontrolled";
import { SignInStep } from "./SignInSignUp";

interface LoginModalProps extends ModalProps {
  step: SignInStep;
  email: string;
}

export function LoginModal(props: LoginModalProps) {
  const { t } = useTranslation("application");
  const [loading, setLoading] = useState(false);
  const [canSendCode, setCanSendCode] = useState(true);
  const [otp, setOtp] = useState(props.email);
  const [error, setError] = useState("");
  const api = useAppApi();
  const feedback = useFeedback();

  function login() {
    if (otp.length === 6) {
      api.auth.signIn(props.email, otp, "email").catch((err) => {
        feedback.handleErrors(err);
      });
    } else {
      setError(t("loginModal.error.otp")!);
    }
  }

  return (
    <Modal visible={props.visible} size={"sm"}>
      <LoadingOverlay loading={loading} />
      <div className="space-y-6">
        <OneTimeCodeInput
          label={t("loginModal.input.otp.label")}
          description={t("loginModal.input.otp.description", {
            email: props.email,
          })}
          error={error}
          value={otp}
          onChange={setOtp}
        />
        <Button
          full
          className="flex w-full justify-center"
          onClick={() => {
            login();
          }}
        >
          {t("loginModal.button.signIn")}
        </Button>
        <div className="flex justify-between ">
          <TextButton
            size="xs"
            onClick={() => {
              props.setVisible && props.setVisible(false);
            }}
          >
            <span aria-hidden="true">←</span>{" "}
            {t("loginModal.button.useDifferentEmail")}
          </TextButton>
          <TextButton
            size="xs"
            disabled={!canSendCode}
            onClick={() => {
              setLoading(true);
              api.users.optOtpEmailGet(props.email).then(() => {
                setCanSendCode(false);
                setOtp("");
                setTimeout(() => {
                  setCanSendCode(true);
                }, 30000);
                setLoading(false);
                feedback.showSuccessToast(t("loginModal.toast.success"));
              });
            }}
          >
            {t("loginModal.button.sendCodeAgain")}
          </TextButton>
        </div>
      </div>
    </Modal>
  );
}
