import { CalendarEvent } from "./WeekCalendar";
import { transform } from "framer-motion";

export function WeekCalendarTimeOff(props: {
  event: CalendarEvent;
  index: number;
  col: number;
  onClick: () => void;
}) {
  const begins = Math.floor(
    ((props.event.start.getHours() * 60 + props.event.start.getMinutes()) /
      60) *
      12 +
      2
  );

  const minutes =
    props.event.end.getHours() * 60 +
    props.event.end.getMinutes() -
    (props.event.start.getHours() * 60 + props.event.start.getMinutes());

  const duration = Math.max(Math.floor((minutes / 60) * 12), 0);

  const width =
    props.event.leftOffset + props.event.width !== 1
      ? props.event.width
      : props.event.width - 0.05;

  return (
    <li
      className={`relative mt-px flex col-start-${props.col} col-end-${props.col}`}
      style={{
        gridRow: `${begins} / span ${duration}`,
        left: `calc((100% - 0px) * ${props.event.leftOffset} + 0px)`,
        width: `calc((100% - 0px) * ${width})`,
        backgroundImage:
          "linear-gradient(45deg, rgba(255, 204, 204, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 204, 204, 0.5) 50%, rgba(255, 204, 204, 0.5) 75%, transparent 75%)",
        backgroundSize: "56px 56px",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <button
        style={{
          backgroundColor: `${props.event.event.user_role.color}15`,
        }}
        onMouseDown={() => {
          props.onClick();
        }}
        className="group absolute inset-0.5 flex flex-col overflow-y-auto pl-1 pt-1 text-xs leading-5"
      >
        <div
          className={
            duration < 16
              ? "flex flex-row gap-1 whitespace-nowrap"
              : "flex flex-col"
          }
        ></div>
      </button>
    </li>
  );
}
