import React from "react";
import { useLocale } from "../../../../../lib/locale";

export function WeekCalendarDateButton(props: {
  selected: boolean;
  date: Date;
  onClick: () => void;
}) {
  const style = props.selected
    ? "p-1 items-center justify-center border-b-2 border-indigo-600 font-semibold text-gray-900"
    : "p-1 items-center justify-center font-semibold text-gray-900 hover:border-b-2 hover:border-indigo-200";
  const locale = useLocale();
  return (
    <button onClick={props.onClick}>
      <div className={`flex items-center justify-center py-3 ${style}`}>
        <span>
          <span>{locale.format(props.date, "EE d")} </span>
        </span>
      </div>
    </button>
  );
}
