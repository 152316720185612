import { useLocale } from "../../../lib/locale";
import React from "react";
import { FullSessionInDb } from "../../../api/webshop";

export function CompletedBookingHeader({
  session,
}: {
  session: FullSessionInDb;
}) {
  const locale = useLocale();

  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <div>
        <h1 className="text-lg font-medium text-gray-900">Afsluttet aftale</h1>
        {session?.start && (
          <p className="mt-1 text-sm text-gray-500">
            Du havde en aftale hos {session?.user_role?.user.name}{" "}
            <span className={"font-bold"}>
              {locale.localeDateWithDay(new Date(session?.start))}
            </span>{" "}
            klokken{" "}
            <span className={"font-bold"}>
              {locale.timeShort(new Date(session?.start))}
            </span>{" "}
            til{" "}
            <span className={"font-bold"}>
              {locale.timeShort(new Date(session?.end!))}
            </span>
          </p>
        )}
      </div>
    </div>
  );
}
