import React, { Fragment, useEffect, useState } from "react";
import { ClockIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { calendarType } from "../CalendarPage";
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isToday,
  isWithinInterval,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useAppApi } from "../../../../../api/staff/AppApi";
import {
  ClientMin,
  SessionMin,
  SessionService,
  SessionStatus,
  UserRole,
} from "../../../../../api/staff";
import { Font, LoadingOverlay, TextButton } from "../../../../../Tailwind";
import { FullScreenSessionModal } from "../../Sessions/FullScreenSessionModal/FullScreenSessionModal";
import { useLocale } from "../../../../../lib/locale";
import { CalendarHeader } from "../CalendarHeader";
import { useTranslation } from "react-i18next";
import { CreateBookingSlideOver } from "../../Sessions/CreateBookingSlideOver/CreateBookingSlideOver";
import { splitIntervalIntoDays } from "../Week/utils";
import { CalendarEvent } from "../Week/WeekCalendar";
import { TimeOffModal } from "../../TimeOff/TimeOffModal";
import { t } from "i18next";
import { addZeroes } from "../../../../../lib/utils";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Days = {
  date: Date;
  events: SessionMin[];
  dayOfMonth: string;
  isToday: boolean;
  isCurrentMonth: boolean;
  isSelected: boolean;
};

export default function MonthCalendar(props: {
  onSwitchCalendar: (type: calendarType) => void;
}) {
  const api = useAppApi();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startTime, setStartTime] = useState<Date | undefined>();
  const [selectedDay, setSelectedDay] = useState<Days>();
  const [days, setDays] = useState<Days[]>([]);
  const [viewSession, setViewSession] = useState<SessionMin | undefined>();
  const [viewTimeOff, setViewTimeOff] = useState<SessionMin | undefined>();
  const [employees, setEmployees] = useState<UserRole[]>([]);
  const [createSession, setCreateSession] = useState(false);
  const [loading, setLoading] = useState(false);
  const locale = useLocale();
  const { t } = useTranslation("application");

  function getCalendarDays() {
    setLoading(true);
    const startCurrentMonth = startOfMonth(currentDate);
    const endCurrentMonth = endOfMonth(currentDate);

    const startCalendar = startOfWeek(startCurrentMonth, { weekStartsOn: 1 });
    const endCalendar = endOfWeek(endCurrentMonth, { weekStartsOn: 1 });

    const eachDayArray = eachDayOfInterval({
      start: startCalendar,
      end: endCalendar,
    });

    api.misc
      .getCalendarSessionsCalendarPost(
        startOfMonth(startCalendar).toISOString(),
        endOfMonth(endCalendar).toISOString(),
        employees.map((e) => e.id)
      )
      .then((sessions) => {
        const events: SessionMin[] = [];
        for (const session of sessions) {
          const start = new Date(session.start!);
          const end = new Date(session.end!);
          const days = splitIntervalIntoDays(start, end);
          for (const day of days) {
            events.push({
              id: session.id,
              start: day.start.toISOString(),
              end: day.end.toISOString(),
              services: session.services,
              client: session.client,
              user_role: session.user_role,
              status: session.status,
            });
          }
        }

        const d = eachDayArray.map((day) => {
          return {
            date: day,
            dayOfMonth: format(day, "d"),
            events: events
              .sort(
                (a, b) =>
                  new Date(a.start!).getTime() - new Date(b.start!).getTime()
              )
              .filter((session) => {
                const sessionDate = new Date(session.start!);
                return (
                  day.getDate() === sessionDate.getDate() &&
                  day.getMonth() === sessionDate.getMonth() &&
                  day.getFullYear() === sessionDate.getFullYear()
                );
              }),
            isToday: isSameDay(day, currentDate),
            isCurrentMonth: isWithinInterval(day, {
              start: startCurrentMonth,
              end: endCurrentMonth,
            }),
            isSelected: false, // This will need to be set based on your selection logic
          };
        });

        setDays(d);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getCalendarDays();
  }, [currentDate, employees]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <div className="lg:flex lg:h-full lg:flex-col">
        <CalendarHeader
          currentCalendar={"month"}
          onSwitchCalendar={props.onSwitchCalendar}
          employeesChanged={setEmployees}
          selectedDate={currentDate}
          onCreate={() => {
            setStartTime(undefined);
            setCreateSession(true);
          }}
          onPreviousWeek={() => {
            setCurrentDate(addMonths(currentDate, -1));
          }}
          onNextWeek={() => {
            setCurrentDate(addMonths(currentDate, +1));
          }}
          onToday={() => {
            setCurrentDate(new Date());
          }}
        />
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
              {days.map((day) => (
                <div
                  key={day.date.toISOString()}
                  className={classNames(
                    day.isCurrentMonth
                      ? "bg-white"
                      : "bg-gray-50 text-gray-500",
                    "relative px-3 py-2 group"
                  )}
                >
                  <div className="flex justify-between">
                    <time
                      dateTime={day.date.toISOString()}
                      className={
                        day.isToday
                          ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 font-semibold text-white"
                          : undefined
                      }
                    >
                      {day.date.getDate()}
                    </time>
                    <div className="hidden group-hover:inline-flex">
                      <TextButton onClick={() => {}}>
                        <PlusCircleIcon
                          onClick={() => {
                            day.date.setHours(8);
                            setStartTime(day.date);
                            setCreateSession(true);
                            console.log(day.date);
                          }}
                          className="h-5 w-5 text-indigo-600"
                        />
                      </TextButton>
                    </div>
                  </div>
                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.slice(0, 10).map((event) => {
                        return event.status == "TimeOff" ? (
                          <TimeOffButton
                            event={event}
                            setViewSession={setViewTimeOff}
                          />
                        ) : (
                          <SessionButton
                            setViewSession={setViewSession}
                            event={event}
                          />
                        );
                      })}
                      {day.events.length > 5 && (
                        <li className="text-gray-500">
                          + {day.events.length - 5} more
                        </li>
                      )}
                    </ol>
                  )}
                </div>
              ))}
            </div>
            <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
              {days.map((day) => (
                <button
                  key={day.date.toISOString()}
                  onClick={() => {
                    setSelectedDay(day);
                  }}
                  type="button"
                  className={classNames(
                    day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                    (day.isSelected || day.isToday) && "font-semibold",
                    day.isSelected && "text-white",
                    !day.isSelected && day.isToday && "text-indigo-600",
                    !day.isSelected &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-900",
                    !day.isSelected &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      "text-gray-500",
                    "flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                  )}
                >
                  <time
                    dateTime={day.dayOfMonth}
                    className={classNames(
                      day.isSelected &&
                        "flex h-6 w-6 items-center justify-center rounded-full",
                      day.isSelected && day.isToday && "bg-indigo-500",
                      day.isSelected && !day.isToday && "bg-gray-900",
                      "ml-auto"
                    )}
                  >
                    {day.date.getDate()}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) =>
                        event.status == SessionStatus.TIME_OFF ? (
                          <span
                            key={event.id}
                            className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full"
                            style={{
                              backgroundImage:
                                "linear-gradient(45deg, rgba(255, 204, 204, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 204, 204, 0.5) 50%, rgba(255, 204, 204, 0.5) 75%, transparent 75%)",
                              backgroundSize: "4px 4px",
                              padding: "4px",
                              backgroundColor: `${event.user_role.color}`,
                            }}
                          />
                        ) : (
                          <>
                            {event.services.map((s) => (
                              <span
                                key={event.id}
                                className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full"
                                style={{
                                  padding: "4px",
                                  backgroundColor: `${s.color}`,
                                }}
                              />
                            ))}
                          </>
                        )
                      )}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        {selectedDay && selectedDay.events.length > 0 && (
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
              {selectedDay.events.map((event) => {
                const start = new Date(event.start!);
                return event.status === SessionStatus.TIME_OFF ? (
                  <SmallTimeOffButton
                    event={event}
                    setViewSession={setViewTimeOff}
                  />
                ) : (
                  <SmallSessionButton
                    event={event}
                    setViewSession={setViewSession}
                  />
                );
              })}
            </ol>
          </div>
        )}
      </div>
      <CreateBookingSlideOver
        requestOpen={() => {
          setCreateSession(true);
        }}
        start={startTime}
        employee={
          employees.length === 1 ? employees[0] : api.userProfile?.current_role!
        }
        isOpen={createSession}
        onClose={() => {
          setCreateSession(false);
        }}
        onSessionCreated={() => {
          setCreateSession(false);
          getCalendarDays();
        }}
      />
      <FullScreenSessionModal
        isOpen={viewSession !== undefined}
        sessionId={viewSession?.id}
        onClose={() => {
          setViewSession(undefined);
          getCalendarDays();
        }}
      />
      <TimeOffModal
        visible={viewTimeOff !== undefined}
        sessionId={viewTimeOff?.id}
        setVisible={() => {
          getCalendarDays();
          setViewTimeOff(undefined);
        }}
      />
    </>
  );
}

function SessionButton({
  event,
  setViewSession,
}: {
  event: SessionMin;
  setViewSession: (event: SessionMin) => void;
}) {
  const locale = useLocale();
  const start = new Date(event.start!);

  return (
    <li
      onClick={() => {
        setViewSession(event);
      }}
      key={event.id}
      className="my-1 px-1 rounded-md border hover:cursor-pointer"
      style={{
        backgroundColor: `${event.user_role.color}15`,
        borderColor: `${event.user_role.color}50`,
      }}
    >
      <div className="flex gap-1 items-center justify-between">
        <div className="flex gap-1 items-center">
          {event.services.map((s) => (
            <div
              style={{ backgroundColor: s.color }}
              className="h-2 w-2 rounded-full"
            />
          ))}
          <div className="flex gap-1 items-center">
            <Font
              size="xs"
              className="overflow-hidden whitespace-nowrap text-left"
            >
              {locale.format(start, "HH:mm")}
            </Font>
          </div>
        </div>
        <div className="hidden xl:inline-block font-light text-xs overflow-hidden">
          {event.client?.phone_number}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="text-xs font-semibold whitespace-nowrap overflow-hidden">
          {event.client?.name}
        </div>
      </div>
    </li>
  );
}

function TimeOffButton({
  event,
  setViewSession,
}: {
  event: SessionMin;
  setViewSession: (event: SessionMin) => void;
}) {
  return (
    <li
      onClick={() => {
        setViewSession(event);
      }}
      key={event.id}
      className="my-1 -mx-3 border hover:cursor-pointer min-h-2"
      style={{
        backgroundImage:
          "linear-gradient(45deg, rgba(255, 204, 204, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 204, 204, 0.5) 50%, rgba(255, 204, 204, 0.5) 75%, transparent 75%)",
        backgroundSize: "56px 56px",
        padding: "4px",
        textAlign: "center",
        backgroundColor: `${event.user_role.color}15`,
        borderColor: `${event.user_role.color}50`,
      }}
    ></li>
  );
}

function SmallTimeOffButton({
  event,
  setViewSession,
}: {
  event: SessionMin;
  setViewSession: (event: SessionMin) => void;
}) {
  const locale = useLocale();
  const start = new Date(event.start!);
  const end = new Date(event.end!);
  const { t } = useTranslation("application");

  return (
    <li
      key={event.id}
      className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
      style={{
        backgroundImage:
          "linear-gradient(45deg, rgba(255, 204, 204, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 204, 204, 0.5) 50%, rgba(255, 204, 204, 0.5) 75%, transparent 75%)",
        backgroundSize: "56px 56px",
        padding: "4px",
        textAlign: "center",
        backgroundColor: `${event.user_role.color}15`,
        borderColor: `${event.user_role.color}50`,
      }}
    >
      <div className="flex-auto">
        <p className="font-semibold text-gray-900 flex gap-1 items-center">
          <div>{event.user_role.user?.name}</div>
          <div className="italic text-indigo-600 font-light">
            {t("smallTimeOffButton.text.time_off")}
          </div>
        </p>
        <div className="flex gap-1 items-center">
          <Font
            size="xs"
            className="overflow-hidden whitespace-nowrap text-left"
          >
            {locale.localeDateTime(start)}
          </Font>
          -
          <Font
            size="xs"
            className="overflow-hidden whitespace-nowrap text-left"
          >
            {locale.localeDateTime(end)}
          </Font>
        </div>
      </div>
      <button
        onClick={() => {
          setViewSession(event);
        }}
        className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
      >
        {t("monthCalendar.button.show")}
      </button>
    </li>
  );
}

function SmallSessionButton({
  event,
  setViewSession,
}: {
  event: SessionMin;
  setViewSession: (event: SessionMin) => void;
}) {
  const start = new Date(event.start!);
  const end = new Date(event.end!);

  return (
    <li
      key={event.id}
      className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
      style={{
        backgroundImage:
          "linear-gradient(45deg, rgba(255, 204, 204, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 204, 204, 0.5) 50%, rgba(255, 204, 204, 0.5) 75%, transparent 75%)",
        backgroundSize: "56px 56px",
        padding: "4px",
        textAlign: "center",
        backgroundColor: `${event.user_role.color}15`,
        borderColor: `${event.user_role.color}50`,
      }}
    >
      <div className="flex-auto">
        <p className="font-semibold text-gray-900 flex gap-1 items-center">
          <div>{event.client?.name}</div>
          {event.services.map((s) => (
            <div
              style={{ backgroundColor: s.color }}
              className="h-2 w-2 rounded-full"
            />
          ))}
        </p>
        <div className="flex gap-1">
          <Font
            size="xs"
            className="overflow-hidden whitespace-nowrap text-left"
          >
            {addZeroes(start.getHours())}:{addZeroes(start.getMinutes())}
          </Font>
          -
          <Font
            size="xs"
            className="overflow-hidden whitespace-nowrap text-left"
          >
            {addZeroes(end.getHours())}:{addZeroes(end.getMinutes())}
          </Font>
        </div>
      </div>
      <button
        onClick={() => {
          setViewSession(event);
        }}
        className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
      >
        {t("monthCalendar.button.show")}
      </button>
    </li>
  );
}
