import { Calendar } from "../../../components/Calendar";
import { Button } from "../../../components/Button";
import React, { useEffect, useState } from "react";
import { useLocale } from "../../../lib/locale";
import { CalendarDay } from "../../../api/webshop";
import { useTranslation } from "react-i18next";

export function BookingCalendarSection({
  selectedDate,
  maxDate,
  bookingCalendar,
  onDateChanged,
  timeslotSelected,
  selectedTimeslot,
  button,
}: {
  selectedDate: Date;
  maxDate?: Date;
  bookingCalendar: CalendarDay[];
  onDateChanged: (date: Date) => void;
  timeslotSelected: (date: Date | undefined) => void;
  selectedTimeslot: Date | undefined;
  button?: React.ReactNode;
}) {
  const locale = useLocale();
  const { t } = useTranslation("webshop");
  const [availableTimeslots, setAvailableTimeslots] = useState<Date[]>([]);

  useEffect(() => {
    const timeslots = bookingCalendar.find((c) => {
      return (
        new Date(c.current_day).toDateString() === selectedDate.toDateString()
      );
    });
    if (timeslots) {
      setAvailableTimeslots(timeslots.bookable_slots.map((t) => new Date(t)));
    } else {
      setAvailableTimeslots([]);
    }
    timeslotSelected(undefined);
  }, [selectedDate, bookingCalendar]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex flex-col md:flex-row justify-between">
        <div className="mb-2 md:mb-0">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-slate-900"
          >
            {t("bookingCalendarSection.title")}
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-slate-500">
            {t("bookingCalendarSection.description")}
          </p>
        </div>
        <div>{button}</div>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-5">
          <div className="shadow-md border col-span-1 md:col-span-2 xl:col-span-2 border-slate-200 rounded-lg h-min">
            <Calendar
              maxDate={maxDate}
              selectedDate={selectedDate}
              bookingCalendar={bookingCalendar}
              onMonthChange={(d) => onDateChanged(d)}
              onDateChange={(d) => onDateChanged(d)}
            />
          </div>
          <div className="grid grid-cols-3 col-span-1 md:col-span-3 xl:col-span-3 gap-2 h-min">
            {availableTimeslots.map((timeslot, index) => {
              return (
                <div key={index}>
                  <Button
                    fullWidth={true}
                    color="white"
                    selected={
                      timeslot.toISOString() === selectedTimeslot?.toISOString()
                    }
                    onClick={() => {
                      timeslotSelected(timeslot);
                    }}
                    disabled={false}
                    text={locale.timeShort(timeslot)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
