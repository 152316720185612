import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import { Link, ActionPanelTopRight } from "Tailwind";
import React from "react";
import { ProductsList } from "../Pages/Products/ProductsList";
import { useTranslation } from "react-i18next";

export function ProductsOnboarding() {
  const { t } = useTranslation("application");
  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={""}
          subTitle={""}
          actions={[
            <Link key={1} to={"/onboarding/services"}>
              <span aria-hidden="true">←</span>{" "}
              {t("productsOnboarding.link.back")}
            </Link>,
            <Link key={1} to={"/onboarding/finish"}>
              {t("productsOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Link>,
          ]}
        >
          <>
            <div className={"my-10"} />
            <ProductsList />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
