import React, { PropsWithChildren } from "react";

const color = {
  enabled: {
    indigo400: "text-indigo-400 hover:text-indigo-300",
    indigo600: "text-indigo-600 hover:text-indigo-500",
    red600: "text-red-600 hover:text-red-500",
  },
  disabled: {
    indigo400: "text-gray-500",
    indigo600: "text-gray-600",
    red600: "text-gray-600",
  },
};

export function TextButton(
  props: PropsWithChildren<{
    onClick: () => void;
    disabled?: boolean;
    color?: keyof typeof color["enabled"];
    size?:
      | "xs"
      | "sm"
      | "base"
      | "lg"
      | "xl"
      | "2xl"
      | "3xl"
      | "4xl"
      | "5xl"
      | "6xl"
      | "7xl"
      | "8xl"
      | "9xl";
  }>
) {
  const size = props.size
    ? `text-${props.size} flex gap-2 `
    : "text-base flex gap-2 ";
  const enabled = props.disabled ? "disabled" : "enabled";

  return (
    <button
      disabled={props.disabled}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
      className={color[enabled][props.color || "indigo600"]}
    >
      {props.children}
    </button>
  );
}
