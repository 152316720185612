import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form";
import React, { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";

interface TextAreaProps {
  register: UseFormRegisterReturn<string>;
  className?: string;
  label?: string | null;
  description?: string | null;
  rows?: number;
  errors?: FieldErrors;
}

export function TextArea(props: TextAreaProps) {
  const [name, setName] = useState(props.register.name);

  return (
    <div className={props.className}>
      <label
        htmlFor={props.register.name}
        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
      >
        {props.label}
      </label>
      <textarea
        rows={props.rows}
        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        {...props.register}
      />
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
      {props.errors && (
        <div>
          <ErrorMessage
            errors={props.errors}
            name={name}
            render={({ message }) => (
              <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                {message}
              </p>
            )}
          />
        </div>
      )}
    </div>
  );
}
