import React, { PropsWithChildren } from "react";
import { Settings } from "lib/settings";
import { LanguageSelector } from "../../../Tailwind";
import { Ink1 } from "../../../video/backgrounds/Ink1";
import { TattooArtist } from "../../../video/backgrounds/TattooArtist";
import { type } from "@testing-library/user-event/dist/type";
import { Ink2 } from "../../../video/backgrounds/Ink2";
import { Barber } from "../../../video/backgrounds/Barber";
import { SkincareSpecialist } from "../../../video/backgrounds/SkincareSpecialist";
import { Beautician } from "../../../video/backgrounds/Beautician";
import { Hairdresser } from "../../../video/backgrounds/Hairdresser";
import { BodyPiercer } from "../../../video/backgrounds/BodyPiercer";
import { Manicurist } from "../../../video/backgrounds/Manicurist";
import { MassageTherapist } from "../../../video/backgrounds/MassageTherapist";
import { Ink3 } from "../../../video/backgrounds/Ink3";
import { ApiPage } from "../ApiPage";

export type VideoBackground =
  | "Ink"
  | "TattooArtist"
  | "Barber"
  | "SkincareSpecialist"
  | "Hairdresser"
  | "BodyPiercer"
  | "Manicurist"
  | "MassageTherapist"
  | "Beautician";

function AuthLayout({
  title,
  children,
  subContent,
  video,
}: PropsWithChildren<{
  title: string;
  subContent?: React.ReactNode;
  video: VideoBackground;
}>) {
  return (
    <ApiPage>
      <TattooArtist show={video === "TattooArtist"} />
      <Barber show={video === "Barber"} />
      <SkincareSpecialist show={video === "SkincareSpecialist"} />
      <Hairdresser show={video === "Hairdresser"} />
      <BodyPiercer show={video === "BodyPiercer"} />
      <Manicurist show={video === "Manicurist"} />
      <MassageTherapist show={video === "MassageTherapist"} />
      <Beautician show={video === "Beautician"} />
      <Ink1 show={video === "Ink"} />
      <div className="fixed right-3 top-3">
        <LanguageSelector dark />
      </div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-14 w-auto"
            src={Settings.textLogoDark}
            alt="Parlorkit"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
            {title}
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">{children}</div>
          {subContent}
        </div>
      </div>
    </ApiPage>
  );
}

export { AuthLayout };
