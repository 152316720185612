/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_unsupported_channel___USER_CONTROLLER_unsupported_channel_____PEM_USER_CONTROLLER_user_not_found___USER_CONTROLLER_user_not_found_____PEM_USER_CONTROLLER_incorrect_otp___USER_CONTROLLER_incorrect_otp____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_unsupported_channel___USER_CONTROLLER_unsupported_channel_____PEM_USER_CONTROLLER_user_not_found___USER_CONTROLLER_user_not_found_____PEM_USER_CONTROLLER_incorrect_otp___USER_CONTROLLER_incorrect_otp____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_unsupported_channel___USER_CONTROLLER_unsupported_channel_____PEM_USER_CONTROLLER_user_not_found___USER_CONTROLLER_user_not_found_____PEM_USER_CONTROLLER_incorrect_otp___USER_CONTROLLER_incorrect_otp____ {

    export enum detail {
        USER_CONTROLLER_UNSUPPORTED_CHANNEL = 'USER_CONTROLLER_unsupported_channel',
        USER_CONTROLLER_USER_NOT_FOUND = 'USER_CONTROLLER_user_not_found',
        USER_CONTROLLER_INCORRECT_OTP = 'USER_CONTROLLER_incorrect_otp',
    }


}

