import React, { useEffect, useState } from "react";
import { ProfileLayout } from "./ProfileLayout";
import { Service, User } from "../../../../api/staff";
import { useAppApi } from "../../../../api/staff/AppApi";
import { Input, Phone } from "../../../../Tailwind/Input/Controlled";
import { useForm } from "react-hook-form";
import { Button } from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import { useFeedback } from "../../../../lib/useFeedback";

export function UserProfileSettings() {
  const api = useAppApi();
  const { t } = useTranslation("application");
  const feedback = useFeedback();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<User>();

  useEffect(() => {
    if (api.userProfile?.user) {
      reset(api.userProfile?.user);
    }
  }, [api.userProfile]);

  function submit(data: User) {
    if (data.name && data.phone_number && data.email) {
      api.users
        .updateUserUsersPut({
          name: data.name,
          phone_number: data.phone_number,
          email: data.email,
        })
        .then(() => {
          api.auth.refresh();
          feedback.showSuccessToast(t("userProfileSettings.toast.success"));
        });
    }
  }

  return (
    <ProfileLayout active={"user"}>
      <div className="flex flex-col gap-y-8">
        <Input
          label={t("userProfileSettings.input.name.label")}
          register={register("name")}
          errors={errors}
        />
        <div className="flex flex-col md:flex-row gap-2 gap-y-8">
          <div className="flex-1">
            <Input
              label={t("userProfileSettings.input.email.label")}
              type="email"
              register={register("email", {
                required: t("userProfileSettings.error.emailRequired")!,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("userProfileSettings.error.invalidEmailFormat"),
                },
              })}
              errors={errors}
            />
          </div>
          <div className="flex-1">
            <Phone
              control={control}
              label={t("userProfileSettings.input.phone.label")}
              name={"phone_number"}
            />
          </div>
        </div>
        <div className="flex justify-end mt-5 gap-2">
          <Button
            color="secondary"
            onClick={() => {
              reset(api.userProfile?.user);
            }}
          >
            {t("userProfileSettings.button.reset")}
          </Button>
          <Button onClick={handleSubmit(submit)}>
            {t("userProfileSettings.button.save")}
          </Button>
        </div>
      </div>
    </ProfileLayout>
  );
}
