import React, {
  HTMLInputTypeAttribute,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ErrorMessage } from "@hookform/error-message";
import { useQueryToken } from "../../../lib/utils";

interface InputProps {
  label?: string | null;
  description?: ReactNode | null;
  errorText?: string;
  type?: HTMLInputTypeAttribute | undefined;
  disabled?: boolean | undefined;
  id?: string | undefined;
  defaultValue?: string | number | ReadonlyArray<string> | undefined;
  placeholder?: string | null;
  className?: string | undefined;
  autoComplete?: string | undefined;
  autoCapitalize?: string;
  leftDecoration?: ReactNode;
  rightDecoration?: ReactNode;
  register: UseFormRegisterReturn<string>;
  errors: FieldErrors;
  dark?: boolean;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

export function Input(props: InputProps) {
  const [color, setColor] = useState(
    props.dark
      ? "bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset placeholder:text-gray-500 ring-white/40 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      : "text-gray-900 ring-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600"
  );

  const token = useQueryToken();

  const [name, setName] = useState(props.register.name);

  const error = props.errors[name];
  const paddingLeft = props.leftDecoration ? "pl-8" : "pl-2";
  const paddingRight = props.rightDecoration ? "pr-12" : "";

  useEffect(() => {
    if (error) {
      setColor(
        "appearance-none text-red-900 ring-red-300 shadow-sm ring-1 placeholder:text-red-300 focus:ring-red-500"
      );
    } else {
      setColor(
        props.dark
          ? "appearance-none bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/40 focus:ring-2 focus:ring-inset placeholder:text-gray-500 focus:ring-indigo-500"
          : "appearance-none text-gray-900 ring-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600"
      );
    }
  }, [error]);

  return (
    <div className={props.className}>
      <label
        className={
          props.dark
            ? "block text-sm font-medium leading-6 text-white"
            : "block text-sm font-medium leading-6 text-gray-900"
        }
      >
        {props.label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        {props.leftDecoration && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 pr-7">
            <span className="text-gray-500 sm:text-sm">
              {props.leftDecoration}
            </span>
          </div>
        )}
        <input
          onFocus={(s) => {
            s.target.select();
          }}
          id={props.id}
          type={props.type}
          inputMode={props.inputMode}
          {...props.register}
          disabled={props.disabled}
          autoCapitalize={props.autoCapitalize}
          className={`block w-full rounded-md border-0 py-1.5 ${paddingLeft} ${paddingRight} focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${color}`}
          placeholder={props.placeholder || undefined}
          defaultValue={props.defaultValue}
          aria-invalid={!!props.errorText}
          aria-describedby={`${name}-error`}
        />
        {props.rightDecoration && !error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <div className="text-gray-500 sm:text-sm">
              {props.rightDecoration}
            </div>
          </div>
        )}
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <div>
        <ErrorMessage
          errors={props.errors}
          name={name}
          render={({ message }) => (
            <p
              className={
                props.dark
                  ? "mt-2 text-sm text-red-300"
                  : "mt-2 text-sm text-red-600"
              }
              id={`${name}-error`}
            >
              {message}
            </p>
          )}
        />
      </div>
      {props.description && (
        <p
          className={
            props.dark
              ? "mt-3 text-sm leading-6 text-gray-300"
              : "mt-3 text-sm leading-6 text-gray-600"
          }
        >
          {props.description}
        </p>
      )}
    </div>
  );
}
