/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarDay } from '../models/CalendarDay';
import type { Timeslot } from '../models/Timeslot';
import type { TimeslotInDb } from '../models/TimeslotInDb';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookingsService {

    /**
     * Get Schedule
     * @param userRoleId
     * @returns TimeslotInDb Successful Response
     * @throws ApiError
     */
    public static getScheduleScheduleGet(
        userRoleId: number,
    ): CancelablePromise<Array<TimeslotInDb>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/schedule',
            query: {
                'user_role_id': userRoleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Schedule
     * @param requestBody
     * @returns Timeslot Successful Response
     * @throws ApiError
     */
    public static createScheduleSchedulePost(
        requestBody: Timeslot,
    ): CancelablePromise<Timeslot> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/schedule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Timeslot
     * @param timeslotId
     * @returns TimeslotInDb Successful Response
     * @throws ApiError
     */
    public static getTimeslotScheduleTimeslotTimeslotIdGet(
        timeslotId: number,
    ): CancelablePromise<TimeslotInDb> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/schedule/timeslot/{timeslot_id}',
            path: {
                'timeslot_id': timeslotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Timeslot
     * @param timeslotId
     * @param requestBody
     * @returns TimeslotInDb Successful Response
     * @throws ApiError
     */
    public static updateTimeslotScheduleTimeslotTimeslotIdPut(
        timeslotId: number,
        requestBody: Timeslot,
    ): CancelablePromise<TimeslotInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/schedule/timeslot/{timeslot_id}',
            path: {
                'timeslot_id': timeslotId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Timeslot
     * @param timeslotId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTimeslotScheduleTimeslotTimeslotIdDelete(
        timeslotId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/schedule/timeslot/{timeslot_id}',
            path: {
                'timeslot_id': timeslotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Booking Calendar
     * @param year
     * @param month
     * @param duration
     * @param ignoreBooking
     * @param employeeId
     * @returns CalendarDay Successful Response
     * @throws ApiError
     */
    public static getBookingCalendarBookingCalendarYearMonthGet(
        year: number,
        month: number,
        duration: number,
        ignoreBooking?: number,
        employeeId?: number,
    ): CancelablePromise<Array<CalendarDay>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/booking-calendar/{year}/{month}',
            path: {
                'year': year,
                'month': month,
            },
            query: {
                'duration': duration,
                'ignore_booking': ignoreBooking,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
