import React, {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export interface InputProps {
  label?: string | null;
  errorText?: string;
  disabled?: boolean | undefined;
  id?: string | undefined;
  description?: string | null;
  placeholder?: string | null;
  className?: string | undefined;
  autoComplete?: string | undefined;
  name: string;
  error?: string;
  onChange: ChangeEventHandler<any>;
  value: any;
  dark?: boolean;
}

interface GenericInputProps extends InputProps {
  type?: HTMLInputTypeAttribute | undefined;
  ref?: RefObject<any>;
  leftDecoration?: ReactNode;
  rightDecoration?: ReactNode;
  defaultValue?: string | number | ReadonlyArray<string> | undefined;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
}

export function Input(props: GenericInputProps) {
  const [color, setColor] = useState(
    props.dark
      ? "bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset placeholder:text-gray-500 ring-white/40 focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      : "text-gray-900 ring-gray-300 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-indigo-600"
  );

  const [name, setName] = useState(props.name);

  const paddingLeft = props.leftDecoration ? "pl-8" : "pl-2";
  const paddingRight = props.rightDecoration ? "pr-12" : "";

  useEffect(() => {
    if (props.error) {
      setColor(
        "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
      );
    } else {
      setColor(
        props.dark
          ? "bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/40 focus:ring-2 focus:ring-inset placeholder:text-gray-500 focus:ring-indigo-500"
          : "text-gray-900 ring-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600"
      );
    }
  }, [props.error]);

  return (
    <div className={props.className}>
      {props.label && (
        <label
          htmlFor="email"
          className={
            props.dark
              ? "block text-sm font-medium leading-6 text-white"
              : "block text-sm font-medium leading-6 text-gray-900"
          }
        >
          {props.label}
        </label>
      )}
      <div className="relative rounded-md shadow-sm">
        {props.leftDecoration && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
            <span className="text-gray-500 sm:text-sm">
              {props.leftDecoration}
            </span>
          </div>
        )}
        <input
          onFocus={(s) => {
            s.target.select();
          }}
          ref={props.ref}
          inputMode={props.inputMode || "text"}
          id={props.id}
          value={props.value}
          type={props.type}
          autoComplete={props.autoComplete}
          onChange={props.onChange}
          disabled={props.disabled}
          className={`block w-full rounded-md border-0 py-1.5 ${paddingLeft} ${paddingRight} sm:text-sm sm:leading-6 ${color}`}
          placeholder={props.placeholder || undefined}
          defaultValue={props.defaultValue}
          aria-invalid={!!props.errorText}
          aria-describedby={`${name}-error`}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {props.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
        {props.rightDecoration && !props.error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              {props.rightDecoration}
            </span>
          </div>
        )}
      </div>
      {props.error && (
        <p
          className={
            props.dark
              ? "mt-2 text-sm text-red-300"
              : "mt-2 text-sm text-red-600"
          }
        >
          {props.error}
        </p>
      )}
      {props.description && (
        <p
          className={
            props.dark
              ? "mt-3 text-sm leading-6 text-gray-400"
              : "mt-3 text-sm leading-6 text-gray-600"
          }
        >
          {props.description}
        </p>
      )}
    </div>
  );
}
