import React, { PropsWithChildren, useState } from "react";
import { color } from "./constants";

interface AProps {
  color?: "primary" | "secondary";
  dark?: boolean;
  href: string;
  className?: string;
  target?: string;
}

function getProps(props: AProps) {
  const shade = props.dark === true ? "dark" : "light";
  const clr = props.color
    ? ` ${color[shade][props.color]}`
    : ` ${color[shade]["primary"]}`;
  return `${clr}`;
}

export function A(props: PropsWithChildren<AProps>) {
  const [cls] = useState(`${getProps(props)} ${props.className}`);

  return (
    <a className={cls} target={props.target} href={props.href}>
      {props.children}
    </a>
  );
}
