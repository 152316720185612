/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_SERVICES_CONTROLLER_service_not_found___SERVICES_CONTROLLER_service_not_found____ = {
    detail: PS_Literal__PEM_SERVICES_CONTROLLER_service_not_found___SERVICES_CONTROLLER_service_not_found____.detail;
};

export namespace PS_Literal__PEM_SERVICES_CONTROLLER_service_not_found___SERVICES_CONTROLLER_service_not_found____ {

    export enum detail {
        SERVICES_CONTROLLER_SERVICE_NOT_FOUND = 'SERVICES_CONTROLLER_service_not_found',
    }


}

