import React, { useEffect, useState } from "react";
import { CreateUserInvitation, File, UserRole, UserRoles } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { useForm } from "react-hook-form";
import { Gallery } from "../Gallery/Gallery";
import { useTranslation } from "react-i18next";
import { useFeedback } from "lib/useFeedback";
import { Input, NativeSelect, TextArea } from "Tailwind/Input/Controlled";
import {
  Button,
  CircularAvatar,
  FormSection,
  Modal,
  SlideOver,
} from "Tailwind";
import { ColorPicker } from "../../../../Tailwind/Input/Uncontrolled/ColorPicker";
import { userRoleColors } from "./colors";

export function UserRoleSlideOver({
  onComplete,
  userRole,
  isOpen,
  onClose,
}: {
  onComplete: () => void;
  userRole?: UserRole;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation("application");
  const [showAlbum, setShowAlbum] = useState(false);
  const [newAvatar, setAvatar] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<string>(
    userRole && userRole.color
      ? userRole.color
      : userRoleColors[Math.floor(Math.random() * userRoleColors.length)]
  );
  const errorHandler = useFeedback();

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateUserInvitation>({
    defaultValues: userRole
      ? {
          name: userRole.user.name,
          email: userRole.user.email,
          title: userRole.title,
          description: userRole.description,
          role: userRole.role,
        }
      : { role: UserRoles.EMPLOYEE },
  });

  const api = useAppApi();

  useEffect(() => {
    if (userRole !== undefined) {
      setAvatar(undefined);
      reset({
        name: userRole.user.name,
        email: userRole.user.email,
        title: userRole.title,
        description: userRole.description,
        role: userRole.role,
      });
    } else {
      setAvatar(undefined);
      reset({ role: UserRoles.EMPLOYEE });
    }
  }, [userRole, isOpen]);

  function submitData(data: CreateUserInvitation) {
    setLoading(true);
    data.color = color;
    if (!userRole) {
      api.users
        .inviteUserAccountInviteUserPost({
          ...data,
          ...{ avatar_id: newAvatar?.id },
        })
        .then(() => {
          onComplete();
        })
        .catch((e) => {
          errorHandler.handleApiError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api.users
        .updateUserRoleAccountUserRoleUserRoleIdPut(userRole.id, {
          ...data,
          ...{
            avatar_id:
              newAvatar?.id ||
              (userRole.avatar ? userRole.avatar?.id : undefined),
          },
        })
        .then(() => {
          onComplete();
        })
        .catch((e) => {
          errorHandler.handleApiError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <SlideOver
      title={
        userRole
          ? t("userRoleForm.slideOver.edit.title")
          : t("userRoleForm.slideOver.invite.title")
      }
      actions={[
        <Button
          disabled={loading}
          type="submit"
          onClick={handleSubmit(submitData)}
        >
          {userRole
            ? t("userRoleForm.button.save")
            : t("userRoleForm.button.invite")}
        </Button>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="col-span-full">
          <FormSection subtitle={t("userRoleForm.formSection.subtitle")} />
        </div>
        <div className="col-span-3">
          <Input
            errors={errors}
            disabled={!!userRole}
            label={t("userRoleForm.input.email.label")}
            register={register("email")}
            type={"email"}
            placeholder={t("userRoleForm.input.email.placeholder")}
          />
        </div>
        <div className="col-span-3">
          <Input
            disabled={!!userRole}
            label={t("userRoleForm.input.name.label")}
            errors={errors}
            register={register("name")}
          />
        </div>
        <div className="col-span-6">
          <Input
            label={t("userRoleForm.input.title.label")}
            errors={errors}
            register={register("title")}
          />
        </div>
        <div className="col-span-6">
          <TextArea
            rows={6}
            label={t("userRoleForm.input.description.label")}
            errors={errors}
            register={register("description")}
          />
        </div>
        <div className="col-span-6">
          <NativeSelect
            label={t("userRoleForm.nativeSelect.role.label")}
            items={
              userRole?.role === UserRoles.OWNER
                ? [
                    {
                      label: t("userRoleForm.nativeSelect.items.owner"),
                      value: UserRoles.OWNER,
                    },
                    {
                      label: t("userRoleForm.nativeSelect.items.employee"),
                      value: UserRoles.EMPLOYEE,
                    },
                    {
                      label: t("userRoleForm.nativeSelect.items.manager"),
                      value: UserRoles.MANAGER,
                    },
                  ]
                : [
                    {
                      label: t("userRoleForm.nativeSelect.items.employee"),
                      value: UserRoles.EMPLOYEE,
                    },
                    {
                      label: t("userRoleForm.nativeSelect.items.manager"),
                      value: UserRoles.MANAGER,
                    },
                  ]
            }
            name={"role"}
            disabled={
              userRole?.role === UserRoles.OWNER ||
              !(
                api.userProfile?.current_role?.role === UserRoles.OWNER ||
                api.userProfile?.current_role?.role === UserRoles.MANAGER
              )
            }
            control={control}
          />
        </div>
        <div className="col-span-full">
          <FormSection
            title={t("userRoleSlideover.formSection.color.title")}
            subtitle={t("userRoleSlideover.formSection.color.subtitle")}
          />
          <div className="col-span-full">
            <ColorPicker
              colors={userRoleColors}
              selectedColor={color}
              onClick={setColor}
            />
          </div>
        </div>
        <div className="col-span-full">
          <FormSection
            title={t("userRoleForm.formSection.images.title")}
            subtitle={""}
          />
          <div className="mt-2">
            <Button
              type="button"
              color="secondary"
              onClick={(event) => {
                event.preventDefault();
                setShowAlbum(true);
              }}
            >
              {t("userRoleForm.button.album")}
            </Button>
          </div>
        </div>
        <div className="col-span-full flex flex-row justify-items-start gap-x-4">
          {newAvatar ? (
            <CircularAvatar file={newAvatar} />
          ) : (
            userRole?.avatar && <CircularAvatar file={userRole.avatar} />
          )}
        </div>
      </div>

      <Modal
        visible={showAlbum}
        setVisible={() => {
          setShowAlbum(false);
        }}
      >
        <Gallery
          onImageClicked={(image) => {
            setAvatar(image);
            setShowAlbum(false);
          }}
        />
        <div className="mt-8 flex justify-end">
          <Button
            onClick={() => {
              setShowAlbum(false);
            }}
          >
            {t("userRoleForm.button.cancel")}
          </Button>
        </div>
      </Modal>
    </SlideOver>
  );
}
