import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import { ActionPanelTopRight, Button, Link } from "Tailwind";
import React from "react";
import { useTranslation } from "react-i18next";
import BusinessHours from "../Pages/Profile/BusinessHours/BusinessHours";
import { useNavigate } from "react-router-dom";

export function BusinessHoursOnboarding() {
  const { t } = useTranslation("application");
  const navigate = useNavigate();
  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={t("businessHoursOnboarding.actionPanel.title")}
          subTitle={t("businessHoursOnboarding.actionPanel.subTitle")}
          actions={[
            <Button
              color="branded"
              onClick={() => {
                navigate("/onboarding/tour-1");
              }}
            >
              {t("businessHoursOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Button>,
          ]}
        >
          <>
            <BusinessHours />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
