import { DefaultService } from "./services/DefaultService";
import { useContext } from "react";
import { ClientContext } from "./ClientContext";

export class WebshopAuth {
  private context = useContext(ClientContext);

  public async signIn(
    username: string,
    password: string,
    channel: "password" | "sms" | "email"
  ) {
    return DefaultService.loginTokenPost({
      username: username,
      password: password,
    }).then((client) => {
      this.context.setClient(client);
    });
  }

  public async logout() {
    // The server has to do the logging out
    DefaultService.logoutLogoutDelete().then(() => {
      this.context.setClient(undefined);
    });
  }

  public async refresh() {
    return DefaultService.whoamiWhoamiGet().then((client) => {
      this.context.setClient(client);
      return client;
    });
  }
}
