import React from "react";
import { Link } from "react-router-dom";

export function MenuItemMobile({
  selected,
  text,
  href,
}: {
  selected: boolean;
  text: string;
  href: string;
}) {
  const cls = selected
    ? "bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
    : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium";

  return (
    <Link to={href} className={cls} aria-current="page">
      {text}
    </Link>
  );
}
