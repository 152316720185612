import React, { Fragment, useEffect, useState } from "react";
import { Client, FullSessionInDb, Service, UserRole } from "api/staff";
import { useTranslation } from "react-i18next";
import { ServiceSection } from "./ServiceSection";
import { EmployeeSection } from "./EmployeeSection";
import { ClientSection } from "./ClientSection";
import { BookingSection } from "./BookingSection";
import { SlideOver } from "Tailwind/Overlays/SlideOver";
import { Button } from "Tailwind/Button";
import { useAppApi } from "api/staff/AppApi";
import { Transition } from "@headlessui/react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocale } from "../../../../../lib/locale";
export function CreateBookingSlideOver(props: {
  start?: Date;
  isOpen: boolean;
  requestOpen: () => void;
  onClose: () => void;
  onSessionCreated: (session: FullSessionInDb) => void;
  employee: UserRole;
}) {
  const { t } = useTranslation("application");
  const [start, setStart] = useState<Date | undefined>(props.start);
  const [duration, setDuration] = useState(0);
  const [draftHelperVisible, setDraftHelperVisible] = useState(false);

  const api = useAppApi();

  const [employee, setEmployee] = useState(props.employee);
  const [client, setClient] = useState<Client | undefined>();
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    setStart(undefined);
  }, [employee]);

  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee]);

  useEffect(() => {
    setStart(props.start);
  }, [props.start]);

  useEffect(() => {
    //Update duration when services change
    setDuration(services.reduce((a, b) => a + b.duration!, 0));
  }, [services]);

  useEffect(() => {
    // Check for conflicts and opening hours when start or duration change
  }, [start, duration]);

  useEffect(() => {}, [start]);

  function clear() {
    setClient(undefined);
    setClient(undefined);
    setStart(undefined);
    setDuration(0);
    setServices([]);
    setDraftHelperVisible(false);
  }

  return (
    <>
      <SessionDraftHelper
        client={client}
        onClear={() => {
          clear();
        }}
        onOpen={() => {
          props.requestOpen();
        }}
        visible={draftHelperVisible}
        services={services}
        onDismiss={() => {
          clear();
        }}
      />
      <SlideOver
        isOpen={props.isOpen}
        onClose={() => {
          props.onClose();
          if (client || services.length > 0) {
            setDraftHelperVisible(true);
          }
        }}
        size="lg"
        title={t("createSessionSlideOver.title")}
        actions={[
          <Button
            color="secondary"
            onClick={() => {
              clear();
            }}
          >
            {t("createSessionSlideOver.action.clear")}
          </Button>,
          <Button
            disabled={
              start === undefined || duration === 0 || client === undefined
            }
            onClick={() => {
              if (employee && client && duration > 0 && start) {
                api.misc
                  .createSessionBookingSessionsBookPost({
                    employee_id: employee.id,
                    client_id: client.id,
                    services: services.map((s) => s.id),
                    start: start.toISOString(),
                    duration: duration,
                  })
                  .then((s) => {
                    props.onSessionCreated(s);
                    clear();
                  });
              } else {
                // Do something
              }
            }}
          >
            {t("createSessionSlideOver.action.create")}
          </Button>,
        ]}
      >
        <div className="flex flex-col gap-8">
          <EmployeeSection employee={employee} setEmployee={setEmployee} />
          <ClientSection
            client={client}
            onClientSelected={(c) => setClient(c)}
            onClientRemoved={() => setClient(undefined)}
          />
          <ServiceSection
            employee={employee}
            services={services}
            onServiceAdded={(s) => {
              setServices([...services, s]);
            }}
            onServiceRemoved={(s) => {
              let found = false;
              setServices(
                services.filter((f) => {
                  if (s.id === f.id && !found) {
                    found = true;
                    return false;
                  }
                  return true;
                })
              );
            }}
          />
          <BookingSection
            employee={employee}
            start={start}
            onChange={(d) => setStart(d)}
            duration={duration}
            onDurationOverride={(override) => setDuration(override)}
          />
        </div>
      </SlideOver>
    </>
  );
}

function SessionDraftHelper(props: {
  visible: boolean;
  onDismiss: () => void;
  client?: Client;
  services?: Service[];
  onClear: () => void;
  onOpen: () => void;
}) {
  const { t } = useTranslation("application");
  const locale = useLocale();

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={props.visible}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <PencilIcon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">
                    {t("sessionDraftHelper.title")}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("sessionDraftHelper.description")}
                  </p>
                  <p className="mt-1 text-sm text-gray-500 flex justify-between">
                    {props.client && <div>{props.client.name}</div>}
                    {props.services && (
                      <div>
                        {locale.duration(
                          props.services.reduce((a, b) => a + b.duration!, 0)
                        )}
                      </div>
                    )}
                  </p>
                  <div className="mt-3 flex space-x-7">
                    <button
                      onClick={() => {
                        props.onOpen();
                      }}
                      type="button"
                      className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {t("sessionDraftHelper.button.open")}
                    </button>
                    <button
                      onClick={() => {
                        props.onClear();
                      }}
                      type="button"
                      className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {t("sessionDraftHelper.button.clear")}
                    </button>
                  </div>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      props.onDismiss();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
