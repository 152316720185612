import { Client, FullSessionInDb, Note } from "../../../../api/staff";
import { useForm } from "react-hook-form";
import {
  Activity,
  Button,
  Feed,
  Font,
  FormSection,
  HeadingWithActions,
  List,
  Modal,
  SimpleEmptyState,
  SlideOver,
  Td,
  TextButton,
} from "../../../../Tailwind";
import { Input, Phone } from "../../../../Tailwind/Input/Controlled";
import { useTranslation } from "react-i18next";
import { validatePhone } from "../../../../lib/utils";
import { useAppApi } from "../../../../api/staff/AppApi";
import { useFeedback } from "../../../../lib/useFeedback";
import React, { useEffect, useState } from "react";
import {
  ClipboardIcon,
  PencilSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { ClientNoteModal } from "./ClientNoteModal";
import { FullScreenSessionModal } from "../Sessions/FullScreenSessionModal/FullScreenSessionModal";
import { useLocale } from "../../../../lib/locale";

export function ClientSlideOver({
  visible,
  session,
  client,
  onClientSaved,
  onCancel,
}: {
  visible: boolean;
  session?: FullSessionInDb;
  client?: Client;
  onClientSaved: (client: Client) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation("application");

  const api = useAppApi();
  const locale = useLocale();

  const [clientSessions, setClientSessions] = useState<FullSessionInDb[]>([]);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSession, setShowSession] = useState<FullSessionInDb | undefined>();
  const [phoneError, setPhoneError] = useState<string | undefined>();
  const feedback = useFeedback();
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    if (client && client.notes) {
      setNotes(client.notes);
    }
  }, [client]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Client>({ defaultValues: client });

  function submit(data: Client) {
    setSaving(true);
    if (!validatePhone(data.phone_number)) {
      setPhoneError(t("createClientForm.error.invalidPhone")!);
      return;
    } else {
      setPhoneError(undefined);
    }
    if (client) {
      api.clients
        .updateClientClientsPut(data)
        .then((c) => {
          onClientSaved(c);
        })
        .catch((e) => {
          feedback.handleErrors(e);
        })
        .finally(() => {
          setSaving(false);
        });
    } else {
      api.clients
        .createClientClientsPost(data)
        .then((c) => {
          onClientSaved(c);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }

  useEffect(() => {
    if (client && visible) {
      api.clients.getSessionsClientClientIdSessionsGet(client.id).then((s) => {
        setClientSessions(s);
        setNotes(client.notes || []);
      });
    }
    reset(client);
  }, [client, visible]);

  return (
    <>
      <SlideOver
        isOpen={visible}
        actions={[
          <Button disabled={saving} onClick={handleSubmit(submit)}>
            {t("clientApp.button.save")}
          </Button>,
          <Button disabled={saving} color="secondary" onClick={onCancel}>
            {t("clientApp.button.cancel")}
          </Button>,
        ]}
        onClose={() => {
          onCancel();
        }}
        title={t("clientApp.SlideOver.title")}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <Input
              label={t("clientApp.input.name.label")}
              register={register("name", {
                required: t("clientApp.error.nameRequired")!,
              })}
              autoCapitalize={"words"}
              errors={errors}
            />
          </div>
          <div className="sm:col-span-3">
            <Input
              label={t("clientApp.input.email.label")}
              register={register("email", {
                required: t("clientApp.error.emailRequired")!,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("clientApp.error.invalidEmailFormat"),
                },
              })}
              type="email"
              inputMode="email"
              errors={errors}
            />
          </div>
          <div className="sm:col-span-3">
            <Phone
              label={t("clientApp.input.phone_number.label")}
              name="phone_number"
              control={control}
              error={phoneError}
            />
          </div>
          {client && (
            <>
              <div className="sm:col-span-6">
                <HeadingWithActions
                  title={t("clientApp.formSection.notes.title")}
                  actions={[
                    <TextButton
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      <PlusIcon className="h-6 w-6" />
                    </TextButton>,
                  ]}
                />
                <Feed>
                  {notes?.map((note, index) => {
                    const first = index === notes?.length - 1;
                    return <Activity first={first} item={note} index={index} />;
                  })}
                </Feed>
                {notes.length === 0 && (
                  <SimpleEmptyState
                    title={t("clientApp.notes.emptyState.title")}
                    description={t("clientApp.notes.emptyState.description")}
                    decoration={<ClipboardIcon width={30} />}
                    actions={[]}
                  />
                )}
              </div>

              <div className="sm:col-span-6">
                <List
                  headers={[t("clientApp.list.sessions.headers.name"), ""]}
                  actions={[]}
                  title={t("clientApp.list.sessions.title")}
                  subtitle={""}
                  data={clientSessions}
                  renderLine={(s) => (
                    <>
                      <Td first>
                        <div className="flex flex-col">
                          <div>
                            <Font size="sm">
                              {s.services.length > 0
                                ? s.services[0].name
                                : "---"}
                            </Font>
                          </div>
                          <div>
                            <Font size="xs">
                              {locale.localeDateTime(new Date(s.start!))}
                            </Font>
                          </div>
                        </div>
                      </Td>
                      <Td last>
                        {session?.id !== s.id ? (
                          <TextButton
                            onClick={() => {
                              setShowSession(s);
                            }}
                          >
                            <PencilSquareIcon width={24} />
                          </TextButton>
                        ) : (
                          <Font size="base" color="green700">
                            {t("clientApp.list.sessions.text.thisSession")}
                          </Font>
                        )}
                      </Td>
                    </>
                  )}
                />
              </div>
            </>
          )}
        </div>
        {client && (
          <>
            <Modal
              size={"2xl"}
              visible={showModal}
              setVisible={() => {
                setShowModal(false);
              }}
            >
              <ClientNoteModal
                client={client}
                onClientUpdated={(c) => {
                  setNotes(c.notes || []);
                  setShowModal(false);
                }}
                onCancel={() => {
                  setShowModal(false);
                }}
              />
            </Modal>
            {showSession && (
              <FullScreenSessionModal
                isOpen={showSession !== undefined}
                onClose={() => {
                  setShowSession(undefined);
                }}
                sessionId={showSession?.id}
              />
            )}
          </>
        )}
      </SlideOver>
    </>
  );
}
