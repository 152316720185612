import React, { useEffect, useState } from "react";
import { ProfileLayout } from "./ProfileLayout";
import { NotificationSettings, Product } from "../../../../api/staff";
import { useAppApi } from "../../../../api/staff/AppApi";
import { Toggle } from "../../../../Tailwind/Input/Controlled";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Heading } from "../../../../Tailwind";
import { useFeedback } from "../../../../lib/useFeedback";

export function NotificationsProfileSettings() {
  const api = useAppApi();
  const { t } = useTranslation("application");
  const feedback = useFeedback();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<NotificationSettings>();

  useEffect(() => {
    api.notifications
      .getNotificationSettingsSettingsNotificationsGet()
      .then((s) => {
        reset(s.settings);
      });
  }, []);

  function submit(data: NotificationSettings) {
    api.notifications
      .getNotificationSettingsSettingsNotificationsPut(data)
      .then((s) => {
        reset(s);
        feedback.showSuccessToast(
          t("notificationsProfileSettings.toast.success")
        );
      })
      .catch((err) => feedback.handleErrors(err));
  }

  return (
    <ProfileLayout active={"notifications"}>
      <div className="flex flex-col gap-y-6">
        <Heading
          title={t("notificationsProfileSettings.heading.bookingCreated.title")}
          description={t(
            "notificationsProfileSettings.heading.bookingCreated.description"
          )}
        />
        <Toggle
          control={control}
          name={"booking_created_email"}
          label={t(
            "notificationsProfileSettings.toggle.booking_created_email.label",
            { email: api.userProfile?.user.email }
          )}
        />
        {/*<Toggle
          control={control}
          name={"booking_created_push"}
          label={t(
            "notificationsProfileSettings.toggle.booking_created_push.label"
          )}
        />*/}
        <Toggle
          control={control}
          name={"booking_created_app"}
          label={t(
            "notificationsProfileSettings.toggle.booking_created_app.label"
          )}
        />
        <div className="mb-2" />
        <Heading
          title={t(
            "notificationsProfileSettings.heading.bookingCanceled.title"
          )}
          description={t(
            "notificationsProfileSettings.heading.bookingCanceled.description"
          )}
        />
        <Toggle
          control={control}
          name={"booking_canceled_email"}
          label={t(
            "notificationsProfileSettings.toggle.booking_canceled_email.label",
            { email: api.userProfile?.user.email }
          )}
        />
        {/*<Toggle
          control={control}
          name={"booking_canceled_push"}
          label={t(
            "notificationsProfileSettings.toggle.booking_canceled_push.label"
          )}
        />*/}
        <Toggle
          control={control}
          name={"booking_canceled_app"}
          label={t(
            "notificationsProfileSettings.toggle.booking_canceled_app.label"
          )}
        />
        {/*<Heading
          title={t("notificationsProfileSettings.heading.pigmentExpires.title")}
          description={t(
            "notificationsProfileSettings.heading.pigmentExpires.description"
          )}
        />
        <Toggle
          control={control}
          name={"booking_pigment_expires_email"}
          label={t(
            "notificationsProfileSettings.toggle.booking_pigment_expires_email.label"
          )}
        />
        <Toggle
          control={control}
          name={"booking_pigment_expires_push"}
          label={t(
            "notificationsProfileSettings.toggle.booking_pigment_expires_push.label"
          )}
        />
        <Toggle
          control={control}
          name={"booking_pigment_expires_app"}
          label={t(
            "notificationsProfileSettings.toggle.booking_pigment_expires_app.label"
          )}
        />*/}
        <div className="flex justify-end mt-5">
          <Button onClick={handleSubmit(submit)}>
            {t("notificationsProfileSettings.button.save")}
          </Button>
        </div>
      </div>
    </ProfileLayout>
  );
}
