/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasePigment } from '../models/BasePigment';
import type { CreatePigmentRequest } from '../models/CreatePigmentRequest';
import type { Pigment } from '../models/Pigment';
import type { SearchBasePigmentsResult } from '../models/SearchBasePigmentsResult';
import type { SearchPigmentsResult } from '../models/SearchPigmentsResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PigmentsService {

    /**
     * Get Pigment
     * @param barcode
     * @param pigmentId
     * @returns Pigment Successful Response
     * @throws ApiError
     */
    public static getPigmentPigmentsGet(
        barcode?: string,
        pigmentId?: number,
    ): CancelablePromise<Pigment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pigments',
            query: {
                'barcode': barcode,
                'pigment_id': pigmentId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pigment
     * @param requestBody
     * @returns Pigment Successful Response
     * @throws ApiError
     */
    public static createPigmentPigmentsPost(
        requestBody: CreatePigmentRequest,
    ): CancelablePromise<Pigment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pigments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Pigment
     * @param pigmentId
     * @returns Pigment Successful Response
     * @throws ApiError
     */
    public static archivePigmentPigmentsDelete(
        pigmentId: number,
    ): CancelablePromise<Pigment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pigments',
            query: {
                'pigment_id': pigmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Pigment
     * @param pigmentId
     * @param requestBody
     * @returns Pigment Successful Response
     * @throws ApiError
     */
    public static updatePigmentPigmentsPigmentIdPut(
        pigmentId: number,
        requestBody: Pigment,
    ): CancelablePromise<Pigment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pigments/{pigment_id}',
            path: {
                'pigment_id': pigmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Base Pigment
     * @param upcBarcode
     * @returns BasePigment Successful Response
     * @throws ApiError
     */
    public static getBasePigmentBasePigmentsGet(
        upcBarcode: string,
    ): CancelablePromise<BasePigment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/base-pigments',
            query: {
                'upc_barcode': upcBarcode,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Base Pigments
     * @param term
     * @param offset
     * @returns SearchBasePigmentsResult Successful Response
     * @throws ApiError
     */
    public static getBasePigmentsSearchBasePigmentsGet(
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchBasePigmentsResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/base-pigments',
            query: {
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pigments
     * @param term
     * @param offset
     * @returns SearchPigmentsResult Successful Response
     * @throws ApiError
     */
    public static getPigmentsSearchPigmentsGet(
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchPigmentsResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/pigments',
            query: {
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
