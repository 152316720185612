import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleLeftIcon,
  CpuChipIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/solid";
import { TextArea } from "../Tailwind/Input/Uncontrolled";
import { CircularAvatar, Spinner, TextButton } from "../Tailwind";
import { Checkbox } from "../catalyst/checkbox";
import { useAppApi } from "../api/staff/AppApi";
import { ClientNotification, FullSessionInDb, Template } from "../api/staff";
import { useLocale } from "../lib/locale";
import { useFeedback } from "../lib/useFeedback";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { TemplateSelect } from "../sections/AppSection/Pages/Templates/TemplateSelect";

interface Media {
  type: "email" | "sms" | "chat";
  status: "delivered" | "failed" | "sending";
  events: { datetime: Date; event: string }[];
}

interface Message {
  sent: Date;
  from?: string;
  to: string;
  message: string;
  media: Media[];
}

export function ChatSlideOver(
  props: PropsWithChildren<{
    session: FullSessionInDb;
    isOpen: boolean;
    onClose: () => void;
  }>
) {
  const [chatText, setChatText] = useState("");
  const [template, setTemplate] = useState<Template | undefined>();
  const [messages, setMessages] = useState<ClientNotification[]>([]);
  const [sms, setSMS] = useState(true);
  const [email, setEmail] = useState(true);
  const [sending, setSending] = useState(false);
  const [credits, setCredits] = useState(0);
  const [estimatedSMSCost, setEstimatedSMSCost] = useState(0);
  const [charLimit, setCharLimit] = useState(160);
  const maxLength = 1600;

  const scrollRef = useRef<HTMLInputElement>(null);

  const api = useAppApi();
  const feedback = useFeedback();
  const { t } = useTranslation("application");

  const hasEnoughCredits = credits >= estimatedSMSCost;
  const canSend =
    ((sms && hasEnoughCredits && chatText.length <= maxLength) || email) &&
    !sending;

  useEffect(() => {
    if (template && template.id) {
      api.misc
        .renderTemplateTemplatesRenderPost(
          template.id,
          props.session.client?.id,
          props.session.id
        )
        .then((r) => {
          setChatText(r.rendered_body);
        });
    }
  }, [template]);

  useEffect(() => {
    loadMessages();
    const interval = setInterval(() => {
      if (props.isOpen) {
        loadMessages();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [props.isOpen]);

  async function checkForUnicodeAndAdjustLimit(message: string) {
    let charLimit = 160; // Default character limit

    // Check for Unicode characters
    for (let i = 0; i < message.length; i++) {
      if (message.charCodeAt(i) > 127) {
        charLimit = 70; // Adjust character limit if Unicode characters are found
        break; // No need to continue checking after finding a Unicode character
      }
    }

    return charLimit;
  }

  function loadMessages() {
    api.communication
      .getMessagesUnifiedChatSessionSessionIdGet(props.session.id)
      .then((m) => {
        setMessages(m);
      });
    api.communication.getSmsCreditsSmsCreditsGet().then((c) => {
      setCredits(c.available_credits);
    });
  }

  useEffect(() => {
    setEstimatedSMSCost(Math.ceil(chatText.length / charLimit));
  }, [charLimit]);

  useEffect(() => {
    checkForUnicodeAndAdjustLimit(chatText).then((newLimit) => {
      if (newLimit !== charLimit) setCharLimit(newLimit);
    });
    if (sms) {
      setEstimatedSMSCost(Math.ceil(chatText.length / charLimit));
    }
  }, [chatText]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages]);

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => props.onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen max-w-2xl`}
                >
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900"></Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => props.onClose()}
                            >
                              <span className="sr-only">Close panel</span>

                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {messages.map((m) => {
                          if (m.direction && m.direction === "incoming") {
                            return <Incoming message={m} />;
                          } else {
                            return <Outgoing message={m} />;
                          }
                        })}
                        <div ref={scrollRef} />
                      </div>
                    </div>
                    <div className="px-4 py-4 gap-x-4">
                      <div className="flex justify-between items-end">
                        <div>
                          <TemplateSelect
                            onSelect={(t) => {
                              setTemplate(t);
                            }}
                            selected={template}
                          />
                        </div>
                        <div className="text-gray-600 font-light text-xs">
                          {chatText.length}
                        </div>
                      </div>
                      <TextArea
                        rows={5}
                        value={chatText}
                        name={"chatInput"}
                        onChange={(t) => {
                          setChatText(t);
                        }}
                      />
                      <div className="flex justify-between mt-4">
                        <div className="flex flex-row gap-4 font-light items-center">
                          <div className="flex flex-row gap-1 items-center">
                            <Checkbox
                              checked={email}
                              onChange={(v) => {
                                setEmail(v);
                              }}
                            />
                            {t("chatSlideOver.checkbox.email")}
                          </div>
                          <div className="flex flex-row gap-1 items-center">
                            <Checkbox
                              checked={sms}
                              onChange={(v) => {
                                setSMS(v);
                              }}
                            />
                            {t("chatSlideOver.checkbox.sms")}
                            <div className="hidden sm:flex flex-row font-light text-xs text-gray-500 items-center my-1">
                              {t("chatSlideOver.checkbox.estimate", {
                                estimate: estimatedSMSCost.toFixed(1),
                              })}
                            </div>
                          </div>
                        </div>
                        <TextButton
                          disabled={!canSend}
                          onClick={() => {
                            if (props.session.id && props.session.client) {
                              setSending(true);
                              api.communication
                                .sendMessageUnifiedChatMessagePost({
                                  sms:
                                    sms &&
                                    estimatedSMSCost <= credits &&
                                    chatText.length <= maxLength,
                                  email: email,
                                  message: chatText,
                                  session_id: props.session.id,
                                  client_id: props.session.client?.id,
                                })
                                .then(() => {
                                  feedback.showSuccessToast(
                                    t("chatSlideOver.success")
                                  );
                                  loadMessages();
                                })
                                .finally(() => {
                                  setSending(false);
                                  setChatText("");
                                });
                            }
                          }}
                        >
                          <div className="flex flex-row gap-1 items-center">
                            {sending && <Spinner size="xs" />}
                            <span>{t("chatSlideOver.button.send")}</span>
                            <PaperAirplaneIcon className="w-4" />
                          </div>
                        </TextButton>
                      </div>
                      <div className="flex flex-col">
                        {sms && chatText.length > 0 && (
                          <div className="flex sm:hidden flex-row font-light text-xs text-gray-500 items-center my-1">
                            {t("chatSlideOver.checkbox.estimate", {
                              estimate: estimatedSMSCost.toFixed(1),
                            })}
                          </div>
                        )}
                        {sms && estimatedSMSCost > credits && (
                          <div className="flex flex-row font-light text-xs text-yellow-500 items-center">
                            <ExclamationCircleIcon className="w-5 mr-1" />
                            {t("chatSlideOver.warning.insufficientCredits", {
                              availableCredits: credits,
                            })}
                          </div>
                        )}
                        {(!sms || estimatedSMSCost > credits) && !email && (
                          <div className="flex flex-row font-light text-xs text-red-500 items-center">
                            <ExclamationCircleIcon className="w-5 mr-1" />
                            {t("chatSlideOver.warning.noChannel")}
                          </div>
                        )}
                        {sms && chatText.length > maxLength && (
                          <div className="flex flex-row font-light text-xs text-red-500 items-center">
                            <ExclamationCircleIcon className="w-5 mr-1" />
                            {t("chatSlideOver.warning.messageTooLongForSMS")}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Incoming(props: { message: ClientNotification }) {
  return (
    <div className="flex items-center justify-start mr-10">
      <div className="flex-1 my-6">
        <div className="justify-between flex flex-row mb-1">
          <div className="font-light text-xs text-slate-600">
            20/3 2024 10:24
          </div>
          <div className="flex flex-row gap-1">
            <DevicePhoneMobileIcon className="w-3 text-red-600" />
            <EnvelopeIcon className="w-3 text-green-600" />
            <ChatBubbleLeftIcon className="w-3 text-green-600" />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="w-3 overflow-hidden">
            <div className="h-4 bg-slate-600 rotate-45 transform origin-bottom-right rounded-sm"></div>
          </div>
          <div className="bg-slate-600 p-4 rounded-lg flex-1 text-white font-light">
            {props.message.content}
          </div>
        </div>
      </div>
    </div>
  );
}

function Outgoing({ message }: { message: ClientNotification }) {
  const [content, setContent] = useState(
    message.content?.replace("<br>", "\n") || ""
  );
  const locale = useLocale();

  function getSMSClass() {
    const exists = message.sms;
    const sent = message.sms?.events?.find((e) => e.message_status === "sent");
    const delivered = message.sms?.events?.find(
      (e) => e.message_status === "delivered"
    );

    if (!exists) {
      return null;
    } else if (delivered) {
      return "text-lime-600";
    } else if (sent || exists) {
      return "text-yellow-600 animate-pulse";
    } else {
      return "text-red-600";
    }
  }

  function getEmailClass() {
    const exists = message.email;
    const sent = message.email?.events?.find((e) => e.event === "sent");
    const delivered = message.email?.events?.find(
      (e) => e.event === "delivered"
    );

    if (!exists) {
      return null;
    } else if (delivered) {
      return "text-lime-600";
    } else if (sent || exists) {
      return "text-yellow-600 animate-pulse";
    } else {
      return "text-red-600";
    }
  }

  const devices = {
    sms: getSMSClass(),
    email: getEmailClass(),
    chat: "text-lime-600",
  };

  return (
    <div className="flex items-center justify-end ml-10">
      <div className="flex-1 my-6">
        <div className="justify-between flex flex-row mb-1">
          <div className="font-light text-xs text-slate-600">
            {locale.localeDateTime(new Date(message.created))}
          </div>
          <div className="flex flex-row gap-1">
            {devices.sms && (
              <DevicePhoneMobileIcon className={`w-3 ${devices.sms}`} />
            )}
            {devices.email && (
              <EnvelopeIcon className={`w-3 ${devices.email}`} />
            )}
            {devices.chat && (
              <ChatBubbleLeftIcon className={`w-3 ${devices.chat}`} />
            )}
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="bg-indigo-600 p-4 rounded-lg flex-1 text-white font-light"
          />
          <div className="w-3 overflow-hidden ">
            <div className="h-4 bg-indigo-600 rotate-45 transform origin-top-left rounded-sm"></div>
          </div>
          <div>
            {message.system || message.user_role == undefined ? (
              <CpuChipIcon className="w-6 h-6" />
            ) : (
              <CircularAvatar
                file={message.user_role?.avatar}
                color={message.user_role?.color}
                size={"xs"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
