/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_PRODUCT_CONTROLLER_barcode_in_use___PRODUCT_CONTROLLER_barcode_in_use____ = {
    detail: PS_Literal__PEM_PRODUCT_CONTROLLER_barcode_in_use___PRODUCT_CONTROLLER_barcode_in_use____.detail;
};

export namespace PS_Literal__PEM_PRODUCT_CONTROLLER_barcode_in_use___PRODUCT_CONTROLLER_barcode_in_use____ {

    export enum detail {
        PRODUCT_CONTROLLER_BARCODE_IN_USE = 'PRODUCT_CONTROLLER_barcode_in_use',
    }


}

