import React from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function Ink1(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/v1696358165/app/jumpstory-download20231003-183539_v3szlq.mp4"
      }
    />
  );
}
