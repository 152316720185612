import React, { useContext, useState } from "react";
import { useLocale } from "../../../lib/locale";
import { Button } from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Service, Staff } from "../../../api/webshop";
import { useWebshopApi } from "../../../api/webshop/WebshopApi";
import { useTranslation } from "react-i18next";
import { TextArea } from "../../../Tailwind/Input/Uncontrolled";

export function ConfirmSection(props: {
  services: Service[];
  staff: Staff;
  timeslot: Date;
}) {
  const locale = useLocale();
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  const [comment, setComment] = useState<string | undefined>();
  const [showTerms, setShowTerms] = useState(false);

  const navigate = useNavigate();

  const terms = api.webshop?.booking_page.terms || "";

  function completeBooking() {
    api.api
      .createBookingBookingPost({
        service_ids: props.services.map((s) => s.id),
        timeslot: props.timeslot.toISOString(),
        user_role_id: props.staff.id,
        comment: comment,
      })
      .then((session) => {
        navigate("/my/bookings");
        toast.success("Aftale oprettet", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-slate-900"
        >
          {t("confirmSection.title")}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {t("confirmSection.description")}
          <span className="text-sm font-bold"> {props.staff.user.name}</span>
        </p>
        <p className="text-sm text-slate-500 font-bold">
          {locale.localeDateWithDay(props.timeslot)}{" "}
          {locale.timeShort(props.timeslot)}
        </p>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              >
                {t("confirmSection.service")}
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {t("confirmSection.duration")}
              </th>
              <th
                scope="col"
                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                {t("confirmSection.price")}
              </th>
            </tr>
          </thead>
          <tbody>
            {props.services.map((s, index) => (
              <ServiceLineItem key={index} service={s} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={2}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
              >
                {t("confirmSection.duration")}
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
              >
                {t("confirmSection.duration")}
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {locale.duration(
                  props.services.reduce((a, b) => a + b.duration, 0)
                )}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={2}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
              >
                {t("confirmSection.price")}
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                {t("confirmSection.price")}
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                {props.services.reduce((a, b) => a + b.price, 0)} kr.
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="py-5">
          <h2
            id="commentSection"
            className="text-lg font-medium leading-6 text-slate-900"
          >
            {t("commentSection.title")}
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-slate-500">
            {t("commentSection.description")}
          </p>
        </div>
        <TextArea
          rows={6}
          value={comment || ""}
          name={"comment"}
          onChange={(value) => {
            setComment(value);
          }}
        />
        <div className="pt-5 flex flex-col sm:flex-row-reverse gap-2">
          <Button
            onClick={() => {
              if (terms === "") {
                completeBooking();
              } else {
                setShowTerms(true);
              }
            }}
            text={t("confirmSection.button.book")}
          />
        </div>
      </div>
      {showTerms && (
        <TermsModal
          onAccept={() => {
            setShowTerms(false);
            completeBooking();
          }}
          onReject={() => {
            setShowTerms(false);
          }}
        />
      )}
    </div>
  );
}

function ServiceLineItem({ service }: { service: Service }) {
  const locale = useLocale();

  return (
    <tr className="border-b border-gray-200">
      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
        <div className="font-medium text-gray-900">{service.name}</div>
        <div className="mt-0.5 text-gray-500 sm:hidden">
          {locale.duration(service.duration)}
        </div>
      </td>
      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
        {locale.duration(service.duration)}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
        {service.price} kr
      </td>
    </tr>
  );
}

function TermsModal(props: { onAccept: () => void; onReject: () => void }) {
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  {t("termsModal.title")}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 text-left whitespace-pre-line">
                    {api.webshop?.booking_page.terms}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <div className="inline-flex w-full sm:col-start-2 sm:text-sm">
                <Button
                  color={"white"}
                  text={"Afvis"}
                  fullWidth
                  onClick={props.onReject}
                />
              </div>
              <div className="mt-3 inline-flex w-full sm:col-start-1 sm:mt-0 sm:text-sm">
                <Button
                  text={t("termsModal.button.accept")}
                  fullWidth
                  onClick={props.onAccept}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
