import React from "react";
import { useController } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

export type SelectItem = {
  label: string;
  value: string | number;
};
export function NativeSelect(props: {
  dark?: boolean;
  label: string | null;
  control: Control<any, string>;
  description?: string | null;
  name: string;
  items: SelectItem[];
  onChange?: (item: string) => void;
  disabled?: boolean;
}) {
  const {
    field: { value, onChange },
  } = useController(props);
  return (
    <div>
      <label
        htmlFor="location"
        className={
          props.dark
            ? "block text-sm font-medium leading-6 text-white"
            : "block text-sm font-medium leading-6 text-gray-900"
        }
      >
        {props.label}
      </label>
      <select
        disabled={props.disabled}
        id={props.name}
        name={props.name}
        onChange={(e) => {
          onChange(e);
          props.onChange && props.onChange(e.target.value);
        }}
        value={value}
        className="mt-2 block w-full rounded-md border-0 py-2 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {props.items.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {props.description && (
        <p
          className={
            props.dark
              ? "mt-3 text-sm leading-6 text-gray-300"
              : "mt-3 text-sm leading-6 text-gray-600"
          }
        >
          {props.description}
        </p>
      )}
    </div>
  );
}
