import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { UserPreferences } from "../sections/AppSection/UserPreferences";
import { Privacy } from "../sections/MarketingSection/Privacy/Privacy";
import { Terms } from "../sections/MarketingSection/Terms/Terms";
import React, { useEffect } from "react";
import { FakeDoor } from "../sections/MarketingSection/FakeDoor";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function MarketingRouter() {
  //TODO Route based on www or everything else.
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={"/"} element={<FakeDoor />} />
          <Route path={"/preferences"} element={<UserPreferences />} />
          <Route path={"/privacy"} element={<Privacy />} />
          <Route path={"/terms"} element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
