import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  Bars3Icon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ClipboardIcon,
  CurrencyDollarIcon,
  GiftIcon,
  StarIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Header } from "./Header";
import { ProtectedPage } from "../ProtectedPage";
import { useTranslation } from "react-i18next";
import { SidebarFull } from "./SidebarFull";
import { SidebarMobile } from "./SidebarMobile";
import { SidebarCompact } from "./SidebarCompact";

export type Page =
  | "settings"
  | "profile"
  | "sessions"
  | "calendar"
  | "timeOff"
  | "sales"
  | "users"
  | "pigments"
  | "products"
  | "services"
  | "templates"
  | "clients"
  | "businessHours";

export interface Navigation {
  name: Page;
  label: string;
  href: string;
  icon: any;
}

const storageKey = "compactMenu";

export function Layout(props: PropsWithChildren<{ active: Page }>) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [compact, setCompact] = useState(
    !!window.localStorage.getItem(storageKey)
  );
  const { t } = useTranslation("application");

  useEffect(() => {
    if (compact) {
      window.localStorage.setItem(storageKey, "yes");
    } else {
      window.localStorage.removeItem(storageKey);
    }
  }, [compact]);

  const navigation: Navigation[] = [
    {
      name: "calendar",
      label: t("layout.navigation.calendar"),
      href: "/calendar",
      icon: CalendarIcon,
    },
    {
      name: "sales",
      label: t("layout.navigation.sales"),
      href: "/sales",
      icon: BanknotesIcon,
    },
    {
      name: "sessions",
      label: t("layout.navigation.sessions"),
      href: "/sessions",
      icon: StarIcon,
    },
    {
      name: "clients",
      label: t("layout.navigation.clients"),
      href: "/clients",
      icon: UserIcon,
    },
  ];

  const administration: Navigation[] = [
    {
      name: "timeOff",
      label: t("layout.navigation.timeOff"),
      href: "/time-off",
      icon: ArrowRightOnRectangleIcon,
    },
    {
      name: "services",
      label: t("layout.navigation.services"),
      href: "/services",
      icon: BriefcaseIcon,
    },
    {
      name: "products",
      label: t("layout.navigation.products"),
      href: "/products",
      icon: GiftIcon,
    },
    {
      name: "templates",
      label: t("layout.navigation.templates"),
      href: "/templates",
      icon: ClipboardIcon,
    },
    {
      name: "users",
      label: t("layout.navigation.users"),
      href: "/users",
      icon: UsersIcon,
    },
    {
      name: "businessHours",
      label: t("layout.navigation.businessHours"),
      href: "/business-hours",
      icon: CalendarDaysIcon,
    },
  ];

  return (
    <>
      <ProtectedPage account>
        <div className="h-full">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-900/80" />
              </Transition.Child>

              <div className="fixed inset-0 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button
                          type="button"
                          className="-m-2.5 p-2.5"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <SidebarMobile
                      active={props.active}
                      navigation={navigation}
                      administration={administration}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            {compact ? (
              <SidebarCompact
                active={props.active}
                navigation={navigation}
                administration={administration}
                useFull={() => {
                  setCompact(false);
                }}
              />
            ) : (
              <SidebarFull
                active={props.active}
                navigation={navigation}
                administration={administration}
                useCompact={() => {
                  setCompact(true);
                }}
              />
            )}
          </div>

          <div
            className={
              compact
                ? "lg:pl-16 -z-10 h-full bg-white"
                : "lg:pl-72 -z-10 h-full bg-white"
            }
          >
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div
                className="h-6 w-px bg-gray-200 lg:hidden"
                aria-hidden="true"
              />

              <Header />
            </div>

            <main className="py-10 bg-white">
              <div className="px-4 sm:px-6 lg:px-8">{props.children}</div>
            </main>
          </div>
        </div>
      </ProtectedPage>
    </>
  );
}
