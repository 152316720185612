import { Button } from "../../Button";
import React from "react";
import { useTranslation } from "react-i18next";

export function Paginator(props: {
  nextOffset: number;
  page_size: number;
  total: number;
  onNext: (newOffset: number) => void;
  onPrevious: (newOffset: number) => void;
}) {
  const { t } = useTranslation("tailwind");
  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white py-12"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{props.total}</span>{" "}
          {t("paginator.text.results")}
        </p>
        <p className="text-sm text-gray-700">
          {t("paginator.text.showing_page")}{" "}
          {props.page_size && (
            <span className="font-medium">
              {Math.ceil(props.nextOffset / props.page_size)}
            </span>
          )}{" "}
          {t("paginator.text.of")}{" "}
          {props.page_size && (
            <span className="font-medium">
              {Math.ceil(props.total / props.page_size)}
            </span>
          )}
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end gap-x-4">
        <Button
          disabled={
            props.page_size === 0 ||
            Math.ceil(props.nextOffset / props.page_size) <= 1
          }
          onClick={() => {
            const offset = Math.max(props.nextOffset - props.page_size * 2, 0);
            props.onPrevious(offset);
          }}
        >
          {t("list.button.previous")}
        </Button>
        <Button
          disabled={
            props.page_size === 0 ||
            Math.ceil(props.nextOffset / props.page_size) >=
              Math.ceil(props.total / props.page_size)
          }
          onClick={() => {
            props.onNext(props.nextOffset);
          }}
        >
          {t("list.button.next")}
        </Button>
      </div>
    </nav>
  );
}
