export const sizes = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1 px-2 text-sm",
  md: "py-1.5 px-2.5 text-sm",
  lg: "py-2 px-3 text-sm",
  xl: "py-2.5 px-3.5 text-sm",
};

export const light = {
  branded:
    "bg-gradient-to-r from-indigo-400 to-emerald-400 font-semibold text-white shadow-sm hover:from-indigo-300 hover:to-emerald-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
  primary:
    "bg-indigo-500 disabled:bg-indigo-200 font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
  secondary:
    "bg-white font-semibold text-gray-900 disabled:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
  soft: "bg-indigo-50 disabled:bg-gray-200 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100",
  red: "bg-red-600 disabled:bg-gray-200font-semibold text-white shadow-sm hover:bg-red-500",
  pro: "text-amber-600 ring-1 ring-inset ring-amber-200 hover:ring-amber-300 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600",
  basic:
    "text-teal-500 ring-1 ring-inset ring-teal-200 hover:ring-teal-300 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500",
};

export const dark = {
  branded:
    "bg-gradient-to-r from-indigo-600 to-emerald-600 font-semibold text-white shadow-sm hover:from-indigo-500 hover:to-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
  primary:
    "bg-indigo-500 disabled:bg-indigo-200 font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500",
  secondary:
    "bg-white/10 disabled:text-gray-200 font-semibold text-white shadow-sm hover:bg-white/20",
  soft: "bg-indigo-50 disabled:bg-gray-200 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100",
  red: "bg-red-600 disabled:bg-gray-200 font-semibold text-white hover:bg-red-500",
  pro: "text-white bg-amber-600 hover:bg-amber-500 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600",
  basic:
    "text-white bg-teal-500 hover:bg-amber-400 leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-500",
};

export const roundedSizes = {
  xs: "rounded",
  sm: "rounded",
  md: "rounded-md",
  lg: "rounded-md",
  xl: "rounded-md",
};

export const color = {
  light: light,
  dark: dark,
};
