import React from "react";
import { Webshop } from "./models/Webshop";

export interface WebshopContextType {
  webshop: Webshop | undefined;
  setWebshop: (webshop: Webshop | undefined) => void;
}

export const WebshopContext = React.createContext<WebshopContextType>({
  webshop: undefined,
  setWebshop: () => undefined,
});
