import React from "react";

export type SelectItem = {
  label: string;
  value: number | string;
};
export function NativeSelect(props: {
  label?: string | null;
  description?: string;
  name: string;
  value: number | string;
  items: SelectItem[];
  onChange: (item: string) => void;
}) {
  return (
    <div>
      {props.label && (
        <label
          htmlFor="location"
          className="mb-2 block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
      )}
      <select
        id={props.name}
        name={props.name}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.value}
        className="block w-full rounded-md border-0 py-2 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {props.items.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
    </div>
  );
}
