import { Note } from "../../api/staff";
import { formatDistance } from "date-fns";
import { useLocale } from "../../lib/locale";
import { useTranslation } from "react-i18next";
import { TextButton } from "../Button";
import { useEffect, useState } from "react";
import { CircularAvatar } from "../Avatar/CircularAvatar";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export function Activity({
  item,
  index,
  first,
}: {
  item: Note;
  index: number;
  first?: boolean;
}) {
  const locale = useLocale();
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation("application");

  useEffect(() => {
    if (item.text.length > 200) {
      setCollapsed(true);
    }
  }, []);

  return (
    <li key={index} className="relative flex gap-x-4">
      <div
        className={classNames(
          first ? "h-6" : "-bottom-6",
          "absolute left-0 top-0 flex w-6 justify-center"
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="mt-3 relative flex-none">
        <CircularAvatar file={item.created_by.avatar} size={"xs"} />
      </div>
      <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
        <div className="flex justify-between gap-x-4">
          <div className="py-0.5 text-xs leading-5 text-gray-500">
            <span className="font-medium text-gray-900">
              {item.created_by.user.name}
            </span>{" "}
            {t("activity.text.wrote")}
          </div>
          <time
            dateTime={item.created_date}
            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
          >
            {formatDistance(new Date(item.created_date), new Date(), {
              locale: locale.currentLocale,
            })}
          </time>
        </div>
        {collapsed ? (
          <p className="text-sm leading-6 text-gray-500">
            {item.text.slice(0, 200)}......
          </p>
        ) : (
          <p className="text-sm leading-6 text-gray-500 whitespace-pre-wrap">
            {item.text}
          </p>
        )}
        {item.text.length > 200 && (
          <div className="flex justify-end">
            {collapsed ? (
              <TextButton
                size={"xs"}
                onClick={() => {
                  setCollapsed(false);
                }}
              >
                {t("activity.button.showMore")}
              </TextButton>
            ) : (
              <TextButton
                size={"xs"}
                onClick={() => {
                  setCollapsed(true);
                }}
              >
                {t("activity.button.showLess")}
              </TextButton>
            )}
          </div>
        )}
      </div>
    </li>
  );
}
