import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useAppApi } from "../../../../api/staff/AppApi";
import { Layout } from "../../Layout/Layout";
import { Link } from "react-router-dom";

type Page =
  | "user"
  | "userRole"
  | "notifications"
  | "permissions"
  | "services"
  | "businessHours";

export function ProfileLayout(props: PropsWithChildren<{ active: Page }>) {
  const { t } = useTranslation("application");
  const api = useAppApi();

  const secondaryNavigation = [
    {
      name: t("profileLayout.navigation.user"),
      href: "/profile/user",
      current: props.active === "user",
    },
    {
      name: t("settingsLayout.navigation.businessHours"),
      href: "/profile/business-hours",
      current: props.active === "businessHours",
    },
    {
      name: t("settingsLayout.navigation.services"),
      href: "/profile/services",
      current: props.active === "services",
    },
    {
      name: t("profileLayout.navigation.userRole"),
      href: "/profile/userRole",
      current: props.active === "userRole",
    },
    {
      name: t("profileLayout.navigation.notifications"),
      href: "/profile/notifications",
      current: props.active === "notifications",
    },
    {
      name: t("profileLayout.navigation.permissions"),
      href: "/profile/permissions",
      current: props.active === "permissions",
    },
  ];

  return (
    <Layout active={"profile"}>
      <main>
        <header className="border-b border-white/5">
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto py-6">
            <ul
              role="list"
              className="flex min-w-full flex-none gap-x-6 px-4 text-base font-semibold leading-6 text-gray-400 sm:px-4 lg:px-8"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={item.current ? "text-indigo-400 border-b" : ""}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>

        <div className="px-4 lg:px-8">{props.children}</div>
      </main>
    </Layout>
  );
}
