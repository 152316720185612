/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ItemType {
    PRODUCT = 'Product',
    SERVICE = 'Service',
}
