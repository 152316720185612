import React, { useCallback, useEffect, useState } from "react";
import { File, Service, Template, UserRole, Webshop } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { Button, TextButton } from "Tailwind/Button";
import { Input, TextArea, Toggle } from "Tailwind/Input/Controlled";
import { FormSection } from "Tailwind/Form/FormSection";

import { ClockIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { manipulate_image_file } from "../../../../lib/utils";
import { Gallery } from "../Gallery/Gallery";
import { Modal } from "Tailwind/Modal/Modal";
import { SlideOver } from "Tailwind/Overlays/SlideOver";
import { ServiceEmployeeForm } from "./ServiceEmployeeForm";
import { useTranslation } from "react-i18next";
import { TemplateSelect } from "../Templates/TemplateSelect";
import { ColorPicker } from "../../../../Tailwind/Input/Uncontrolled/ColorPicker";
import { serviceColors } from "./colors";

export function ServiceSlideOver({
  onComplete,
  isOpen,
  onClose,
  s,
}: {
  onComplete: () => void;
  onClose: () => void;
  isOpen: boolean;
  s?: Service;
}) {
  const [showAlbum, setShowAlbum] = useState(false);
  const [color, setColor] = useState<string>(
    s && s.color
      ? s.color
      : serviceColors[Math.floor(Math.random() * serviceColors.length)]
  );
  const [images, setImages] = useState<File[]>(s ? s.files : []);
  const [selectedEmployees, setSelectedEmployees] = useState<UserRole[]>([]);
  const [employees, setEmployees] = useState<UserRole[]>([]);
  const { t } = useTranslation("application");
  const [confirmationTemplate, setConfirmationTemplate] = useState<
    Template | undefined
  >();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Service>({
    defaultValues: s ? s : doReset(),
  });

  useEffect(() => {
    api.users.getAccountUsersAccountUsersGet().then((u) => {
      setEmployees(u.my_roles);
      if (!s) {
        setSelectedEmployees(u.my_roles);
      }
    });
  }, [s]);

  useEffect(() => {
    if (s) {
      reset(s);
      setSelectedEmployees(s.employees);
      setImages(s.files);
      if (s.color) {
        setColor(s.color);
      }
      setConfirmationTemplate(s.booking_confirmation_template);
    } else {
      reset(doReset());
    }
  }, [s]);

  function doReset() {
    return {
      vat: 25,
      description: "",
      name: "",
      price: 0,
      duration: 0,
      bookable: true,
      bookable_by_clients: true,
    };
  }

  const api = useAppApi();

  function submitData(data: Service) {
    data.booking_confirmation_template = confirmationTemplate;
    data.color = color;
    if (s) {
      api.misc.updateServiceServicesServiceIdPut(s.id, data).then((s) => {
        Promise.all([
          api.misc.setFilesServicesServiceIdFilesPost(
            s.id,
            images.map((i) => i.id)
          ),
          api.misc.setEmployeesServicesServiceIdEmployeesPost(
            s.id,
            selectedEmployees.map((e) => e.id)
          ),
        ]).then(() => {
          reset(doReset());
          setImages([]);
          setSelectedEmployees([]);
          setImages([]);
          setConfirmationTemplate(undefined);
          onComplete();
        });
      });
    } else {
      api.misc.createServiceServicesPost(data).then((service) => {
        Promise.all([
          api.misc.setFilesServicesServiceIdFilesPost(
            service.id,
            images.map((i) => i.id)
          ),
          api.misc.setEmployeesServicesServiceIdEmployeesPost(
            service.id,
            selectedEmployees.map((e) => e.id)
          ),
        ]).then((s) => {
          reset(doReset());
          setImages([]);
          setSelectedEmployees([]);
          setImages([]);
          onComplete();
        });
      });
    }
  }

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title={
        s
          ? t("serviceForm.sliderOver.edit.title")
          : t("serviceForm.sliderOver.create.title")
      }
      actions={[
        <>
          {s && (
            <Button
              color="red"
              onClick={() => {
                const confirm = window.confirm(
                  t("serviceForm.confirm.delete")!
                );
                if (confirm) {
                  api.misc
                    .deleteServiceServicesServiceIdDelete(s.id)
                    .then(() => {
                      onComplete();
                    });
                }
              }}
            >
              {t("serviceForm.button.delete")}
            </Button>
          )}
        </>,
        <>
          {!s && (
            <Button color="red" onClick={() => reset(doReset())}>
              {t("serviceForm.button.reset")}
            </Button>
          )}
        </>,
        <Button color="secondary" onClick={() => onComplete()}>
          {t("serviceForm.button.cancel")}
        </Button>,
        <Button type="submit" onClick={handleSubmit(submitData)}>
          {t("serviceForm.button.save")}
        </Button>,
      ]}
    >
      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
        <div className="col-span-full">
          <Input
            label={t("serviceForm.input.name.label")}
            type="text"
            errors={errors}
            register={register("name")}
            description={t("serviceForm.input.name.description")}
          />
        </div>
        <div className="col-span-full">
          <TextArea
            label={t("serviceForm.input.description.label")}
            errors={errors}
            register={register("description")}
            description={t("serviceForm.input.description.description")}
            rows={6}
          />
        </div>
        <div className="col-span-full sm:col-span-2">
          <Input
            label={t("serviceForm.input.price.label")}
            register={register("price")}
            errors={errors}
            leftDecoration={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>
            }
            rightDecoration={api.userProfile?.current_role?.account.currency}
          />
        </div>
        <div className="col-span-full sm:col-span-2">
          <Input
            register={register("vat", { min: 0, max: 100 })}
            errors={errors}
            label={t("serviceForm.input.vat.label")}
            rightDecoration={"%"}
          />
        </div>
        <div className="col-span-full sm:col-span-2">
          <Input
            label={t("serviceForm.input.duration.label")}
            errors={errors}
            register={register("duration")}
            leftDecoration={<ClockIcon className={"w-5 h-5"} />}
            rightDecoration={t("serviceForm.input.duration.unit")}
          />
        </div>
        <div className="col-span-full">
          <FormSection
            title={t("serviceForm.formSection.color.title")}
            subtitle={t("serviceForm.formSection.color.subtitle")}
          />
          <div className="col-span-full">
            <ColorPicker
              colors={serviceColors}
              selectedColor={color}
              onClick={setColor}
            />
          </div>
        </div>
        <div className="col-span-full">
          <FormSection
            title={t("serviceForm.formSection.booking.title")}
            subtitle={t("serviceForm.formSection.booking.subtitle")}
          />
        </div>
        <div className="col-span-full">
          <Toggle
            label={t("serviceForm.toggle.bookable.label")}
            control={control}
            name={"bookable"}
          />
        </div>
        <div className="col-span-full">
          <Toggle
            control={control}
            name={"bookable_by_clients"}
            label={t("serviceForm.toggle.bookable_by_clients.label")}
          />
        </div>
        <div className="col-span-full">
          <FormSection
            title={t("serviceForm.formSection.employees.title")}
            subtitle={t("serviceForm.formSection.employees.subtitle")}
            actions={[
              <TextButton
                onClick={() => {
                  setSelectedEmployees(employees);
                }}
              >
                {t("serviceForm.button.addAllEmployees")}
              </TextButton>,
            ]}
          />
        </div>
        <div className="col-span-full">
          <ServiceEmployeeForm
            onEmployeeAdded={(e) => {
              setSelectedEmployees([...selectedEmployees, e]);
            }}
            onEmployeeRemoved={(e) => {
              setSelectedEmployees(
                selectedEmployees.filter((s) => s.id !== e.id)
              );
            }}
            employees={employees}
            selected={selectedEmployees}
          />
        </div>
        {/*

        <div className="col-span-full">
          <FormSection
            title={t("serviceForm.formSection.template.title")}
            subtitle={t("serviceForm.formSection.template.subtitle")}
          />
          <TemplateSelect
            onSelect={(template) => {
              setConfirmationTemplate(template);
            }}
            selected={confirmationTemplate}
          />
        </div>
*/}

        <div className="col-span-full">
          <FormSection
            title={t("serviceForm.formSection.images.title")}
            subtitle={t("serviceForm.formSection.images.subtitle")}
          />
          <div className="mt-2">
            <Button
              color="secondary"
              onClick={(event) => {
                event.preventDefault();
                setShowAlbum(true);
              }}
            >
              {t("serviceForm.button.album")}
            </Button>
          </div>
        </div>

        {images.map((f, i) => {
          return (
            <div key={i} className="col-span-full sm:col-span-1">
              <ServiceTile
                file={f}
                onClick={() => {
                  setImages(images.filter((i) => i.id != f.id));
                }}
              />
            </div>
          );
        })}
      </div>
      <Modal
        visible={showAlbum}
        setVisible={() => {
          setShowAlbum(false);
        }}
      >
        <Gallery
          onImageClicked={(image) => {
            setImages([...images, image]);
            setShowAlbum(false);
          }}
        />
        <div className="mt-8 flex justify-end">
          <Button
            onClick={() => {
              setShowAlbum(false);
            }}
          >
            {t("serviceForm.button.cancel")}
          </Button>
        </div>
      </Modal>
    </SlideOver>
  );
}

export function ServiceTile({
  file,
  onClick,
}: {
  file: File;
  onClick: () => void;
}) {
  return (
    <a onClick={() => onClick()}>
      <div className="cursor-pointer shadow-md aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md lg:aspect-none hover:opacity-75">
        <img
          src={manipulate_image_file(file, ["h_200"])}
          className="object-cover object-center"
        />
      </div>
    </a>
  );
}
