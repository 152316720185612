import { CalendarEvent } from "./WeekCalendar";
import { Interval, PointIntervalRelation } from "@toryt/allen";

type CollisionMap = { [key: string]: number[] };

export function layoutEvents(events: CalendarEvent[], days: Date[]) {
  // Sort events by duration. We want the longest lasting events to be leftmost
  const durationSortedEvents = [...events].sort((a, b) => {
    return (
      b.end.getTime() -
      b.start.getTime() -
      (a.end.getTime() - a.start.getTime())
    );
  });

  const laidOutEvents: CalendarEvent[] = [];
  const eventMap: { [id: number]: CalendarEvent } = {};

  days.forEach((day) => {
    // Sort the events further by day. As we only care how they relate pr day, and it's just easier to process in my head
    const dayAndDurationSortedEvents = durationSortedEvents.filter(
      (se) => se.start.toDateString() === day.toDateString()
    );

    // Create collision map that holds all collisions pr event
    const collisionsMap: CollisionMap = {};
    dayAndDurationSortedEvents.forEach((longestEvent) => {
      eventMap[longestEvent.id] = longestEvent;
      let collisions: number[] = [];
      dayAndDurationSortedEvents.forEach((shortestEvent) => {
        // We don't care if we collide with ourselves
        if (longestEvent !== shortestEvent) {
          // Do the intervals overlap
          if (collide(longestEvent, shortestEvent)) {
            // Add to the collisions of longestEvent
            collisions.push(shortestEvent.id);
          }
        }
      });
      // Save longestEvent's collisions in the map
      collisionsMap[longestEvent.id] = collisions;
    });

    let ignore: number[] = [];

    let groups: CalendarEvent[][] = [];

    for (let key in collisionsMap) {
      const allCollisions = collisionGroupsRecursive(
        parseInt(key, 10),
        collisionsMap,
        ignore
      );
      if (allCollisions.length > 0) {
        const events = allCollisions.map((c) => eventMap[c]);
        groups.push(events);
      }
    }
    groups.forEach((group) => {
      let ignore: number[] = [];
      let stackedEvents: CalendarEvent[][] = [];
      for (const e of group) {
        if (ignore.includes(e.id)) {
          continue;
        }
        const stack = [e];
        ignore.push(e.id);
        group.forEach((g) => {
          if (e !== g && !collideAny(g, stack) && !ignore.includes(g.id)) {
            stack.push(g);
            ignore.push(g.id);
          }
        });
        stackedEvents.push(stack);
      }
      stackedEvents.forEach((events, index) => {
        const overlappingCount = stackedEvents.length;

        const width = 1 / overlappingCount;
        const leftOffset = index * width;
        events.forEach((event) => {
          laidOutEvents.push({
            ...event,
            leftOffset,
            width,
          });
        });
      });
    });

    // Layout the events
  });
  return laidOutEvents;
}

function collisionGroupsRecursive(
  event: number,
  collisionMap: CollisionMap,
  ignore: number[]
) {
  if (ignore.includes(event)) {
    return [];
  }
  let collisions: number[] = [event, ...collisionMap[event]];
  ignore.push(event);

  collisions.forEach((c) => {
    collisions = [
      ...collisions,
      ...collisionGroupsRecursive(c, collisionMap, ignore),
    ];
  });
  return [...new Set(collisions)];
}

function collide(a: CalendarEvent, b: CalendarEvent) {
  return (
    a.end.getTime() > b.start.getTime() && a.start.getTime() < b.end.getTime()
  );
}

function collideAny(event: CalendarEvent, events: CalendarEvent[]) {
  let collides = false;
  events.forEach((e) => {
    if (collide(event, e)) {
      collides = true;
    }
  });
  return collides;
}

export function splitIntervalIntoDays(
  start: Date,
  end: Date
): { start: Date; end: Date }[] {
  let current = new Date(start.getTime());
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);
  current.setMilliseconds(0);
  let intervals: { start: Date; end: Date }[] = [];

  while (current.getTime() <= end.getTime()) {
    let intervalStart = new Date(
      current.getFullYear(),
      current.getMonth(),
      current.getDate(),
      0,
      0,
      0,
      0
    );
    let intervalEnd = new Date(
      current.getFullYear(),
      current.getMonth(),
      current.getDate(),
      23,
      59,
      59,
      999
    );

    if (start >= intervalStart) {
      intervalStart = new Date(start);
    }

    if (intervalEnd >= end) {
      intervalEnd = new Date(end);
    }

    intervals.push({ start: intervalStart, end: intervalEnd });

    // Move to the next day
    current.setDate(current.getDate() + 1);
  }

  return intervals;
}
