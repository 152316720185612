import {
  Button,
  DashedEmptyState,
  FormSection,
  Modal,
  TextButton,
} from "Tailwind";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServicePicker } from "../../Services/ServicePicker";
import { Service, UserRole } from "api/staff";
import { ShoppingBagIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useLocale } from "lib/locale";

export function ServiceSection({
  employee,
  services,
  onServiceAdded,
  onServiceRemoved,
}: {
  employee: UserRole;
  services: Service[];
  onServiceAdded: (service: Service) => void;
  onServiceRemoved: (service: Service) => void;
}) {
  const { t } = useTranslation("application");
  const [addService, setAddService] = useState(false);

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-6">
        <FormSection
          title={t("sessionServiceSection.formSection.title")}
          subtitle={t("sessionServiceSection.formSection.subtitle")}
        />
      </div>
      {services.length > 0 ? (
        <>
          <div className={"col-span-full"}>
            <Button
              onClick={() => {
                setAddService(true);
              }}
            >
              {t("sessionServiceSection.button.addService")}
            </Button>
          </div>
          <div className={"col-span-full"}>
            <ServiceList
              services={services}
              onServiceRemoved={(s) => onServiceRemoved(s)}
            />
          </div>
        </>
      ) : (
        <div className="col-span-6">
          <DashedEmptyState
            text={t("sessionServiceSection.emptyState.title")}
            onClick={() => {
              setAddService(true);
            }}
            accessory={<ShoppingBagIcon />}
          />
        </div>
      )}
      <Modal
        visible={addService}
        setVisible={() => {
          setAddService(false);
        }}
      >
        <ServicePicker
          employee={employee}
          onServicePicked={(service) => {
            onServiceAdded(service);
            setAddService(false);
          }}
        />
      </Modal>
    </div>
  );
}

function ServiceList(props: {
  services: Service[];
  onServiceRemoved: (service: Service) => void;
}) {
  const { t } = useTranslation("application");
  const locale = useLocale();

  return (
    <div>
      <div className="-mx-4 flow-root sm:mx-0">
        <table className="min-w-full">
          <colgroup>
            <col className="w-full sm:w-1/2" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
            <col className="sm:w-1/6" />
          </colgroup>
          <thead className="border-b border-gray-300 text-gray-900">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                {t("serviceList.header.name")}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                {t("serviceList.header.duration")}
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 pl-3 pr-4 text-right text-sm font-semibold text-gray-900"
              >
                {t("serviceList.header.price")}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.services.map((service) => (
              <ServiceItem
                service={service}
                onRemove={() => props.onServiceRemoved(service)}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={3}
                className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
              >
                {t("serviceList.summary.duration")}
              </th>
              <th
                scope="row"
                className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
              >
                {t("serviceList.summary.duration")}
              </th>
              <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                {locale.duration(
                  props.services.reduce((a, b) => a + b.duration!, 0)
                )}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={3}
                className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
              >
                {t("serviceList.summary.priceTotal")}
              </th>
              <th
                scope="row"
                className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                {t("serviceList.summary.priceTotal")}
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                {props.services.reduce((a, b) => a + b.price, 0)}.-
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

function ServiceItem(props: { service: Service; onRemove: () => void }) {
  const { t } = useTranslation("application");
  const locale = useLocale();

  return (
    <tr key={props.service.id} className="border-b border-gray-200">
      <div>
        <div className="flex gap-2 font-medium text-sm items-center text-gray-900">
          <div>{props.service.name}</div>
          <div
            style={{ backgroundColor: props.service.color }}
            className="h-3 w-3 rounded-full"
          />
        </div>
        {props.service.description && (
          <div className="mt-1 text-sm max-w-[300px] font-normal text-gray-500">
            {props.service.description.slice(0, 50)}
            {props.service.description.length > 50 && "..."}
          </div>
        )}
      </div>
      <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
        {locale.duration(props.service.duration!)}
      </td>
      <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
        {props.service.price}.-
      </td>
      <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
        <TextButton onClick={props.onRemove}>
          <XCircleIcon width={24} />
        </TextButton>
      </td>
    </tr>
  );
}
