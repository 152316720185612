/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_cvr_not_found___USER_CONTROLLER_cvr_not_found____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_cvr_not_found___USER_CONTROLLER_cvr_not_found____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_cvr_not_found___USER_CONTROLLER_cvr_not_found____ {

    export enum detail {
        USER_CONTROLLER_CVR_NOT_FOUND = 'USER_CONTROLLER_cvr_not_found',
    }


}

