import React, { useState } from "react";
import {useCalendarConsts, UtilDay} from "../../../../../lib/utils";

export function BusinessHoursMenu(props: {
  selectedDay?: UtilDay;
  onDaySelected: (day: UtilDay) => void;
}) {
  const { daysOfWeek } = useCalendarConsts();

  return (
    <>
      <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
        {daysOfWeek.map((day, i) => {
          return (
            <Button
              key={i}
              day={day.label.slice(0, 2)}
              selected={props.selectedDay?.value === day.value}
              onClick={() => {
                props.onDaySelected(day);
              }}
            />
          );
        })}
      </div>
      <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
        <div className="col-end-1 w-14" />
        {daysOfWeek.map((day, i) => (
          <Button
            key={i}
            day={day.label}
            selected={props.selectedDay?.value === day.value}
            onClick={() => {
              props.onDaySelected(day);
            }}
          />
        ))}
      </div>
    </>
  );
}

function Button(props: {
  day: string;
  selected: boolean;
  date?: number;
  onClick: () => void;
}) {
  const style = props.selected
    ? "p-1 items-center justify-center border-b-2 border-indigo-600 font-semibold text-gray-900 border-width-10"
    : "p-1 items-center justify-center font-semibold text-gray-900 hover:border-b-2 hover:border-indigo-200";

  return (
    <button onClick={props.onClick}>
      <div className="flex items-center justify-center py-3">
        <span>
          <span className={style}>{props.day}</span>
          {props.date && (
            <span className="items-center justify-center font-semibold text-gray-900">
              {props.date}
            </span>
          )}
        </span>
      </div>
    </button>
  );
}
