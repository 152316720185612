import React from "react";
import { Staff } from "../../../api/webshop";
import { CircularAvatar } from "Tailwind";
import { useTranslation } from "react-i18next";

export function StaffSection({
  availableStaff,
  selectedStaff,
  staffClicked,
}: {
  availableStaff: Staff[];
  selectedStaff?: Staff;
  staffClicked: (staff: Staff) => void;
}) {
  const { t } = useTranslation("webshop");
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-slate-900"
        >
          {t("staffSection.title")}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {t("staffSection.description")}
        </p>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
        >
          {availableStaff.map((s, index) => (
            <StaffCard
              key={index}
              staff={s}
              selected={
                selectedStaff !== undefined && selectedStaff.id === s.id
              }
              staffClicked={staffClicked}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function StaffCard({
  staff,
  selected,
  staffClicked,
}: {
  staff: Staff;
  selected: boolean;
  staffClicked: (staff: Staff) => void;
}) {
  const cls = selected
    ? "cursor-pointer col-span-1 flex flex-col divide-y divide-gray-200 shadow-md rounded-lg bg-white border text-center shadow border border-indigo-500 ring-2 ring-indigo-500 shadow-sm focus:outline-none"
    : "shadow-md cursor-pointer col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white border text-center shadow";

  return (
    <li className={cls} onClick={() => staffClicked(staff)}>
      <div className="flex flex-1 flex-col p-8 justify-center relative">
        <div className="flex flex-row justify-center">
          {staff.avatar ? (
            <CircularAvatar size="xl" file={staff.avatar} />
          ) : (
            <span className="inline-flex h-24 w-24 items-center justify-center rounded-full text-gray-100 bg-slate-800">
              <span className="text-xl font-medium leading-none">
                {staff.user.name?.slice(0, 2).toUpperCase()}
              </span>
            </span>
          )}
        </div>
        {selected && (
          <div className="absolute right-5 top-5 h-5 w-5">
            <svg
              className="h-5 w-5 text-indigo-600 align-self-end"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        <h3 className="mt-6 text-lg font-light text-gray-900">
          {staff.user.name}
        </h3>
        {staff.title && (
          <dl className="flex flex-grow flex-col justify-between">
            <dd className="text-sm text-gray-500"></dd>
            <dd className="mt-3">
              <span className="px-2 py-1 text-sm font-semibold text-slate-800">
                {staff.title}
              </span>
            </dd>
          </dl>
        )}
      </div>
    </li>
  );
}
