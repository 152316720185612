import React, { PropsWithChildren } from "react";
import { Font } from "../Font/Font";

export function ActionPanelTopRight(
  props: PropsWithChildren<{
    title: string;
    subTitle: string;
    actions: React.ReactNode[];
  }>
) {
  return (
    <div className="bg-white sm:shadow-lg lg:rounded-lg  opacity-90">
      <div className="px-4 py-5 sm:p-6">
        <div className="sm:flex sm:items-start sm:justify-between">
          <h3>
            <Font className="leading-6" color={"gray"} weight={"semibold"}>
              {props.title}
            </Font>
          </h3>
          <div className="mt-5 sm:ml-6 sm:mt-0 flex sm:flex-shrink-0 gap-x-4 sm:items-center">
            {props.actions.map((a, i) => (
              <div key={i}>{a}</div>
            ))}
          </div>
        </div>
        <div className="mt-2 max-w-xl">
          <Font color={"gray500"} size="sm">
            {props.subTitle}
          </Font>
        </div>
        {props.children}
      </div>
    </div>
  );
}
