import React, { useState } from "react";
import Cal from "react-calendar";
import "./calendar.css";
import { CalendarDay } from "../api/webshop";

interface CalendarProps {
  bookingCalendar: CalendarDay[];
  selectedDate: Date;
  maxDate?: Date;
  onMonthChange: (date: Date) => void;
  onDateChange: (date: Date) => void;
}

export function Calendar(props: CalendarProps) {
  const [minDate, setMinDate] = useState(new Date());

  return (
    <Cal
      showNeighboringMonth={false}
      minDate={minDate}
      maxDate={props.maxDate}
      onChange={props.onDateChange}
      value={props.selectedDate}
      activeStartDate={props.selectedDate}
      tileContent={(d) => {
        const match = props.bookingCalendar.find((day) => {
          const date = new Date(day.current_day);
          return (
            date.toLocaleDateString() === d.date.toLocaleDateString() &&
            day.bookable_slots.length > 0
          );
        });
        if (match) {
          return (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "3px",
                  transform: "translateY(2px)",
                  display: "block",
                  width: "6px",
                  height: "6px",
                  border: "1px solid white",
                  backgroundColor: "#6366f1",
                }}
              />
            </div>
          );
        } else {
          return null;
        }
      }}
      onActiveStartDateChange={(c) => {
        props.onMonthChange(c.activeStartDate);
      }}
      onClickMonth={(c) => {
        props.onMonthChange(c);
        props.onDateChange(c);
      }}
    />
  );
}
