import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { WebshopNewBookingPage } from "../sections/WebshopSection/WebshopNewBookingPage";
import { LoginPage } from "../sections/WebshopSection/LoginPage";
import { BookingsPage } from "../sections/ClientSection/BookingsPage";
import { NewBookingPage } from "../sections/ClientSection/NewBookingPage";
import { BookingPage } from "../sections/ClientSection/booking_page/BookingPage";
import { ClientPreferences } from "../sections/ClientSection/ClientPreferences";
import { LoadingScreen } from "../pages/LoadingScreen";
import { Client, Webshop } from "../api/webshop";
import { useWebshopApi } from "../api/webshop/WebshopApi";
import { WebshopContext } from "../api/webshop/WebshopContext";
import { ClientContext } from "../api/webshop/ClientContext";
import { useLocale } from "../lib/locale";

export function WebshopRouter() {
  const [webshop, setWebshop] = useState<Webshop>();
  const [client, setClient] = useState<Client>();
  const [website404, setWebsite404] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prefix, setPrefix] = useState("da");
  const locale = useLocale();

  const { api } = useWebshopApi();

  useEffect(() => {
    api
      .whereamiWhereamiGet()
      .then((w) => {
        setWebshop(w.webshop);
        setLoading(false);
        document.title = w.webshop.company_name;
      })
      .catch((err) => {
        setWebsite404(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setPrefix(locale.detectLanguage());
  }, [window.location.pathname]);

  useEffect(() => {
    if (webshop) {
      checkLogin();
    }
  }, [webshop]);

  function checkLogin(onCheckComplete?: (loggedIn: boolean) => void) {
    setLoading(true);
    api
      .whoamiWhoamiGet()
      .then((c) => {
        setClient(c);
        setLoading(false);
        onCheckComplete && onCheckComplete(true);
      })
      .catch((err) => {
        setClient(undefined);
        setLoading(false);
        onCheckComplete && onCheckComplete(false);
      });
  }

  return (
    <>
      {webshop && (
        <BrowserRouter basename={prefix}>
          <WebshopContext.Provider
            value={{ webshop: webshop, setWebshop: setWebshop }}
          >
            <ClientContext.Provider
              value={{ client: client, setClient: setClient }}
            >
              <Routes>
                <Route
                  path={"/"}
                  element={<Navigate replace to="/booking" />}
                />
                <Route
                  path={"/my"}
                  element={<Navigate replace to="/my/bookings" />}
                />
                <Route path={`/booking`} element={<WebshopNewBookingPage />} />
                <Route path={`/login`} element={<LoginPage />} />
                <Route path={`/my/bookings`} element={<BookingsPage />} />
                <Route path={`/my/book`} element={<NewBookingPage />} />
                <Route path={`/my/booking/:id`} element={<BookingPage />} />
                <Route
                  path={"/client-preferences"}
                  element={<ClientPreferences />}
                />
              </Routes>
            </ClientContext.Provider>
          </WebshopContext.Provider>
        </BrowserRouter>
      )}
      {loading && <LoadingScreen />}
      {website404 && (
        <div
          style={{
            position: "fixed",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%)",
            }}
          >
            <h1>The requested page does not exist (404)</h1>
          </div>
        </div>
      )}
    </>
  );
}
