import React, { useContext } from "react";
import { useWebshopApi } from "../../../api/webshop/WebshopApi";

export function AboutSection() {
  const api = useWebshopApi();

  return (
    <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-slate-900"
        >
          {api.webshop?.company_name}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {api.webshop?.address}
        </p>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {api.webshop?.contact_email}
        </p>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {api.webshop?.contact_phone}
        </p>
      </div>
    </div>
  );
}
