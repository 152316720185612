import React from "react";

interface TextAreaProps {
  value: string;
  name: string;
  onChange: (value: string) => void;
  className?: string;
  label?: string;
  description?: string;
  rows?: number;
}

export function TextArea(props: TextAreaProps) {
  return (
    <div className={props.className}>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="mt-2">
        <textarea
          rows={props.rows}
          name={props.name}
          className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </div>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
    </div>
  );
}
