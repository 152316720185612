/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_cvr_already_exists___USER_CONTROLLER_cvr_already_exists_____PEM_USER_CONTROLLER_url_not_available___USER_CONTROLLER_url_not_available____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_cvr_already_exists___USER_CONTROLLER_cvr_already_exists_____PEM_USER_CONTROLLER_url_not_available___USER_CONTROLLER_url_not_available____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_cvr_already_exists___USER_CONTROLLER_cvr_already_exists_____PEM_USER_CONTROLLER_url_not_available___USER_CONTROLLER_url_not_available____ {

    export enum detail {
        USER_CONTROLLER_CVR_ALREADY_EXISTS = 'USER_CONTROLLER_cvr_already_exists',
        USER_CONTROLLER_URL_NOT_AVAILABLE = 'USER_CONTROLLER_url_not_available',
    }


}

