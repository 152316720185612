import { InboxIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";

export function NotificationWithActionsBelow(props: {
  actions: ReactNode[];
  title: string;
  content: string;
  onDismiss: () => void;
}) {
  return (
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <InboxIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{props.title}</p>
            <div
              className="mt-1 text-sm text-gray-500"
              dangerouslySetInnerHTML={{ __html: props.content }}
            />

            <div className="mt-3 flex space-x-7">{props.actions}</div>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                props.onDismiss();
              }}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
