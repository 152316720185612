import React from "react";
import { BrandedSpinner } from "Tailwind/Spinner";

export function LoadingOverlay(props: {
  loading: boolean;
  size?: "xs" | "lg" | "xl";
  background?: "bg-gray-950" | "bg-indigo-950" | "bg-emerald-950" | "bg-white";
}) {
  const background = props.background ? props.background : "bg-transparent";

  return (
    <>
      {props.loading && (
        <div
          className={`fixed inset-0 left-0 top-0 flex min-h-full z-50 min-w-full flex-col justify-center ${background}`}
        >
          <div className="flex items-center justify-center">
            <BrandedSpinner size={props.size} />
          </div>
        </div>
      )}
    </>
  );
}
