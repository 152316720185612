import { Header } from "./Components/Header";
import React from "react";
import { Footer } from "./Components/Footer";

export function MarketingPageLayout(props: any) {
  return (
    <div className="bg-white">
      <Header />
      <main className="">{props.children}</main>
      <Footer />
    </div>
  );
}
