import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { UserRole } from "api/staff";
import { manipulate_image_file } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";

export function UserSelect(props: {
  users: UserRole[];
  selected: UserRole;
  onChange: (user: UserRole) => void;
}) {
  const { t } = useTranslation("application");

  return (
    <Listbox
      value={props.selected}
      onChange={(s) => {
        props.onChange(s);
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {t("userSelect.listBox.label")}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full items-center cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <div className="flex">
                {props.selected.avatar ? (
                  <img
                    src={manipulate_image_file(props.selected.avatar, [
                      "h_200",
                      "w_200",
                    ])}
                    alt=""
                    className="h-6 w-6 flex-shrink-0 rounded-full"
                  />
                ) : (
                  <span
                    style={{ backgroundColor: props.selected.color }}
                    className="inline-flex h-6 w-6 items-center justify-center rounded-full"
                  >
                    <span className="text-xs leading-none text-white">
                      {props.selected.user.name?.slice(0, 1).toUpperCase()}
                    </span>
                  </span>
                )}
                <span className="inline-flex w-full truncate ml-2">
                  <span className="truncate">{props.selected.user.name}</span>
                  <span className="ml-2 truncate text-gray-500">
                    {props.selected.user.email}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.users.map((user) => (
                  <Listbox.Option
                    key={user.id}
                    className={({ active }) =>
                      active
                        ? "bg-indigo-500 text-white relative cursor-default select-none py-2 pl-3 pr-9"
                        : "text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                    }
                    value={user}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          {user.avatar ? (
                            <img
                              src={manipulate_image_file(user.avatar, [
                                "h_200",
                                "w_200",
                              ])}
                              alt=""
                              className="h-6 w-6 flex-shrink-0 rounded-full"
                            />
                          ) : (
                            <span
                              style={{ backgroundColor: user.color }}
                              className="text-xs inline-flex h-6 w-6 items-center justify-center rounded-full bg-slate-500"
                            >
                              <span className="leading-none text-white">
                                {user.user.name?.slice(0, 1).toUpperCase()}
                              </span>
                            </span>
                          )}
                          <span
                            className={
                              selected
                                ? "ml-2 font-semibold truncate"
                                : "ml-2 font-normal truncate"
                            }
                          >
                            {user.user.name}
                          </span>
                          <span
                            className={
                              active
                                ? "text-indigo-200 ml-2 truncate"
                                : "text-gray-500 ml-2 truncate"
                            }
                          >
                            {user.user.email}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={
                              active
                                ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
