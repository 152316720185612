import { ProtectedPage } from "../../ProtectedPage";
import { Layout } from "../../Layout/Layout";
import { ClientList } from "./ClientList";

export function ClientsPage() {
  return (
    <Layout active={"clients"}>
      <ClientList />
    </Layout>
  );
}
