import React from "react";
import { ClientPageLayout } from "./ClientPageLayout";
import { BookingComponent } from "../../components/BookingComponent";

export function NewBookingPage() {
  return (
    <ClientPageLayout index={1}>
      <main className="py-10">
        <div className="mt-4 mx-4 mm:px-6 lg:mx-8">
          <BookingComponent compact={true} />
        </div>
      </main>
    </ClientPageLayout>
  );
}
