/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_not_confirmed___USER_CONTROLLER_not_confirmed____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_not_confirmed___USER_CONTROLLER_not_confirmed____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_not_confirmed___USER_CONTROLLER_not_confirmed____ {

    export enum detail {
        USER_CONTROLLER_NOT_CONFIRMED = 'USER_CONTROLLER_not_confirmed',
    }


}

