import React, { useState } from "react";
import { Link as Lnk, LinkProps as LnkProps } from "react-router-dom";
import { color } from "./constants";

interface LinkProps extends LnkProps {
  color?: "primary" | "secondary";
  dark?: boolean;
}

function getProps(props: LinkProps) {
  const shade = props.dark === true ? "dark" : "light";
  const clr = props.color
    ? ` ${color[shade][props.color]}`
    : ` ${color[shade]["primary"]}`;
  return `${clr}`;
}

export function Link({ children, className, ...props }: LinkProps) {
  const [cls] = useState(`${getProps(props)} ${className}`);

  return (
    <Lnk className={cls} to={props.to}>
      {children}
    </Lnk>
  );
}
