/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_DINERO_CONTROLLER_item_not_found___DINERO_CONTROLLER_item_not_found____ = {
    detail: PS_Literal__PEM_DINERO_CONTROLLER_item_not_found___DINERO_CONTROLLER_item_not_found____.detail;
};

export namespace PS_Literal__PEM_DINERO_CONTROLLER_item_not_found___DINERO_CONTROLLER_item_not_found____ {

    export enum detail {
        DINERO_CONTROLLER_ITEM_NOT_FOUND = 'DINERO_CONTROLLER_item_not_found',
    }


}

