import { useAppApi } from "api/staff/AppApi";
import React, { useEffect, useState } from "react";
import { useQueryToken, validatePhone } from "lib/utils";
import {
  CompleteSignupRequest,
  InvitationResponse,
  Professions,
  User,
  UserInvitation,
} from "api/staff";
import {
  FormSection,
  A,
  ActionPanelBottom,
  StackedListItem,
  StackedList,
  SimpleEmptyState,
  Button,
  LinkButton,
} from "Tailwind";
import { AuthLayout } from "./AuthLayout";
import { Input, NativeSelect, Phone, Toggle } from "Tailwind/Input/Controlled";
import { Input as UncontrolledInput } from "Tailwind/Input/Uncontrolled";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useFeedback } from "lib/useFeedback";
import { useLocale } from "../../../lib/locale";

export function AcceptInvitation() {
  const api = useAppApi();
  const [invitations, setInvitations] = useState<InvitationResponse>();
  const token = useQueryToken();

  useEffect(() => {
    if (token) {
      getInvitations();
    } else {
      console.log("No token");
    }
  }, []);

  function getInvitations() {
    api.users.getMyInvitationsAccountMyInvitationsGet(token).then((i) => {
      setInvitations(i);
    });
  }

  return (
    <>
      {invitations && (
        <>
          {invitations.must_finish_user_creation ? (
            <FinishUserCreationSection
              user={invitations.user}
              onSignupComplete={() => {
                getInvitations();
              }}
            />
          ) : (
            <InvitationSection
              invitations={invitations.invitations}
              onRefresh={() => {
                getInvitations();
              }}
            />
          )}
        </>
      )}
    </>
  );
}

function FinishUserCreationSection(props: {
  user: User;
  onSignupComplete: () => void;
}) {
  const { t } = useTranslation("application");
  const [acceptError, setAcceptError] = useState<string>();
  const [phoneError, setPhoneError] = useState<string>();
  const api = useAppApi();
  const feedback = useFeedback();
  const token = useQueryToken();
  const locale = useLocale();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CompleteSignupRequest>({
    defaultValues: {
      name: props.user.name,
      permission_given: false,
    },
  });

  const acceptToggle = watch("accepted_terms");

  useEffect(() => {
    if (acceptToggle) {
      setAcceptError(undefined);
    }
  }, [acceptToggle]);

  function submit(data: CompleteSignupRequest) {
    if (data.accepted_terms) {
      api.users
        .completeSignupAccountCompleteSignupPost(token, data)
        .then((login) => {
          props.onSignupComplete();
        })
        .catch((e) => {
          feedback.handleErrors(e);
        });
    } else {
      setAcceptError(t("acceptInvitation.error.mustAcceptTerms")!);
    }
  }

  return (
    <AuthLayout title={""} subContent={""} video={"Ink"}>
      <FormSection
        dark
        title={t("acceptInvitation.formSection.completeUser.title")}
        subtitle={t("acceptInvitation.formSection.completeUser.subtitle")}
      />
      <form className={"flex flex-col space-y-6"}>
        <NativeSelect
          name={"profession"}
          control={control}
          label={t("createUserModal.input.profession.label")}
          description={t("createUserModal.input.profession.description")}
          items={locale.professions}
        />
        <UncontrolledInput
          dark
          label={t("acceptInvitation.input.email.label")}
          type="email"
          name="email"
          disabled={true}
          value={props.user.email}
          onChange={(e) => {}}
        />
        <Input
          dark
          errors={errors}
          label={t("acceptInvitation.input.name.label")}
          type="name"
          register={register("name", {
            required: t("acceptInvitation.error.passwordRequired")!,
          })}
        />
        <Input
          dark
          errors={errors}
          label={t("acceptInvitation.input.password.label")}
          type="password"
          register={register("password", {
            required: t("acceptInvitation.error.passwordRequired")!,
            minLength: {
              message: t("acceptInvitation.error.passwordLength"),
              value: 8,
            },
          })}
        />
        <Toggle
          dark
          error={acceptError}
          label={t("acceptInvitation.toggle.accepted_terms.label")}
          control={control}
          description={
            <div>
              {t("acceptInvitation.text.readTerms")}{" "}
              <A target="blank" href="https://www.parlorkit.com/terms">
                {t("acceptInvitation.text.termsLink")}
              </A>
            </div>
          }
          name={"accepted_terms"}
        />
        <Toggle
          dark
          label={t("acceptInvitation.toggle.permission_given.label")}
          control={control}
          name={"permission_given"}
        />
        <div className={"w-full flex justify-end"}>
          <Button type="submit" onClick={handleSubmit(submit)}>
            {t("acceptInvitation.button.finish")}
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
}

function InvitationSection({
  invitations,
  onRefresh,
}: {
  invitations: Array<UserInvitation>;
  onRefresh: () => void;
}) {
  const { t } = useTranslation("application");
  const api = useAppApi();
  const token = useQueryToken();
  const confirmText = t("invitationSection.button.confirmReject");

  return (
    <AuthLayout video={"Ink"} title={t("invitationSection.title")}>
      {invitations.length > 0 ? (
        <StackedList>
          {invitations.map((i, index) => {
            return (
              <div key={index}>
                <StackedListItem>
                  <ActionPanelBottom
                    title={i.user_role.account.company_name}
                    subTitle={i.user_role.title || ""}
                    actions={[
                      <Button
                        onClick={(e, setLoading) => {
                          setLoading(true);
                          api.users
                            .respondToInvitationAccountMyInvitationsInvitationIdGet(
                              i.id,
                              true,
                              token
                            )
                            .then(() => {
                              setLoading(false);
                              onRefresh();
                            });
                        }}
                      >
                        {t("invitationSection.button.accept")}
                      </Button>,
                      <Button
                        onClick={(e, setLoading) => {
                          const confirmed = window.confirm(confirmText);
                          if (confirmed) {
                            setLoading(true);
                            api.users
                              .respondToInvitationAccountMyInvitationsInvitationIdGet(
                                i.id,
                                false,
                                token
                              )
                              .then(() => {
                                setLoading(false);
                                onRefresh();
                              });
                          }
                        }}
                        color="red"
                      >
                        {t("invitationSection.button.reject")}
                      </Button>,
                    ]}
                  />
                </StackedListItem>
              </div>
            );
          })}
        </StackedList>
      ) : (
        <SimpleEmptyState
          title={t("invitationSection.emptyState.title")}
          description={t("invitationSection.emptyState.description")}
          actions={[
            <LinkButton to={"/"}>
              {t("invitationSection.button.gotoApp")}
            </LinkButton>,
          ]}
        />
      )}
    </AuthLayout>
  );
}
