/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserRoles {
    OWNER = 'Owner',
    EMPLOYEE = 'Employee',
    MANAGER = 'Manager',
}
