/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_invitation_already_exists___USER_CONTROLLER_invitation_already_exists____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_invitation_already_exists___USER_CONTROLLER_invitation_already_exists____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_invitation_already_exists___USER_CONTROLLER_invitation_already_exists____ {

    export enum detail {
        USER_CONTROLLER_INVITATION_ALREADY_EXISTS = 'USER_CONTROLLER_invitation_already_exists',
    }


}

