import React, { useEffect, useState } from "react";
import { useAppApi } from "api/staff/AppApi";
import { UserRole } from "api/staff";
import { UserSelect } from "./UserSelect";

export function UserRolePicker(props: {
  currentUserRole?: UserRole;
  onUserRoleChanged: (userRole: UserRole) => void;
}) {
  const api = useAppApi();

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    api.users.getAccountUsersAccountUsersGet().then((users) => {
      setUserRoles(users.my_roles);
    });
  }, []);
  return (
    <>
      {userRoles.length > 0 && (
        <UserSelect
          selected={
            userRoles.find((u) => {
              return props.currentUserRole && props.currentUserRole.id === u.id;
            }) || userRoles[0]
          }
          users={userRoles}
          onChange={(u) => {
            props.onUserRoleChanged(u);
          }}
        />
      )}
    </>
  );
}
