import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Layout } from "../../Layout/Layout";
import { useAppApi } from "../../../../api/staff/AppApi";

type Page = "website" | "booking" | "businessHours" | "subscription";

export function SettingsLayout(props: PropsWithChildren<{ active: Page }>) {
  const { t } = useTranslation("application");
  const api = useAppApi();

  const secondaryNavigation = [
    {
      name: t("settingsLayout.navigation.website"),
      href: "/settings/website",
      current: props.active === "website",
      restricted: true,
    },
    {
      name: t("settingsLayout.navigation.booking"),
      href: "/settings/booking",
      current: props.active === "booking",
      restricted: true,
    },
  ];

  return (
    <Layout active={"settings"}>
      <main>
        <header className="border-b border-white/5">
          {/* Secondary navigation */}
          <nav className="flex overflow-x-auto py-6">
            <ul
              role="list"
              className="flex min-w-full flex-none gap-x-6 px-4 text-base font-semibold leading-6 text-gray-400 sm:px-4 lg:px-8"
            >
              {secondaryNavigation
                .filter(
                  (item) =>
                    item.restricted &&
                    (api.userProfile?.current_role?.role === "Owner" ||
                      api.userProfile?.current_role?.role === "Manager")
                )
                .map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={item.current ? "text-indigo-400 border-b" : ""}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        </header>

        <div className="px-4 lg:px-8">{props.children}</div>
      </main>
    </Layout>
  );
}
