import { ProtectedPage } from "../../ProtectedPage";
import { Layout } from "../../Layout/Layout";
import {
  Button,
  List,
  LoadingOverlay,
  Paginator,
  Td,
  TextButton,
} from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../../api/staff/AppApi";
import { FullSessionInDb } from "../../../../api/staff";
import { TimeOffModal } from "./TimeOffModal";
import { useLocale } from "../../../../lib/locale";
import { differenceInMinutes } from "date-fns";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

export function TimeOffPage() {
  const [visible, setVisible] = useState(false);

  const [editTimeOff, setEditTimeOff] = useState<FullSessionInDb>();
  const [data, setData] = useState<FullSessionInDb[]>([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const { t } = useTranslation("application");
  const api = useAppApi();
  const locale = useLocale();

  useEffect(() => {
    setLoading(true);
    api.misc
      .searchSessionsSearchSessionsGet(
        false,
        false,
        false,
        true,
        false,
        term,
        offset
      )
      .then((result) => {
        setData(result.results);
        setTotal(result.total);
        setNextOffset(result.offset);
        setPageSize(result.page_size);
        setLoading(false);
      });
  }, [visible]);

  return (
    <ProtectedPage>
      <LoadingOverlay loading={loading} />
      <Layout active={"timeOff"}>
        <List
          title={t("timeOffPage.list.title")}
          subtitle={t("timeOffPage.list.subTitle")}
          data={data}
          renderLine={(timeOff) => {
            const start = new Date(timeOff.start!);
            const end = new Date(timeOff.end!);

            const totalMinutes = differenceInMinutes(end, start);
            const daysWithDecimal = (totalMinutes / (60 * 24)).toFixed(1);

            return (
              <>
                <Td first>{timeOff.user_role?.user.name}</Td>
                <Td>{locale.localeDateTime(start)}</Td>
                <Td>{locale.localeDateTime(end)}</Td>
                <Td last>
                  {t("timeOffPage.list.td.days", { days: daysWithDecimal })}
                </Td>
                <Td last>
                  <TextButton
                    onClick={() => {
                      setEditTimeOff(timeOff);
                      setVisible(true);
                    }}
                  >
                    <div className={"p-2"}>
                      <PencilSquareIcon width={24} />
                    </div>
                  </TextButton>
                </Td>
              </>
            );
          }}
          headers={[
            t("timeOffPage.list.col.user"),
            t("timeOffPage.list.col.start"),
            t("timeOffPage.list.col.end"),
            t("timeOffPage.list.col.duration"),
            "",
          ]}
          actions={[
            <Button
              onClick={() => {
                setVisible(true);
              }}
            >
              {t("timeOffPage.button.planTimeOff")}
            </Button>,
          ]}
        />
        <Paginator
          nextOffset={nextOffset}
          page_size={pageSize}
          total={total}
          onNext={(newOffset) => {
            setOffset(newOffset);
          }}
          onPrevious={(newOffset) => {
            setOffset(newOffset);
          }}
        />
      </Layout>
      <TimeOffModal
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setEditTimeOff(undefined);
        }}
        sessionId={editTimeOff?.id}
      />
    </ProtectedPage>
  );
}
