import { useContext } from "react";
import { WebshopAuth } from "./WebshopAuth";
import { WebshopContext } from "./WebshopContext";
import { OpenAPI } from "./core/OpenAPI";
import { DefaultService } from "./services/DefaultService";
import { ClientContext } from "./ClientContext";

const getToken = async () => {
  // Some code that requests a token...
  return window.localStorage.getItem("TOKEN") || "";
};

OpenAPI.TOKEN = getToken;

export function useWebshopApi() {
  const webshopContext = useContext(WebshopContext);
  const clientContext = useContext(ClientContext);

  return {
    api: DefaultService,
    auth: new WebshopAuth(),
    webshopContext: webshopContext,
    webshop: webshopContext.webshop,
    clientContext: clientContext,
    client: clientContext.client,
  };
}
