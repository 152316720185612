import React from "react";
import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";
import { OnboardingContainer } from "./OnboardingContainer";
import { Link, DividerWithLabel, ActionPanelTopRight } from "Tailwind";
import { useTranslation } from "react-i18next";
import { BookingForm } from "../Pages/WebsiteSettings/Booking/BookingForm";
import { useNavigate } from "react-router-dom";

export function BookingOnboarding() {
  const { t } = useTranslation("application");
  const navigate = useNavigate();

  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={t("bookingOnboarding.actionPanel.title")}
          subTitle={t("bookingOnboarding.actionPanel.subtitle")}
          actions={[
            <Link to={"/onboarding/website"}>
              {t("bookingOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Link>,
          ]}
        >
          <>
            <div className={"my-10"}>
              <DividerWithLabel label={t("bookingOnboarding.divider")} />
            </div>
            <BookingForm
              onSave={() => {
                navigate("/onboarding/website");
              }}
            />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
