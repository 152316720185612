import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function Beautician(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696449130/app/video_2160p_yhsb7j.mp4"
      }
    />
  );
}
