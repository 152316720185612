import { SettingsLayout } from "./SettingsLayout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BookingForm } from "../WebsiteSettings/Booking/BookingForm";

export function BookingPageSettings() {
  const { t } = useTranslation("application");

  return (
    <SettingsLayout active={"booking"}>
      <BookingForm
        onSave={() => {
          toast.success(t("bookingPageSettings.toast.success.content"));
        }}
      />
    </SettingsLayout>
  );
}
