/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Professions {
    TATTOO_ARTIST = 'TattooArtist',
    SKINCARE_SPECIALIST = 'SkincareSpecialist',
    MASSAGE_THERAPIST = 'MassageTherapist',
    BODY_PIERCER = 'BodyPiercer',
    BEAUTICIAN = 'Beautician',
    HAIRDRESSER = 'Hairdresser',
    BARBER = 'Barber',
    MANICURIST = 'Manicurist',
    OTHER = 'Other',
}
