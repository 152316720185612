import React, { useEffect, useState } from "react";
import {
  FullSessionInDb,
  SessionStatus,
  UserRole,
} from "../../../../../api/staff";
import {
  Button,
  CircularAvatar,
  Font,
  HeadingWithActions,
  Modal,
  TextButton,
} from "../../../../../Tailwind";
import { useTranslation } from "react-i18next";
import { UserRolePicker } from "../../Users/UserRolePicker";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";

export function EmployeeSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (session: FullSessionInDb) => void;
}) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation("application");
  const api = useAppApi();

  const [newUser, setNewUser] = useState<UserRole | undefined>(
    session.user_role
  );

  useEffect(() => {
    setNewUser(session.user_role);
  }, [session.user_role]);

  return (
    <>
      <div>
        <HeadingWithActions
          title={t("employeeSection.heading.title")}
          actions={[
            session.status === SessionStatus.SCHEDULED && (
              <TextButton
                onClick={() => {
                  setVisible(true);
                }}
              >
                <ArrowsRightLeftIcon className="h-6 w-6" />
              </TextButton>
            ),
          ]}
        />
        <div className="flex items-center">
          <div>
            <CircularAvatar
              file={session.user_role?.avatar}
              color={session.user_role?.color}
              size={"sm"}
            />
          </div>
          <div className="ml-4 flex flex-col">
            <Font size={"xl"}>{session.user_role?.user.name}</Font>
            <div className="flex gap-2">
              <Font size={"sm"} color="gray500">
                {session.user_role?.user.email}
              </Font>
              <Font size={"sm"} color="gray500">
                {session.user_role?.user.phone_number}
              </Font>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size={"xl"}
          visible={visible}
          setVisible={() => {
            setVisible(false);
          }}
        >
          <UserRolePicker
            currentUserRole={newUser}
            onUserRoleChanged={(userRole) => {
              setNewUser(userRole);
            }}
          />
          <div className={"mt-4 flex justify-end gap-2"}>
            <Button
              disabled={
                newUser === undefined || newUser.id === session.user_role?.id
              }
              onClick={() => {
                if (newUser) {
                  api.misc
                    .updateEmployeeSessionsSessionIdEmployeePut(session.id, {
                      employee_id: newUser.id,
                    })
                    .then((s) => {
                      setNewUser(undefined);
                      setVisible(false);
                      onSessionUpdated(s);
                    });
                }
              }}
            >
              {t("employeeSection.button.apply")}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setVisible(false);
              }}
            >
              {t("employeeSection.button.cancel")}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}
