import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useLocale } from "lib/locale";
import { Button } from "Tailwind";
import { UserRole } from "api/staff";
import { calendarType } from "./CalendarPage";
import React from "react";
import { UserRolesPicker } from "../Users/UserRolesPicker";
import { CalendarSelector } from "./CalendarSelector";
import { MobileCalendarSelector } from "./MobileCalendarSelector";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function CalendarHeader(props: {
  selectedDate: Date;
  employeesChanged: (employees: UserRole[]) => void;
  onPreviousWeek: () => void;
  onNextWeek: () => void;
  onToday: () => void;
  currentCalendar: "month" | "week";
  onCreate: () => void;
  onSwitchCalendar: (type: calendarType) => void;
}) {
  const { t } = useTranslation("application");
  const locale = useLocale();
  return (
    <header className="flex flex-col w-full gap-4 justify-between border-b border-gray-200 pb-4">
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        <time>{locale.format(props.selectedDate, "MMMM yyyy")}</time>
      </h1>
      <div className="flex flex-col md:flex-row w-full gap-4 justify-between border-b border-gray-200 pb-4">
        <div className="md:mr-4">
          <UserRolesPicker
            onUserRoleChanged={props.employeesChanged}
            currentUserRoles={[]}
            all
          />
        </div>
        <div className="flex flex-row w-full justify-evenly">
          <div className="relative flex-1 flex rounded-md bg-white shadow-sm">
            <div
              className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
              aria-hidden="true"
            />
            <button
              type="button"
              onClick={props.onPreviousWeek}
              className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">
                {t("weekCalendarHeader.button.previousWeek")}
              </span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={props.onToday}
              type="button"
              className="px-3.5 w-full text-sm font-semibold border-t border-b border-gray-200 text-gray-900 hover:bg-gray-50 focus:relative md:block"
            >
              {t("weekCalendarHeader.button.today")}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 hidden" />
            <button
              type="button"
              onClick={props.onNextWeek}
              className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">
                {t("weekCalendarHeader.button.nextWeek")}
              </span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="relative md:hidden">
            <MobileCalendarSelector onSwitchCalendar={props.onSwitchCalendar} />
          </div>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <CalendarSelector
            onSwitchCalendar={props.onSwitchCalendar}
            currentCalendar={props.currentCalendar}
          />
        </div>
        <div className="hidden md:flex md:items-center">
          <div className="mx-6 h-6 w-px bg-gray-300" />
          <Button
            onClick={() => {
              props.onCreate();
            }}
          >
            {t("weekCalendarHeader.button.create")}
          </Button>
        </div>
      </div>
    </header>
  );
}
