import React, { PropsWithChildren, useState } from "react";
import { Spinner } from "../Spinner";
import { ButtonProps } from "./ButtonProps";
import { color, roundedSizes, sizes } from "./constants";

function getProps(props: ButtonProps, extra?: string) {
  const shade = props.dark ? "dark" : "light";
  const s = props.size ? props.size : "lg";
  const clr = props.color
    ? ` ${color[shade][props.color]}`
    : ` ${color[shade]["primary"]}`;
  const e = extra ? ` ${extra}` : "";
  const r = props.rounded ? "rounded-full" : ` ${roundedSizes[s]}`;
  const space = props.leadingIcon || props.trailingIcon ? "gap-x-2" : "";
  const width = props.full ? "w-full" : "";
  return `${clr}${e} ${sizes[s]} ${r} ${space} ${width}`;
}

export function Button({
  className,
  children,
  type,
  onClick,
  disabled,
  ...props
}: PropsWithChildren<ButtonProps>) {
  const cls = `${getProps(props)} ${className}`;
  const [loading, setLoading] = useState(false);
  return (
    <button
      onClick={(e) => {
        onClick && onClick(e, setLoading);
      }}
      disabled={disabled !== undefined && disabled}
      type={type ? type : "button"}
      className={cls}
    >
      <div className="flex gap-1">
        {loading && (
          <div className="flex flex-col justify-center">
            <Spinner
              size={"xs"}
              color={props.color === "secondary" ? "secondary" : "white"}
            />
          </div>
        )}
        {props.leadingIcon}
        <span
          className={
            props.full ? "whitespace-nowrap w-full" : "whitespace-nowrap"
          }
        >
          {children}
        </span>
        {props.trailingIcon}
      </div>
    </button>
  );
}
