/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_images_post } from '../models/Body_upload_images_post';
import type { Body_upload_to_gallery_gallery_post } from '../models/Body_upload_to_gallery_gallery_post';
import type { File } from '../models/File';
import type { FileTag } from '../models/FileTag';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesService {

    /**
     * Upload
     * @param formData
     * @returns File Successful Response
     * @throws ApiError
     */
    public static uploadImagesPost(
        formData: Body_upload_images_post,
    ): CancelablePromise<File> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/images',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Gallery
     * @returns File Successful Response
     * @throws ApiError
     */
    public static getGalleryGalleryGet(): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/gallery',
        });
    }

    /**
     * Upload To Gallery
     * @param formData
     * @returns File Successful Response
     * @throws ApiError
     */
    public static uploadToGalleryGalleryPost(
        formData: Body_upload_to_gallery_gallery_post,
    ): CancelablePromise<File> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/gallery',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete
     * @param fileId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteGalleryDelete(
        fileId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/gallery',
            query: {
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag File
     * @param fileId
     * @param tag
     * @returns FileTag Successful Response
     * @throws ApiError
     */
    public static tagFileImagesTagPost(
        fileId: number,
        tag: string,
    ): CancelablePromise<FileTag> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/images/tag',
            query: {
                'file_id': fileId,
                'tag': tag,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
