import React, { ReactNode, useEffect, useState } from "react";
import { WeekCalendarHorizontalLines } from "./WeekCalendarHorizontalLines";
import { WeekCalendarDates } from "./WeekCalendarDates";
import { WeekCalendarSession } from "./WeekCalendarSession";
import { DndContext } from "@dnd-kit/core";
import { Breakpoints, useBreakpoint, useCalendarConsts } from "lib/utils";
import { addDays, endOfWeek, startOfWeek } from "date-fns";
import { useAppApi } from "api/staff/AppApi";
import { SessionMin, UserRole } from "api/staff";
import { layoutEvents, splitIntervalIntoDays } from "./utils";
import { CreateBookingSlideOver } from "../../Sessions/CreateBookingSlideOver/CreateBookingSlideOver";
import { useLocale } from "lib/locale";
import { LoadingOverlay, Modal } from "Tailwind";
import { FullScreenSessionModal } from "../../Sessions/FullScreenSessionModal/FullScreenSessionModal";
import { calendarType } from "../CalendarPage";
import { CalendarHeader } from "../CalendarHeader";
import { WeekCalendarTimeOff } from "./WeekCalendarTimeOff";
import { TimeOffModal } from "../../TimeOff/TimeOffModal";

export interface CalendarEvent {
  start: Date;
  end: Date;
  event: SessionMin;
  leftOffset: number;
  width: number;
  id: number;
}

export default function WeekCalendar(props: {
  onSwitchCalendar: (type: calendarType) => void;
}) {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const { hoursOfday } = useCalendarConsts();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [week, setWeek] = useState<Date>(new Date());
  const [selected, setSelected] = useState<Date | undefined>();
  const breakpoint = useBreakpoint();
  const [currentDates, setCurrentDates] = useState<Date[]>([]);
  const [createSession, setCreateSession] = useState(false);
  const [viewSession, setViewSession] = useState<SessionMin | undefined>();
  const [viewTimeOff, setViewTimeOff] = useState<SessionMin | undefined>();
  const [employees, setEmployees] = useState<UserRole[]>([]);

  const locale = useLocale();

  const api = useAppApi();

  useEffect(() => {
    if (currentDates.length > 0) {
      fetchSessions();
    }
  }, [currentDates, employees]);

  function fetchSessions() {
    setLoading(true);
    api.misc
      .getCalendarSessionsCalendarPost(
        startOfWeek(week, {
          locale: locale.currentLocale,
        }).toISOString(),
        endOfWeek(week, { locale: locale.currentLocale }).toISOString(),
        employees.map((e) => e.id)
      )
      .then((sessions) => {
        const events: CalendarEvent[] = [];
        for (const session of sessions) {
          const start = new Date(session.start!);
          const end = new Date(session.end!);
          const days = splitIntervalIntoDays(start, end);
          for (const day of days) {
            events.push({
              id: session.id,
              event: session,
              start: day.start,
              end: day.end,
              leftOffset: 0,
              width: 0,
            });
          }
        }
        setEvents(layoutEvents(events, currentDates));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const day1 = startOfWeek(week, { locale: locale.currentLocale });
    const day2 = addDays(day1, 1);
    const day3 = addDays(day2, 1);
    const day4 = addDays(day3, 1);
    const day5 = addDays(day4, 1);
    const day6 = addDays(day5, 1);
    const day7 = addDays(day6, 1);
    setCurrentDates([day1, day2, day3, day4, day5, day6, day7]);
  }, [week]);

  function getClickableHoursFull() {
    let hours: ReactNode[] = [];
    const currentTime = new Date();
    currentDates.forEach((date, i) => {
      hoursOfday.forEach((hour, j) => {
        let dt = new Date(date.getTime());
        dt.setHours(hour.value, 0);
        if (dt > currentTime) {
          const style = `${2 + hour.value * 12} / span 12`;
          const col = dt.getDay() === 0 ? 7 : dt.getDay();
          const clss = `relative mt-px flex col-start-${col} col-end-${col}`;
          hours.push(
            <div
              key={`hours-desktop-${i}-${j}`}
              className={clss}
              style={{ gridRow: style }}
            >
              <a
                onClick={() => {
                  setSelected(dt);
                  setCreateSession(true);
                }}
                className="group absolute flex flex-col inset-0 overflow-y-auto text-xs leading-5 hover:bg-gray-100"
              ></a>
            </div>
          );
        } else {
          const style = `${2 + hour.value * 12} / span 12`;
          const col = dt.getDay() === 0 ? 7 : dt.getDay();
          const clss = `relative mt-px flex col-start-${col} col-end-${col}`;
          hours.push(
            <div
              key={`hours-desktop-${i}-${j}`}
              className={clss}
              style={{ gridRow: style }}
            >
              <div className="group absolute flex flex-col inset-0 overflow-y-auto rounded-lg text-xs leading-5 bg-gray-50"></div>
            </div>
          );
        }
      });
    });
    return hours;
  }

  function getClickableHoursDay() {
    let hours: ReactNode[] = [];
    const currentTime = new Date();
    hoursOfday.forEach((hour, i) => {
      const style = `${2 + hour.value * 12} / span 12`;
      const clss = `relative mt-px flex col-start-1 col-end-1`;
      let date = new Date(selectedDate.getTime());
      date.setHours(hour.value, 0);
      if (date > currentTime) {
        hours.push(
          <div
            key={`hours-mobile-${i}`}
            className={clss}
            style={{ gridRow: style }}
          >
            <a
              onClick={() => {
                setSelected(date);
                setCreateSession(true);
              }}
              className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-gray-50"
            ></a>
          </div>
        );
      }
    });
    return hours;
  }

  function getClickableHours() {
    if (breakpoint >= Breakpoints.md) {
      return <>{getClickableHoursFull()}</>;
    } else {
      return <>{getClickableHoursDay()}</>;
    }
  }

  return (
    <DndContext>
      <>
        <LoadingOverlay loading={loading} />
        {/* See https://docs.dndkit.com/introduction/getting-started */}
        <div className="flex h-full flex-col">
          <CalendarHeader
            currentCalendar="week"
            onSwitchCalendar={props.onSwitchCalendar}
            employeesChanged={setEmployees}
            selectedDate={week}
            onCreate={() => {
              setSelected(undefined);
              setCreateSession(true);
            }}
            onPreviousWeek={() => {
              setWeek(addDays(selectedDate, -7));
              setSelectedDate(addDays(selectedDate, -7));
            }}
            onNextWeek={() => {
              setWeek(addDays(selectedDate, +7));
              setSelectedDate(addDays(selectedDate, +7));
            }}
            onToday={() => {
              setWeek(new Date());
              setSelectedDate(new Date());
            }}
          />
          <div className="isolate flex flex-auto flex-col overflow-auto bg-white">
            <div
              style={{ width: "165%" }}
              className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
            >
              <div className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
                <WeekCalendarDates
                  selectedDate={selectedDate}
                  dates={currentDates}
                  onDaySelected={(day) => {
                    setSelectedDate(day);
                  }}
                />
              </div>
              <div className="flex flex-auto">
                <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                  {/* Horizontal lines */}
                  <WeekCalendarHorizontalLines />

                  {/* Vertical lines */}
                  <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                    <div className="col-start-1 row-span-full" />
                    <div className="col-start-2 row-span-full" />
                    <div className="col-start-3 row-span-full" />
                    <div className="col-start-4 row-span-full" />
                    <div className="col-start-5 row-span-full" />
                    <div className="col-start-6 row-span-full" />
                    <div className="col-start-7 row-span-full" />
                    <div className="col-start-8 row-span-full w-8" />
                  </div>

                  {/* Events */}
                  <ol
                    className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                    style={{
                      gridTemplateRows:
                        "1.75rem repeat(288, minmax(0, 1fr)) auto",
                    }}
                  >
                    <>
                      {getClickableHours()}
                      {breakpoint < Breakpoints.md
                        ? events
                            .filter((a) => {
                              return (
                                a.start.getDate() === selectedDate.getDate()
                              );
                            })
                            .map((a, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {a.event.status === "TimeOff" ? (
                                    <WeekCalendarTimeOff
                                      event={a}
                                      index={index}
                                      col={1}
                                      onClick={() => {
                                        setViewTimeOff(a.event);
                                      }}
                                    />
                                  ) : (
                                    <WeekCalendarSession
                                      event={a}
                                      index={index}
                                      col={1}
                                      onClick={() => {
                                        setViewSession(a.event);
                                      }}
                                    />
                                  )}
                                </React.Fragment>
                              );
                            })
                        : events.map((a, index) => {
                            const col =
                              a.start.getDay() != 0 ? a.start.getDay() : 7;
                            return (
                              <React.Fragment key={index}>
                                {a.event.status === "TimeOff" ? (
                                  <WeekCalendarTimeOff
                                    event={a}
                                    index={index}
                                    col={col}
                                    onClick={() => {
                                      setViewTimeOff(a.event);
                                    }}
                                  />
                                ) : (
                                  <WeekCalendarSession
                                    event={a}
                                    index={index}
                                    col={col}
                                    onClick={() => {
                                      setViewSession(a.event);
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}
                    </>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateBookingSlideOver
          requestOpen={() => {
            setCreateSession(true);
          }}
          start={selected}
          employee={
            employees.length === 1
              ? employees[0]
              : api.userProfile?.current_role!
          }
          isOpen={createSession}
          onClose={() => {
            setCreateSession(false);
          }}
          onSessionCreated={() => {
            setCreateSession(false);
            fetchSessions();
          }}
        />
        <FullScreenSessionModal
          isOpen={viewSession !== undefined}
          sessionId={viewSession?.id}
          onClose={() => {
            fetchSessions();
            setViewSession(undefined);
          }}
        />
        <TimeOffModal
          visible={viewTimeOff !== undefined}
          sessionId={viewTimeOff?.id}
          setVisible={() => {
            fetchSessions();
            setViewTimeOff(undefined);
          }}
        />
      </>
    </DndContext>
  );
}
