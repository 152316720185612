import React, { ReactElement, ReactNode } from "react";
import { Font } from "../Font/Font";

export function HeadingWithActions(props: {
  title: ReactNode;
  subtitle?: ReactNode;
  actions: ReactNode[];
}) {
  return (
    <>
      <div className="flex flex-col pb-2 mb-4 border-b border-gray-200">
        <div>
          <div className="flex sm:flex-row justify-between items-center">
            <h1 className="leading-6">
              <Font size={"base"} weight={"semibold"} color={"gray"}>
                {props.title}
              </Font>
            </h1>

            <div className="sm:ml-16 sm:mt-0 flex flex-col sm:flex-row gap-4 items-center">
              {props.actions.map((i, index) => (
                <div className="flex-row justify-center" key={index}>
                  {i}
                </div>
              ))}
            </div>
          </div>
          {props.subtitle && (
            <div className="mt-2">
              <Font size={"sm"} color={"gray500"}>
                {props.subtitle}
              </Font>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
