import React, { useState } from "react";

export function CTASimpleCentered(props: {
  title: string;
  content: string;
  actions?: React.ReactNode[];
}) {
  return (
    <div className="bg-white opacity-90 sm:rounded-xl sm:shadow-xl">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {props.title}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            {props.content}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            {props.actions}
          </div>
        </div>
      </div>
    </div>
  );
}
