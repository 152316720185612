import { useLocale } from "../../../lib/locale";
import DatePicker from "react-datepicker";
import React from "react";

export function DateTimePicker(props: {
  date: Date;
  label: string;
  disabled?: boolean;
  onChange: (date: Date) => void;
  showTimeSelect?: boolean;
  inline?: boolean;
}) {
  const locale = useLocale();
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {props.label}
      </label>
      <DatePicker
        disabled={props.disabled}
        wrapperClassName="w-full"
        selected={props.date}
        className={
          props.inline
            ? "py-1.5 text-center bg-white font-semibold disabled:bg-gray-50 shadow-sm border-t border-b hover:bg-gray-50 hover:cursor-pointer border-gray-300 text-gray-900 sm:text-sm sm:leading-6"
            : "py-1.5 pl-2 bg-white disabled:bg-gray-50 shadow-sm ring-1 border-0 rounded-md ring-gray-300 min-w-full text-gray-900 sm:text-sm sm:leading-6 w-full"
        }
        onChange={(date: Date) => props.onChange(date)}
        locale={locale.currentLocale}
        showTimeSelect={props.showTimeSelect}
        timeFormat={"p"}
        timeIntervals={15}
        dateFormat={props.showTimeSelect ? "Pp" : "P"}
      />
    </div>
  );
}
