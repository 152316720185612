import { useLocale } from "../../../lib/locale";
import React from "react";
import { Service } from "../../../api/webshop";
import { manipulate_image_file } from "../../../lib/utils";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";

export function ServiceSection({
  availableServices,
  selectedServices,
  serviceClicked,
}: {
  availableServices: Service[];
  selectedServices: number[];
  serviceClicked: (service: Service) => void;
}) {
  const { t } = useTranslation("webshop");
  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-slate-900"
        >
          {t("serviceSection.title")}
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-slate-500">
          {t("serviceSection.description")}
        </p>
      </div>
      <div className="border-t border-slate-200 px-4 py-5 sm:px-6">
        <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 sm:gap-x-4">
          {availableServices.map((s, index) => (
            <ServiceCard
              key={index}
              service={s}
              selected={selectedServices.indexOf(s.id) >= 0}
              onClick={(service) => {
                serviceClicked(service);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function ServiceCard(props: {
  service: Service;
  selected: boolean;
  onClick: (service: Service) => void;
}) {
  const locale = useLocale();
  const cls = props.selected
    ? "cursor-pointer rounded-lg border border-indigo-100 ring-2 ring-indigo-500 hover:bg-gray-50 shadow-sm focus:outline-none shadow-lg"
    : "flex cursor-pointer rounded-lg shadow-md border border-slate-100 focus:outline-none";

  const hasImage = props.service.files.length > 0;

  return (
    <div className={cls}>
      <div className="flex flex-1 h-full">
        <div className="flex flex-col w-full">
          {props.service && (
            <span
              className={`group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-t-lg ${
                hasImage ? "border-b border-slate-200" : ""
              } `}
            >
              {props.service.files.length > 0 ? (
                <Carousel
                  autoPlay={true}
                  onClickItem={() => props.onClick(props.service)}
                  infiniteLoop={true}
                  interval={5000}
                  showThumbs={false}
                >
                  {props.service.files.map((i, index) => (
                    <div key={index}>
                      <img
                        src={manipulate_image_file(i, [
                          "h_300",
                          "w_500",
                          "c_fill",
                          "g_auto",
                        ])}
                      />
                    </div>
                  ))}
                </Carousel>
              ) : (
                <span
                  id="project-type-2-label"
                  className="block text-lg font-semibold text-gray-100 bg-slate-800 p-4"
                >
                  {props.service.name}
                </span>
              )}
            </span>
          )}
          <div
            onClick={() => props.onClick(props.service)}
            className=" cursor-pointer hover:bg-gray-50 relative flex flex-col flex-grow w-full"
          >
            <div className="px-4 pt-4 flex flex-row justify-between">
              {hasImage && (
                <span
                  id="project-type-2-label"
                  className="block text-base font-medium text-slate-900"
                >
                  {props.service.name}
                </span>
              )}
              {props.selected && (
                <div className="h-5 w-5">
                  <svg
                    className="h-5 w-5 text-indigo-600 align-self-end"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
              {!props.selected && props.service.files.length === 0 && (
                <span
                  className="pointer-events-none absolute -inset-px rounded-lg"
                  aria-hidden="true"
                />
              )}
              {!props.selected && props.service.files.length > 0 && (
                <span
                  className="pointer-events-none absolute -inset-px rounded-b-lg border-t-0"
                  aria-hidden="true"
                />
              )}
            </div>
            <div
              id="project-type-2-description-0"
              className="px-4 mt-2 flex-grow text-xs text-slate-500 whitespace-pre-wrap"
            >
              {props.service.description}
            </div>
            <div className="px-4 pb-4 flex justify-between align-self-end">
              <div
                id="project-type-2-description-1"
                className="mt-6 text-sm font-medium text-slate-900"
              >
                {locale.duration(props.service.duration)}
              </div>
              <div
                id="project-type-2-description-1"
                className="mt-6 text-sm font-medium text-slate-900"
              >
                {props.service.price} kr.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
