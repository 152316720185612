import { addZeroes } from "../../lib/utils";
import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useLocale } from "../../lib/locale";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function Datepicker({
  days,
  onChange,
  onPreviousMonth,
  onNextMonth,
  selected,
}: {
  days: { date: Date; showDot: boolean }[];
  onChange: (date: Date) => void;
  onPreviousMonth: () => void;
  onNextMonth: () => void;
  selected?: Date;
}) {
  const today = new Date();
  const month = days.length > 0 ? days[0].date : today;
  const locale = useLocale();
  const { t } = useTranslation("application");

  const isSelected = (day: Date) => {
    if (!selected) {
      return false;
    } else {
      return day.toDateString() === selected.toDateString();
    }
  };

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          onClick={onPreviousMonth}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">
            {t("datepicker.button.previousMonth")}
          </span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {locale.format(month, "MMMM")}
        </div>
        <button
          onClick={onNextMonth}
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">{t("datepicker.button.nextMonth")}</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>{t("datePicker.monday")}</div>
        <div>{t("datePicker.tuesday")}</div>
        <div>{t("datePicker.wednesday")}</div>
        <div>{t("datePicker.thursday")}</div>
        <div>{t("datePicker.friday")}</div>
        <div>{t("datePicker.saturday")}</div>
        <div>{t("datePicker.sunday")}</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => {
          const isCurrentMonth = day.date >= addDays(today, -1);
          const isToday = day.date.toDateString() === today.toDateString();
          const selected = isSelected(day.date);
          return (
            <button
              key={day.date.getDate()}
              disabled={!isCurrentMonth}
              onClick={() => onChange(day.date)}
              type="button"
              className={classNames(
                "py-1.5 hover:bg-gray-100 focus:z-10",
                isCurrentMonth ? "bg-white" : "bg-gray-50",
                (selected || isToday) && "font-semibold",
                selected && "text-white",
                !selected && isCurrentMonth && !isToday && "text-gray-900",
                !selected && !isCurrentMonth && !isToday && "text-gray-400",
                isToday && !selected && "text-indigo-600",
                dayIdx === 0 && day.date.getDay() === 1 && "rounded-tl-lg",
                dayIdx < 7 && day.date.getDay() === 0 && "rounded-tr-lg",
                dayIdx > 22 && day.date.getDay() === 1 && "rounded-bl-lg",
                dayIdx > 27 && day.date.getDay() === 0 && "rounded-br-lg",
                `col-start-${day.date.getDay() === 0 ? 7 : day.date.getDay()}`
              )}
            >
              <time
                dateTime={day.date.toDateString()}
                className={classNames(
                  "relative mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  selected && isToday && "bg-indigo-500",
                  selected && !isToday && "bg-gray-900"
                )}
              >
                {addZeroes(day.date.getDate())}
                {day.showDot && (
                  <div
                    className={
                      "absolute bottom-0 bg-indigo-500 rounded-2xl w-1 h-1"
                    }
                  />
                )}
              </time>
            </button>
          );
        })}
      </div>
    </div>
  );
}
