import { ProfileLayout } from "./ProfileLayout";
import BusinessHours from "./BusinessHours/BusinessHours";

export function BusinessHoursSettings() {
  return (
    <ProfileLayout active={"businessHours"}>
      <BusinessHours />
    </ProfileLayout>
  );
}
