/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_SESSIONS_CONTROLLER_booking_unavailable___SESSIONS_CONTROLLER_booking_unavailable____ = {
    detail: PS_Literal__PEM_SESSIONS_CONTROLLER_booking_unavailable___SESSIONS_CONTROLLER_booking_unavailable____.detail;
};

export namespace PS_Literal__PEM_SESSIONS_CONTROLLER_booking_unavailable___SESSIONS_CONTROLLER_booking_unavailable____ {

    export enum detail {
        SESSIONS_CONTROLLER_BOOKING_UNAVAILABLE = 'SESSIONS_CONTROLLER_booking_unavailable',
    }


}

