import {
  Button,
  DashedEmptyState,
  HeadingWithActions,
  TextButton,
} from "../../../../../Tailwind";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";
import { DateTimePicker, Input } from "Tailwind/Input/Uncontrolled";
import { ClockIcon } from "@heroicons/react/24/outline";
import { FullSessionInDb, SessionStatus } from "../../../../../api/staff";
import { PickTimeModal } from "../CreateBookingSlideOver/PickTimeModal";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { useLocale } from "../../../../../lib/locale";

export function SessionBookingSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (updatedSession: FullSessionInDb) => void;
}) {
  const { t } = useTranslation("application");
  const [pickTime, setPickTime] = useState(false);
  const duration = useMemo(() => {
    return session.services.reduce((s, n) => s + n.duration, 0);
  }, [session.services]);

  const api = useAppApi();
  const locale = useLocale();

  function changeBooking(start: Date, duration: number) {
    api.misc
      .bookSessionSessionsSessionIdBookPut(session.id, {
        duration: duration,
        start: start.toISOString(),
        enforce_business_hours: false,
      })
      .then((s) => {
        onSessionUpdated(s);
      })
      .finally(() => {
        setPickTime(false);
      });
  }

  return (
    <>
      <div>
        <HeadingWithActions
          title={t("sessionBookingSection.heading.title")}
          actions={[
            session.status === SessionStatus.SCHEDULED && (
              <TextButton onClick={() => setPickTime(true)}>
                <ClockIcon className="h-6 w-6" />
              </TextButton>
            ),
          ]}
        />
        {session.start ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <div className="flex-1">
                <Input
                  name="start"
                  disabled={true}
                  label={t("sessionBookingSection.dateInput.start.label")}
                  value={locale.localeDateTime(new Date(session.start))}
                  onChange={(d) => {}}
                />
              </div>
              {session.end && (
                <div className="flex-1">
                  <Input
                    name="end"
                    disabled={true}
                    label={t("sessionBookingSection.dateInput.start.label")}
                    value={locale.localeDateTime(new Date(session.end))}
                    onChange={(d) => {}}
                  />
                </div>
              )}
              <div className="flex-1">
                <Input
                  disabled={true}
                  name="duration"
                  label={t("sessionBookingSection.input.duration.label")}
                  onChange={() => {}}
                  value={duration}
                  leftDecoration={<ClockIcon className={"w-5 h-5"} />}
                  rightDecoration={t(
                    "sessionBookingSection.input.duration.unit"
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <DashedEmptyState
              text={t("sessionBookingSection.emptyState.title")}
              onClick={() => setPickTime(true)}
              accessory={<ClockIcon />}
            />
          </div>
        )}
      </div>
      {session.user_role && (
        <PickTimeModal
          isOpen={pickTime}
          employee={session.user_role}
          start={session.start ? new Date(session.start) : new Date()}
          onClose={() => setPickTime(false)}
          duration={duration}
          onTimePicked={(start, duration) => {
            changeBooking(start, duration);
            setPickTime(false);
          }}
        />
      )}
    </>
  );
}
