import { CountryCode } from "libphonenumber-js/types";
import { Control } from "react-hook-form/dist/types/form";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneProps {
  county?: CountryCode;
  control: Control<any, string>;

  className?: string;
  label?: string | null;
  description?: string | null;
  name: string;
  error?: string;
  disabled?: boolean;
}

export function Phone(props: PhoneProps) {
  const [country, setCountry] = useState(props.county ? props.county : "dk");

  const {
    field: { value, onChange, name },
  } = useController(props);

  // @ts-ignore
  return (
    <div className={props.className}>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="mt-2 rounded-md shadow-sm w-full">
        {/* @ts-ignore */}
        <PhoneInput
          name={name}
          defaultCountry={country}
          value={value || ""}
          onChange={(v) => {
            onChange({ target: { value: v } });
          }}
          inputClassName="w-full"
        />
      </div>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
      {props.error && (
        <p className="mt-2 text-sm text-red-600">{props.error}</p>
      )}
    </div>
  );
}
