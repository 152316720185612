import React from "react";
import { UserProfile } from "./models/UserProfile";
import axios from "axios";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const subdomain = window.location.host.split(".")[0];
    if (error.response.status === 401 && subdomain === "app") {
      // Handle logout logic here - for app only
      window.localStorage.removeItem("USERPROFILE");
      window.localStorage.removeItem("WEBSHOP");
      if (!window.location.pathname.includes("/signin")) {
        window.location.replace("/signin");
      }
    }
    return Promise.reject(error);
  }
);

export interface AuthContextType {
  userProfile: UserProfile | undefined;
  setUserProfile: (profile: UserProfile | undefined) => void;
}

export const AppContext = React.createContext<AuthContextType>({
  userProfile: undefined,
  setUserProfile: (p) => {},
});
