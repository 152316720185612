/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountConnectionType } from '../models/AccountConnectionType';
import type { DineroAccount } from '../models/DineroAccount';
import type { DineroAccountConnection } from '../models/DineroAccountConnection';
import type { DineroAccountConnectionCreate } from '../models/DineroAccountConnectionCreate';
import type { DineroConnectRedirect } from '../models/DineroConnectRedirect';
import type { DineroDepositAccount } from '../models/DineroDepositAccount';
import type { DineroPaymentCreate } from '../models/DineroPaymentCreate';
import type { DineroSessionInvoice } from '../models/DineroSessionInvoice';
import type { Organization } from '../models/Organization';
import type { Page_DineroAccountConnection_ } from '../models/Page_DineroAccountConnection_';
import type { VismaConnection } from '../models/VismaConnection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DineroService {

    /**
     * Login
     * @returns DineroConnectRedirect Successful Response
     * @throws ApiError
     */
    public static loginDineroConnectGet(): CancelablePromise<DineroConnectRedirect> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/connect',
        });
    }

    /**
     * Callback
     * @returns string Successful Response
     * @throws ApiError
     */
    public static callbackDineroCallbackPost(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/callback',
        });
    }

    /**
     * Set Payment
     * @param sessionId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPaymentDineroSessionsSessionIdPaymentsPost(
        sessionId: number,
        requestBody: DineroPaymentCreate,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/sessions/{session_id}/payments',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Deposit Accounts
     * @returns DineroDepositAccount Successful Response
     * @throws ApiError
     */
    public static getDepositAccountsDineroDepositAccountsGet(): CancelablePromise<Array<DineroDepositAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/deposit-accounts',
        });
    }

    /**
     * Send Invoice Mail
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendInvoiceMailDineroSessionsSessionIdInvoiceMailPost(
        sessionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/sessions/{session_id}/invoice-mail',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Invoice
     * @param sessionId
     * @returns DineroSessionInvoice Successful Response
     * @throws ApiError
     */
    public static getInvoiceDineroSessionsSessionIdInvoiceGet(
        sessionId: number,
    ): CancelablePromise<DineroSessionInvoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/sessions/{session_id}/invoice',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Invoice
     * @param sessionId
     * @returns DineroSessionInvoice Successful Response
     * @throws ApiError
     */
    public static createInvoiceDineroSessionsSessionIdInvoicePost(
        sessionId: number,
    ): CancelablePromise<DineroSessionInvoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/sessions/{session_id}/invoice',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Dinero
     * @returns VismaConnection Successful Response
     * @throws ApiError
     */
    public static currentDineroDineroCurrentGet(): CancelablePromise<VismaConnection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/current',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * Current Dinero
     * @param requestBody
     * @returns VismaConnection Successful Response
     * @throws ApiError
     */
    public static currentDineroDineroCurrentPost(
        requestBody: VismaConnection,
    ): CancelablePromise<VismaConnection> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/current',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Dinero
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentDineroDineroCurrentDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dinero/current',
        });
    }

    /**
     * Accounts Entries
     * @param filter
     * @returns DineroAccount Successful Response
     * @throws ApiError
     */
    public static accountsEntriesDineroAccountsEntriesGet(
        filter?: string,
    ): CancelablePromise<Array<DineroAccount>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/accounts-entries',
            query: {
                'filter': filter,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static organizationDineroOrganizationsGet(): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/organizations',
        });
    }

    /**
     * Set Account Connection
     * @param requestBody
     * @returns DineroAccountConnection Successful Response
     * @throws ApiError
     */
    public static setAccountConnectionDineroAccountConnectionsPost(
        requestBody: DineroAccountConnectionCreate,
    ): CancelablePromise<DineroAccountConnection> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dinero/account-connections',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Account Connections
     * @param itemId
     * @param accountType
     * @param page
     * @param size
     * @returns Page_DineroAccountConnection_ Successful Response
     * @throws ApiError
     */
    public static searchAccountConnectionsDineroSearchAccountConnectionsGet(
        itemId?: number,
        accountType?: AccountConnectionType,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_DineroAccountConnection_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/search/account-connections',
            query: {
                'item_id': itemId,
                'account_type': accountType,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Account Connections
     * @returns DineroAccountConnection Successful Response
     * @throws ApiError
     */
    public static getAccountConnectionsDineroAccountConnectionsAccountConnectionIdGet(): CancelablePromise<Array<DineroAccountConnection>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dinero/account-connections/{account_connection_id}',
        });
    }

    /**
     * Delete Account Connection
     * @param accountConnectionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteAccountConnectionDineroAccountConnectionsAccountConnectionIdDelete(
        accountConnectionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/dinero/account-connections/{account_connection_id}',
            path: {
                'account_connection_id': accountConnectionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
