import { File } from "../../api/staff";
import { manipulate_image_file } from "../../lib/utils";
import React from "react";

const sizeMap = {
  xs: {
    imageWidth: "w_150",
    imageHeight: "h_150",
    width: "w-6",
    height: "h-6",
  },
  sm: {
    imageWidth: "w_200",
    imageHeight: "h_200",
    width: "w-8",
    height: "h-8",
  },
  md: {
    imageWidth: "w_250",
    imageHeight: "h_250",
    width: "w-10",
    height: "h-10",
  },
  lg: {
    imageWidth: "w_300",
    imageHeight: "h_300",
    width: "w-16",
    height: "h-16",
  },
  xl: {
    imageWidth: "w_300",
    imageHeight: "h_300",
    width: "w-24",
    height: "h-24",
  },
};

export function CircularAvatar({
  file,
  size,
  color,
}: {
  file?: File;
  color?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}) {
  const s = size ? sizeMap[size] : sizeMap["md"];

  return (
    <>
      {file ? (
        <img
          style={{ borderColor: color }}
          src={manipulate_image_file(file, [
            "c_thumb",
            "g_face",
            s.imageWidth,
            s.imageHeight,
          ])}
          className={`inline-block ${s.height} ${s.width} rounded-full border`}
        />
      ) : (
        <div
          style={{ borderColor: color, backgroundColor: color }}
          className={`inline-block ${s.height} ${s.width} overflow-hidden border rounded-full`}
        >
          <svg
            className={`${s.height} ${s.width} text-white`}
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>
      )}
    </>
  );
}
