import {
  Badge,
  Font,
  List,
  Paginator,
  Td,
  TextButton,
} from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { FullSessionInDb, SessionStatus } from "../../../../api/staff";
import { useAppApi } from "../../../../api/staff/AppApi";
import { FullScreenSessionModal } from "./FullScreenSessionModal/FullScreenSessionModal";
import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useLocale } from "../../../../lib/locale";
import { Input } from "../../../../Tailwind/Input/Uncontrolled";

export function SessionsList() {
  const [visible, setVisible] = useState(false);
  const [sessionId, setSessionId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FullSessionInDb[]>([]);
  const [term, setTerm] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextOffset, setNextOffset] = useState<number>(0);

  const api = useAppApi();
  const { t } = useTranslation("application");
  const locale = useLocale();

  useEffect(() => {
    loadData();
  }, [term, offset]);

  useEffect(() => {
    setOffset(0);
  }, [term]);

  function loadData() {
    setLoading(true);
    api.misc
      .searchSessionsSearchSessionsGet(
        true,
        true,
        true,
        false,
        true,
        term,
        offset
      )
      .then((result) => {
        setData(result.results);
        setTotal(result.total);
        setNextOffset(result.offset);
        setPageSize(result.page_size);
        setLoading(false);
      });
  }

  function getStatusBadge(status: SessionStatus) {
    switch (status) {
      case SessionStatus.DRAFT:
        return (
          <Badge color={"yellow"} label={t("sessionsList.badge.draft.text")} />
        );
      case SessionStatus.SCHEDULED:
        return (
          <Badge
            color={"blue"}
            label={t("sessionsList.badge.scheduled.text")}
          />
        );
      case SessionStatus.CANCELED:
        return (
          <Badge color={"red"} label={t("sessionsList.badge.canceled.text")} />
        );
      case SessionStatus.COMPLETED:
        return (
          <Badge
            color={"green"}
            label={t("sessionsList.badge.completed.text")}
          />
        );
    }
  }

  return (
    <>
      <List
        title={t("sessionsList.list.title")}
        subtitle={t("sessionsList.list.subtitle")}
        data={data}
        renderLine={(item) => (
          <>
            <Td first>
              {item.start ? (
                <div className={"flex flex-col"}>
                  <Font weight="semibold">
                    {locale.localeDateTime(new Date(item.start))}
                  </Font>
                  <Font weight={"normal"} size={"xs"} color="gray500">
                    {item.user_role?.user.name}
                  </Font>
                </div>
              ) : (
                <div>-</div>
              )}
            </Td>
            <Td>{getStatusBadge(item.status)}</Td>
            <Td>
              {item.client ? (
                <div>
                  <div>{item.client.name}</div>
                  <div className="flex gap-2">
                    <Font weight={"normal"} size={"xs"} color="gray500">
                      {item.client.email}
                    </Font>
                    <Font weight={"normal"} size={"xs"} color="gray500">
                      {item.client.phone_number}
                    </Font>
                  </div>
                </div>
              ) : (
                <div>{t("sessionList.td.client.emptyState")}</div>
              )}
            </Td>
            <Td>
              <div className={"flex flex-col"}>
                {item.services.map((s, i) => (
                  <div key={i}>
                    <Font size={"xs"}>{s.name}</Font>
                  </div>
                ))}
              </div>
            </Td>
            <Td last>
              <TextButton
                onClick={() => {
                  setSessionId(item.id);
                  setVisible(true);
                }}
              >
                <div className="pl-4">
                  <ArrowTopRightOnSquareIcon width={24} />
                </div>
              </TextButton>
            </Td>
          </>
        )}
        headers={[
          t("sessionsList.list.header.date"),
          t("sessionsList.list.header.status"),
          t("sessionsList.list.header.client"),
          t("sessionsList.list.header.services"),
          "",
        ]}
        searchWidget={
          <Input
            leftDecoration={
              <MagnifyingGlassIcon
                className="mx-auto h-5 w-5 text-gray-300"
                aria-hidden="true"
              />
            }
            className={"max-w-30"}
            name={t("productsList.input.search")}
            onChange={(v) => {
              setTerm(v.target.value);
            }}
            value={term}
          />
        }
        actions={[]}
      />
      <Paginator
        nextOffset={nextOffset}
        page_size={pageSize}
        total={total}
        onNext={(newOffset) => {
          setOffset(newOffset);
        }}
        onPrevious={(newOffset) => {
          setOffset(newOffset);
        }}
      />
      <FullScreenSessionModal
        isOpen={visible}
        onClose={() => {
          setVisible(false);
          setSessionId(undefined);
          loadData();
        }}
        sessionId={sessionId}
      />
    </>
  );
}
