import React, { useEffect, useState } from "react";
import { UserRole, UserRoles } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { Badge, CircularAvatar, Font, Button, TextButton, Td } from "Tailwind";
import { UserRoleSlideOver } from "./UserRoleSlideOver";
import { List } from "Tailwind/List";
import { useTranslation } from "react-i18next";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

export function UsersList() {
  const [visible, setVisible] = useState(false);
  const [inviteVisible, setInviteVisible] = useState(false);
  const [editUserRole, setEditUserRole] = useState<UserRole | undefined>();
  const [data, setData] = useState<UserRole[]>([]);
  const api = useAppApi();

  const { t } = useTranslation("application");

  function getRoleBadge(role: UserRoles) {
    switch (role) {
      case UserRoles.EMPLOYEE:
        return <Badge color={"green"} label={t("userLists.role.employee")} />;
      case UserRoles.MANAGER:
        return <Badge color={"purple"} label={t("userLists.role.manager")} />;
      case UserRoles.OWNER:
        return <Badge color={"indigo"} label={t("userLists.role.owner")} />;
    }
  }

  useEffect(() => {
    if (editUserRole !== undefined) {
      setVisible(true);
    }
  }, [editUserRole]);

  useEffect(() => {
    api.users.getAccountUsersAccountUsersGet().then((s) => {
      setData(s.my_roles);
    });
  }, []);

  return (
    <>
      <List
        title={t("userList.list.title")}
        subtitle={t("userList.list.subtitle")}
        data={data}
        renderLine={(u: UserRole) => {
          return (
            <>
              <Td first>
                <div className="flex items-center">
                  <div className="min-w-[70px]">
                    <CircularAvatar file={u.avatar} color={u.color} />
                  </div>
                  <div>
                    <div className="font-medium text-gray-900">
                      {u.user.name}
                    </div>
                    <Font size="xs" color="gray500">
                      {u.title?.slice(0, 200)}
                    </Font>
                  </div>
                </div>
              </Td>
              <Td>{u.description}</Td>
              <Td>{u.user.email}</Td>
              <Td>{u.user.phone_number}</Td>
              <Td>
                <div className="flex flex-col space-y-1">
                  {!u.accepted_invitation && (
                    <Badge
                      color={"green"}
                      label={t("userList.badge.invited.label")}
                    />
                  )}
                  {getRoleBadge(u.role)}
                </div>
              </Td>

              <Td last>
                <div className="flex justify-end">
                  <TextButton
                    onClick={() => {
                      setEditUserRole(u);
                    }}
                  >
                    <div className={"p-2"}>
                      <PencilSquareIcon width={24} />
                    </div>
                  </TextButton>
                  {!u.accepted_invitation && (
                    <TextButton
                      onClick={() => {
                        api.users
                          .deleteUserInviteAccountInviteUserDelete(
                            u.user.email,
                            u.role
                          )
                          .then(() => {
                            api.users
                              .getAccountUsersAccountUsersGet()
                              .then((s) => {
                                setData(s.my_roles);
                              });
                          });
                      }}
                    >
                      <div className={"p-2"}>
                        {t("userList.multiActionButton.deleteInvitation.label")}
                      </div>
                    </TextButton>
                  )}
                </div>
              </Td>
            </>
          );
        }}
        headers={[
          t("userList.list.headers.name"),
          t("userList.list.headers.description"),
          t("userList.list.headers.email"),
          t("userList.list.headers.phone"),
          t("userList.list.headers.roll"),
          "",
        ]}
        actions={[
          <Button
            onClick={() => {
              setInviteVisible(true);
            }}
          >
            {t("userList.button.invite")}
          </Button>,
        ]}
      />

      <UserRoleSlideOver
        isOpen={inviteVisible || visible}
        userRole={editUserRole}
        onClose={() => {
          setInviteVisible(false);
          setVisible(false);
          setEditUserRole(undefined);
        }}
        onComplete={() => {
          setInviteVisible(false);
          setVisible(false);
          setEditUserRole(undefined);
          api.users.getAccountUsersAccountUsersGet().then((u) => {
            setData(u.my_roles);
            setEditUserRole(undefined);
          });
        }}
      />
    </>
  );
}
