import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";

import { Button, LoadingOverlay, TextButton } from "Tailwind";
import { AuthLayout, VideoBackground } from "./AuthLayout";
import { Input } from "Tailwind/Input/Uncontrolled";
import { useAppApi } from "api/staff/AppApi";
import { isValidEmail } from "../../../lib/utils";
import { Professions } from "../../../api/staff";
import { LoginWithPasswordModal } from "./LoginWithPasswordModal";
import { LoginModal } from "./LoginModal";
import { CreateUserModal } from "./CreateUserModal";
import { useNavigate } from "react-router-dom";

export type SignInStep =
  | "CollectEmail"
  | "UseManualPassword"
  | "Login"
  | "CreateUser"
  | "Authenticated";
export function SignInSignUp() {
  const { t } = useTranslation("application");

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<SignInStep>("CollectEmail");
  const [emailValidationError, setEmailValidationError] = useState<
    string | undefined
  >();
  const { users, auth, userProfile } = useAppApi();
  const [email, setEmail] = useState("");
  const [video, setVideo] = useState<VideoBackground>("Ink");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    auth.refresh().finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (emailValidationError && isValidEmail(email)) {
      setEmailValidationError(undefined);
    }
  }, [email]);

  useEffect(() => {
    switch (step) {
      case "CollectEmail":
        if (video !== "Ink") {
          setVideo("Ink");
        }
        break;
    }
  }, [step]);

  function getOpt() {
    if (isValidEmail(email)) {
      setLoading(true);
      users
        .optOtpEmailGet(email)
        .then((r) => {
          if (r.user_exists) {
            setStep("Login");
          } else {
            setStep("CreateUser");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEmailValidationError(t("signInSignUp.input.email.error")!);
    }
  }

  return (
    <>
      <LoadingOverlay loading={loading} size="xs" />
      <AuthLayout title={t("signInSignUp.title")} video={video}>
        <Input
          dark
          name={"email"}
          value={email}
          disabled={step !== "CollectEmail"}
          placeholder={t("signInSignUp.input.email.placeholder")}
          onChange={(event) => setEmail(event.target.value)}
          description={
            step === "CollectEmail"
              ? t("signInSignUp.input.email.description")
              : ""
          }
          label={t("signInSignUp.input.email.label")}
          type="email"
          error={emailValidationError}
          autoComplete="email"
        />

        {step === "CollectEmail" && (
          <>
            <Button
              dark
              full
              className="flex w-full justify-center"
              onClick={() => {
                getOpt();
              }}
            >
              {t("signInSignUp.button.getOpt")}
            </Button>
            <div className="flex justify-end">
              <TextButton
                color="indigo400"
                size="xs"
                onClick={() => {
                  setStep("UseManualPassword");
                }}
              >
                {t("signInSignUp.button.usePassword")}{" "}
                <span aria-hidden="true">→</span>
              </TextButton>
            </div>
          </>
        )}
      </AuthLayout>
      <LoginModal
        email={email}
        step={step}
        visible={step === "Login"}
        setVisible={() => {
          setStep("CollectEmail");
          setEmail("");
        }}
      />
      <LoginWithPasswordModal
        email={email}
        step={step}
        visible={step === "UseManualPassword"}
        setVisible={() => {
          setStep("CollectEmail");
          setEmail("");
        }}
      />
      <CreateUserModal
        email={email}
        step={step}
        onProfessionChanged={(profession) => {
          switch (profession) {
            case Professions.OTHER:
              setVideo("Ink");
              break;
            case Professions.TATTOO_ARTIST:
              setVideo("TattooArtist");
              break;
            case Professions.BARBER:
              setVideo("Barber");
              break;
            case Professions.SKINCARE_SPECIALIST:
              setVideo("SkincareSpecialist");
              break;
            case Professions.MASSAGE_THERAPIST:
              setVideo("MassageTherapist");
              break;
            case Professions.HAIRDRESSER:
              setVideo("Hairdresser");
              break;
            case Professions.BODY_PIERCER:
              setVideo("BodyPiercer");
              break;
            case Professions.BEAUTICIAN:
              setVideo("Beautician");
              break;
            case Professions.MANICURIST:
              setVideo("Manicurist");
              break;
          }
        }}
        visible={step === "CreateUser"}
        setVisible={() => {
          setStep("CollectEmail");
          setEmail("");
        }}
      />
    </>
  );
}
