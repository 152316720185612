import React, { useState } from "react";
import { FullSessionInDb, SessionStatus } from "../../../../../api/staff";
import {
  Font,
  HeadingWithActions,
  SlideOver,
  TextButton,
} from "../../../../../Tailwind";
import { useTranslation } from "react-i18next";
import {
  ArrowsRightLeftIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { ClientSlideOver } from "../../Clients/ClientSlideOver";
import { useAppApi } from "../../../../../api/staff/AppApi";
import { ClientPicker } from "../../Clients/ClientPicker";
import { AddClientModal } from "../CreateBookingSlideOver/AddClientModal";

export function ClientSection({
  session,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onSessionUpdated: (updatedSession: FullSessionInDb) => void;
}) {
  const { t } = useTranslation("application");

  const [visible, setVisible] = useState(false);
  const [clientPickerVisible, setClientPickerVisible] = useState(false);
  const api = useAppApi();

  function updateSession() {
    api.misc.getSessionSessionsSessionIdGet(session.id).then((s) => {
      onSessionUpdated(s);
    });
  }

  function changeClient(clientId: number) {
    api.misc
      .addClientSessionsSessionIdClientClientIdPost(session.id, clientId)
      .then((s) => {
        onSessionUpdated(s);
      });
  }

  return (
    <>
      <div>
        <HeadingWithActions
          title={t("clientSection.heading.title")}
          actions={[
            session.status === SessionStatus.SCHEDULED && (
              <TextButton
                onClick={() => {
                  setClientPickerVisible(true);
                }}
              >
                <ArrowsRightLeftIcon className="h-6 w-6" />
              </TextButton>
            ),
          ]}
        />
        <div className="flex flex-row justify-between items-center">
          {session.client && (
            <>
              <div className="flex flex-col">
                <Font size="xl">{session.client?.name}</Font>
                <div className="flex flex-row gap-2 ">
                  <Font size={"sm"} color="gray500">
                    {session.client?.email}
                  </Font>
                  <Font size={"sm"} color="gray500">
                    {session.client?.phone_number}
                  </Font>
                </div>
              </div>
              <div>
                <TextButton
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <div className="pl-4">
                    <ArrowTopRightOnSquareIcon width={24} />
                  </div>
                </TextButton>
                <ClientSlideOver
                  visible={visible}
                  session={session}
                  client={session.client}
                  onClientSaved={() => {
                    updateSession();
                    setVisible(false);
                  }}
                  onCancel={() => {
                    setVisible(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <AddClientModal
        isOpen={clientPickerVisible}
        onClose={() => {
          setClientPickerVisible(false);
        }}
        onAddClient={(c) => {
          changeClient(c.id);
          setClientPickerVisible(false);
        }}
      />
    </>
  );
}
