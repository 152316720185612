import { TextButton } from "../../../Tailwind";
import { Link } from "react-router-dom";
import { UserRoles } from "../../../api/staff";
import {
  ChevronRightIcon,
  CogIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useAppApi } from "../../../api/staff/AppApi";
import { Navigation, Page } from "./Layout";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function SidebarCompact({
  navigation,
  administration,
  active,
  useFull,
}: {
  navigation: Navigation[];
  administration: Navigation[];
  active: Page;
  useFull: () => void;
}) {
  const api = useAppApi();
  const { t } = useTranslation("application");

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-4 pb-4 w-18 scroll">
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li className="mb-6">
            <ul role="list" className="space-y-1">
              <li className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold mb-8 mt-3">
                <TextButton onClick={useFull}>
                  <ChevronRightIcon
                    width={24}
                    className="bg-gradient-to-r from-indigo-400 to-emerald-400 text-white rounded-full"
                  />
                </TextButton>
              </li>
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.name === active
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === active
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {(api.userProfile?.current_role?.role === UserRoles.OWNER ||
            api.userProfile?.current_role?.role === UserRoles.MANAGER) && (
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400"></div>
              <ul role="list" className="mt-2 space-y-1">
                {administration.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.name === active
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.name === active
                            ? "text-indigo-600"
                            : "text-gray-400 group-hover:text-indigo-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
          <li className="mt-auto">
            <Link
              to="/profile/user"
              className={classNames(
                active === "profile"
                  ? "bg-gray-50 text-indigo-600"
                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold mb-2"
              )}
            >
              <UserIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                aria-hidden="true"
              />
            </Link>
            {(api.userProfile?.current_role?.role === UserRoles.OWNER ||
              api.userProfile?.current_role?.role === UserRoles.MANAGER) && (
              <Link
                to="/settings/website"
                className={classNames(
                  active === "settings"
                    ? "bg-gray-50 text-indigo-600"
                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                )}
              >
                <CogIcon
                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}
