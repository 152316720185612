import React, { useState } from "react";
import { Client, FullSessionInDb } from "../../../../../api/staff";
import {
  Activity,
  Feed,
  HeadingWithActions,
  Modal,
  TextButton,
} from "Tailwind";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ClientNoteModal } from "../../Clients/ClientNoteModal";

export function ClientNotesSection({
  client,
  onClientUpdated,
}: {
  client?: Client;
  onClientUpdated: () => void;
}) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation("application");

  return (
    <>
      {client && (
        <div>
          <div>
            <HeadingWithActions
              title={t("clientNotesSection.heading.title")}
              actions={[
                <TextButton
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <PlusIcon className="w-6 h-6" />
                </TextButton>,
              ]}
            />

            <Feed>
              {client.notes?.map((note, index) => {
                if (client.notes) {
                  const first = index === client.notes?.length - 1;
                  return <Activity first={first} item={note} index={index} />;
                } else {
                  return <div />;
                }
              })}
            </Feed>
          </div>

          <Modal
            size={"2xl"}
            visible={visible}
            setVisible={() => {
              setVisible(false);
            }}
          >
            <ClientNoteModal
              client={client}
              onClientUpdated={() => {
                setVisible(false);
                onClientUpdated();
              }}
              onCancel={() => {
                setVisible(false);
              }}
            />
          </Modal>
        </div>
      )}
    </>
  );
}
