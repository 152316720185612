import { PropsWithChildren } from "react";
import { textReducedColors } from "../colors";

export const size = {
  xs: "text-xs",
  sm: "text-sm",
  base: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  xl2: "text-2xl",
  xl3: "text-3xl",
  xl4: "text-4xl",
  xl5: "text-5xl",
};

const weights = {
  thin: "font-thin",
  extralight: "font-extralight",
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
  black: "font-black",
};

interface TextProps {
  size?: keyof typeof size;
  color?: keyof typeof textReducedColors;
  weight?: keyof typeof weights;
  className?: string;
}

function getProps(props: TextProps): string {
  const sizeClass = props.size ? size[props.size] : size["sm"];
  const weightClass = props.weight ? weights[props.weight] : weights["normal"];
  const colorClass = props.color
    ? textReducedColors[props.color]
    : "text-inherit";
  return `${sizeClass} ${colorClass} ${weightClass} ${props.className}`;
}

export function Font(props: PropsWithChildren<TextProps>) {
  return <div className={getProps(props)}>{props.children}</div>;
}
