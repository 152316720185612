import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Button } from "Tailwind/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NativeSelect } from "Tailwind/Input/Uncontrolled";
import { UserRole } from "api/staff";
import { Bars3Icon } from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function BusinessHoursHeader(props: {
  title: string;
  onCreateTimeslot: () => void;
  currentUserRoleId: number;
  userRoles?: UserRole[];
  onUserRoleChanged: (roleId: number) => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("application");

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4">
      <h1 className="text-base font-semibold leading-6 text-gray-900">
        {props.title}
      </h1>
      <div className="flex items-center">
        <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <div
            className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
            aria-hidden="true"
          />
        </div>
        <div className="hidden md:ml-4 md:flex space-x-4 md:items-center">
          {props.userRoles !== undefined && (
            <NativeSelect
              name={"users"}
              value={props.currentUserRoleId}
              items={props.userRoles.map((u) => {
                return { value: u.id, label: u.user.name! };
              })}
              onChange={(v) => {
                props.onUserRoleChanged(parseInt(v, 10));
              }}
            />
          )}
          <Button
            onClick={() => {
              props.onCreateTimeslot();
            }}
          >
            {t("businessHoursHeader.button.add")}
          </Button>
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">
              {t("businessHoursHeader.button.openMenu")}
            </span>
            <Bars3Icon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => {
                        props.onCreateTimeslot();
                      }}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {t("businessHoursHeader.menuItem.add")}
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}
