import { ModalProps } from "../../../lib/props";
import { SignInStep } from "./SignInSignUp";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../api/staff/AppApi";
import { useFeedback } from "../../../lib/useFeedback";
import { useForm } from "react-hook-form";
import { Professions, UserCreate } from "../../../api/staff";
import {
  Input,
  NativeSelect,
  OneTimeCodeInput,
  Toggle,
} from "../../../Tailwind/Input/Controlled";
import {
  A,
  Button,
  LoadingOverlay,
  Modal,
  TextButton,
} from "../../../Tailwind";
import { useLocale } from "../../../lib/locale";
import { use } from "i18next";
import { useNavigate } from "react-router-dom";

interface CreateUserModalProps extends ModalProps {
  step: SignInStep;
  email: string;
  onProfessionChanged: (profession: Professions) => void;
}

export function CreateUserModal(props: CreateUserModalProps) {
  const { t } = useTranslation("application");
  const api = useAppApi();
  const [otpError, setOtpError] = useState("");
  const [acceptError, setAcceptError] = useState("");
  const feedback = useFeedback();
  const [loading, setLoading] = useState(false);
  const locale = useLocale();
  const [canSendCode, setCanSendCode] = useState(true);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserCreate>({ defaultValues: { email: props.email } });

  useEffect(() => {
    reset({
      email: props.email,
      permission_given: false,
      accepted_terms: false,
      profession: Professions.OTHER,
    });
  }, [props.email]);

  function submit(data: UserCreate) {
    if (!data.otp || data.otp.length < 6) {
      setOtpError(t("createUserModal.error.otp")!);
      return;
    }
    setOtpError("");
    if (!data.accepted_terms) {
      setAcceptError(t("createUserModal.error.acceptedTerms")!);
      return;
    }
    setAcceptError("");
    setLoading(true);
    api.users
      .createUserUsersPost(data)
      .then((userProfile) => {
        api.context.setUserProfile(userProfile);
        navigate("/onboarding");
      })
      .catch((err) => feedback.handleErrors(err))
      .finally(() => setLoading(false));
  }

  return (
    <Modal visible={props.visible} size={"md"}>
      <LoadingOverlay loading={loading} />
      <div className="space-y-6">
        <NativeSelect
          name={"profession"}
          control={control}
          label={t("createUserModal.input.profession.label")}
          description={t("createUserModal.input.profession.description")}
          items={locale.professions}
          onChange={(profession: string) => {
            props.onProfessionChanged(profession as Professions);
          }}
        />
        <Input
          register={register("email")}
          disabled
          errors={errors}
          label={t("createUserModal.input.email.label")}
          description={t("createUserModal.input.email.description")}
        />
        <Input
          register={register("name", {
            required: t("createUserModal.error.nameRequired")!,
          })}
          errors={errors}
          label={t("createUserModal.input.name.label")}
          description={t("createUserModal.input.name.description")}
        />

        <Toggle
          error={acceptError}
          label={t("acceptInvitation.toggle.accepted_terms.label")}
          control={control}
          description={
            <div className="text-xs">
              {t("acceptInvitation.text.readTerms")}{" "}
              <A
                className="text-xs"
                target="blank"
                href="https://www.parlorkit.com/terms"
              >
                {t("acceptInvitation.text.termsLink")}
              </A>
            </div>
          }
          name={"accepted_terms"}
        />
        <Toggle
          label={t("acceptInvitation.toggle.permission_given.label")}
          control={control}
          name={"permission_given"}
        />
        <OneTimeCodeInput
          label={t("createUserModal.input.otp.label")}
          description={t("createUserModal.input.otp.description", {
            email: props.email,
          })}
          error={otpError}
          name="otp"
          control={control}
        />
        <Button onClick={handleSubmit(submit)} full>
          {t("createUserModal.button.create")}
        </Button>
        <div className="flex justify-between ">
          <TextButton
            size="xs"
            onClick={() => {
              props.setVisible && props.setVisible(false);
            }}
          >
            <span aria-hidden="true">←</span>{" "}
            {t("createUserModal.button.useDifferentEmail")}
          </TextButton>
          <TextButton
            size="xs"
            disabled={!canSendCode}
            onClick={() => {
              setLoading(true);
              api.users.optOtpEmailGet(props.email).then(() => {
                setCanSendCode(false);
                setTimeout(() => {
                  setCanSendCode(true);
                }, 30000);
                feedback.showSuccessToast(t("createUserModal.toast.success"));
                setLoading(false);
              });
            }}
          >
            {t("createUserModal.button.sendCodeAgain")}
          </TextButton>
        </div>
      </div>
    </Modal>
  );
}
