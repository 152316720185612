import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";

export function MultiActionButton(
  props: PropsWithChildren<{
    dark?: boolean;
    actions: { label: string; action: () => void; icon?: ReactNode }[];
  }>
) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={
          props.dark
            ? "i-m-2.5 block bg-transparent rounded-md text-white"
            : "i-m-2.5 block bg-white rounded-md border border-indigo-500 text-gray-500 hover:text-gray-900"
        }
      >
        {props.children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            props.dark
              ? "z-50 absolute right-5 mt-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              : "z-50 absolute right-5 mt-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          }
        >
          {props.actions.map((action) => (
            <Menu.Item>
              {({ active: boolean }) => (
                <button
                  onClick={action.action}
                  className={
                    props.dark
                      ? `group flex w-full items-center px-2 py-2 text-white text-sm`
                      : `group flex w-full items-center px-2 py-2 text-sm bg-white`
                  }
                >
                  {action.icon}
                  {action.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
