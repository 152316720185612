import { useAppApi } from "api/staff/AppApi";
import React, { useEffect, useState } from "react";
import { File, Webshop } from "api/staff";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input, Phone, Toggle } from "Tailwind/Input/Controlled";
import { FormSection, Button, Logo, Modal, TextButton, Link } from "Tailwind";
import { Gallery } from "../../Gallery/Gallery";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon } from "@heroicons/react/24/outline";

export function WebsiteForm(props: { onSave: () => void }) {
  const api = useAppApi();
  const [webshop, setWebshop] = useState<Webshop>();
  const [showAlbum, setShowAlbum] = useState(false);
  const [newImage, setNewImage] = useState<File | undefined>();
  const { t } = useTranslation("application");

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<Webshop>({ defaultValues: { contact_phone: "" } });

  useEffect(() => {
    getWebshop();
  }, []);

  function getWebshop() {
    api.misc.getWebshopSettingsWebshopGeneralGet().then((b) => {
      reset(b);
      setWebshop(b);
    });
  }

  return (
    <>
      <form
        className="grid grid-cols-1 gap-x-6 gap-y-8 grid-cols-1 sm:grid-cols-6"
        onSubmit={handleSubmit((data) => {
          api.misc.updateWebshopSettingsWebshopGeneralPut(data).then(() => {
            if (newImage !== undefined) {
              api.misc
                .logoFromGallerySettingsWebshopLogoFromGalleryPost(newImage.id)
                .then((w) => {
                  props.onSave();
                });
            } else {
              props.onSave();
            }
          });
        })}
      >
        <Input
          className="col-span-1 sm:col-span-3"
          register={register("title")}
          errors={errors}
          label={t("websiteForm.input.title.label")}
        />
        <Input
          className="col-span-1 sm:col-span-3"
          register={register("company_name")}
          errors={errors}
          label={t("websiteForm.input.company_name.label")}
        />
        <Input
          className="col-span-1 sm:col-span-3"
          register={register("address")}
          errors={errors}
          label={t("websiteForm.input.address.label")}
        />
        <Phone
          className="col-span-1 sm:col-span-3 w-full"
          name="contact_phone"
          control={control}
          label={t("websiteForm.input.contact_phone.label")}
        />
        <Input
          className="col-span-1 sm:col-span-3"
          register={register("contact_email")}
          errors={errors}
          label={t("websiteForm.input.contact_email.label")}
        />
        <Input
          className="col-span-1 sm:col-span-3"
          register={register("url")}
          disabled={true}
          errors={errors}
          description={
            <div className="flex gap-2">
              <GlobeAltIcon className="w-6 h-6 text-gray-400" />
              <TextButton
                onClick={() => {
                  window.open(`https://${webshop?.url}.parlorkit.com/`);
                }}
              >
                {`${webshop?.url}.parlorkit.com`}
              </TextButton>
            </div>
          }
          label={t("websiteForm.input.url.label")}
        />

        <div className="sm:col-span-2" />
        <div className="col-span-full">
          <FormSection
            title={t("websiteForm.formSection.logo.title")}
            subtitle={t("websiteForm.formSection.logo.subtitle")}
          />
          <div className="mt-2">
            <Button
              color="secondary"
              onClick={(event) => {
                event.preventDefault();
                setShowAlbum(true);
              }}
            >
              {t("websiteForm.button.openAlbum")}
            </Button>
          </div>
        </div>
        <div className="col-span-full flex flex-row justify-items-start gap-x-4">
          {newImage ? (
            <Logo file={newImage} />
          ) : (
            webshop?.logo && <Logo file={webshop.logo} />
          )}
        </div>
        <div className="col-span-full flex justify-end">
          <Button type="submit">{t("websiteForm.button.save")}</Button>
        </div>
      </form>
      <Modal
        visible={showAlbum}
        setVisible={() => {
          setShowAlbum(false);
        }}
      >
        <Gallery
          onImageClicked={(image) => {
            setNewImage(image);
            setShowAlbum(false);
          }}
        />
        <div className="mt-8 flex justify-end">
          <Button
            onClick={() => {
              setShowAlbum(false);
            }}
          >
            {t("websiteForm.button.cancel")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
