import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppApi } from "../../api/staff/AppApi";
import { LoadingOverlay } from "Tailwind";
import { ApiPage } from "./ApiPage";

export function ProtectedPage(props: PropsWithChildren<{ account?: boolean }>) {
  return (
    <ApiPage>
      {/*<AuthChecker account={props.account} />*/}
      <div className={"h-full -z-20"}>{props.children}</div>
    </ApiPage>
  );
}

function AuthChecker(props: PropsWithChildren<{ account?: boolean }>) {
  const { auth, userProfile } = useAppApi();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    auth.refresh().then((res) => {
      if (!res?.user) {
        navigate("/signin");
      }
      if (props.account && !res?.current_role) {
        navigate("/select-account");
      }
      setLoading(false);
    });
  }, []);

  return loading ? <LoadingOverlay loading={loading} /> : <></>;
}
