import React, { ReactNode } from "react";
import { Font } from "../Font/Font";

export function FormSection({
  title,
  subtitle,
  actions,
  dark,
}: {
  dark?: boolean;
  title?: string | null;
  subtitle?: string | null;
  actions?: ReactNode[];
}) {
  return (
    <>
      <div className="flex flex-col mt-4">
        <div>
          <div className="flex justify-between">
            <h1 className="leading-6 flex-grow">
              <Font
                size={"base"}
                weight={"semibold"}
                color={dark ? "white" : "gray"}
              >
                {title}
              </Font>
            </h1>

            {actions && (
              <div className="sm:ml-16 sm:mt-0 flex flex-col sm:flex-row gap-2">
                {actions.map((i, index) => (
                  <div className="flex-row justify-center" key={index}>
                    {i}
                  </div>
                ))}
              </div>
            )}
          </div>
          {subtitle && (
            <div className="mt-2">
              <Font size={"sm"} color={dark ? "gray300" : "gray500"}>
                {subtitle}
              </Font>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
