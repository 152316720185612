import { useContext } from "react";
import { AppContext } from "./AppContext";
import { UsersService } from "./services/UsersService";
import { UserProfile } from "./models/UserProfile";

export class AppAuth {
  private context = useContext(AppContext);

  public async signIn(
    username: string,
    password: string,
    channel: "password" | "sms" | "email"
  ) {
    return UsersService.loginTokenPost(
      { username: username, password: password },
      channel
    ).then((r) => {
      return this.refresh().then((userProfile) => {
        window.location.replace("/");
      });
    });
  }

  public async logout() {
    UsersService.logoutLogoutDelete().then(() => {
      window.localStorage.removeItem("USERPROFILE");
      this.context.setUserProfile(undefined);
      window.location.replace("/signin");
    });
  }

  public async refresh() {
    return UsersService.whoamiUsersWhoamiGet().then((profile) => {
      this.setCache(profile);
      return profile;
    });
  }

  private async setCache(profile: UserProfile) {
    const userProfile = window.localStorage.getItem("USERPROFILE");
    const stringProfile = JSON.stringify(profile);
    if (!userProfile || userProfile !== stringProfile) {
      window.localStorage.setItem("USERPROFILE", stringProfile);
      this.context.setUserProfile(profile);
    }
  }

  public async switchRole(roleId: number) {
    return UsersService.switchRoleSwitchRoleGet(roleId).then((response) => {
      this.refresh().then((p) => {
        this.context.setUserProfile(p);
        return Promise.resolve(p);
      });
    });
  }
}
