/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_logo_settings_webshop_logo_post } from '../models/Body_logo_settings_webshop_logo_post';
import type { Body_upload_image_services__service_id__images_post } from '../models/Body_upload_image_services__service_id__images_post';
import type { Body_upload_image_sessions__session_id__images_post } from '../models/Body_upload_image_sessions__session_id__images_post';
import type { Booking } from '../models/Booking';
import type { BookingPage } from '../models/BookingPage';
import type { CheckAvailabilityResponse } from '../models/CheckAvailabilityResponse';
import type { CreateSessionBookingData } from '../models/CreateSessionBookingData';
import type { FullSessionInDb } from '../models/FullSessionInDb';
import type { NewNote } from '../models/NewNote';
import type { PaymentInformation } from '../models/PaymentInformation';
import type { PurchaseComplete } from '../models/PurchaseComplete';
import type { RenderedTemplate } from '../models/RenderedTemplate';
import type { SalesDay } from '../models/SalesDay';
import type { SalesRequest } from '../models/SalesRequest';
import type { ScheduleTimeOffRequest } from '../models/ScheduleTimeOffRequest';
import type { SearchResult } from '../models/SearchResult';
import type { SearchServicesResult } from '../models/SearchServicesResult';
import type { SearchSessionsResult } from '../models/SearchSessionsResult';
import type { SearchTemplateResult } from '../models/SearchTemplateResult';
import type { Service } from '../models/Service';
import type { ServiceCreate } from '../models/ServiceCreate';
import type { SessionMin } from '../models/SessionMin';
import type { StripeSession } from '../models/StripeSession';
import type { Template } from '../models/Template';
import type { UpdateEmployee } from '../models/UpdateEmployee';
import type { UpdateSessionProduct } from '../models/UpdateSessionProduct';
import type { UpdateSessionService } from '../models/UpdateSessionService';
import type { UpdateTimeOffRequest } from '../models/UpdateTimeOffRequest';
import type { Webshop } from '../models/Webshop';
import type { WebshopData } from '../models/WebshopData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get Services
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static getServicesServicesGet(): CancelablePromise<Array<Service>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services',
        });
    }

    /**
     * Create Service
     * @param requestBody
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static createServiceServicesPost(
        requestBody: ServiceCreate,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Service
     * @param serviceId
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static getServiceServicesServiceIdGet(
        serviceId: number,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}',
            path: {
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Service
     * @param serviceId
     * @param requestBody
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static updateServiceServicesServiceIdPut(
        serviceId: number,
        requestBody: ServiceCreate,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/services/{service_id}',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Service
     * @param serviceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteServiceServicesServiceIdDelete(
        serviceId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/services/{service_id}',
            path: {
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Image
     * @param serviceId
     * @param formData
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static uploadImageServicesServiceIdImagesPost(
        serviceId: number,
        formData: Body_upload_image_services__service_id__images_post,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}/images',
            path: {
                'service_id': serviceId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Files
     * @param serviceId
     * @param requestBody
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static setFilesServicesServiceIdFilesPost(
        serviceId: number,
        requestBody: Array<number>,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}/files',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Employees
     * @param serviceId
     * @param requestBody
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static setEmployeesServicesServiceIdEmployeesPost(
        serviceId: number,
        requestBody: Array<number>,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}/employees',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Image
     * @param serviceId
     * @param imageId
     * @returns Service Successful Response
     * @throws ApiError
     */
    public static deleteImageServicesServiceIdImagesImageIdDelete(
        serviceId: number,
        imageId: number,
    ): CancelablePromise<Service> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/services/{service_id}/images/{image_id}',
            path: {
                'service_id': serviceId,
                'image_id': imageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Services
     * @param term
     * @param offset
     * @param employeeId
     * @returns SearchServicesResult Successful Response
     * @throws ApiError
     */
    public static searchServicesSearchServicesGet(
        term?: string,
        offset?: number,
        employeeId?: number,
    ): CancelablePromise<SearchServicesResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/services',
            query: {
                'term': term,
                'offset': offset,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Session
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static getSessionSessionsSessionIdGet(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Session
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteSessionSessionsSessionIdDelete(
        sessionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sessions
     * @param start
     * @param end
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static getSessionsSessionsGet(
        start: string,
        end: string,
    ): CancelablePromise<Array<FullSessionInDb>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions',
            query: {
                'start': start,
                'end': end,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Session
     * @param userRoleId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static createSessionSessionsPost(
        userRoleId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions',
            query: {
                'user_role_id': userRoleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Session Receipt
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getSessionReceiptSessionsSessionIdReceiptPdfGet(
        sessionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/{session_id}/receipt.pdf',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Session Receipt
     * @param sessionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendSessionReceiptSessionsSessionIdEmailReceiptGet(
        sessionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions/{session_id}/email_receipt',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Session Booking
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static createSessionBookingSessionsBookPost(
        requestBody: CreateSessionBookingData,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/book',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Sessions
     * @param draft
     * @param completed
     * @param scheduled
     * @param timeOff
     * @param canceled
     * @param term
     * @param offset
     * @returns SearchSessionsResult Successful Response
     * @throws ApiError
     */
    public static searchSessionsSearchSessionsGet(
        draft?: boolean,
        completed?: boolean,
        scheduled?: boolean,
        timeOff?: boolean,
        canceled?: boolean,
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchSessionsResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/sessions',
            query: {
                'draft': draft,
                'completed': completed,
                'scheduled': scheduled,
                'time_off': timeOff,
                'canceled': canceled,
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sessions Bymonth
     * @param month
     * @param year
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sessionsBymonthSessionsBymonthGet(
        month?: number,
        year?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions_bymonth',
            query: {
                'month': month,
                'year': year,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Sessions Agenda
     * @param draft
     * @param completed
     * @param scheduled
     * @param term
     * @param offset
     * @param direction
     * @returns SearchSessionsResult Successful Response
     * @throws ApiError
     */
    public static sessionsAgendaSessionsAgendaGet(
        draft?: boolean,
        completed?: boolean,
        scheduled?: boolean,
        term?: string,
        offset?: number,
        direction: number = 1,
    ): CancelablePromise<SearchSessionsResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sessions_agenda',
            query: {
                'draft': draft,
                'completed': completed,
                'scheduled': scheduled,
                'term': term,
                'offset': offset,
                'direction': direction,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Calendar
     * @param start
     * @param end
     * @param requestBody
     * @returns SessionMin Successful Response
     * @throws ApiError
     */
    public static getCalendarSessionsCalendarPost(
        start: string,
        end: string,
        requestBody: Array<number>,
    ): CancelablePromise<Array<SessionMin>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/calendar',
            query: {
                'start': start,
                'end': end,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Client
     * @param sessionId
     * @param clientId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static addClientSessionsSessionIdClientClientIdPost(
        sessionId: number,
        clientId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/client/{client_id}',
            path: {
                'session_id': sessionId,
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Client
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static addClientSessionsSessionIdClientDelete(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/client',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Pigment
     * @param sessionId
     * @param pigmentId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static addPigmentSessionsSessionIdPigmentPigmentIdPost(
        sessionId: number,
        pigmentId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/pigment/{pigment_id}',
            path: {
                'session_id': sessionId,
                'pigment_id': pigmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Pigment
     * @param sessionId
     * @param pigmentId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static removePigmentSessionsSessionIdPigmentPigmentIdDelete(
        sessionId: number,
        pigmentId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/pigment/{pigment_id}',
            path: {
                'session_id': sessionId,
                'pigment_id': pigmentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Session Service
     * @param sessionId
     * @param serviceId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static addSessionServiceSessionsSessionIdServiceServiceIdPost(
        sessionId: number,
        serviceId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/service/{service_id}',
            path: {
                'session_id': sessionId,
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Session Service
     * @param sessionId
     * @param serviceId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static removeSessionServiceSessionsSessionIdServiceServiceIdDelete(
        sessionId: number,
        serviceId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/service/{service_id}',
            path: {
                'session_id': sessionId,
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Session Product
     * @param sessionId
     * @param productId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static addSessionProductSessionsSessionIdProductProductIdPost(
        sessionId: number,
        productId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/product/{product_id}',
            path: {
                'session_id': sessionId,
                'product_id': productId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Session Product
     * @param sessionId
     * @param productId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static removeSessionProductSessionsSessionIdProductProductIdDelete(
        sessionId: number,
        productId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/product/{product_id}',
            path: {
                'session_id': sessionId,
                'product_id': productId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Session Service
     * @param sessionId
     * @param sessionServiceId
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static updateSessionServiceSessionsSessionIdServiceSessionServiceIdPut(
        sessionId: number,
        sessionServiceId: number,
        requestBody: UpdateSessionService,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/service/{session_service_id}',
            path: {
                'session_id': sessionId,
                'session_service_id': sessionServiceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Session Product
     * @param sessionId
     * @param sessionProductId
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static updateSessionProductSessionsSessionIdProductSessionProductIdPut(
        sessionId: number,
        sessionProductId: number,
        requestBody: UpdateSessionProduct,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/product/{session_product_id}',
            path: {
                'session_id': sessionId,
                'session_product_id': sessionProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * End Session
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static endSessionSessionsSessionIdEndPut(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/end',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Session Draft
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static saveSessionDraftSessionsSessionIdSaveDraftPut(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/save-draft',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Book Session
     * @param sessionId
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static bookSessionSessionsSessionIdBookPut(
        sessionId: number,
        requestBody: Booking,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/book',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Booking
     * @param sessionId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static cancelBookingSessionsSessionIdBookDelete(
        sessionId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/book',
            path: {
                'session_id': sessionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Session Note
     * @param sessionId
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static createSessionNoteSessionsSessionIdNotesPost(
        sessionId: number,
        requestBody: NewNote,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/notes',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Session Note
     * @param sessionId
     * @param noteId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static deleteSessionNoteSessionsSessionIdNotesNoteIdDelete(
        sessionId: number,
        noteId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/notes/{note_id}',
            path: {
                'session_id': sessionId,
                'note_id': noteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Image
     * @param sessionId
     * @param formData
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static uploadImageSessionsSessionIdImagesPost(
        sessionId: number,
        formData: Body_upload_image_sessions__session_id__images_post,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sessions/{session_id}/images',
            path: {
                'session_id': sessionId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Image
     * @param sessionId
     * @param imageId
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static deleteImageSessionsSessionIdImagesImageIdDelete(
        sessionId: number,
        imageId: number,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sessions/{session_id}/images/{image_id}',
            path: {
                'session_id': sessionId,
                'image_id': imageId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Employee
     * @param sessionId
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static updateEmployeeSessionsSessionIdEmployeePut(
        sessionId: any,
        requestBody: UpdateEmployee,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sessions/{session_id}/employee',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Time Off
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static updateTimeOffTimeOffPut(
        requestBody: UpdateTimeOffRequest,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/time-off',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Time Off
     * @param requestBody
     * @returns FullSessionInDb Successful Response
     * @throws ApiError
     */
    public static scheduleTimeOffTimeOffPost(
        requestBody: ScheduleTimeOffRequest,
    ): CancelablePromise<FullSessionInDb> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/time-off',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Time Off
     * @param timeOffId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTimeOffTimeOffTimeOffIdDelete(
        timeOffId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/time-off/{time_off_id}',
            path: {
                'time_off_id': timeOffId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Templates
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static getAllTemplatesTemplatesGet(): CancelablePromise<Array<Template>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/templates',
        });
    }

    /**
     * Create Template
     * @param requestBody
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static createTemplateTemplatesPost(
        requestBody: Template,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Template
     * @param templateId
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static createTemplateTemplatesTemplateIdGet(
        templateId: number,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Template
     * @param templateId
     * @param requestBody
     * @returns Template Successful Response
     * @throws ApiError
     */
    public static createTemplateTemplatesTemplateIdPut(
        templateId: number,
        requestBody: Template,
    ): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Templates
     * @param term
     * @param offset
     * @returns SearchTemplateResult Successful Response
     * @throws ApiError
     */
    public static searchTemplatesSearchTemplatesGet(
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchTemplateResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/templates',
            query: {
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Render Template
     * @param templateId
     * @param clientId
     * @param clientSessionId
     * @returns RenderedTemplate Successful Response
     * @throws ApiError
     */
    public static renderTemplateTemplatesRenderPost(
        templateId: number,
        clientId?: number,
        clientSessionId?: number,
    ): CancelablePromise<RenderedTemplate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/templates/render',
            query: {
                'template_id': templateId,
                'client_id': clientId,
                'clientSession_id': clientSessionId,
            },
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Webshop
     * @returns Webshop Successful Response
     * @throws ApiError
     */
    public static getWebshopSettingsWebshopGeneralGet(): CancelablePromise<Webshop> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/webshop/general',
        });
    }

    /**
     * Update Webshop
     * @param requestBody
     * @returns Webshop Successful Response
     * @throws ApiError
     */
    public static updateWebshopSettingsWebshopGeneralPut(
        requestBody: WebshopData,
    ): CancelablePromise<Webshop> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/settings/webshop/general',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logo
     * @param formData
     * @returns Webshop Successful Response
     * @throws ApiError
     */
    public static logoSettingsWebshopLogoPost(
        formData: Body_logo_settings_webshop_logo_post,
    ): CancelablePromise<Webshop> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/webshop/logo',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logo
     * @returns Webshop Successful Response
     * @throws ApiError
     */
    public static logoSettingsWebshopLogoDelete(): CancelablePromise<Webshop> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/settings/webshop/logo',
        });
    }

    /**
     * Logo From Gallery
     * @param fileId
     * @returns Webshop Successful Response
     * @throws ApiError
     */
    public static logoFromGallerySettingsWebshopLogoFromGalleryPost(
        fileId: number,
    ): CancelablePromise<Webshop> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/settings/webshop/logo-from-gallery',
            query: {
                'file_id': fileId,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Booking
     * @returns BookingPage Successful Response
     * @throws ApiError
     */
    public static getBookingSettingsWebshopBookingGet(): CancelablePromise<BookingPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/webshop/booking',
        });
    }

    /**
     * Update Booking
     * @param requestBody
     * @returns BookingPage Successful Response
     * @throws ApiError
     */
    public static updateBookingSettingsWebshopBookingPut(
        requestBody: BookingPage,
    ): CancelablePromise<BookingPage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/settings/webshop/booking',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Availability
     * @param url
     * @returns CheckAvailabilityResponse Successful Response
     * @throws ApiError
     */
    public static checkAvailabilitySettingsWebshopCheckAvailabilityGet(
        url: string,
    ): CancelablePromise<CheckAvailabilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/settings/webshop/check-availability',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Payment Information
     * @returns PaymentInformation Successful Response
     * @throws ApiError
     */
    public static getPaymentInformationPaymentInformationGet(): CancelablePromise<PaymentInformation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/payment-information',
        });
    }

    /**
     * Complete Session And Apply
     * @param requestBody
     * @returns PurchaseComplete Successful Response
     * @throws ApiError
     */
    public static completeSessionAndApplyPaymentsCompletePost(
        requestBody: StripeSession,
    ): CancelablePromise<PurchaseComplete> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payments/complete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Query Product
     * @returns any Successful Response
     * @throws ApiError
     */
    public static queryProductStatsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stats',
        });
    }

    /**
     * Cron Run
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cronRunCronRunPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/cron/run',
        });
    }

    /**
     * Get Sales
     * @param requestBody
     * @returns SalesDay Successful Response
     * @throws ApiError
     */
    public static getSalesSalesPost(
        requestBody: SalesRequest,
    ): CancelablePromise<SalesDay> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sales',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search
     * @param term
     * @param offset
     * @param clientNotes
     * @param sessionNotes
     * @param clients
     * @param basePigments
     * @returns SearchResult Successful Response
     * @throws ApiError
     */
    public static searchSearchPost(
        term: string,
        offset?: number,
        clientNotes: boolean = true,
        sessionNotes: boolean = true,
        clients: boolean = true,
        basePigments: boolean = true,
    ): CancelablePromise<SearchResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/search',
            query: {
                'term': term,
                'offset': offset,
                'client_notes': clientNotes,
                'session_notes': sessionNotes,
                'clients': clients,
                'base_pigments': basePigments,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
