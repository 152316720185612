/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_PIGMENT_CONTROLLER_base_pigment_not_found___PIGMENT_CONTROLLER_base_pigment_not_found____ = {
    detail: PS_Literal__PEM_PIGMENT_CONTROLLER_base_pigment_not_found___PIGMENT_CONTROLLER_base_pigment_not_found____.detail;
};

export namespace PS_Literal__PEM_PIGMENT_CONTROLLER_base_pigment_not_found___PIGMENT_CONTROLLER_base_pigment_not_found____ {

    export enum detail {
        PIGMENT_CONTROLLER_BASE_PIGMENT_NOT_FOUND = 'PIGMENT_CONTROLLER_base_pigment_not_found',
    }


}

