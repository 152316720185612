import { Ink2 } from "../video/backgrounds/Ink2";
import { Settings } from "../lib/settings";
import React from "react";
import { CogIcon, WrenchIcon } from "@heroicons/react/24/outline";

export default function Maintenance() {
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <Ink2 show={true} />
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <div className="flex justify-center w-100">
            <WrenchIcon className="text-indigo-400 h-12 w-12" />
            <img
              className="h-12 sm:h-16 w-auto"
              src={Settings.textLogoDark}
              alt="Parlorkit"
            />
            <CogIcon className="text-transparent h-12 w-12" />
          </div>

          <h1 className="mt-8 text-3xl font-bold tracking-tight text-gray-100 sm:text-5xl">
            There is currently no connection to the server
          </h1>
          <p className="text-base font-semibold text-indigo-500 mt-4">
            We will take you back to where you were when the connection is
            reestablished
          </p>
        </div>
      </main>
    </>
  );
}
