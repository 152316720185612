import { ProtectedPage } from "../../ProtectedPage";
import { Layout } from "../../Layout/Layout";
import { UserRolesPicker } from "../Users/UserRolesPicker";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { SalesDay, UserRole } from "../../../../api/staff";
import { DateTimePicker } from "../../../../Tailwind/Input/Uncontrolled";
import { useAppApi } from "../../../../api/staff/AppApi";
import { List, Td } from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { useLocale } from "../../../../lib/locale";

export function SalesPage() {
  const [employees, setEmployees] = useState<UserRole[]>([]);
  const [day, setDay] = useState(new Date());
  const [sales, setSales] = useState<SalesDay>();
  const api = useAppApi();
  const { t } = useTranslation("application");
  const locale = useLocale();

  useEffect(() => {
    api.misc
      .getSalesSalesPost({
        date: day.toISOString(),
        staff: employees.map((e) => e.id),
      })
      .then((s) => {
        setSales(s);
      });
  }, [day, employees]);

  const formatter = new Intl.NumberFormat("da", {
    style: "currency",
    currency: "DKK",
  });

  return (
    <ProtectedPage>
      <Layout active={"sales"}>
        <div className="flex flex-col gap-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {t("sales.table.title", {
              date: locale.localeDateWithDay(day),
            })}
          </h1>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="sm:mr-4">
              <UserRolesPicker
                onUserRoleChanged={(userRoles) => setEmployees(userRoles)}
                currentUserRoles={employees}
                all
              />
            </div>
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <div
                className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300"
                aria-hidden="true"
              />
              <button
                type="button"
                onClick={() => {
                  setDay(addDays(day, -1));
                }}
                className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">
                  {t("weekCalendarHeader.button.previousWeek")}
                </span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="w-full text-center">
                <DateTimePicker
                  date={day}
                  onChange={(d) => {
                    setDay(d);
                  }}
                  label=""
                  inline
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  setDay(addDays(day, 1));
                }}
                className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">
                  {t("weekCalendarHeader.button.nextWeek")}
                </span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <List
          title=""
          subtitle={""}
          data={sales?.sales || []}
          renderLine={(sale) => (
            <>
              <Td first>{sale.item_name}</Td>
              <Td>{sale.vat}%</Td>
              <Td>{formatter.format(sale.discount)}</Td>
              <Td last>{formatter.format(sale.total)}</Td>
            </>
          )}
          headers={[
            t("sales.table.headers.itemName"),
            t("sales.table.headers.vat"),
            t("sales.table.headers.discount"),
            t("sales.table.headers.price"),
          ]}
          actions={[]}
          footer={
            <>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                >
                  {t("sales.table.headers.total")}
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-3">
                  {sales && formatter.format(sales.total)}
                </td>
              </tr>
            </>
          }
        />
      </Layout>
    </ProtectedPage>
  );
}
