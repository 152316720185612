import { FormSection } from "Tailwind/Form/FormSection";
import { useTranslation } from "react-i18next";
import { ClientCreate, Client } from "api/staff";
import { useAppApi } from "api/staff/AppApi";
import { useForm } from "react-hook-form";
import { Input, Phone } from "Tailwind/Input/Controlled";
import { Button } from "Tailwind/Button";
import { useFeedback } from "../../../../lib/useFeedback";
import { validatePhone } from "../../../../lib/utils";
import { useState } from "react";

export function CreateClientForm(props: {
  onClientCreated: (client: Client) => void;
}) {
  const { t } = useTranslation("application");
  const api = useAppApi();
  const feedback = useFeedback();
  const [phoneError, setPhoneError] = useState<string | undefined>();

  function submit(data: ClientCreate) {
    if (!validatePhone(data.phone_number)) {
      setPhoneError(t("createClientForm.error.invalidPhone")!);
      return;
    } else {
      setPhoneError(undefined);
    }

    api.clients
      .createClientClientsPost(data)
      .then((c) => {
        props.onClientCreated(c);
      })
      .catch((e) => {
        feedback.handleErrors(e);
      });
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ClientCreate>();

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="sm:col-span-6">
        <FormSection
          title={t("createClientForm.formSection.general.title")}
          subtitle={t("createClientForm.formSection.general.subtitle")}
        />
      </div>
      <div className="sm:col-span-6">
        <Input
          label={t("clientCreateForm.input.name.label")}
          register={register("name", {
            required: t("clientCreateForm.error.nameRequired")!,
          })}
          autoCapitalize={"words"}
          errors={errors}
        />
      </div>
      <div className="sm:col-span-3">
        <Input
          label={t("clientCreateForm.input.email.label")}
          register={register("email", {
            required: t("clientCreateForm.error.emailRequired")!,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t("clientCreateForm.error.invalidEmailFormat"),
            },
          })}
          type="email"
          inputMode="email"
          errors={errors}
        />
      </div>
      <div className="sm:col-span-3">
        <Phone
          label={t("clientCreateForm.input.phone_number.label")}
          name="phone_number"
          control={control}
          error={phoneError}
        />
      </div>

      <div className="sm:col-span-full flex justify-end">
        <Button onClick={handleSubmit(submit)}>
          {t("createClientForm.button.create")}
        </Button>
      </div>
    </div>
  );
}
