import React, { useMemo } from "react";
import { FullSessionInDb } from "../../../../../api/staff";
import { Heading } from "Tailwind";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../../../../lib/locale";

export function SummerySection({ session }: { session: FullSessionInDb }) {
  const { t } = useTranslation("application");
  const locale = useLocale();

  const subtotal = useMemo(() => {
    const servicesTotal = session.services.reduce(
      (sum, service) => sum + service.price,
      0
    );
    const productsTotal = session.products.reduce(
      (sum, product) => sum + product.price,
      0
    );
    return servicesTotal + productsTotal;
  }, [session.products, session.services]);

  const vat = useMemo(() => {
    const servicesVat = session.services.reduce(
      (sum, service) => sum + service.vat_amount,
      0
    );
    const productsVat = session.products.reduce(
      (sum, product) => sum + product.vat_amount,
      0
    );
    return servicesVat + productsVat;
  }, [session.products, session.services]);

  const discount = useMemo(() => {
    const servicesDiscount = session.services.reduce(
      (sum, service) => sum + service.discount_amount,
      0
    );
    const productsDiscount = session.products.reduce(
      (sum, product) => sum + product.discount_amount,
      0
    );
    return servicesDiscount + productsDiscount;
  }, [session.products, session.services]);

  const total = useMemo(() => {
    return subtotal - discount;
  }, [subtotal, vat, discount]);

  return (
    <>
      <Heading title={t("summerySection.summery")} description={""} />
      <div>
        <div className="rounded-lg bg-gray-50 px-4 sm:p-6 lg:p-8 mt-6">
          <div className="flow-root">
            <dl className="-my-4 divide-y divide-gray-200 text-sm">
              <div className="flex items-center justify-between py-4">
                <dt className="text-gray-600">
                  {t("invoiceSection.text.subtotal")}
                </dt>
                <dd className="font-medium text-gray-900">
                  {locale.currency.format(subtotal)}
                </dd>
              </div>
              {discount > 0 && (
                <div className="flex items-center justify-between py-4">
                  <dt className="text-gray-600">
                    {t("invoiceSection.text.discount")}
                  </dt>
                  <dd className="font-medium text-gray-900">
                    {locale.currency.format(discount)}
                  </dd>
                </div>
              )}
              <div className="flex items-center justify-between py-4">
                <dt className="text-gray-600">
                  {t("invoiceSection.text.vat")}
                </dt>
                <dd className="font-medium text-gray-900">
                  {locale.currency.format(vat)}
                </dd>
              </div>
              <div className="flex items-center justify-between py-4">
                <dt className="text-base font-medium text-gray-900">
                  {t("invoiceSection.text.total")}
                </dt>
                <dd className="text-base font-medium text-gray-900">
                  {locale.currency.format(total)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
