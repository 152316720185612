import { FullSessionInDb } from "../../../../api/staff";
import { TextArea } from "Tailwind/Input/Uncontrolled";
import { Button, FormSection, Heading } from "Tailwind";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppApi } from "../../../../api/staff/AppApi";

export function SessionNoteModal({
  session,
  onCancel,
  onSessionUpdated,
}: {
  session: FullSessionInDb;
  onCancel: () => void;
  onSessionUpdated: (session: FullSessionInDb) => void;
}) {
  const [note, setNote] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation("application");
  const api = useAppApi();

  function saveNote() {
    setSaving(true);
    api.misc
      .createSessionNoteSessionsSessionIdNotesPost(session.id, { text: note })
      .then((s) => {
        setSaving(false);
        setNote("");
        onSessionUpdated(s);
      });
  }

  return (
    <div>
      <FormSection
        title={t("sessionNoteModal.heading.title")}
        subtitle={t("sessionNoteModal.heading.subtitle")}
      />
      <TextArea
        value={note}
        rows={10}
        name={"note"}
        onChange={(t) => {
          setNote(t);
        }}
      />
      <div className="flex gap-2 justify-end mt-4">
        <Button disabled={note === "" || saving} onClick={saveNote}>
          {t("sessionNoteModal.button.save")}
        </Button>
        <Button color="secondary" onClick={onCancel}>
          {t("sessionNoteModal.button.cancel")}
        </Button>
      </div>
    </div>
  );
}
