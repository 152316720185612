import { Settings } from "../../../lib/settings";
import { Font, TextButton } from "../../../Tailwind";
import { Link } from "react-router-dom";
import { Credit, UserRoles } from "../../../api/staff";
import {
  ChevronLeftIcon,
  CogIcon,
  DevicePhoneMobileIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useAppApi } from "../../../api/staff/AppApi";
import { Navigation, Page } from "./Layout";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function SidebarFull({
  navigation,
  administration,
  active,
  useCompact,
}: {
  navigation: Navigation[];
  administration: Navigation[];
  active: Page;
  useCompact: () => void;
}) {
  const api = useAppApi();
  const { t } = useTranslation("application");
  const [credits, setCredits] = useState<number>(0);

  useEffect(() => {
    api.communication.getSmsCreditsSmsCreditsGet().then((credit) => {
      setCredits(credit.available_credits);
    });
  }, []);

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 lg:w-72">
      <div className="flex h-16 shrink-0 items-center">
        <div className="relative">
          <img
            className="h-12 w-auto "
            src={Settings.textLogoDark}
            alt="Parlorkit"
          />
          {api.plan === "Pro" ? (
            <div className="absolute -right-6 top-2">
              <Font color="amber500" weight="extrabold" size="sm">
                Pro
              </Font>
            </div>
          ) : (
            <div className="absolute -right-9 top-2">
              <Font color="teal500" weight="light" size="sm">
                Basic
              </Font>
            </div>
          )}
        </div>
        <div className="absolute right-1 top-5">
          <TextButton onClick={useCompact}>
            <ChevronLeftIcon width={24} className="text-gray-300" />
          </TextButton>
        </div>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.name === active
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === active
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          {(api.userProfile?.current_role?.role === UserRoles.OWNER ||
            api.userProfile?.current_role?.role === UserRoles.MANAGER) && (
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                {t("layout.navigation.divider", {
                  defaultValue: "Administration",
                })}
              </div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {administration.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.name === active
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.name === active
                            ? "text-indigo-600"
                            : "text-gray-400 group-hover:text-indigo-600",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
          <li className="mt-auto">
            <div className="flex gap-x-3 items-center rounded-md text-sm leading-6 font-semibold mb-2">
              <DevicePhoneMobileIcon
                className="h-6 w-6 shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div className="flex flex-row gap-2 items-center justify-center flex-shrink">
                <div className="text-gray-700">
                  {credits} {t("layout.credits")}
                </div>
                <div>
                  <Link to="/store/sms-packages" className="text-amber-500">
                    {t("layout.button.buy")}
                  </Link>
                </div>
              </div>
            </div>
            <Link
              to="/profile/user"
              className={classNames(
                active === "profile"
                  ? "bg-gray-50 text-indigo-600"
                  : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                "group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold mb-2"
              )}
            >
              <UserIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                aria-hidden="true"
              />
              {t("layout.link.profile")}
            </Link>
            {(api.userProfile?.current_role?.role === UserRoles.OWNER ||
              api.userProfile?.current_role?.role === UserRoles.MANAGER) && (
              <Link
                to="/settings/website"
                className={classNames(
                  active === "settings"
                    ? "bg-gray-50 text-indigo-600"
                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                  "group flex gap-x-3 rounded-md py-2 text-sm leading-6 font-semibold"
                )}
              >
                <CogIcon
                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
                {t("layout.link.settings")}
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}
