/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PS_Literal__PEM_USER_CONTROLLER_max_requests_reached___USER_CONTROLLER_max_requests_reached____ = {
    detail: PS_Literal__PEM_USER_CONTROLLER_max_requests_reached___USER_CONTROLLER_max_requests_reached____.detail;
};

export namespace PS_Literal__PEM_USER_CONTROLLER_max_requests_reached___USER_CONTROLLER_max_requests_reached____ {

    export enum detail {
        USER_CONTROLLER_MAX_REQUESTS_REACHED = 'USER_CONTROLLER_max_requests_reached',
    }


}

