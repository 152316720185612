import { Button, Heading, SlideOver } from "../../../../Tailwind";
import { useTranslation } from "react-i18next";
import { Template } from "../../../../api/staff";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Input, TextArea } from "../../../../Tailwind/Input/Controlled";
import { useAppApi } from "../../../../api/staff/AppApi";

export function TemplateSlideOver(props: {
  template?: Template;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation("application");
  const api = useAppApi();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Template>({
    defaultValues: props.template
      ? props.template
      : {
          title: "",
          content: "",
        },
  });

  useEffect(() => {
    if (props.template) {
      reset(props.template);
    } else {
      reset({
        title: "",
        content: "",
      });
    }
  }, [props.template]);

  function submitData(data: Template) {
    if (props.template && props.template.id) {
      api.misc
        .createTemplateTemplatesTemplateIdPut(props.template.id, data)
        .then((s) => {
          props.onClose();
        });
    } else {
      api.misc.createTemplateTemplatesPost(data).then((template) => {
        props.onClose();
      });
    }
  }

  return (
    <SlideOver
      title={
        props.template
          ? t("templateSlideOver.title.edit")
          : t("templateSlideOver.title.create")
      }
      actions={[
        <Button color="secondary" onClick={() => props.onClose()}>
          {t("templateSlideOver.button.cancel")}
        </Button>,
        <Button type="submit" onClick={handleSubmit(submitData)}>
          {t("templateSlideOver.button.save")}
        </Button>,
      ]}
      isOpen={props.open}
      onClose={props.onClose}
    >
      <div>
        <Heading
          title={t("templateSlideOver.heading.title")}
          description={t("templateSlideOver.heading.description")}
        />
        <Input
          label={t("templateSlideOver.input.title.label")}
          register={register("title", {
            required: t("templateSlideOver.input.title.required")!,
          })}
          errors={errors}
        />
        <TextArea
          description={t("templateSlideOver.input.content.description")}
          rows={10}
          label={t("templateSlideOver.input.content.label")}
          register={register("content")}
          errors={errors}
        />
      </div>
    </SlideOver>
  );
}
