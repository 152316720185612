/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product } from '../models/Product';
import type { ProductCreate } from '../models/ProductCreate';
import type { SearchProductResult } from '../models/SearchProductResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * Search Products
     * @param term
     * @param offset
     * @returns SearchProductResult Successful Response
     * @throws ApiError
     */
    public static searchProductsSearchProductsGet(
        term?: string,
        offset?: number,
    ): CancelablePromise<SearchProductResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/search/products',
            query: {
                'term': term,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Product
     * @param productId
     * @param barcode
     * @returns Product Successful Response
     * @throws ApiError
     */
    public static getProductProductsProductIdGet(
        productId: number,
        barcode?: string,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/{product_id}',
            path: {
                'product_id': productId,
            },
            query: {
                'barcode': barcode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Product
     * @param productId
     * @param requestBody
     * @returns Product Successful Response
     * @throws ApiError
     */
    public static updateProductProductsProductIdPut(
        productId: number,
        requestBody: ProductCreate,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/{product_id}',
            path: {
                'product_id': productId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Query Product
     * @param barcode
     * @param productId
     * @returns Product Successful Response
     * @throws ApiError
     */
    public static queryProductProductsGet(
        barcode?: string,
        productId?: number,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products',
            query: {
                'barcode': barcode,
                'product_id': productId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Product
     * @param requestBody
     * @returns Product Successful Response
     * @throws ApiError
     */
    public static createProductProductsPost(
        requestBody: ProductCreate,
    ): CancelablePromise<Product> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

}
