import React, { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ErrorMessage } from "@hookform/error-message";
import { InputProps } from "./Input";

interface TimeInputProps extends InputProps {}

export function TimeInput(props: TimeInputProps) {
  const [color, setColor] = useState(
    "text-gray-900 ring-gray-300 placeholder:text-gray-300 focus:ring-gray-500"
  );
  const [name, setName] = useState(props.name);

  return (
    <div className={props.className}>
      <label
        htmlFor="email"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="time"
          id={props.id}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          className={`px-2 block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${color}`}
          placeholder={props.placeholder || undefined}
          aria-invalid={!!props.errorText}
          aria-describedby={`${name}-error`}
          required
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {props.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {props.description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">
          {props.description}
        </p>
      )}
      <div>
        <ErrorMessage
          errors={[props.error]}
          name={name}
          render={({ message }) => (
            <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
              {message}
            </p>
          )}
        />
      </div>
    </div>
  );
}
