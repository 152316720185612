import { Button, FormSection } from "../../../../Tailwind";
import { TextArea } from "../../../../Tailwind/Input/Uncontrolled";
import { Client, FullSessionInDb } from "../../../../api/staff";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppApi } from "../../../../api/staff/AppApi";

export function ClientNoteModal({
  client,
  onCancel,
  onClientUpdated,
}: {
  client: Client;
  onCancel: () => void;
  onClientUpdated: (client: Client) => void;
}) {
  const [note, setNote] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation("application");
  const api = useAppApi();

  function saveNote() {
    setSaving(true);
    api.clients
      .createClientNoteClientsClientIdNotesPost(client.id, { text: note })
      .then((s) => {
        setSaving(false);
        setNote("");
        onClientUpdated(s);
      });
  }

  return (
    <div>
      <FormSection
        title={t("clientNoteModal.heading.title")}
        subtitle={t("clientNoteModal.heading.subtitle")}
      />
      <TextArea
        value={note}
        rows={10}
        name={"note"}
        onChange={(t) => {
          setNote(t);
        }}
      />
      <div className="flex gap-2 justify-end mt-4">
        <Button disabled={note === "" || saving} onClick={saveNote}>
          {t("clientNoteModal.button.save")}
        </Button>
        <Button color="secondary" onClick={onCancel}>
          {t("clientNoteModal.button.cancel")}
        </Button>
      </div>
    </div>
  );
}
