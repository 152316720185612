import { Button, Heading, LoadingOverlay, Modal } from "../../../Tailwind";
import React, { useEffect, useState } from "react";
import { AuthLayout, VideoBackground } from "./AuthLayout";
import { Input } from "../../../Tailwind/Input/Uncontrolled";
import { useTranslation } from "react-i18next";
import { useAppApi } from "../../../api/staff/AppApi";
import { isValidEmail, useQueryToken } from "../../../lib/utils";
import { useFeedback } from "../../../lib/useFeedback";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export function ResetPassword() {
  const [video, setVideo] = useState<VideoBackground>("Ink");
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const token = useQueryToken();
  const feedback = useFeedback();

  const [lengthError, setLengthError] = useState("");
  const [matchError, setMatchError] = useState("");

  const { t } = useTranslation("application");
  const api = useAppApi();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (password.length > 7) {
      setLengthError("");
      return;
    }
    if (password === passwordRepeat) {
      setMatchError("");
      return;
    }
  }, [password, passwordRepeat]);

  return (
    <>
      <LoadingOverlay loading={loading} size="xs" />
      <AuthLayout title={t("resetPassword.title")} video={video}>
        <div className="space-y-6">
          <Input
            dark
            name={"password"}
            value={password}
            placeholder={t("resetPassword.input.password.placeholder")}
            onChange={(event) => setPassword(event.target.value)}
            description={t("resetPassword.input.password.description")}
            label={t("resetPassword.input.password.label")}
            error={lengthError}
            type="password"
            autoComplete="password"
          />
          <Input
            dark
            name={"passwordRepeat"}
            value={passwordRepeat}
            placeholder={t("resetPassword.input.passwordRepeat.placeholder")}
            onChange={(event) => setPasswordRepeat(event.target.value)}
            description={t("resetPassword.input.passwordRepeat.description")}
            label={t("resetPassword.input.passwordRepeat.label")}
            error={matchError}
            type="password"
            autoComplete="password"
          />
          <Button
            dark
            full
            className="flex w-full justify-center"
            onClick={() => {
              if (password.length < 8) {
                setLengthError(t("resetPassword.error.passwordLength")!);
                return;
              }
              if (password !== passwordRepeat) {
                setMatchError(t("resetPassword.error.passwordMatch")!);
                return;
              }
              api.users
                .resetPasswordsUsersResetPasswordPost(token, {
                  password: password,
                })
                .then(() => {
                  feedback.showSuccessToast(t("resetPassword.toast.success"));
                  setVisible(true);
                })
                .catch((err) => {
                  feedback.handleErrors(err);
                })
                .finally(() => {
                  setPassword("");
                  setPasswordRepeat("");
                  setLengthError("");
                  setMatchError("");
                });
            }}
          >
            {t("resetPassword.button.getOpt")}
          </Button>
        </div>
      </AuthLayout>
      <SuccessModal visible={visible} />
    </>
  );
}

function SuccessModal(props: { visible: boolean }) {
  const { t } = useTranslation("application");
  const navigate = useNavigate();

  return (
    <Modal visible={props.visible} size={"md"}>
      <div className="space-y-6">
        <Heading
          title={t("successModal.heading.title")}
          description={t("successModal.heading.description")}
        />
        <Button
          full
          onClick={() => {
            navigate("/signin");
          }}
        >
          {t("successModal.button.gotoSignInSignUp")}
        </Button>
      </div>
    </Modal>
  );
}
