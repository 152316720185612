import React, { useEffect, useState } from "react";
import { OnboardingContainer } from "./OnboardingContainer";
import { ProtectedPage } from "../ProtectedPage";
import { OnboardingHeader } from "./OnboardingHeader";

import { ActionPanelTopRight, Link } from "Tailwind";
import { useTranslation } from "react-i18next";
import { UsersList } from "../Pages/Users/UsersList";

export function UsersOnboarding() {
  const { t } = useTranslation("application");

  return (
    <ProtectedPage account>
      <OnboardingHeader />
      <OnboardingContainer>
        <ActionPanelTopRight
          title={""}
          subTitle={""}
          actions={[
            <Link key={0} to={"/onboarding/website"}>
              <span aria-hidden="true">←</span> {t("usersOnboarding.link.back")}
            </Link>,
            <Link key={1} to={"/onboarding/business-hours"}>
              {t("usersOnboarding.link.continue")}{" "}
              <span aria-hidden="true">→</span>
            </Link>,
          ]}
        >
          <>
            <div className={"my-10"} />
            <UsersList />
          </>
        </ActionPanelTopRight>
      </OnboardingContainer>
    </ProtectedPage>
  );
}
