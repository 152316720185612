import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { calendarType } from "./CalendarPage";
import { useTranslation } from "react-i18next";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function CalendarSelector(props: {
  currentCalendar: calendarType;
  onSwitchCalendar: (type: calendarType) => void;
}) {
  const { t } = useTranslation("application");
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        type="button"
        className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {props.currentCalendar === "month" && t("calendarSelector.text.month")}
        {props.currentCalendar === "week" && t("calendarSelector.text.week")}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => props.onSwitchCalendar("week")}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm w-full"
                  )}
                >
                  {t("calendarSelector.text.week")}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => props.onSwitchCalendar("month")}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm w-full"
                  )}
                >
                  {t("calendarSelector.text.month")}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
