import React from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function Ink2(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696435486/app/jumpstory-download20231004-160423_asokfe.mp4"
      }
    />
  );
}
