import React, { Fragment } from "react";
import { BackgroundVideo } from "./BackgroundVideo";

export function SkincareSpecialist(props: { show: boolean }) {
  return (
    <BackgroundVideo
      show={props.show}
      url={
        "https://res.cloudinary.com/dpxaexudg/video/upload/q_50/v1696449026/app/pexels-ron-lach-7582851_2160p_ccbhbb.mp4"
      }
    />
  );
}
