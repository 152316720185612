import React, { useContext, useEffect, useRef, useState } from "react";
import { ServiceSection } from "../sections/WebshopSection/booking_page/ServiceSection";
import { StaffSection } from "../sections/WebshopSection/booking_page/StaffSection";
import { BookingCalendarSection } from "../sections/WebshopSection/booking_page/BookingCalendarSection";
import { ConfirmSection } from "../sections/WebshopSection/booking_page/ConfirmSection";
import { ClientVerification } from "../sections/WebshopSection/booking_page/ClientVerification";
import { AboutSection } from "../sections/WebshopSection/booking_page/AboutSection";
import { Button } from "./Button";
import { BookingCalendar, CalendarDay, Service, Staff } from "../api/webshop";
import { Settings } from "../lib/settings";
import { useWebshopApi } from "../api/webshop/WebshopApi";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Phone } from "../Tailwind/Input/Uncontrolled";

export function BookingComponent(props: { compact: boolean }) {
  const [selectedStaff, setSelectedStaff] = useState<Staff>();
  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [bookingCalendar, setBookingCalendar] = useState<CalendarDay[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [availableService, setAvailableService] = useState<Service[]>([]);
  const [availableStaff, setAvailableStaff] = useState<Staff[]>([]);

  const [selectedTimeslot, setSelectedTimeslot] = useState<Date>();

  const [maxDate, setMaxDate] = useState<Date>();

  const staffRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<HTMLInputElement>(null);
  const completeRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("webshop");

  const api = useWebshopApi();

  useEffect(() => {
    if (api.webshop?.booking_page.booking_limit_max_days) {
      const dt = new Date();
      dt.setDate(
        dt.getDate() + api.webshop?.booking_page.booking_limit_max_days
      );
      setMaxDate(dt);
    }
  }, [api.webshop]);

  useEffect(() => {
    fetchServices();
  }, [selectedStaff]);

  useEffect(() => {
    fetchStaff();
  }, [selectedServices]);

  useEffect(() => {
    if (selectedStaff !== undefined && selectedServices.length > 0) {
      fetchCalendar();
      calendarRef.current!.scrollIntoView({ behavior: "smooth" });
    } else {
      setBookingCalendar([]);
    }
  }, [selectedStaff, selectedServices, selectedDate]);

  useEffect(() => {}, [selectedStaff]);

  useEffect(() => {}, [selectedStaff]);

  useEffect(() => {
    if (selectedTimeslot !== undefined) {
      completeRef.current!.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedTimeslot]);

  useEffect(() => {
    if (!!api.client && selectedTimeslot !== undefined) {
      completeRef.current!.scrollIntoView({ behavior: "smooth" });
    }
  }, [api.client]);

  function fetchServices() {
    api.api.getServicesServicesGet(selectedStaff?.id).then((services) => {
      setAvailableService(services);
    });
  }

  function fetchStaff() {
    api.api.getServicesStaffGet(selectedServices).then((staff) => {
      setAvailableStaff(staff);
      if (staff.length === 1 && !selectedStaff) {
        setSelectedStaff(staff[0]);
      }
    });
  }

  function fetchCalendar() {
    const services = selectedServices.map((id) => id);
    const staff = selectedStaff ? [selectedStaff?.id] : [];
    api.api
      .getBookingCalendarBookingCalendarGet(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        services,
        staff
      )
      .then((calendars) => {
        setBookingCalendar(
          calendars.find((c) => c.staff.id === selectedStaff?.id)!.calendar
        );
      });
  }

  const cols = "grid-cols-3";
  const description = api.webshop?.booking_page.description || "";

  const [phone, setPhone] = useState<string>("");

  return (
    <>
      <div
        className={
          "mt-8 grid gap-6 mx-auto lg:max-w-7xl lg:grid-flow-col-dense " + cols
        }
      >
        <h1 className="col-span-3 col-start-1 text-2xl font-bold leading-tight tracking-tight text-slate-900">
          {t("bookingComponent.title")}
        </h1>
        {description && (
          <section aria-labelledby="Service" className="col-span-3 col-start-1">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <span
                  className="text-slate-700 whitespace-pre-line"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </div>
          </section>
        )}
        <section aria-labelledby="Service" className="col-span-3 col-start-1">
          <ServiceSection
            availableServices={availableService}
            selectedServices={selectedServices}
            serviceClicked={(service) => {
              const index = selectedServices.indexOf(service.id);
              if (index >= 0) {
                setSelectedServices(
                  selectedServices.filter((s) => s !== service.id)
                );
              } else {
                setSelectedServices(selectedServices.concat(service.id));
              }
            }}
          />
        </section>
        <section
          ref={staffRef}
          aria-labelledby="Staff"
          className="col-span-3 col-start-1"
        >
          <StaffSection
            availableStaff={availableStaff}
            selectedStaff={selectedStaff}
            staffClicked={(staff) => {
              if (selectedStaff && staff.id === selectedStaff.id) {
                setSelectedStaff(undefined);
              } else {
                setSelectedStaff(staff);
              }
            }}
          />
        </section>
        <section
          ref={calendarRef}
          aria-labelledby="Calendar"
          className="col-span-3 col-start-1"
        >
          <BookingCalendarSection
            maxDate={maxDate}
            bookingCalendar={bookingCalendar}
            selectedDate={selectedDate}
            onDateChanged={setSelectedDate}
            selectedTimeslot={selectedTimeslot}
            timeslotSelected={setSelectedTimeslot}
            button={
              selectedServices.length > 0 &&
              selectedStaff && (
                <NextAvailableDateButton
                  employeeId={selectedStaff.id}
                  availableDateFound={(date) => setSelectedDate(date)}
                  duration={availableService
                    .filter((s) => {
                      return selectedServices.find((sel) => sel === s.id);
                    })
                    .map((s) => s.duration)
                    .reduce((a, b) => a + b, 0)}
                />
              )
            }
          />
        </section>
        <div className="col-span-3 col-start-1" ref={completeRef} />
        {selectedTimeslot && (
          <section
            aria-labelledby="Complete"
            className="col-span-3 col-start-1"
          >
            {api.client && selectedStaff ? (
              <ConfirmSection
                services={availableService.filter((s) => {
                  return selectedServices.find((sel) => sel === s.id);
                })}
                timeslot={selectedTimeslot}
                staff={selectedStaff}
              />
            ) : (
              <ClientVerification onComplete={() => {}} />
            )}
          </section>
        )}
        <section
          aria-labelledby="Information"
          className="col-span-3 col-start-1"
        >
          <AboutSection />
        </section>
      </div>
    </>
  );
}

function NextAvailableDateButton({
  duration,
  employeeId,
  availableDateFound,
}: {
  duration: number;
  employeeId: number;
  availableDateFound: (date: Date) => void;
}) {
  const api = useWebshopApi();
  const { t } = useTranslation("webshop");

  return (
    <Button
      onClick={() => {
        api.api
          .nextAvailableDateBookingNextAvailableDateGet(duration, employeeId)
          .then((response) => {
            availableDateFound(new Date(response.date));
          })
          .catch((reason) => {
            alert(reason.message);
          });
      }}
      text={t("nextAvailableDateButton.button")}
    />
  );
}
