import { useLocale } from "../../../lib/locale";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { toast } from "react-toastify";
import Confirm from "../../../components/Confirm";
import { FullSessionInDb } from "../../../api/webshop";
import { useWebshopApi } from "../../../api/webshop/WebshopApi";

export function UpcomingBookingHeader({
  session,
}: {
  session: FullSessionInDb;
}) {
  const locale = useLocale();
  const navigate = useNavigate();
  const api = useWebshopApi();
  const [canCancel, setCanCancel] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (session) {
      checkCanCancel();
      const interval = setInterval(() => {
        checkCanCancel();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [session]);

  function confirmCancel() {
    api.api.cancelSessionSessionSessionIdDelete(session.id).then(() => {
      navigate("/my");
      toast.info("Aftale slettet", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }

  function checkCanCancel() {
    if (session && api.webshop) {
      const start = new Date(session.start!).getTime();
      const now = new Date().getTime();
      const limit =
        start -
        api.webshop?.booking_page.cancel_limit_max_hours * 60 * 60 * 1000;
      if (now < limit) {
        setCanCancel(true);
      } else {
        setCanCancel(false);
      }
    } else {
      setCanCancel(false);
    }
  }

  return (
    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
      <div>
        <h1 className="text-lg font-medium text-gray-900">Kommende aftale</h1>
        {session?.start && (
          <p className="mt-1 text-sm text-gray-500">
            Du har en aftale hos {session?.user_role?.user.name}{" "}
            <span className={"font-bold"}>
              {locale.localeDateWithDay(new Date(session?.start))}
            </span>{" "}
            klokken{" "}
            <span className={"font-bold"}>
              {locale.timeShort(new Date(session?.start))}
            </span>{" "}
            til{" "}
            <span className={"font-bold"}>
              {locale.timeShort(new Date(session?.end!))}
            </span>
          </p>
        )}
      </div>
      <div className="mt-3 flex sm:mt-0 sm:ml-4 flex-col">
        <Button
          disabled={!canCancel}
          onClick={() => {
            setShowConfirm(true);
          }}
          text={"Aflys aftale"}
          color="danger"
        />
        {!canCancel && (
          <span className="text-xs text-gray-500">
            Kontakt behandler for at aflyse
          </span>
        )}
      </div>
      {showConfirm && (
        <Confirm
          title={"Vil du annulere aftalen?"}
          description={""}
          declineText={"Nej"}
          confirmText={"Ja"}
          onConfirm={() => {
            confirmCancel();
          }}
          onCancel={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}
