import { Template } from "../../../../api/staff";
import { Listbox, Transition } from "@headlessui/react";
import { Font } from "../../../../Tailwind";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";
import { useAppApi } from "../../../../api/staff/AppApi";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export function TemplateSelect(props: {
  selected?: Template;
  onSelect: (template?: Template) => void;
}) {
  const [templates, setTemplates] = useState<Template[]>([]);

  const { t } = useTranslation("application");
  const api = useAppApi();

  useEffect(() => {
    api.misc.getAllTemplatesTemplatesGet().then((t) => {
      setTemplates(t);
    });
  }, []);
  return (
    <Listbox
      multiple={false}
      value={props.selected}
      onChange={(s) => {
        props.onSelect(s);
      }}
    >
      {({ open }) => (
        <>
          <div className="relative w-full sm:w-72">
            <Listbox.Button className="w-full h-9 flex justify-center flex-col cursor-default rounded-md bg-white py-1.5 pl-3 pr-1 md:pl-3 md:pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <div className="flex items-center justify-between">
                {!props.selected ? (
                  <>
                    <span className="inline-flex w-full">
                      <Font>{t("templateSelect.text.selectTemplate")}</Font>
                    </span>
                  </>
                ) : (
                  <div className="mr-0.5">
                    <span className="text-xs leading-none">
                      {props.selected?.title}
                    </span>
                  </div>
                )}
                <span className="pointer-events-none absolute right-0 items-center pr-2 flex flex-row justify-center">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  key={"first"}
                  className={({ active }) =>
                    active
                      ? "bg-indigo-500 text-white relative cursor-default select-none py-2 pl-3 pr-9"
                      : "text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                  }
                  value={undefined}
                >
                  <span className="ml-2 font-normal truncate">
                    {t("templateSelect.option.none")}
                  </span>
                </Listbox.Option>
                {templates.map((template) => (
                  <Listbox.Option
                    key={template.id}
                    className={({ active }) =>
                      active
                        ? "bg-indigo-500 text-white relative cursor-default select-none py-2 pl-3 pr-9"
                        : "text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                    }
                    value={template}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={
                              selected
                                ? "ml-2 font-semibold truncate"
                                : "ml-2 font-normal truncate"
                            }
                          >
                            {template.title}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={
                              active
                                ? "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                : "text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
