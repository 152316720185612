import { Layout } from "../../Layout/Layout";
import { ServicesList } from "./ServicesList";

export function ServicesPage() {
  return (
    <Layout active={"services"}>
      <ServicesList />
    </Layout>
  );
}
